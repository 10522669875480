import Request from '../../utils/Request';
import Env from '../../Env';
import _ from 'lodash';

export const SET_MULTICALENDAR = 'SET_MULTICALENDAR';
export const FIND_AVAILABLITY = 'FIND_AVAILABLITY';
export const ADD_UNAVAILABLE_BLOCK = 'ADD_UNAVAILABLE_BLOCK';
export const EDIT_UNAVAILABLE_BLOCK = 'EDIT_UNAVAILABLE_BLOCK';
export const CHANGE_PRICE_AND_MIN_NIGHTS = 'CHANGE_PRICE_AND_MIN_NIGHTS';

export const getDates = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const result = await Request.get(`${Env.dbServerUrl}/calendar`, token);
        return result;
    };
};

export const getCalendar = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
      const result = await Request.get(`${Env.dbServerUrl}/multicalendar`, token);
        return result;
    };
};

export const searchCalendar = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
      const result = await Request.get(`${Env.dbServerUrl}/multicalendar`, token);
        return result;
    };
};

export const searchAllListings = (search) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
      const result = await Request.get(`${Env.dbServerUrl}/listings?q=${search}`, token);
        return result;
    };
};

// might have to add get custom stay by Listing ID
export const getCustomStay = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/custom_stay`, token)
        return result;
    };
};

export const setMultiCalendar = (multicalendar) => {
    return async (dispatch, getState) => {
        const newMultiCalendar = _.cloneDeep(multicalendar);
        dispatch({
            type: SET_MULTICALENDAR,
            multicalendar: newMultiCalendar,
        });
    };
};

export const findAvailability = (multicalendar, dates, filters) => {
    return async (dispatch, getState) => {
        // const token = getState().auth.token;
        // const result = await Request.put(`http://localhost:4000/multicalendar`, newSettings, token);
        // if ok
        const availableDates = _.cloneDeep(multicalendar);
        const newDates = dates;
        const duration =
            (Date.parse(newDates.end_date) - Date.parse(newDates.start_date)) / 86400000;
        const newFilters = _.cloneDeep(filters);

        const multicalendarFreeDates = availableDates.reduce((acc, mltc) => {
            if (
                mltc.capacity >= newFilters.guests &&
                mltc.calendar.filter(
                    (c) =>
                        c.date >= newDates.start_date &&
                        c.end_date <= newDates.end_date &&
                        c.status === 'available'
                ).length > duration
            ) {
                acc.push(mltc);
            }
            return acc;
        }, []);

        dispatch({
            type: FIND_AVAILABLITY,
            multicalendar: multicalendarFreeDates,
        });
    };
};

export const addUnavailableBlock = (listing, unavailableBlock, multicalendar) => {
    return async (dispatch, getState) => {
        const newMultiCalendar = _.cloneDeep(multicalendar);
        const newListing = _.cloneDeep(listing);
        const block = _.cloneDeep(unavailableBlock);

        const newCalendar = newListing.calendar.map((cal) => {
            if (cal.date < block.start_date || cal.date > block.end_date) {
                return { ...cal, removed: false };
            }
            if (cal.date === block.date) {
                return { ...cal, ...block };
            } else return { ...cal, removed: true, status: 'unavailable' };
        });

        dispatch({
            type: ADD_UNAVAILABLE_BLOCK,
            multicalendar: newMultiCalendar.map((l) => {
                if (l.id === newListing.id) {
                    l.calendar = newCalendar;
                    return l;
                } else {
                    return l;
                }
            }),
        });
    };
};

export const editUnavailableBlock = (listing, unavailableBlock, multicalendar) => {
    return {
        type: EDIT_UNAVAILABLE_BLOCK,
        payload: {
            listing,
            unavailableBlock,
            multicalendar,
        },
    };
};

export const changePriceAndMinNights = (listing, newSettings, multicalendar) => {
    return async (dispatch, getState) => {
        // const token = getState().auth.token;
        // const result = await Request.put(`http://localhost:4000/multicalendar`, newSettings, token);
        // if ok
        const newMultiCalendar = _.cloneDeep(multicalendar);
        const newInfo = _.cloneDeep(newSettings);
        const newListing = _.cloneDeep(listing);

        const newCalendar = newListing.calendar.map((cal) => {
            if (cal.date < newInfo.date || cal.date > newInfo.end_date) {
                return { ...cal };
            }
            if (cal.date >= newInfo.date && cal.date <= newInfo.end_date) {
                return {
                    ...cal,
                    ...newInfo,
                    date: cal.date,
                    end_date: cal.end_date,
                    id: cal.id,
                };
            } else return { ...cal };
        });

        newMultiCalendar.map((l) => {
            if (l.id === newListing.id) {
                l.calendar = newCalendar;
                return l;
            } else {
                return l;
            }
        });

        dispatch({ type: CHANGE_PRICE_AND_MIN_NIGHTS, multicalendar: newMultiCalendar });
    };
};
