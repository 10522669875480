import { useSelector } from 'react-redux';

import DudaTemplateItem from './DudaTemplateItem';

const DudaTemplatesSection = (props) => {

  const templates = useSelector((state) => state.template.templates);

  return (
    <>
      <div className="templates">
      {templates.map((template)=>{
            return(
                <DudaTemplateItem key={template.id} template={template}/>
            )
        })}
      </div>
    </>
  );
};

export default DudaTemplatesSection;
