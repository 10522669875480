import React from 'react';
import { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import '../css/ListHeaderViewManager.css'

import * as listActions from '../../../store/actions/list';

const ListHeaderViewsManager = React.forwardRef((props, ref) => {
  const currentView = useSelector((state) => state.view.currentView);
  const allViews = useSelector((state) => state.view.views);
  const [showViews, setShowViews] = useState(false);
  const [selectable, setSelectable] = useState(false);

  let history = useHistory();
  const dispatch = useDispatch();

  const changeView = async (viewId) => {
    setShowViews(false);
    const c = allViews.filter((v) => {
      return v.id === viewId;
    });
    if (c.length) {
      history.replace({search: `?v=${c[0].id}`, state:`?v=${JSON.stringify(c[0].id)}`});
       dispatch(listActions.setPageNumber(1));
       dispatch(listActions.setSelectedView(c[0].id));
    }
  };

  const handleCurrentViewClick = () => {
    setShowViews(!showViews);
  };

  const favView = async (viewId) => {
    try {
      dispatch(listActions.setFavoriteView(viewId));
    } catch (e) {
      console.error(e);
    }
  };

  const showTitleOnly = () => {
    setSelectable(false);
  };

  const showSelect = () => {
    setSelectable(true);
  };

  const mineViews = allViews.filter((v) => {
    return v.isMine;
  });

  const sharedViews = allViews.filter((v) => {
    return !v.isMine;
  });

  return (
    <div onMouseEnter={showSelect} onMouseLeave={showTitleOnly} style={{ display: 'table' }}>
      <Fragment>
        <div className="choose-view-container">
          <button
            className={'list-current-view' + (showViews ? ' menu-active' : '')}
            onClick={handleCurrentViewClick}
          >
            {currentView.name}
            <i className="fa fa-sort-desc"></i>
          </button>

          {showViews && (
            <div className="list-view-selector">
              {mineViews.length > 0 && (
                <Fragment>
                  <div className="view-category">Created by me</div>
                  {mineViews.map((view) => {
                    return (
                      <div key={view.id} className="list-view-option">
                        <a onClick={() => favView(view.id)}>
                          <i
                            className={classNames('far', 'mr-5', {
                              'fa-star': view.favourite,
                              'fa-star': !view.favourite,
                            })}
                          ></i>
                        </a>
                        <a onClick={() => changeView(view.id)}>{view.name}</a>
                      </div>
                    );
                  })}
                  <div className="view-category">Shared with me</div>
                </Fragment>
              )}

              {sharedViews.map((view) => {
                return (
                  <div key={view.id} className="list-view-option">
                    <a onClick={() => favView(view.id)}>
                      <i
                        className={classNames('far', 'mr-5', {
                          'fa-star': view.favourite,
                          'fa-star': !view.favourite,
                        })}
                      ></i>
                    </a>
                    <a onClick={() => changeView(view.id)}>{view.name}</a>
                  </div>
                );
              })}
              {!window.ownerURL && (
                <div>
                  <a href={`/${props.objectType}/add`} className="create-new-view">
                    Create view
                  </a>
                </div>
              )}
            </div>
          )}
        </div>

        {(selectable && parseInt(currentView.default) === 0) &&
				<a href={`/${props.objectType}/modify/${currentView.id}`}>
					<i className="pencil fa fa-pencil"></i>
				</a>
				}
      </Fragment>
    </div>
  );
});

export default enhanceWithClickOutside(ListHeaderViewsManager);
