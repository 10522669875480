import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListTable from './table/ListTable';
import FSTablePagination from '../../components/elements/FSTablePagination.js';
import FSCheckbox from '../../components/elements/FSCheckbox.js';
import './css/ListTableSection.css';

import * as listActions from '../../store/actions/list';

const ListTableSection = (props) => {
  const selectedColumns = useSelector((state) => state.list.selectedColumns);
  const items = useSelector((state) => state.list.items);
  const selectedIds = useSelector((state) => state.list.selectedIds);
  const dispatch = useDispatch();
  const [sort, setSort] = useState('asc');
  const [colField, setColField] = useState('');
  const [icon, setIcon] = useState('fa fa-sort-amount-asc');

  const handleClick = (column, ascDesc) => {
    let sort = ascDesc === 'asc' ? 'desc' : 'asc';
    let icon = ascDesc === 'asc' ? 'fa fa-sort-amount-desc' : 'fa fa-sort-amount-asc';

    setSort(sort);
    setIcon(icon);
    setColField(column.field);
    handleOrderBy(column.field, ascDesc, column.prefix);
  };

  const handleOrderBy = async (orderBy, ascDesc, prefix) => {
    prefix = orderBy === 'confirmation_code' ? 'r' : prefix;
    orderBy = orderBy === 'listing_name' ? 'name' : orderBy;
    orderBy = orderBy === 'fs_integration_id' ? 'nickname' : orderBy;

    if (prefix) {
      orderBy = prefix + '.' + orderBy;
    }
    dispatch(listActions.setOrder(orderBy));
    dispatch(listActions.setOrderDirections(ascDesc));
    dispatch(listActions.getItems(props.objectType));
  };
  const handleSelectAllIds = () => {
    let allSelectedIds;
    if (selectedIds.length === items.length) {
      allSelectedIds = [];
    } else {
      allSelectedIds = items.map((i) => {
        if (i.id) return i.reservation_id;
        else return i.listing_id;
      });
    }
    dispatch(listActions.setSelectedIds(allSelectedIds));
  };

  return (
    <>
      <div className="scrollable">
        <div className="list-table-container">
          <div className="table-responsive">
            <table className="table table-hover mb-5">
              <thead>
                <tr>
                  <th>
                    {props.objectType === 'reservation' || props.objectType === 'listing' ? (
                      <FSCheckbox
                        className="select-all "
                        type="checkbox"
                        onChange={() => handleSelectAllIds()}
                        checked={selectedIds?.length === items?.length}
                      />
                    ) : null}
                  </th>
                  {selectedColumns.map((column, index) => {
                    return (
                      <th key={index}>
                        {column.name}
                        <span className="sort-column">
                          <a
                            onClick={() => {
                              handleClick(column, column.field === colField ? sort : 'asc');
                            }}
                          >
                            {column.sortable && (
                              <i
                                className={
                                  column.field === colField ? icon : 'fa fa-sort-amount-asc'
                                }
                              ></i>
                            )}
                          </a>
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <ListTable selectedIds={selectedIds} objectType={props.objectType} />
            </table>
          </div>
        </div>
      </div>
      {props.pagination && <FSTablePagination objectType={props.objectType} />}
    </>
  );
};

export default ListTableSection;
