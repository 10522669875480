import React from 'react';

const ListTableItemTags = (props) => {
  if (!props.item.tags || !props.item.tags.length) {
    return '';
  }

  const tags = props.item.tags.map(el => el.tag);

  return (
    <React.Fragment>
      {tags.map((tag, index) => {

        return (
          <span key={index} className="label label-sm label-success">
            {tag}{' '}
          </span>
        );
      })}
    </React.Fragment>
  );
};

export default ListTableItemTags;
