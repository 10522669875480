import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PagePreloader from '../../components/Base/PagePreloader';
import DudaTemplatesSection from '../../components/apps/duda/DudaTemplatesSection';
import * as templateActions from '../../store/actions/templates';
import '../../components/apps/duda/templates.scss';
import { useHistory } from 'react-router';
import TechnicalError from '../TechnicalError/TechnicalError';

const DudaTemplatesPage = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);

  const permissions = useSelector((state) => state.auth.permissions);

  const history = useHistory();
  if (!permissions.includes('DudaTemplatesPage')) {
      history.push('/access-denied')
  }

  useEffect(() => {
    const initData = async () => {
      try {
        await dispatch(templateActions.getTemplates());
      } catch (error) {
        setDidPromisesSucceed(false);
      }

      setIsLoaded(true);
      document.title = 'Templates'
    };
    initData();
  }, []);

  if (!isLoaded) {
    return <PagePreloader />;
  }

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  return (
    <>
      <div
        className={`body-wrapper ${
          user?.user_metadata?.navigation_layout === 'Vertical' ? 'body-wrapper-two' : ''
        }`}
      >
        <DudaTemplatesSection />
        {/* <FSTemplatesPagination /> */}
      </div>
    </>
  );
};

export default DudaTemplatesPage;
