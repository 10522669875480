import React from 'react';
import moment from 'moment';
import Flatpickr from 'react-flatpickr'

import 'flatpickr/dist/themes/airbnb.css'

const style = {
    width: '100%',
    lineHeight: '50px'
};

const handleChange = (fn, value) => {
    value = moment(value, 'HH:mm', true).format("HH:mm");
    return fn(value);
}

const FSTimePicker = (props) => {
    let options = {
        disableMobile: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minTime : props.checkIn ? props.checkIn.slice(0, -3) : '',
    }

    const isEmptyValue = typeof props.value !== 'undefined' && (props.value === false || props.value === '');

    let value;

    if (isEmptyValue || typeof props.value === 'undefined' || props.value === 'Invalid date') {
        value = moment().startOf('day').add(12, 'hour');
    } else {
        if (moment(props.value).isValid()) {
            value = props.value;
        } else if (moment(props.value).isValid()){
            value = moment(props.value, 'HH:mm', true).format("HH:mm");
        } else if (moment(props.value, 'HH:mm', true).isValid()){
            value = moment(props.value, 'HH:mm', true).format("HH:mm");
        } else if (moment(props.value, 'HH:mm:ss', true).isValid()) {
            value = moment(props.value, 'HH:mm:ss', true).format("HH:mm:ss");
        } else {
            value = moment().startOf('day').add(12, 'hour').format("HH:mm:ss");
        }
    }

    // let value = typeof props.value !== 'undefined' && moment(props.value, 'HH:mm', true).isValid() ? props.value : moment().startOf('day').add(12, 'hour').toDate();

    return <div style={style} className={props.className}>
        <Flatpickr
            value={isEmptyValue ? '' : value}
            onOpen={() => {
                handleChange(props.onChange, value)
            }}
            onChange={v => {
                handleChange(props.onChange, v[0])
            }}
            options={options}
        />
    </div>

}

export default FSTimePicker;