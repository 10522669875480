import { useSelector } from 'react-redux';

import SitesTableItem from './SitesTableItem';

const SitesTable = (props) => {
  const sites = useSelector((state) => state.site.sites);

  return (
    <div className={props.isMobile ?'sites-items-container row' : 'sites-items-container'}>
      {sites?.length > 0 ? sites.map((item, index) => {
        return <SitesTableItem key={index} item={item} isMobile={props.isMobile}/>;
      }) : (<div>No Results</div>)}
      </div>
  );
};

export default SitesTable;
