import { useEffect, useState, /* useRef */ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Text from '../../components/ui/Text';
import PagePreloader from '../../components/Base/PagePreloader';
import './multicalendar.scss';
import EditAvailabilityMenu from '../../components/MultiCalendar/EditAvailabilityMenu/EditAvailabilityMenu';
import FindAvailabilityMenu from '../../components/MultiCalendar/FindAvailabilityMenu/FindAvailabilityMenu';
import * as multiCalendarActions from '../../store/actions/multiCalendar';
import BulkEditMenu from '../../components/MultiCalendar/BulkEditMenu/BulkEditMenu';
import CustomStay from '../../components/MultiCalendar/CustomStay/CustomStay';
import React from 'react';
import TableMap from '../../components/MultiCalendar/TableMap/TableMap';
import HeaderMenu from '../../components/MultiCalendar/HeaderMenu/HeaderMenu';
import SelectionBox from '../../components/MultiCalendar/SelectionBox/SelectionBox';
import { useHistory } from 'react-router';
import TechnicalError from '../TechnicalError/TechnicalError';

const MultiCalendar = () => {
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dates, setDates] = useState([]);
    const [dateObj, setDateObj] = useState({});
    const [searchTerms, setSearchTerms] = useState([]);
    const [checkedListings, setCheckedListings] = useState([]);
    const [listing, setListing] = useState([]);
    const [showEditAvailability, setShowEditAvailability] = useState(false);
    const [showFindAvailability, setShowFindAvailability] = useState(false);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [showCustomStay, setShowCustomStay] = useState(false);
    const [findAvailabilityResult, setFindAvailabilityResult] = useState(false);
    const [bulkEditButton, setBulkEditButton] = useState(false);
    const [customStayButton, setCustomStayButton] = useState(false);
    const [dateRange, setDateRange] = useState({
        from_date: moment(Date.now()).format('YYYY-MM-DD'),
        to_date: moment(Date.now()).add(7, 'days').format('YYYY-MM-DD'),
    });
    const [resultLimit, setResultLimit] = useState(5); // Used for loading on scroll

    const [notCheckedRowIDs, setNotCheckedRowIDs] = useState([]);

    const [toggleSelectedCells, setToggleSelectedCells] = useState(false);
    const [selectionMode, setSelectionMode] = useState(false);

    const [startCoordinates, setStartCoordinates] = useState([]);
    const [endCoordinates, setEndCoordinates] = useState([]);

    const permissions = useSelector((state) => state.auth.permissions);

    const history = useHistory();
    if (!permissions.includes('multicalendar.read')) {
        history.push('/access-denied')
    }

    const setEndDate = (date) => {
        if (dateObj.start_date > date && selectionMode) {
            setDateObj({ ...dateObj, end_date: date });
        } else {
            setDateObj({ ...dateObj, end_date: date });
        }
    };

    const toggleEditAvailability = () => {
        if (dateObj.status !== undefined) {
            setDateObj({});
        }
        setShowEditAvailability(!showEditAvailability);
    };

    const toggleFindAvailability = () => setShowFindAvailability(!showFindAvailability);
    const toggleResultFindAvailability = () => {
        if (findAvailabilityResult) {
            clearFindAvailabilityResult();
        }
        setFindAvailabilityResult(!findAvailabilityResult);
    };
    const toggleBulkEdit = () => setShowBulkEdit(!showBulkEdit);
    const toggleCustomStay = () => setShowCustomStay(!showCustomStay);

    const dispatch = useDispatch();
    const multicalendar = useSelector((state) => state.multicalendar);

    useEffect(() => {
        const getCurrentCalendar = async () => {
            try {
                const result = await dispatch(multiCalendarActions.getDates());
                setDates(result);
            } catch (error) {
                setDidPromisesSucceed(false);
            }
        };

        getCurrentCalendar();

        const getMultiCalendar = async () => {
            try {
                const result = await dispatch(multiCalendarActions.searchCalendar());
            
                dispatch(multiCalendarActions.setMultiCalendar(result));
                setNotCheckedRowIDs(
                    result.map((ml) => {
                        if (ml.multiunits) {
                            return [ml.id, ml.multiunits.map((m) => Number(m.id))];
                        } else return ml.id;
                    })
                    .flat(2)
                );
            } catch (error) {
                setDidPromisesSucceed(false);
            }

            setIsLoaded(true);
        };

        getMultiCalendar();
    }, [dispatch]);

    const clearFindAvailabilityResult = () => {
        (async () => {
            const result = await dispatch(multiCalendarActions.getCalendar());
            dispatch(multiCalendarActions.setMultiCalendar(result));

            setIsLoaded(true);
        })();
    };

    if (didPromisesSucceed === false) {
        return <TechnicalError />;
    }

    const numberOfCheckedListings = () => {
        const checklistItems = Array.from(document.getElementsByName('listing-name'));
        if (checklistItems.filter((cl) => cl.checked).length > 0) {
            setBulkEditButton(true);
            setCustomStayButton(true);

            const checkedIDs = checklistItems.filter((cl) => cl.checked).map((cl) => Number(cl.id));
            setCheckedListings(multicalendar.filter((mltc) => checkedIDs.includes(mltc.id)));
        } else {
            setCustomStayButton(false);
            setBulkEditButton(false);
        }
    };

    const unCheckListings = () => {
        const checklistItems = Array.from(document.getElementsByName('listing-name'));

        checklistItems.map((i) => (i.checked = false));
        setBulkEditButton(false);
        setCustomStayButton(false);
        setNotCheckedRowIDs(multicalendar.map((ml) => ml.id));
    };

    const handleScroll = (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + resultLimit / 4;

        if (bottom) {
            setResultLimit(resultLimit + 4);
        }
    };

    const addSearchTags = (e) => {
        if (e.keyCode === 13) {
            if (searchTerms.includes(e.target.value)) {
                document.getElementById('search-box').value = '';
            } else {
                setSearchTerms([...searchTerms, e.target.value]);
                document.getElementById('search-box').value = '';
            }
        } else {
            if (e.type === 'click') {
                console.log(e.target.className === 'btn btn-warning');
                if (e.target.className === 'btn btn-warning') {
                    const newTerm = document.getElementById('search-box').value;
                    if (searchTerms.includes(newTerm)) {
                        document.getElementById('search-box').value = '';
                    } else {
                        setSearchTerms([...searchTerms, newTerm]);
                        document.getElementById('search-box').value = '';
                    }
                } else {
                    setSearchTerms(searchTerms.filter((st) => st !== e.target.value));
                }
            }
        }
    };

    const setMultiCalendarWithSearchTags = (e) => {
        dispatch(
            multiCalendarActions.setMultiCalendar(
                multicalendar.reduce((acc, mlt) => {
                    searchTerms.forEach((st) => {
                        if (mlt.name.toLowerCase().includes(st.toLowerCase())) {
                            acc.push(mlt);
                        }
                    });

                    return acc;
                }, [])
            )
        );

        if (searchTerms.length === 0) {
            const getMultiCalendar = async () => {
                const result = await dispatch(multiCalendarActions.searchCalendar());
                setIsLoaded(true);
                dispatch(multiCalendarActions.setMultiCalendar(result));
            };

            getMultiCalendar();
        }
    };

    const clearCellSelection = () => {
        setToggleSelectedCells(false);
        setSelectionMode(false);
    };
    if (!isLoaded) {
        return <PagePreloader />;
    }

    return (
        <div>
            <div>
                <h1>
                    <Text>MultiCalendar</Text>
                </h1>
                <HeaderMenu
                    addSearchTags={addSearchTags}
                    searchTerms={searchTerms}
                    setMultiCalendarWithSearchTags={setMultiCalendarWithSearchTags}
                    toggleFindAvailability={toggleFindAvailability}
                    clearFindAvailabilityResult={clearFindAvailabilityResult}
                    toggleResultFindAvailability={toggleResultFindAvailability}
                    findAvailabilityResult={findAvailabilityResult}
                    bulkEditButton={bulkEditButton}
                    toggleBulkEdit={toggleBulkEdit}
                    unCheckListings={unCheckListings}
                    checkedListings={checkedListings}
                    toggleCustomStay={toggleCustomStay}
                    customStayButton={customStayButton}
                    setDateRange={setDateRange}
                    dateRange={dateRange}
                />
                <div
                    className={toggleSelectedCells ? 'table-lowligth' : ''}
                    onMouseMove={(e) => {
                        if (selectionMode) {
                            setEndCoordinates([e.pageX, e.pageY]);
                        }
                    }}
                    onMouseUp={() =>
                        setSelectionMode(false) +
                        (() => {
                            if (dateObj.start_date && dateObj.end_date) {
                                setDateObj((dateobj) => {
                                    if (dateobj.start_date > dateobj.end_date) {
                                        return {
                                            ...dateobj,
                                            start_date: dateobj.end_date,
                                            end_date: dateobj.start_date,
                                        };
                                    } else {
                                        return dateobj;
                                    }
                                });
                                setShowEditAvailability(true);
                                setToggleSelectedCells(true);
                            }
                        })()
                    }
                >
                    <div className='table-container' onScroll={(e) => handleScroll(e)}>
                        {selectionMode && (
                            <SelectionBox
                                startCoordinates={startCoordinates}
                                endCoordinates={endCoordinates}
                            />
                        )}
                        <table className='multicalendar-table'>
                            <thead className='table-head'>
                                <tr>
                                    <th scope='col' className='listing-head' colSpan={2}>
                                        <Text>Listing</Text>
                                    </th>
                                    {dates.map((d) => {
                                        return (
                                            <th className='table-headers' key={d.id} scope='col'>
                                                {moment(d.date).format('ddD')}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody
                                className='table-body'
                                // onMouseDown={(e) => {
                                //     setSelectionMode(true);
                                //     setStartCoordinates([e.pageX, e.pageY]);
                                //     setToggleSelectedCells(false);
                                // }}
                            >
                                {multicalendar?.map((mltc) => {
                                    return (
                                        <TableMap
                                            key={mltc.id}
                                            mltc={mltc}
                                            numberOfCheckedListings={numberOfCheckedListings}
                                            dateObj={dateObj}
                                            setDateObj={setDateObj}
                                            setListing={setListing}
                                            listing={listing}
                                            dispatch={dispatch}
                                            setModalEditAvailability={setShowEditAvailability}
                                            toggleEditAvailability={toggleEditAvailability}
                                            notCheckedRowIDs={notCheckedRowIDs}
                                            setNotCheckedRowIDs={setNotCheckedRowIDs}
                                            selectionMode={selectionMode}
                                            setSelectionMode={setSelectionMode}
                                            setEndDate={setEndDate}
                                            setStartCoordinates={setStartCoordinates}
                                            setToggleSelectedCells={setToggleSelectedCells}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showFindAvailability ? (
                <FindAvailabilityMenu
                    toggle={toggleFindAvailability}
                    modal={showFindAvailability}
                    multicalendar={multicalendar}
                    toggleResult={toggleResultFindAvailability}
                />
            ) : (
                <></>
            )}
            {showEditAvailability ? (
                <EditAvailabilityMenu
                    toggle={toggleEditAvailability}
                    modal={showEditAvailability}
                    dateObj={dateObj}
                    listing={listing}
                    clearCellSelection={clearCellSelection}
                />
            ) : (
                <></>
            )}
            {showBulkEdit ? (
                <BulkEditMenu
                    toggle={toggleBulkEdit}
                    modal={showBulkEdit}
                    checkedListings={checkedListings}
                />
            ) : (
                <></>
            )}
            {showCustomStay ? (
                <CustomStay
                    modal={showCustomStay}
                    toggle={toggleCustomStay}
                    checkedListings={checkedListings}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default MultiCalendar;
