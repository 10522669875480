import Request from '../../utils/Request';
import Env from '../../Env';
import { SET_LISTINGS } from '../actions/listing';
export const SET_SITES = 'SET_SITES';
export const SET_IDS = 'SET_IDS';
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE';
export const SET_PAGE = 'SET_PAGE';
export const SET_SELECTED_IDS = 'SET_SELECTED_IDS';
export const SET_LISTING_ACCOUNTS = 'SET_LISTING_ACCOUNTS';
export const SET_DEFAULT_STRIPE_ACCOUNTS = 'SET_DEFAULT_STRIPE_ACCOUNTS';
export const SET_DEFAULT_STRIPE_ACCOUNT_ID = 'SET_DEFAULT_STRIPE_ACCOUNT_ID';
export const SET_PAYMENT_SCHEDULES = 'SET_PAYMENT_SCHEDULES';

export const getSites = (name, token) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const page = getState().site.page;
        const itemsPerPage = getState().site.itemsPerPage;
        const customerId = getState().auth.customer.id;

        const sites = await Request.get(
            `${Env.apiDomainDuda}/sites/all-customer-sites?page=${page}&pageSize=${itemsPerPage}&customerId=${customerId}&name=${name}`,
            token
        );
        dispatch({ type: SET_SITES, sites: sites.sites, total: sites.sitesCount });
    };
};
export const updateSiteListings = (data) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const sites = await Request.post(
            `${Env.apiDomainDuda}/sites/update-collection-listings`,
            data,
            token
        );
        return sites;
    };
};
export const setPageNumber = (page) => {
    return async (dispatch, getState) => {
        await dispatch({ type: SET_PAGE, page: page });
    };
};
export const setItemsPerPage = (number) => {
    return async (dispatch, getState) => {
        await dispatch({ type: SET_ITEMS_PER_PAGE, itemsPerPage: number });
    };
};
export const getAccounts = (siteName) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const accounts = await Request.get(
            `${Env.apiDomainDuda}/sites/${siteName}/stripe-accounts`,
            token
        );
        const defaultAccounts = [];
        const listingAccounts = [{ value: 'Use Default Account', text: 'Use Default Account' }];
        accounts.forEach((account) => {
            defaultAccounts.push({ value: account.id, text: account.nickname });
            listingAccounts.push({ value: account.id, text: account.nickname });
        });
        dispatch({ type: SET_DEFAULT_STRIPE_ACCOUNTS, default_accounts: defaultAccounts });
        dispatch({ type: SET_LISTING_ACCOUNTS, listing_accounts: listingAccounts });
    };
};
export const setSelectedIds = (selected) => {
    return async (dispatch, getState) => {
        const listings = getState().listing.listings;
        const newListings = [...listings];
        newListings.forEach((listing, index) => {
            const isSelected = selected.some((id) => id === listing.listing_id);
            if (isSelected) {
                newListings[index].is_selected = true;
            } else {
                newListings[index].is_selected = false;
            }
        });
        dispatch({ type: SET_SELECTED_IDS, selectedIds: selected });
        dispatch({ type: SET_LISTINGS, listings: newListings });
        return newListings;
    };
};
export const getSiteById = (id) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        console.log(id);
        const site = await Request.get(`${Env.apiDomainDuda}/sites/${id}`, token);
        return site;
    };
};

export const publishSite = (id) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        console.log(id);
        const site = await Request.post(`${Env.apiDomainDuda}/sites/${id}/publish`, token);
        return site;
    };
};
export const unPublishSite = (id) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        console.log(id);
        const site = await Request.post(`${Env.apiDomainDuda}/sites/${id}/unpublish`, token);
        return site;
    };
};