import moment from 'moment';

export const getURLParams = () => {
    let url = new URL(window.location.href);
    let query = url.search.replace('?', '').split('&');

    if (!query.length) {
        return {};
    }

    let params = {};

    query.forEach(p => {
        let tmp = p.split('=');
        params[tmp[0]] = tmp[1];
    });

    return params;
};

export const getHashParams = () => {
    let url = window.location.hash;
    let query = url.replace('#', '').split('&');

    if (!query.length) {
        return {};
    }

    let params = {};

    query.forEach(p => {
        let tmp = p.split('=');
        params[tmp[0]] = tmp[1];
    });

    return params;
};

export const setHashParam = (key, value) => {
    let params = getHashParams();
    delete params[""];
    params[key] = value.trim();
    setHashParams(params);
}

export const setHashParams = (params) => {
    let hash = [];

    Object.keys(params).forEach(k => {
        hash.push(`${k}=${params[k]}`);
    });

    window.location.hash = hash.join('&');
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const buildUrlQuery = (params) => {
    const tmp = Object.keys(params).map(key => {
        return `${key}=${params[key]}`;
    });

    window.history.replaceState({}, null, `?${tmp.join('&')}`);
}

export const setUrlHash = (key, value) => {
    if (!key)
        window.location.hash = '';
    else
        window.location.hash = '#' + key + (value ? '=' + value : '');
}

export const getUrlHash = () => {
    let query = window.location.hash.replace('#', '').split('&');

    if (!query.length) {
        return {};
    }

    let params = {};

    query.forEach(p => {
        let tmp = p.split('=');

        if (tmp[0])
            params[tmp[0]] = tmp[1];
    });

    return params;
}

export const copy = (text) => {
    const inputId = "tmpInputId" + Math.round(Math.random() * 100000);

    const input = document.createElement('input');
    input.setAttribute("id", inputId);

    document.body.appendChild(input);

    input.value = text;

    try {
        input.select();
        document.execCommand("copy");
    } catch (error) { }

    input.remove();
}

export const parseTime = (time) => {
    return moment(time).format('HH:mm');
}

export const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const setCookie = (cname, cvalue, minutes) => {
    var d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    var expires = "expires=" + d.toUTCString();

    let domainParts = window.location.hostname.split('.');

    domainParts = domainParts.slice(-2);

    let cookie = `${cname}=${cvalue};${expires};domain=${domainParts.join('.')};path=/`;

    if (window.location.protocol.indexOf('s') > -1) {
        cookie += ";secure"
    }

    document.cookie = cookie;
}

export const deleteCookie = (cname) => {
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.fsapp.io`;
}

export const randomStr = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const arrayUnique = (arr) => {
    const unique = (value, index, self) => {
        return self.indexOf(value) === index
    }

    return arr.filter(unique);
}

export const combinePermissions = (defaultPermissions, additionalPermissions) => {
    let permissionsDiff = { ...defaultPermissions };
    if (Object.keys(additionalPermissions).length > 0) {
        Object.keys(additionalPermissions).forEach(key => {
            if (additionalPermissions[key] === 'none') {
                delete permissionsDiff[key];
            } else {
                permissionsDiff[key] = additionalPermissions[key];
            }
        })
    }

    return permissionsDiff;
}

export const hasPermissions = (permissions, permissionName, access) => {

    if (access === 'write') {
        return permissions[permissionName] === 'write';
    }

    if (access === 'read') {
        return permissions[permissionName] === 'write' || permissions[permissionName] === 'read';
    }
};