import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CURRENCY_FORMAT } from "../../../common/constants";
import ListTableItemListing from './ListTableItemListing';
import ListTableItemGuest from './ListTableItemGuest';
import ListTableItemReservation from './ListTableItemReservation';
import ListTableItemTags from './ListTableItemTags';
import ListTableItemLabel from './ListTableItemLabel';
import ListTableItemDate from './ListTableItemDate';
import ListTableItemTime from './ListTableItemTime';
import ListTableItemPrice from './ListTableItemPrice';
import ListTableItemIntegration from './ListTableItemIntegration';
import ListTableItemBool from './ListTableItemBool';
import ListTableItemCreditCard from './ListTableItemCreditCard';
import ListTableItemLink from './ListTableItemLink';
import ListTableItemImage from './ListTableItemImage';
import ListTableItemChannels from './ListTableItemChannels';
import FSCheckbox from '../../../components/elements/FSCheckbox.js';
import FSRating from './FSRating';

import * as listActions from '../../../store/actions/list';

const ListTableItem = (props) => {
  const { selectedIds, item } = props;
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.list.selectedColumns);
  const dateFormat = 'YYYY-MM-DD';

  const handleLinkClick = async (id) => {
    window.location.href = 'contacts/' + id;
  };

  const handleItemCheck = (id) => {
    let i = selectedIds.indexOf(id);
    const newItemsCheck = [...selectedIds];
    if (i === -1) {
      newItemsCheck.push(id);
    } else {
      newItemsCheck.splice(i, 1);
    }
    dispatch(listActions.setSelectedIds(newItemsCheck));
  };

  let id = '';
  if (item.reservation_id) id = item.reservation_id;
  else id = item.id;

  return (
    <tr>
      <td>
        {props.objectType === 'reservation' || props.objectType === 'listing' ? (
          <FSCheckbox
            type="checkbox"
            key={id}
            onChange={() => handleItemCheck(id)}
            checked={selectedIds.indexOf(id) > -1}
          />
        ) : null}
      </td>
      {columns.map((column, index) => {
        const value = item[column.field];

        return (
          <td key={index}>
            {column.field === 'nickname' && <ListTableItemListing item={item} />}
            {column.type === 'guest' && <ListTableItemGuest item={item} />}
            {column.type === 'reservation' && <ListTableItemReservation item={item} />}
            {column.type === 'tags' && <ListTableItemTags item={item} />}
            {column.type === 'label' && (
              <ListTableItemLabel value={value} label={item[column.label]} error={item.error} />
            )}
            {/* {column.type === 'date' && <ListTableItemDate value={value} dateFormat={dateFormat} />}
            {column.type === 'datetime' && (
              <ListTableItemDate value={value} dateFormat={dateFormat} timeFormat="HH:mm" />
            )}
            {column.type === 'time' && <ListTableItemTime value={value} />} */}
            {column.field === 'prices/dailyPrice' && (
              <ListTableItemPrice value={item.prices.dailyPrice} format={CURRENCY_FORMAT[item.prices.currency]} />
            )}
            {column.field === 'integration/integrationTypeName' && <ListTableItemChannels channels={item.integration.integrationTypeName} />}
            {column.field === 'prices/fees/cleaningFee' && (CURRENCY_FORMAT[item.prices.currency][0] + parseFloat(Math.abs(item.prices.fees.cleaningFee)).toFixed(2))}
            {column.field === 'prices/fees/extraPerson' && (CURRENCY_FORMAT[item.prices.currency][0] + parseFloat(Math.abs(item.prices.fees.extraPerson)).toFixed(2))}
            {column.field === 'prices/weekendPrice' && (CURRENCY_FORMAT[item.prices.currency][0] + parseFloat(Math.abs(item.prices.weekendPrice)).toFixed(2))}
            {column.field === 'prices/deposit' && (CURRENCY_FORMAT[item.prices.currency][0] + parseFloat(Math.abs(item.prices.deposit)).toFixed(2))}
            {column.field === 'prices/priceMarkup' && (CURRENCY_FORMAT[item.prices.currency][0] + parseFloat(Math.abs(item.prices.priceMarkup)).toFixed(2))}
            {column.field === 'prices/currency' && CURRENCY_FORMAT[item.prices.currency][0]}
            {column.field === 'address/zipcode' && item.address.zipcode}
            {column.field === 'address/street' && item.address.street}
            {column.field === 'details/bedrooms' && item.details.bedrooms}
            {column.field === 'details/beds' && item.details.beds}
            {column.field === 'details/bathrooms' && item.details.bathrooms}
            {column.field === 'details/capacity' && item.details.capacity}
            {column.field === 'restrictions/minNights' && item.restrictions.minNights}
            {column.field === 'restrictions/maxNights' && item.restrictions.maxNights}
            {column.field === 'policies/cancellationPolicy/name' && item.policies.cancellationPolicy.name}
            {column.field === 'listing_name' && item.name.value}
            {column.field === 'details/guestsIncluded' && item.details.guestsIncluded}
            {column.field === 'createdAt' && `${item.createdAt.slice(0, 10)} (${item.createdAt.slice(11)})`}
            {column.field === 'restrictions/checkInStart' && item.restrictions.checkInStart.slice(0, 5)}
            {column.field === 'restrictions/checkInEnd' && item.restrictions.checkInEnd.slice(0, 5)}
            {column.field === 'restrictions/checkOut' && item.restrictions.checkOut.slice(0, 5)}
            {column.field === 'restrictions/smokingAllowed' && (item.restrictions.smokingAllowed ? 'Yes' : 'No')}
            {column.field === 'wireless_ssid' && (item.amenities.find((el) => el.amenityId === 2) ? 'Yes' : 'No')}
            {column.field === 'restrictions/childrenAllowed' && (item.restrictions.childrenAllowed ? 'Yes' : 'No')}
            {column.field === 'restrictions/petsAllowed' && (item.restrictions.petsAllowed ? 'Yes' : 'No')}
            {column.field === 'restrictions/eventsAllowed' && (item.restrictions.eventsAllowed ? 'Yes' : 'No')}
            {column.field === 'isListed' && (item.isListed ? 'Yes' : 'No')}
            {column.field === 'policies/instantBooking' && (item.policies.instantBooking ? 'Yes' : 'No')}
            {column.type === 'bool' && <ListTableItemBool value={value} />}
            {column.type === 'creditCard' && <ListTableItemCreditCard item={item} />}
            {column.type === 'rating' && <FSRating rating={value} />}
            {column.type === 'internalLink' && (
              <ListTableItemLink
                text={value}
                onClick={(event) => handleLinkClick(item.id || event)}
              />
            )}
            {column.type === 'image' && <ListTableItemImage src={value} />}
            {column.type === 'channels' && <ListTableItemChannels channels={item.integration.integrationTypeName} />}
            {column.field === 'address/address' && item.address.address}
            {column.field === 'address/city' && item.address.city}
            {column.field === 'address/countryCode' && item.address.countryCode}
            {column.field === 'revenueFormulas/ownerRevenueFormula' && item.revenueFormulas.ownerRevenueFormula}
            {column.field === 'revenueFormulas/netRevenueFormula' && item.revenueFormulas.netRevenueFormula}
            {column.type === 'number' && value}
            {column.type === 'long_text' && value}
          </td>
        );
      })}
    </tr>
  );
};

export default ListTableItem;
