import './NameYourWorkspace.css';
import { default as fs_logo } from '../../assets/icons/fs-logo.svg';
import { default as question_mark_info } from '../../assets/icons/question-mark-info.svg';
import { default as error_try_again } from '../../assets/icons/error-try-again.svg';
import { useState, useEffect } from 'react';
import * as authActions from '../../store/actions/auth';
import { useDispatch, useSelector } from 'react-redux';

const forbiddenWorkspaceNames = ['app', 'api', 'docs', 'help', 'www'];

const NameYourWorkspace = () => {
    const [workspace, setWorkspace] = useState('');
    const [isWorkspaceValid, setIsWorkspaceValid] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [duplicateWorkspace, setDuplicateWorkspace] = useState(false);
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
    const dispatch = useDispatch();
    const newUser = useSelector((state) => state.auth.user);

    useEffect(() => {   
        if (workspace.match(/^(?!.*--)[a-z\d][-a-z\d]*[a-z\d]$/) && !forbiddenWorkspaceNames.includes(workspace)) {
            setIsWorkspaceValid(true);
        } else {
            setIsWorkspaceValid(false);
        }
    }, [workspace]);

    const createNewWorkspace = async () => {
        if (isRequestPending) return;
        setDuplicateWorkspace(false);
        setIsRequestPending(true);
        try {
            await dispatch(authActions.createWorkspace(workspace, newUser.user_id, 'FantasticStay'));
            setIsRequestPending(false);
            const tmp = window.location.hostname.split('.');
            tmp[0] = workspace.toLowerCase();
            window.location.href = `https://${tmp.join('.')}`;
        } catch (error) {
            setIsRequestPending(false);
            if (error.includes('exists')) {
                setDuplicateWorkspace(true);
            } else {
                setDidPromisesSucceed(false);
            }
        }
    };

    return (
        <div className='the-whole-sign-up-page'>
            <div className='left-side-of-sign-up'>
                <div className="d-flex">
                    <img src={fs_logo} alt='' />
                    {!didPromisesSucceed ?
                        <div className="error-try-again-holder ml-auto">
                            <div className="error-try-again-box">
                                <img className="error-try-again-icon" src={error_try_again} alt='' />
                            </div>
                            <div className="error-try-again-text-box">
                                <div className="ml-3">Error occured! Please try again.</div>
                                <div className="close-error-try-again ml-auto mr-3" onClick={() => setDidPromisesSucceed(true)}>&times;</div>
                            </div>
                        </div>
                    :
                        ''
                    }
                </div>
                <div className='sign-up-mid-section'>
                    <div className='name-your-workspace-header-text'>Name your workspace</div>
                    <div className='name-your-workspace-reminder'>This will be your workspace web address, so don’t forget to bookmark it!</div>
                    <div className='workspace-url-plus-info d-flex'>
                        <div className='workspace-url-header-text'>WORKSPACE URL</div>
                        <img className='workspace-url-additional-info' src={question_mark_info} alt=''/>
                    </div>
                    <div className='workspace-input-holder d-flex'>
                        <input
                            type='text'
                            value={workspace}
                            onChange={e => setWorkspace(e.target.value)}
                            onInput={() => setDuplicateWorkspace(false)}
                            className={`workspace-actual-input ${duplicateWorkspace ? 'invalid-registration-input' : ''}`}>
                        </input>
                        <div className='fsapp-io-holder'>
                            <div className='fsapp-io-holder-text'>.fsapp.io</div>
                        </div>
                    </div>
                    {duplicateWorkspace ? <p className='invalid-registration-workspace-label'>Workspace already exists!</p> : ''}
                    <div className='workspace-url-additional-details'>Your workspace name can only contain lowercase letters, numbers and dashes(separated by letters/numbers and not at start/end). Two characters minimum.</div>
                    {isWorkspaceValid ?
                        <div className='set-your-password-next-button' onClick={createNewWorkspace} style={{marginRight: '340px'}}>
                            <div className='text-inside-set-your-password-next-button'>Next</div>
                            {isRequestPending ? <i className="fa fa-circle-o-notch fa-spin" style={{marginLeft: '120px', position: 'relative', top: '-10px'}}></i> : ''}
                        </div>
                    :
                        <div className='set-your-password-next-button-disabled' style={{marginRight: '340px'}}>
                            <div className='text-inside-set-your-password-next-button-disabled'>Next</div>
                        </div>
                    }
                </div>
            </div>
            <div className='sign-up-page-image' style={{backgroundPosition: 'center center'}}></div>
        </div>
    );
};

export default NameYourWorkspace;
