const ListTableItemChannels = (props) => {
  const { channels } = props;

  if (typeof channels === 'undefined') {
    return '';
  }

  return (
    // <ul className="channels">
    //   {channels.map((channel, index) => {
    //     return (
    //       <li
    //         key={index}
    //         style={{
    //           opacity: channel.is_listed ? 1 : 0.3,
    //         }}
    //       >
    //         <a title={channel.name}>
    //           <img height="20" src={channel.img} alt='channel'/>
    //         </a>
    //       </li>
    //     );
    //   })}
    // </ul>
    <p>{channels}</p>
  );
};

export default ListTableItemChannels;
