import { useEffect } from 'react';
import FSSelect from '../../../components/elements/FSSelect.js';
import FSTimePicker from '../../../components/elements/FSTimePicker.js';

const availableOptions = [
  { value: 'eq', text: 'Is' },
  { value: 'lt', text: 'Is before' },
  { value: 'gt', text: 'Is after' },
];

const ListFilterTime = (props) => {
  const { selectedFilterSettings } = props;
  const option = !selectedFilterSettings ? 'eq' : selectedFilterSettings.option;
  const value = !selectedFilterSettings ? '' : selectedFilterSettings.value;

  useEffect(() => {
    props.onChange(value, 'eq');
  }, []);

  const handleValueChange = (value) => {
    props.onChange(value, option);
  };
  const handleOptionChange = (e) => {
    props.onChange(value, e.target.value);
  };
  return (
    <div className="options-filter-container">
      <FSSelect
        options={availableOptions}
        selected={option}
        onChange={handleOptionChange}
      />
      <FSTimePicker className="mt-10" value={value} onChange={handleValueChange} />
    </div>
  );
};

export default ListFilterTime;
