import { useEffect } from "react";
import { useState } from "react";
import Text from "../../../../components/ui/Text";

const InvitedUsers = (props) => {
  const [invitedUsers, setInvitedUsers] = useState([]);
  useEffect(() => {
    setInvitedUsers(props.invitedUsers);
  }, [props.invitedUsers]);

  return (
    <div id='successful-inv-view'>
      <div className='invited-users-content'>
        <div className='successful-inv-heading'>
          <label className='mb-0'><Text>tooltip.successful_invitation</Text></label>
        </div>

        <div className='mt-4'>
          <label className='inv-form-labels mb-3'>
            <Text>tooltip.invitation_sent_to</Text> ({invitedUsers.length}) {invitedUsers.length > 1 ? <Text>main.users</Text> : <Text>main.user</Text>}
          </label>
          {invitedUsers.map((user, index) => {
            return (
              <div className='invited-user d-flex' key={index}>
                <div className='invited-user-avatar mr-2 d-flex justify-content-center align-items-center'>
                  <img src='/img/user.svg' alt='' />
                </div>
                <label>{invitedUsers.length > 1 ? user.displayValue : user}</label>
              </div>
            )
          })}
        </div>
      </div>

      <div className='invited-users-bottom-container'>
        <div className='invited-users-ok-btn d-flex align-items-center justify-content-center' onClick={props.closeForm}>
          <label className='mb-0 inv-form-labels'><Text>main.ok</Text></label>
        </div>
      </div>
    </div>
  );
};

export default InvitedUsers;