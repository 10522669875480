import Env from '../../Env';
import Request from '../../utils/Request';

export const GET_USERS = 'GET_USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const UPDATE_ACC_STATUS = 'UPDATE_ACC_STATUS';



export const getUsers = (workspaceId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.apiDomainSSO}/workspace/users`, token);

    const activeListings = getState().listing.active;

    result.forEach(user => {
      user.properties = activeListings.filter(l => user.properties.includes(l.id));
    });

    dispatch({ type: GET_USERS, data: result })
    return result;
  }
};

export const updateAccountStatus = (userId, data) => {
  const status = `"${data}"`;

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const users = [...getState().users.allUsers];
    const user = users.find(el => el.user_id === userId)

    await Request.put(`${Env.apiDomainSSO}/workspace/users/${userId}/status`, status, token);

    user.status = data;
    dispatch({ type: GET_USERS, data: users });
  }
};

export const deleteUsers = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    await Request.post(`${Env.apiDomainSSO}/workspace/users/delete`, data, token);
    const users = [...getState().users.allUsers];
    const result = users.filter(user => !data.find(el => el.user_id === user.user_id));
    dispatch({ type: GET_USERS, data: result });
  }
};

export const getUserById = (userId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.apiDomainSSO}/workspace/users/${userId}`, token);
    result.permissions = {};
    result.additional_permissions.forEach(element => { result.permissions[element.permission_name] = element.access });
    dispatch({ type: GET_USER_BY_ID, data: result });
    return result;
  }
}

export const setInvitedUsers = (invitedUsers) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const users = [...getState().users.allUsers];
    const permissions = getState().auth.allPermissions;
    const reworkedInvitedUsers = { ...invitedUsers };
    const additionalPermsArray = [];


    if (Object.keys(reworkedInvitedUsers.additional_permissions).length > 0) {
      for (const perm in reworkedInvitedUsers.additional_permissions) {
        const currentPerm = permissions.find(el => el.name === perm);

        if (currentPerm) {
          additionalPermsArray.push({
            permission_id: currentPerm.id,
            access: reworkedInvitedUsers.additional_permissions[perm]
          });
        }
      }
    }

    reworkedInvitedUsers.additional_permissions = additionalPermsArray;
    const response = await Request.post(`${Env.apiDomainSSO}/workspace/users`, reworkedInvitedUsers, token);
    const invitedUsersArr = invitedUsers.emails.map(el => ({
      user_id: response.find(user => user.email === el).user_id,
      email: el,
      first_name: '',
      last_name: '',
      status: 'invited',
      properties: invitedUsers.properties,
      role_id: invitedUsers.role_id,
      additional_permissions: additionalPermsArray
    }));
    const newUsers = [...users, ...invitedUsersArr];

    dispatch({ type: GET_USERS, data: newUsers });
  }
};

export const resendUserInvitation = (email) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    await Request.post(`${Env.apiDomainSSO}/workspace/send-invite?email=${email}`, '', token);
  }
};

export const updateWorkspaceUser = (userId, data) => {
  return async (dispatch, getState) => {
    const token = await getState().auth.token;
    await Request.put(`${Env.apiDomainSSO}/workspace/users/${userId}`, data, token);
  }
};