import React from 'react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from '../breakpoints';
//Theme

const ThemeSelector = ({ children }) => {
    const DarkTheme = React.lazy(() => import('./DarkTheme.js'));
    // const LightTheme = React.lazy(() => import('./LightTheme.js'));
    // const CoolTheme = React.lazy(() => import('./CoolTheme.js'));
    const DefaultTheme = React.lazy(() => import('./DefaultTheme.js'));
    const theme = useSelector((state) => state.auth.theme);
    const CHOSEN_THEME = theme || 'Default';
    return (
        <>
            <React.Suspense fallback={<></>}>
                {CHOSEN_THEME === 'Default' && <DefaultTheme />}
                {/* {CHOSEN_THEME === 'Light' && <LightTheme />} */}
                {CHOSEN_THEME === 'Dark' && <DarkTheme />}
                {/* {CHOSEN_THEME === 'Cool' && <CoolTheme />} */}
            </React.Suspense>
            {children}
        </>
    );
};
//Nav color

const NavBarColor = ({ children }) => {
    // const CharcoalTheme = React.lazy(() => import('./CharcoalTheme.js'));
    // const DeepBlueTheme = React.lazy(() => import('./DeepBlueTheme.js'));
    // const GradientTheme = React.lazy(() => import('./GradientTheme.js'));
    const DefaultTheme = React.lazy(() => import('./DefaultTheme.js'));
    const user = useSelector((state) => state.auth.user);
    const CHOSEN_COLOR = user?.user_metadata?.navigation_theme || 'Default';
    return (
        <>
            <React.Suspense fallback={<></>}>
                {CHOSEN_COLOR === 'Default' && <DefaultTheme />}
                {/* {CHOSEN_COLOR === 'Charcoal' && <CharcoalTheme />}
                {CHOSEN_COLOR === 'Deepblue' && <DeepBlueTheme />}
                {CHOSEN_COLOR === 'Gradient' && <GradientTheme />} */}
            </React.Suspense>
            {children}
        </>
    );
};

const VerticalNav = React.lazy(() => import('../../components/Base/SideNavigation/SideNavigation'));
const HorizontalNav = React.lazy(() => import('../../components/Base/NavBar-n'));

const NavSelector = ({ children }) => {
    const isLg = useBreakpoint('lg')
    const navigation = useSelector((state) => state.auth.navigation);
    const CHOSEN_THEME = navigation || 'Horizontal';
    return (
      <>
        <React.Suspense fallback={<></>}>
          {isLg ? (
            <>
              {CHOSEN_THEME === 'Horizontal' && <HorizontalNav />}
              {CHOSEN_THEME === 'Vertical' && <VerticalNav />}
            </>
          ) : (
            // enforce HorizontalNav for smaller resolutions
            <HorizontalNav />
          )}
        </React.Suspense>
        {children}
      </>
    )
};

export { ThemeSelector, NavBarColor, NavSelector };
