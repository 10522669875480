import React from 'react';
//import Request from '../actions/Requests';
import('./start.css');

/*const f  inishStep = async () => {
    const result = await Request.post('/api/account/finishStep', {
        step: 'Welcome page'
    });
}*/

class Start extends React.Component{
    state = {
        loaded: false,
        username: null,
        subdomain: null,
        onboarding: null,
    }

    typeformdone = false;

    componentDidMount() {
        this.initData();
    }

    initData = async () => {
       // let result;

        try {
            /*result = await Request.get('/api/account/me');
            if (!result.data.success) {
                throw Error(result.statusText);
            }

            this.setState({
                loaded: true,
                username: result.data.user.email,
                subdomain: result.data.customer.name,
                onboarding: "start",
            });*/

        } catch (error) {
            console.log(error);
        }

    }

    render() {

        return (

            <div>
                <iframe id='typeform-full' title="kyc" width='100%' height='100%' frameBorder='0'
                        allow='camera; microphone; autoplay; encrypted-media;'
                        src={`https://form.typeform.com/to/jyu5ANCg?typeform-medium=embed-snippet#user=${this.state.username}&subdomain=${this.state.subdomain}&onboarding=${this.state.onboarding}`}/>
                <script type="text/javascript" src="https://embed.typeform.com/embed.js"/>
            </div>

        )

    }

}

export default Start;