import {useState} from 'react';
import './css/style.css';

const WAIT_INTERVAL = 200;

const FSInput =(props)=> {
 
    const [value,setValue] = useState(props.value|| '')
    let timer;
    const handleChange = async (event) => {
        if(typeof timer !== 'undefined'){
            clearTimeout(timer);
        }

        let v = event.target.value;
        if (typeof props.type !== "undefined" && props.type === 'number') {
            const { min, max } = props;

            if (typeof min !== 'undefined' && parseInt(min) > parseInt(v)) {
                v = min
            }

            if (typeof max !== 'undefined' && parseInt(max) < parseInt(v)) {
                v = max;
            }
        }
        setValue(v)

        if (typeof props.onChange === 'function') {
            timer = setTimeout(() => {
                props.onChange(v);
            }, WAIT_INTERVAL);
        }
    }

        const type = props.type ? props.type : 'text';

        return (
            <input
                type={type}
                value={value}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={handleChange}
                className={props.className}
                autoFocus={props.autoFocus}
                onKeyPress={props.onKeyPress}
                onKeyDown={props.onKeyDown}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                min={props.min}
                max={props.max}
                maxLength={props.maxLength}
            />
        )
    }

export default FSInput;