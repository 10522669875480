const QuestionnaireDropdown = (props) => {

  return (
    <div className={props.wrapperClassName}>
      <select
        className={props.selectClassName}
        style={props.optionStyle}
        onChange={(event) => props.passDropdownSelection(event.target.value)}>
        <option hidden></option>
        {props.options.map((option, index) => (
          <option key={index} >{option}</option>
        ))}

      </select>
    </div>
  )
};

export default QuestionnaireDropdown;