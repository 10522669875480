import { useEffect } from 'react';
import FSSelect from '../../../components/elements/FSSelect.js';
import FSInput from '../../../components/elements/FSInput.js';

const availableOptions = [
  { value: 'eq', text: 'Equals' },
  { value: 'ne', text: 'Does not equal' },
  { value: 'lt', text: 'Is less than' },
  { value: 'le', text: 'Is less than or equal to' },
  { value: 'gt', text: 'Is more than' },
  { value: 'ge', text: 'Is more than or equal to' },
  { value: 'between', text: 'Is between' },
];

const ListFilterNumber = (props) => {
  const { selectedFilterSettings } = props;
  const option = !selectedFilterSettings.option ? 'eq' : selectedFilterSettings.option;
  const value = !selectedFilterSettings.value ? null : selectedFilterSettings.value;
  const isBetween = option === 'between';

  useEffect(() => {
    props.onChange(value, option || 'eq');
  }, []);

  const handleOptionChange = (e) => {
    let newValue = '';

    if (e.target.value === 'between') {
      newValue = [value, ''];
    } else {
      newValue = value;
    }

    props.onChange(newValue, e.target.value);
  };

  const handleValueChange = (inputValue) => {
    let newValue = inputValue;
    if (isBetween) {
      newValue = [inputValue, value[1]];
    }
    props.onChange(newValue, option);
  };

  const handleSecondaryOptionChange = (inputValue) => {
    const newValue = [value[0], inputValue];
    props.onChange(newValue, option);
  };

  return (
    <div className="options-filter-container">
      <FSSelect
        options={availableOptions}
        selected={option}
        onChange={(e) => handleOptionChange(e)}
      />
      {!isBetween && (
        <FSInput type="number" value={value} className="mt-10" onChange={handleValueChange} />
      )}
      {isBetween && (
        <div className="d-flex mt-10">
          <FSInput
            type="number"
            value={value[0]}
            className="d-inline"
            onChange={handleValueChange}
          />
          <span style={{ margin: '0 5px', lineHeight: '30px' }}>-</span>
          <FSInput
            type="number"
            value={value[1]}
            className="d-inline"
            onChange={handleSecondaryOptionChange}
          />
        </div>
      )}
    </div>
  );
};

export default ListFilterNumber;
