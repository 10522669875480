import { useEffect } from 'react';
import FSSelect from '../../../components/elements/FSSelect.js';

const availableOptions = [
  { value: true, text: 'Yes' },
  { value: false, text: 'No' },
];

const ListFilterBool = (props) => {
  const { selectedFilterSettings } = props;
  const value = !selectedFilterSettings ? false : selectedFilterSettings.value;

  useEffect(() => {
    props.onChange(false, 'eq');
  }, []);

  const handleChange = (event) => {
    props.onChange(event.target.value, 'eq');
  };

  return (
    <div className="options-filter-container">
      <FSSelect options={availableOptions} value={value} selected={value} onChange={handleChange} />
    </div>
  );
};

export default ListFilterBool;
