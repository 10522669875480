/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../components/ui/Text';
import SideMenuInterface from '../SideMenuInterface/SideMenuInterface';
import * as userActions from '../../../store/actions/users';
import * as rolesActions from '../../../store/actions/roles';
import * as authActions from '../../../store/actions/auth';
import './UsersControl.css';
import { default as sort_arrow_down } from '../../../assets/icons/sort-arrow-down.svg';
import { default as sort_arrow_up } from '../../../assets/icons/sort-arrow-up.svg';
import { default as sort_arrows } from '../../../assets/icons/sort-arrows.svg'
import InviteUsersForm from './InviteUsersForm/InviteUsersForm';
import FilterDropdown from '../../../components/elements/FilterDropdown';
import PropertiesContainer from './PropertiesContainer/PropertiesContainer';
import UserActions from './UserActions/UserActions';
import { useOutsideAlerter } from '../../../components/ui/ClickOut';
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Base/Loading';
import TechnicalError from '../../TechnicalError/TechnicalError';
import AccessDenied from '../../AccessDenied/AccessDenied';

const UsersControl = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const [nameSortType, setNameSortType] = useState('asc');
  const [roleSortType, setRoleSortType] = useState('asc');
  const [propertiesSortType, setPropertiesSortType] = useState('asc');
  const [statusSortType, setStatusSortType] = useState('asc');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [checked, setChecked] = useState([]);
  const [viewInvForm, setViewInvForm] = useState(false);
  const [viewDeleteModal, setViewDeleteModal] = useState(false);
  const [filterQueries, setFilterQueries] = useState([]);
  const [openUserEditActions, setOpenUserEditActions] = useState(null);
  const [currentReinvitedUser, setCurrentReinvitedUser] = useState('');
  const [reinvitedUsers, setReinvitedUsers] = useState([]);
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const workspaceUser = useSelector(state => state.auth.customer);
  const allUsers = useSelector(state => state.users.allUsers);
  const activeListings = useSelector(state => state.listing.active);
  const userPermissions = useSelector(state => state.auth.user.default_permissions);

  useOutsideAlerter(filterRef, () => setIsFilterOpen(false));
  useEffect(() => {

    const initData = async () => {
      try {
        await dispatch(userActions.getUsers(workspaceUser.id));
        await dispatch(rolesActions.getRoles());
        await dispatch(authActions.setAllPermissions());
      } catch (error) {
        setDidPromisesSucceed(false);
      }
      setIsLoaded(true);
    };

    initData();
  }, [dispatch]);


  const toggleInvForm = () => setViewInvForm(!viewInvForm);

  const sort = (sortingParam, columnSortType) => {
    allUsers.sort((a, b) => {
      const isReversed = (columnSortType === 'asc') ? 1 : -1;

      if (sortingParam === 'name') {
        setNameSortType(nameSortType === 'asc' ? 'desc' : 'asc');
        return isReversed * a.first_name.localeCompare(b.first_name);
      }

      if (sortingParam === 'role') {
        setRoleSortType(roleSortType === 'asc' ? 'desc' : 'asc');
        const role = a.role || ''
        return isReversed * role.localeCompare(b.role || '');
      }

      if (sortingParam === 'properties') {
        setPropertiesSortType(propertiesSortType === 'asc' ? 'desc' : 'asc');
        if (a.properties === null && b.properties !== null) return -1;

        else if (a.properties !== null && b.properties === null) return -1;

        else if (a.properties === null && b.properties === null) return -1;

        else return 1;
      }

      if (sortingParam === 'status') {
        setStatusSortType(statusSortType === 'asc' ? 'desc' : 'asc');
        return isReversed * a.status.localeCompare(b.status);
      }

      return 0;
    });
  };

  const handleSingleCheck = (id) => {
    const index = checked.indexOf(id);

    if (index === -1) {
      setChecked([...checked, id]);
    } else {
      setChecked(checked.filter(element => element !== id));
    }
  };

  const handleCheckAll = () => {

    if (checked.length === allUsers.length) {
      setChecked([]);
    } else {
      setChecked(allUsers.map(user => user.user_id));
    }
  };

  const changeAccountStatus = async (userId, currentStatus) => {
    if (currentStatus === "active") {
      await dispatch(userActions.updateAccountStatus(userId, "deactivated"));
    }
    if (currentStatus === "deactivated") {
      await dispatch(userActions.updateAccountStatus(userId, "active"));
    }
  };

  const handleResendUserInvitation = async (email, id) => {
    setRequestInProgress(true);
    if (requestInProgress) return;
    setCurrentReinvitedUser(id);

    try {
      await dispatch(userActions.resendUserInvitation(email));
      setReinvitedUsers([...reinvitedUsers, id]);
    } catch (e) {
      return setDidSubmitSucceed(false);
    };

    setCurrentReinvitedUser(null);
    setRequestInProgress(false);
    setDidSubmitSucceed(true);
  }

  if (!isLoaded) {
    return <Loading />;
  }

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  if (!userPermissions.hasOwnProperty('Manage users')) {
    return <AccessDenied />
  }

  return (
    <div className='users-control'>
      <SideMenuInterface selectedInterface='users' />

      <div className="user-control-page-content">
        <div className='users-control-heading'>
          <h4>
            <Text>main.users</Text>
          </h4>
        </div>

        <div className='d-flex mb-2 mt-2 learn-more-sign'>
          <label className='mb-0'><Text>tooltip.invite_manage_users</Text>.</label>
          <a href='https://hub.fantasticstay.com/docs/user-management'
            target="_blank" className='mb-0 ml-1 learn-more-label'><Text>tooltip.learn_more</Text></a>
        </div>

        <div className="table-controls my-4">

          <div className='no-select' ref={filterRef}>
            <div
              className={isFilterOpen ? 'd-flex filter-dropdown-button-open' : 'd-flex filter-dropdown-button'}
              onClick={() => setIsFilterOpen(!isFilterOpen)}>
              <img src={isFilterOpen ? '/img/white-filter.png' : '/img/filter.svg'}
                alt=''
                style={{ width: '18px', height: '18px' }} />
              <label className='mb-0'><Text>action.filter</Text></label>
              <img src={isFilterOpen ? '/img/dropdown-arrow-up-white.svg' : '/img/dropdown-arrow-down.svg'}
                alt=''
                style={{ width: '14px', height: '14px' }} />
            </div>

            {isFilterOpen &&
              <FilterDropdown
                isFilterOpen={isFilterOpen}
                className='users-table-filter-dropdown'
                setQueries={setFilterQueries}
                queriesArray={filterQueries} />}
          </div>

          <div className='users-table-search-bar ml-3'>
            <div className="search-box">
              <img src="/img/search.svg" alt='' />
              <input className='search-bar-input-field'
                placeholder='Search...'
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }} />
            </div>
          </div>

          {userPermissions['Manage users'] === 'write'
            ? <div className='ml-auto invite-users-btn' onClick={toggleInvForm}>
              <img src='/img/plus.svg' alt='' />
              <label><Text>secondary.invite_users</Text></label>
            </div>
            : ''
          }
        </div>

        <table className="users-table">
          <thead>
            <tr className={checked.length > 0 ? 'hidden-table-cols' : ''} >

              <th className='col-w-30'>
                {userPermissions['Manage users'] === 'write'
                  ? <div className={checked.length < allUsers.length
                    ? 'main-checkbox users-table-empty-checkbox'
                    : 'main-checkbox users-table-checked-checkbox'}
                    onClick={() => handleCheckAll()}>
                    {checked.length > 0 && <label className='checked-users-label'>
                      {checked.length} {checked.length === 1 ? <Text>lowercase.user</Text> : <Text>lowercase.users</Text>}
                      &nbsp; <Text>lowercase.selected</Text></label>}
                  </div>
                  : ''
                }
              </th>

              <th className='table-col-users'>
                <div>
                  <label className='mr-2 table-col-labels'><Text>main.users</Text></label>
                  <img className='users-sort-arrow'
                    src={nameSortType === 'asc' ? sort_arrow_down : sort_arrow_up} alt=''
                    onClick={() => sort('name', nameSortType)} />
                </div>
              </th>

              <th className='table-col-role'>
                <div>
                  <label className='mb-0 table-col-labels'><Text>secondary.role</Text></label>
                  <img className='users-cols-sort-icon' src={sort_arrows} alt='' onClick={() => sort('role', roleSortType)} />
                </div>
              </th>

              <th className='table-col-properties'>
                <div>
                  <label className='mb-0 table-col-labels'><Text>secondary.properties</Text></label>
                  <img className='users-cols-sort-icon' src={sort_arrows} alt='' />
                </div>
              </th>

              <th className='table-col-active'>
                <div>
                  <label className='mb-0 table-col-labels'><Text>secondary.active</Text></label>
                  <img className='users-cols-sort-icon' src={sort_arrows} alt='' onClick={() => sort('status', statusSortType)} />
                </div>
              </th>

              <th className='table-last-col'>
                {checked.length > 0 &&
                  <div className='users-table-delete-btn' onClick={() => setViewDeleteModal(true)}>
                    <img src='/img/bin.svg' alt='' />
                    <label className='mb-0 ml-1'><Text>action.delete</Text></label>
                  </div>
                }
              </th>
            </tr>
          </thead>

          <tbody className='users-control-table'>
            {allUsers?.filter(user => {
              const searchBool = searchTerm.length === 0
                || user.email.indexOf(searchTerm) > -1
                || user.first_name.toLowerCase().includes(searchTerm.toLowerCase())
                || user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
              const filterBool = filterQueries.length > 0 ? filterQueries.includes(user.role) || filterQueries.includes(user.status) : user
              if (searchBool && filterBool && user.status === 'invited') return user;
            })
              .map((user, index) => {
                return (
                  <tr className='users-table-row-inactive' key={index}>
                    <td />

                    <td>
                      {userPermissions['Manage users'] === 'write'
                        ? <div className="d-flex align-items-center">
                          <Link to={`/user/${user.user_id}`}>
                            <div className='users-table-invitees-avatar mr-2'>
                              <div className='users-table-avatar-label'>
                                <img src='/img/clock.svg' alt='' />
                              </div>
                            </div>
                          </Link>

                          <div className='user-email-name-field'>
                            <Link to={`/user/${user.user_id}`} className='users-table-names'>
                              <label >{user.email}</label>
                            </Link>
                          </div>
                        </div>

                        : <div className="d-flex align-items-center">
                          <div className='users-table-invitees-avatar mr-2'>
                            <div className='users-table-avatar-label'>
                              <img src='/img/clock.svg' alt='' />
                            </div>
                          </div>

                          <div className='user-email-name-field'>
                            <label className='users-table-names'>{user.email}</label>
                          </div>
                        </div>
                      }
                    </td>

                    <td />
                    <td />

                    <td>
                      {userPermissions['Manage users'] === 'write'
                        ? <div className='users-table-send-invitation-email d-flex justify-content-center align-items-center'
                        >
                          {reinvitedUsers.includes(user.user_id)
                            ? <div>
                              <i className="fas fa-check" />
                              <p className='invitation-email-tooltip'><Text>action.invitation_sent</Text></p>
                            </div>
                            : currentReinvitedUser !== user.user_id
                              ? <div>
                                <i className="far fa-envelope" onClick={() => handleResendUserInvitation(user.email, user.user_id)} />
                                <p className='invitation-email-tooltip'><Text>action.resend_invitation</Text></p>

                              </div>
                              : <div>
                                <i className="fa fa-circle-o-notch fa-spin" />
                                <p className='invitation-email-tooltip'><Text>action.sending_invitation</Text>..</p>
                              </div>
                          }
                        </div>
                        : ''
                      }
                    </td>

                    <td >
                      {userPermissions['Manage users'] === 'write'

                        ? <div className='edit-action-button'
                          onClick={() => setOpenUserEditActions(openUserEditActions && openUserEditActions === user.user_id ? null : user.user_id)}>
                          {openUserEditActions === user.user_id
                            ? <div className='d-flex edit-action-close-button'>
                              <img src='/img/cross.svg' alt='' />
                            </div>
                            : <img src="/img/dots.svg" alt='' />}
                        </div>
                        : ''
                      }
                      {openUserEditActions && openUserEditActions === user.user_id
                        ? <UserActions deleteUser={setViewDeleteModal} className='users-table' user={user} />
                        : ''}
                    </td>
                  </tr>
                );
              })}
            {allUsers?.filter(user => {
              const searchBool = searchTerm.length === 0
                || user.email.indexOf(searchTerm) > -1
                || user.first_name.toLowerCase().includes(searchTerm.toLowerCase())
                || user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
              const filterBool = filterQueries.length > 0 ? filterQueries.includes(user.role) || filterQueries.includes(user.status) : user
              if (searchBool && filterBool && user.status !== 'invited') return user;
            })
              .map((user, index) => {

                return (
                  <tr className={`users-table-row${user.status === 'active' ? '' : '-inactive'}`} key={index} >
                    <td>
                      {userPermissions['Manage users'] === 'write'
                        ? <div className={checked.indexOf(user.user_id) > -1
                          ? 'users-table-checked-checkbox'
                          : 'users-table-empty-checkbox'}
                          onClick={() => handleSingleCheck(user.user_id)}>
                        </div>
                        : ''
                      }
                    </td>

                    <td>
                      {userPermissions['Manage users'] === 'write'
                        ? <div className="avatar-name-mail-container d-flex align-items-center">
                          <Link to={`/user/${user.user_id}`}>
                            <div className='users-table-avatar mr-2'>
                              <div className='users-table-avatar-label'>{user?.first_name.slice(0, 1)}{user?.last_name.slice(0, 1)}</div>
                            </div>
                          </Link>

                          <div className='user-email-name-field'>
                            <Link to={`/user/${user.user_id}`} className='users-table-names'>
                              <label >{user.first_name} {user.last_name}</label>
                            </Link>

                            <Link to={`/user/${user.user_id}`} className='users-table-email'>
                              <label >{user.email}</label>
                            </Link>
                          </div>
                        </div>

                        : <div className="avatar-name-mail-container d-flex align-items-center">
                          <div className='users-table-avatar mr-2'>
                            <div className='users-table-avatar-label'>{user?.first_name.slice(0, 1)}{user?.last_name.slice(0, 1)}</div>
                          </div>

                          <div className='user-email-name-field'>
                            <label className='users-table-names'>{user.first_name} {user.last_name}</label>
                            <label className='users-table-email'>{user.email}</label>
                          </div>
                        </div>
                      }

                    </td>

                    <td>
                      <div className='users-table-roles'>
                        <label className='mb-0'>{user.role ? user.role : 'None'}</label>
                      </div>
                    </td>

                    <td>
                      {user.properties.length > 0 ? user.properties.length : ''}{user.properties.length === activeListings.length && ' (All)'}
                      <img className={`properties-icon${user.properties.length > 1 ? '' : '-disabled'} ml-2`}
                        src="/img/info-circle-dark.svg"
                        alt='' />
                      <PropertiesContainer properties={user?.properties} className='users-table-properties-container' />
                    </td>

                    <td>
                      <div>
                        <label className="mb-0 mt-2 switch">
                          {userPermissions['Manage users'] === 'write'
                            ? <div>
                              <input checked={user.status === 'active' ? true : false}
                                type="checkbox"
                                onChange={() => changeAccountStatus(user.user_id, user.status)} />
                              <span className="slider round"></span>
                            </div>
                            : ''
                          }
                        </label>
                      </div>
                    </td>

                    <td >
                      {userPermissions['Manage users'] === 'write'
                        ? <div className='edit-action-button'
                          onClick={() => setOpenUserEditActions(openUserEditActions && openUserEditActions === user.user_id ? null : user.user_id)}>
                          {openUserEditActions === user.user_id
                            ? <div className='d-flex edit-action-close-button'>
                              <img src='/img/cross.svg' alt='' />
                            </div>
                            : <img src="/img/dots.svg" alt='' />}
                        </div>
                        : ''
                      }
                      {openUserEditActions && openUserEditActions === user.user_id
                        ? <UserActions deleteUser={setViewDeleteModal} className='users-table' user={user} />
                        : ''}
                    </td>

                    <DeleteUserModal
                      user=''
                      open={viewDeleteModal}
                      clearSelected={() => setChecked([])}
                      users={allUsers.filter(user => checked.includes(user.user_id))}
                      onClose={() => setViewDeleteModal(false)} />
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {viewInvForm && <InviteUsersForm modal={viewInvForm} toggle={toggleInvForm} />}
    </div >
  );
};

export default UsersControl;