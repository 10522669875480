import { SET_TRANSLATIONS } from '../actions/translation';

const initialState = {
  translations: {},
};

const translationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSLATIONS:
      return {
        translations: action.translations,
      };
    default:
      return state;
  }
};

export default translationReducer;