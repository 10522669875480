import Request from '../../utils/Request';
import { SET_SELECTED_IDS, SET_DEFAULT_STRIPE_ACCOUNT_ID, SET_PAYMENT_SCHEDULES } from './sites';
import Env from '../../Env';

export const SET_ACTIVE_LISTINGS = 'SET_ACTIVE_LISTINGS';
export const SET_LISTINGS = 'SET_LISTINGS';
export const SET_RENTALSUNITED_PROPERTIES = 'SET_RENTALSUNITED_PROPERTIES';

export const setActiveListings = () => {

    return async (dispatch, getState) => {
        const token = getState().auth.token;
        // const listings = await Request.get('/listings', token);
        const currentUserListings = getState().auth.user.properties;
        // const workspaceId = getState().auth.customer.id;
        // const encoded = window.btoa('vT8iyq811Y3pLKq:IHxrCbFWLM17nACXbpFR');
        // const token = 'Basic ' + encoded;
        const result = await Request.get(`${Env.apiDomainListing}/listings/by-customer`, token);

        let listings = result.listings.map(l => ({
            id: l.id,
            name: !l.nickname ? l.name : l.nickname,
            thumb: l.thumbnail_url,
            gcs: l.service_communication
        }));

        // TODO: filter user listings
        // if (currentUserListings.length > 0) {
        //     listings = listings.filter(l => currentUserListings.includes(l.id));
        // }

        dispatch({ type: SET_ACTIVE_LISTINGS, listings: listings });
    };
};

export const setRentalsUnitedProperties = () => {

    return async (dispatch, getState) => {
        // const token = getState().auth.token;
        // const listings = await Request.get('/listings', token);

        dispatch({
            type: SET_RENTALSUNITED_PROPERTIES,
            listings: [{
                "id": 321854912,
                "name": "Rentals United Property 1"
            },
            {
                "id": 12321412,
                "name": "Rentals United Property 2"
            }]
        });
    };
}

export const submitRUPropertiesMapping = (data) => {

    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const result = await Request.post(
            `${Env.dbServerUrl}/rentalsunited/mapping`,
            { data },
            token
        );
        return result;
    };
};

export const getListingById = (listingId) => {

    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const result = await Request.get(`${Env.dbServerUrl}/listings/${listingId}`, token);
        return result;
    };
};

export const getAllActiveListings = (siteName) => {
    console.log('getAllActiveListings');
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const result = await Request.get(`${Env.apiDomainDuda}/sites/select-collection-listings?siteName=${siteName}`, token);
        const selectedIds = []
        result.select_listings.forEach((listing) => {
            if (listing.is_selected === true) {
                selectedIds.push(listing.listing_id)
            } else {
                return;
            }
        })
        console.log(result)
        dispatch({ type: SET_LISTINGS, listings: result.select_listings });
        dispatch({ type: SET_SELECTED_IDS, selectedIds: selectedIds });
        dispatch({ type: SET_DEFAULT_STRIPE_ACCOUNT_ID, default_account_id: result.payment_processor_id });
        dispatch({ type: SET_PAYMENT_SCHEDULES, site_payment_schedules: result.payment_schedules });
        return result;
    };
};

