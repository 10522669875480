import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ViewHeadline from '@material-ui/icons/ViewHeadline';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
    fontSize: '1.4rem',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
  },
});
let visualise = false;
const theme = createMuiTheme({
  typography: {
    fontSize: 21,
  },
});

const SortableItem = SortableElement(({ value }) => (
  <ListItem style={{ fontSize: '14px', margin: '0', padding: '0' }}>
    <ListItemIcon style={{ margin: '0' }}>
      <ViewHeadline />
    </ListItemIcon>
    {visualise ? (
      value
    ) : (
      <ListItemText primary={value.name} style={{ padding: '0', fontSize: '14px' }} />
    )}
  </ListItem>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

// function generate(element) {
//   return [0, 1, 2].map((value) =>
//     React.cloneElement(element, {
//       key: value,
//     }),
//   );
// }

const FSSortList = (props) => {
  const {items} = props;
  if (props.visualise) {
    visualise = this.props.visualise;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <SortableList items={items} onSortEnd={props.onSortEnd} />
    </MuiThemeProvider>
  );
};

FSSortList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FSSortList);
