import { useState } from "react";
import { useSelector } from "react-redux";
import Text from "../../../../components/ui/Text";

const AssignProperties = (props) => {
  const allListings = useSelector(state => state.listing.active);
  const [checked, setChecked] = useState(allListings.map(el => el.id) || []);

  const handleSingleCheck = (id) => {
    const property = allListings.find(el => el.id === id);
    const checkedProperty = checked.find(el => el === property?.id);

    if (checkedProperty) {
      setChecked(checked.filter(element => element !== id));
    } else {
      setChecked([...checked, property.id]);
    }
  };

  const handleSubmit = () => {
    props.setAssignedProperties(checked);
  };

  return (
    <div id='assign-properties-view'>
      <div className='assign-properties-content'>

        <div className='assign-properties-heading'>
          <label className='mb-0'><Text>secondary.restricted_to_properties</Text></label>
        </div>


        <div className={`d-flex align-items-center mb-3 ml-1 assign-properties-deselect${checked.length > 0 ? '' : '-hidden'}`}>
          <div className='deselect-properties-checkbox' onClick={() => setChecked([])}>
            <img src='/img/minus-checkbox.svg' alt='' />
          </div>
          <p className='mb-0 ml-1'>({checked.length}) {checked.length === 1 ? 'property' : 'properties'} <Text>lowercase.selected</Text></p>
        </div>


        <div className='d-flex assign-property-boxes'>
          {allListings.map(property => {
            return (
              <div key={property?.id} className={`mb-2 mr-3 ${checked.find(el => el === property?.id) ? 'property-box-checked' : 'property-box'}`}>
                <div className='p-1 d-flex align-items-center'>
                  <div className={`users-table-${checked.find(el => el === property?.id) ? 'checked' : 'empty'}-checkbox`}
                    onClick={() => handleSingleCheck(property?.id)} />
                  <label className='mb-0 ml-2'>{property?.name}</label>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className='d-flex align-items-center assign-properties-bottom-container'>
        <div className={`assign-properties-btn`}
          onClick={() => {
            handleSubmit();
            props.closeForm();
          }}>
          <label className='mb-0'><Text>action.restrict_from</Text> ({allListings.length - checked.length})&nbsp;
            {allListings.length - checked.length === 1
              ? <Text>lowercase.property</Text>
              : <Text>lowercase.properties</Text>}
          </label>
        </div>

        <div className='inv-form-close-btn' >
          <label className='mb-0' onClick={props.closeForm}><Text>action.back</Text></label>
        </div>
      </div>
    </div>
  );
};

export default AssignProperties;