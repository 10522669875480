import { useSelector } from "react-redux";

const Text = props => {
    const key = props.children.toString();
    const translation = useSelector(state => state.translation.translations[key]);

    if (!translation) {
        return <span style={{backgroundColor:'red'}}>{key}</span>
    }

    return translation || key || '';
}

export default Text;
