import React from 'react';
// import SideBar from '../Base/SideNavigation/SideBar';
// import NavBarNew from '../Base/NavBar-n';
import Loading from '../Base/Loading';
import FeatherIcon from 'feather-icons-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
// import SideNav from '../Base/SideNavigation/SideNavigation';
// import { Provider, useSelector } from 'react-redux';
import {NavSelector, /* ThemeSelector */} from '../../common/themes/AllThemes';
import { ReactComponent as IconSearch } from '../../assets/icons/search.svg';

// const VerticalNav = React.lazy(() => import('../Ba/SideNavigation'));
// const HorizontalNav = React.lazy(() => import('../components/NavBar-n'));
// const ThemeSelector = ({ children }) => {
//     const user = useSelector((state) => state.auth.user);
//     const CHOSEN_THEME = user?.user_metadata?.navigation_layout || 'Horizontal';
//     return (
//         <>
//             <React.Suspense fallback={<></>}>
//                 {CHOSEN_THEME === 'Horizontal' && <HorizontalNav />}
//                 {CHOSEN_THEME === 'Vertical' && <VerticalNav />}
//             </React.Suspense>
//             {children}
//         </>
//     );
// };
const Base = (props) => {

    return (
        <NavSelector>
            <div className="content ht-100v pd-0" style={{height: '90vh'}}>
                <div className="content-header">
                    <div className="content-search" style={{width: '265px'}}>
                        <IconSearch className="feather" />
                        <input type="search" className="form-control" placeholder="Search..." />
                    </div>
                    <nav className="nav">
                        <a href="/#" className="nav-link">
                            <FeatherIcon icon="help-circle" />
                        </a>
                        <a href="/#" className="nav-link">
                            <FeatherIcon icon="grid" />
                        </a>
                        <a href="/#" className="nav-link">
                            <FeatherIcon icon="align-left" />
                        </a>
                    </nav>
                </div>
                {props.children}
            </div>
            <div className="aside-backdrop"></div>
        </NavSelector>
    );
};

/* <div id="app" className="d-flex flex-column h-100">
            <SideBar/>
            <NavBar/>
            <Container className="flex-grow-1 mt-5">
                {props.children}
            </Container>
        </div> */

//export default withAuthenticationRequired;

export default withAuthenticationRequired(Base, {
    onRedirecting: () => <Loading />,
});