import { Picky } from 'react-picky';
import FSCheckbox from './FSCheckbox.js';

import 'react-picky/dist/picky.css';
// import '../../css/multiselect.css';

const FSMultipleSelect = (props) => {
  const handleOptionChange = async (value) => {
    if (props.selected.indexOf(value) === -1) {
      props.onChange([...props.selected, value]);
    } else {
      props.onChange(props.selected.filter(v => v !== value));
    }
  };

  const handleChange = (value) => {
    const selected = value.map((opt) => {
      return opt.value;
    }); 
    props.onChange(selected);
  };

  const optionRender = ({ style, isSelected, item, selectValue, labelKey, valueKey, multiple }) => {
    return (
      <li style={style} className={isSelected ? 'selected' : ''} key={item[valueKey]}>
        <FSCheckbox
          onChange={handleOptionChange}
          checked={isSelected}
          label={item[labelKey]}
          value={item[valueKey]}
        />
      </li>
    );
  };
  const renderSelectAll = ({ filtered, allSelected, toggleSelectAll }) => {
    if (filtered) {
      return '';
    }
    return (
      <li className="selected-all">
        <FSCheckbox onChange={toggleSelectAll} checked={allSelected === 'all'} label="Select all" />
      </li>
    );
  };

  const selected = props.options.filter(option => props.selected.indexOf(option.value) > -1);
  
  return (
    <Picky
      options={props.options}
      value={selected}
      valueKey="value"
      labelKey="text"
      multiple={true}
      renderSelectAll={renderSelectAll}
      includeSelectAll={true}
      includeFilter={true}
      onChange={handleChange}
      dropdownHeight={600}
      render={optionRender}
    />
  );
};

export default FSMultipleSelect;
