import './CustomStay.scss';
import { Button } from 'reactstrap';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Text from '../../../components/ui/Text';
import * as multiCalendarActions from '../../../store/actions/multiCalendar';

const CustomStay = ({ toggle, modal, checkedListings }) => {
    const ruleTemplate = {
        date_start: moment(Date.now()).format('YYYY-MM-DD'),
        date_end: moment(Date.now()).add(1, 'days').format('YYYY-MM-DD'),
        min_stay: 1,
        checkin_weekday: '',
        id: Math.floor(Math.random() * 100000),
    };
    const mainRulesTemplate = {
        min_nights: '1',
        max_nights: '60',
        weekday_min_stay: {
            monday: '1',
            tuesday: '1',
            wednesday: '1',
            thursday: '1',
            friday: '1',
            saturday: '1',
            sunday: '1',
        },
    };
    const [rules, setRules] = useState([ruleTemplate]);
    const [mainRules, setMainRules] = useState(mainRulesTemplate);

    const dispatch = useDispatch();

    useEffect(() => {
        if (modal && checkedListings.length < 2) {
            (async () => {
                const result = await dispatch(multiCalendarActions.getCustomStay());
                setRules(result.custom_stay_rules);
                setMainRules({ ...result, custom_stay_rules: [] });
            })();
        }
        if (checkedListings.length > 1) {
            setRules([ruleTemplate]);
            setMainRules(mainRulesTemplate);
        }
    }, [checkedListings, modal]);

    const saveRules = (rules) => {
        const conflictDates = rules.reduce((acc, el) => {
            rules.forEach((r) => {
                if (
                    r.id !== el.id &&
                    ((r.date >= el.date && r.date <= el.date_end) ||
                        (r.date_end >= el.date && r.date_end <= el.date_end))
                ) {
                    acc.push(r);
                }
            });

            return acc;
        }, []);

        if (conflictDates.length > 1) {
            alert(
                `The following date ranges are overlapping: ${conflictDates.map(
                    (d) => `\n${d.date_start} -- ${d.date_end}`
                )}`
            );
        } else {
            // POST Request to Backend
            setMainRules({ ...mainRules, custom_stay_rules: rules });
            console.log({ ...mainRules, custom_stay_rules: rules });
        }
    };

    const getDaysOfTheWeek = () => {
        const daysOfTheWeek = Array.from(document.getElementsByName('days-of-week_custom')).map(
            (d) => {
                if (d.value !== 'any-day-of-week') {
                    return d.value;
                } else {
                    return null;
                }
            }
        );

        setRules(
            rules.reduce((acc, r, i) => {
                daysOfTheWeek.forEach((d, id) => {
                    if (i === id) {
                        acc.push({ ...r, checkin_weekday: d });
                    }
                });
                return acc;
            }, [])
        );
    };

    return (
        <div className={modal ? 'side-menu-custom-stay' : 'hidden-side-menu-custom-stay'}>
            <h2>
                <Text>Custom Stay</Text>
            </h2>
            {checkedListings.length > 1 ? (
                <h3>
                    <Text>
                        You are editing more than 1 listings - all rules saved will override current
                        data
                    </Text>
                </h3>
            ) : (
                <h3>
                    <Text>You are editing rules for {checkedListings[0]?.name}</Text>
                </h3>
            )}
            <button className='close-button' onClick={() => toggle() + setRules([ruleTemplate])}>
                X
            </button>
            <div className='scrollable-content'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <Text>From</Text>
                            </th>
                            <th>
                                <Text>To</Text>
                            </th>
                            <th>
                                <Text>Min Nights</Text>
                            </th>
                            <th>
                                <Text>Days of Week</Text>
                            </th>
                            <th>
                                <Text>Option</Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rules?.map((r) => {
                            return (
                                <tr key={r.id} id={r.id}>
                                    <td>
                                        {' '}
                                        <input
                                            type='date'
                                            name='from-date'
                                            value={r.date_start}
                                            onChange={(e) =>
                                                setRules(
                                                    rules.map((rul) => {
                                                        if (rul.id === r.id) {
                                                            return {
                                                                ...rul,
                                                                date_start: e.target.value,
                                                            };
                                                        } else return rul;
                                                    })
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        {' '}
                                        <input
                                            type='date'
                                            name='to-date'
                                            value={r.date_end}
                                            onChange={(e) =>
                                                setRules(
                                                    rules.map((rul) => {
                                                        if (rul.id === r.id) {
                                                            return {
                                                                ...rul,
                                                                date_end: e.target.value,
                                                            };
                                                        } else return rul;
                                                    })
                                                )
                                            }
                                        />
                                    </td>
                                    <td className='min-nights'>
                                        {' '}
                                        <input
                                            type='number'
                                            name='min-nights'
                                            className='min-nights'
                                            value={r.min_stay}
                                            onChange={(e) =>
                                                setRules(
                                                    rules.map((rul) => {
                                                        if (rul.id === r.id) {
                                                            return {
                                                                ...rul,
                                                                min_stay: e.target.value,
                                                            };
                                                        } else return rul;
                                                    })
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <div className='select-days-of-week-custom'>
                                            <select
                                                name='days-of-week_custom'
                                                onChange={() => getDaysOfTheWeek()}
                                            >
                                                <option
                                                    className='days-of-week_custom'
                                                    value='any-day-of-week'
                                                    id='any-day-of-week'
                                                    selected={r.checkin_weekday === null}
                                                >
                                                    Any Day of Week
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='monday'
                                                    id='monday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('monday')?.value
                                                    }
                                                >
                                                    Monday
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='tuesday'
                                                    id='tuesday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('tuesday')?.value
                                                    }
                                                >
                                                    Tuesday
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='wednesday'
                                                    id='wednesday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('wednesday')?.value
                                                    }
                                                >
                                                    Wednesday
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='thursday'
                                                    id='thursday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('thursday')?.value
                                                    }
                                                >
                                                    Thursday
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='friday'
                                                    id='friday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('friday')?.value
                                                    }
                                                >
                                                    Friday
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='saturday'
                                                    id='saturday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('saturday')?.value
                                                    }
                                                >
                                                    Saturday
                                                </option>
                                                <option
                                                    className='days-of-week_custom'
                                                    value='sunday'
                                                    id='sunday'
                                                    selected={
                                                        r.checkin_weekday ===
                                                        document.getElementById('sunday')?.value
                                                    }
                                                >
                                                    Sunday
                                                </option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() =>
                                                setRules(rules.filter((rl) => rl.id !== r.id))
                                            }
                                        >
                                            <Text>Delete</Text>
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <br />
                <Button color='warning' onClick={() => setRules([...rules, ruleTemplate])}>
                    <Text>+Add Rule</Text>
                </Button>
                <br />
                <br />
                <hr />
                <table>
                    <tbody className='table-dafault-rules'>
                        <tr>
                            <td>
                                <Text>Default min stay</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.min_nights}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            min_nights: e.target.value,
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Default max stay</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.max_nights}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            max_nights: e.target.value,
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <h3>
                            <Text>Weekday min stay rules</Text>
                        </h3>
                        <tr>
                            <td>
                                <Text>Monday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.monday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                monday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Tuesday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.tuesday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                tuesday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Wednesday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.wednesday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                wednesday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Thursday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.thursday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                thursday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Friday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.friday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                friday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Saturday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.saturday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                saturday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Text>Sunday</Text>
                            </td>
                            <td>
                                {' '}
                                <input
                                    type='number'
                                    placeholder='nights'
                                    value={mainRules.weekday_min_stay?.sunday}
                                    onChange={(e) =>
                                        setMainRules({
                                            ...mainRules,
                                            weekday_min_stay: {
                                                ...mainRules.weekday_min_stay,
                                                sunday: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Button
                    color='primary'
                    onClick={() =>
                        saveRules(rules) +
                        toggle() +
                        setRules([ruleTemplate]) +
                        setMainRules(mainRulesTemplate)
                    }
                >
                    <Text>Save</Text>
                </Button>{' '}
                <Button
                    color='secondary'
                    onClick={() =>
                        toggle() + setRules([ruleTemplate]) + setMainRules(mainRulesTemplate)
                    }
                >
                    <Text>Cancel</Text>
                </Button>
            </div>
        </div>
    );
};

export default CustomStay;
