import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Table, FormGroup, Toast, ToastBody, ToastHeader, Label, Input, Button } from 'reactstrap';
import PagePreloader from '../../Base/PagePreloader';
import Text from '../../ui/Text';
import FeatherIcon from 'feather-icons-react';
import EditGuest from '../EditGuest/EditGuest';
import './SingleGuest.scss';

import * as guestActions from '../../../store/actions/guest';
import TechnicalError from '../../../pages/TechnicalError/TechnicalError';

const EDIT_TYPE_NAME = 'name';
const EDIT_TYPE_EMAIL = 'email';
const EDIT_TYPE_PHONE = 'phone';

const SingleGuest = () => {
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
  const [guest, setGuest] = useState([]);
  const [guests, setGuests] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [note, setNote] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editLabel, setEditLabel] = useState('');
  const [editValue, setEditValue] = useState('');
  const [editType, setEditType] = useState('');
  const [editId, setEditId] = useState('');
  // const user = useSelector((state) => state.auth.user);
  const navLayout = useSelector((state) => state.auth.navigation);
  const dispatch = useDispatch();
  const { id } = useParams();

  const toggleEditModal = useCallback(() => {
    setIsEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen]);

  const permissions = useSelector((state) => state.auth.permissions);

  const history = useHistory();
  if (!permissions.includes('SingleGuest')) {
      history.push('/access-denied')
  }

  useEffect(() => {
    const getGuest = async () => {
      try {
        const result = await dispatch(guestActions.getGuest(id));
        setGuest(result);
      } catch (error) {
        setDidPromisesSucceed(false);
      }

      setIsLoaded(true);
    };

    getGuest();
  }, [dispatch, id]);

  useEffect(() => {
    const getGuest = async () => {
      try {
        const result = await dispatch(guestActions.getGuests());
        setGuests(result);
      } catch (error) {
        setDidPromisesSucceed(false);
      }
    
      setIsLoaded(true);
    };

    getGuest();
  }, [dispatch]);

  const handleNoteDeleteClick = async (id, noteId) => {
    await dispatch(guestActions.deleteGuestNote(id, noteId));
    const c = {
      ...guest,
      notes: guest.notes.filter((note) => note.id !== noteId),
    };

    setGuest(c);
  };

  const handleAddNewNote = (id, data) => {
    const addNote = async () => {
      const result = await dispatch(guestActions.addNewNote(id, data));
      setGuest(result);
    };
    
    addNote();
  };

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  const openNewEmail = () => {
    setEditType(EDIT_TYPE_EMAIL);
    setEditLabel('email');
    setEditValue('');
    setEditId('');
    setIsEditModalOpen(true);
  };

  const openEdcurrentContactail = (id) => {
    const email = guest.emails.find((e) => e.id === id);
    setEditType(EDIT_TYPE_EMAIL);
    setEditLabel('email');
    setEditValue(email.email);
    setEditId(id);
    setIsEditModalOpen(true);
  };
  const openNewPhone = () => {
    setEditType(EDIT_TYPE_PHONE);
    setEditLabel('phone');
    setEditValue('');
    setEditId('');
    setIsEditModalOpen(true);
  };

  const openEditPhone = (id) => {
    const phone = guest.phones.find((e) => e.id === id);
    setEditType(EDIT_TYPE_PHONE);
    setEditLabel('phone');
    setEditValue(phone.number);
    setEditId(id);
    setIsEditModalOpen(true);
  };

  const openEditName = () => {
    setEditType(EDIT_TYPE_NAME);
    setEditLabel('name');
    setEditValue(guest.name);
    setEditId();
    setIsEditModalOpen(true);
  };

  const deleteEmail = (id) => {
    dispatch(guestActions.deleteEmail(guest.id, id));
    const g = {
      ...guest,
      emails: guest.emails.filter((email) => email.id !== id),
    };
    setGuest(g);
  };

  const deletePhone = (id) => {
    dispatch(guestActions.deletePhone(guest.id, id));
    const g = {
      ...guest,
      phones: guest.phones.filter((phone) => phone.id !== id),
    };
    setGuest(g);
  };

  const handleSave = (value) => {
    if (editType === EDIT_TYPE_NAME) {
      dispatch(guestActions.updateGuest(guest.id, { name: value }));
    } else if (editType === EDIT_TYPE_EMAIL) {
      if (editId) {
        dispatch(guestActions.updateGuestEmail(guest.id, editId, { email: value }));
      } else {
        dispatch(guestActions.addNewEmail(guest.id, { email: value }));
      }
    } else if (editType === EDIT_TYPE_PHONE) {
      if (editId) {
        dispatch(guestActions.updateGuestPhone(guest.id, editId, { phone: value }));
      } else {
        dispatch(guestActions.addNewPhone(guest.id, { phone: value }));
      }
    }

    setIsEditModalOpen(false);
  };

  if (!isLoaded) {
    return <PagePreloader />;
  }
  guests.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });
  const groupContacts = (contacts) => {
    const groupedContacts = [];
    const hash = {};
    contacts.forEach(function (contact) {
      let letter = contact.name.toLowerCase().substring(0, 1);
      if (hash[letter]) {
        hash[letter].names.push(contact);
      } else {
        groupedContacts.push(
          (hash[letter] = {
            letter,
            names: [contact],
          }),
        );
      }
    });
    return groupedContacts;
  };
  const contacts = groupContacts(guests);
  return (

        <div
          className={`body-wrapper ${
            navLayout === 'Vertical' ? 'body-wrapper-two' : ''
          }`}
        >
          <div className="body-navleft">
            <nav className="nav flex-column">
              <a href="#tabContact" className="nav-link active" data-toggle="tab">
                <span
                  data-toggle="tooltip"
                  title=""
                  data-placement="right"
                  data-original-title="All Contacts"
                >
                  <FeatherIcon icon="users" />
                </span>
              </a>
              <a href="#tabPhoneCall" className="nav-link" data-toggle="tab">
                <span
                  data-toggle="tooltip"
                  title=""
                  data-placement="right"
                  data-original-title="Recently Contacted"
                >
                  <FeatherIcon icon="phone-call" />
                </span>
              </a>
              <a href="#tabFavorites" className="nav-link" data-toggle="tab">
                <span
                  data-toggle="tooltip"
                  title=""
                  data-placement="right"
                  data-original-title="Favorites"
                >
                  <FeatherIcon icon="star" />
                </span>
              </a>
              <a href="#tabTags" className="nav-link" data-toggle="tab">
                <span
                  data-toggle="tooltip"
                  title=""
                  data-placement="right"
                  data-original-title="Contact Labels"
                >
                  <FeatherIcon icon="tag" />
                </span>
              </a>
              <a href="#tabExport" className="nav-link" data-toggle="tab">
                <span
                  data-toggle="tooltip"
                  title=""
                  data-placement="right"
                  data-original-title="Export Contacts"
                >
                  <FeatherIcon icon="upload" />
                </span>
              </a>
              <a href="" className="nav-link">
                <span
                  data-toggle="tooltip"
                  title=""
                  data-placement="right"
                  data-original-title="Contact Settings"
                >
                  <FeatherIcon icon="settings" />
                </span>
              </a>
            </nav>
          </div>
          <div className="body-sidebar">
            <div className="body-sidebar-header">
              <FeatherIcon icon="search" />
              <div className="search-form">
                <input type="search" className="form-control" placeholder="Search contacts"></input>
              </div>
              <a
                href="#modalNewContact"
                className="btn btn-xs btn-icon btn-primary"
                data-toggle="modal"
              >
                <span data-toggle="tooltip" title="" data-original-title="Add New Contact">
                  <FeatherIcon icon="user-plus" />
                </span>
              </a>
            </div>
            <div className="body-sidebar-body ps">
              <div className="tab-content">
                <div id="tabContact" className="tab-pane fade active show">
                  <div className="pd-y-20 pd-x-10 body-list">
                    {contacts.map((alpabeticalSortedContacts) => {
                      return (
                        <div>
                          <label
                            id={`contact${alpabeticalSortedContacts.letter}`}
                            className="body-list-divider"
                          >
                            {alpabeticalSortedContacts.letter}
                          </label>
                          {alpabeticalSortedContacts?.names.map((currentContact) => {
                            return (
                              <div
                                className={`media ${
                                  guest.name === currentContact.name ? 'active' : ''
                                }`}
                                onClick={() => setGuest(currentContact)}
                              >
                                <div className="avatar avatar-sm avatar-online">
                                  <span class="avatar-initial rounded-circle bg-gray-700">
                                    {currentContact.name[0]}
                                  </span>
                                </div>
                                <div className="media-body mg-l-10">
                                  <h6 className="tx-13 mg-b-3">{currentContact.name}</h6>
                                  <span className="tx-12">{currentContact.phone}</span>
                                </div>
                                <nav>
                                  <a href="">
                                    <FeatherIcon icon="star" />
                                  </a>
                                  <a href="">
                                    <FeatherIcon icon="edit-2" />
                                  </a>
                                </nav>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div id="tabPhoneCall" className="tab-pane fade">
                  <div className="pd-y-20 pd-x-10 body-list">
                    <label className="body-list-divider">
                      <Text>guest.recently_contacted</Text>
                    </label>
                    <div className="media active">
                      <div className="avatar avatar-sm avatar-online">
                        <img
                          src="../../assets/img/img13.jpg"
                          className="rounded-circle"
                          alt=""
                        ></img>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Camille Audrey</h6>
                        <span className="tx-12">+1-234-567-890</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-offline">
                        <span className="avatar-initial rounded-circle bg-success">E</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Elvis Vircede</h6>
                        <span className="tx-12">+63929-123-4567</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div id="tabFavorites" className="tab-pane fade">
                  <div className="pd-y-20 pd-x-10 body-list">
                    <label className="body-list-divider">
                      <Text>guest.my_favourites</Text>
                    </label>
                    <div className="media active">
                      <div className="avatar avatar-sm avatar-online">
                        <img
                          src="../../assets/img/img14.jpg"
                          className="rounded-circle"
                          alt=""
                        ></img>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Archie Cantones</h6>
                        <span className="tx-12">archie@cantones.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-online">
                        <img
                          src="../../assets/img/img11.jpg"
                          className="rounded-circle"
                          alt=""
                        ></img>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Brenda Aceron</h6>
                        <span className="tx-12">brenda@aceron.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-online">
                        <span className="avatar-initial rounded-circle bg-indigo">B</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Brandibelle Yap</h6>
                        <span className="tx-12">byap@urmail.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-online">
                        <img src="../../assets/img/img13.jpg" class="rounded-circle" alt=""></img>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Camille Audrey</h6>
                        <span className="tx-12">+1-234-567-890</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-offline">
                        <span className="avatar-initial rounded-circle bg-success">E</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Elvis Vircede</h6>
                        <span className="tx-12">+63929-123-4567</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div id="tabTags" className="tab-pane fade">
                  <div className="pd-y-20 pd-x-10 body-list">
                    <label className="body-list-divider">
                      <Text>guest.my_familty</Text>
                    </label>
                    <div className="media active">
                      <div className="avatar avatar-sm avatar-online">
                        <span className="avatar-initial rounded-circle bg-gray-700">A</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Abigail Johnson</h6>
                        <span className="tx-12">+1-234-567-890</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-online">
                        <img
                          src="../../assets/img/img14.jpg"
                          className="rounded-circle"
                          alt=""
                        ></img>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Archie Cantones</h6>
                        <span className="tx-12">archie@cantones.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <label className="body-list-divider">
                      <Text>guest.my_friends</Text>
                    </label>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-online">
                        <span className="avatar-initial rounded-circle bg-primary">a</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Allan Rey Palban</h6>
                        <span className="tx-12">allanr@palban.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-offline">
                        <span className="avatar-initial rounded-circle bg-primary">D</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Daniel Calinawan</h6>
                        <span className="tx-12">daniel@youremail.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-offline">
                        <span className="avatar-initial rounded-circle bg-success">E</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Elvis Vircede</h6>
                        <span className="tx-12">+63929-123-4567</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                    <label className="body-list-divider">
                      <Text>guest.my_officemates</Text>
                    </label>
                    <div className="media">
                      <div className="avatar avatar-sm avatar-online">
                        <span className="avatar-initial rounded-circle bg-gray-700">E</span>
                      </div>
                      <div className="media-body mg-l-10">
                        <h6 className="tx-13 mg-b-3">Elena Salubre</h6>
                        <span className="tx-12">e.salubre@myemail.com</span>
                      </div>
                      <nav>
                        <a href="">
                          <FeatherIcon icon="star" />
                        </a>
                        <a href="">
                          <FeatherIcon icon="edit-2" />
                        </a>
                      </nav>
                    </div>
                  </div>
                </div>
                <div id="tabExport" className="tab-pane fade">
                  <div className="pd-y-25 pd-x-20">
                    <h6 className="tx-12 tx-semibold tx-spacing-1 tx-uppercase">
                      <Text>guest.export_contacts</Text>
                    </h6>
                    <p className="tx-13 tx-color-03 mg-b-20">
                      You can export your contacts and import them into other email apps.
                    </p>
                    <div className="form-group">
                      <label className="tx-13">Which contacts do you want to export?</label>
                      <select className="custom-select tx-13">
                        <option value="1" selected="">
                          <Text>guest.export_all_contacts</Text>
                        </option>
                        <option value="2">
                          {' '}
                          <Text>guest.export_my_favourites</Text>
                        </option>
                        <option value="3">
                          <Text>guest.export_my_family</Text>
                        </option>
                        <option value="4">
                          <Text>guest.export_my_friends</Text>
                        </option>
                        <option value="4">
                          <Text>guest.export_my_officemates</Text>
                        </option>
                      </select>
                    </div>
                    <button className="btn btn-sm btn-primary">
                      <Text>guest.export</Text>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ps__rail-x">
                <div className="ps__thumb-x" tabindex="0"></div>
              </div>
              <div className="ps__rail-y">
                <div className="ps__thumb-y" tabindex="0"></div>
              </div>
            </div>
          </div>
          <div className="body-content">
            <div className="body-content-header">
              <nav className="nav">
                <a href="#contactInformation" className="nav-link active" data-toggle="tab">
                  <Text>guest.info</Text>
                </a>
              </nav>
            </div>
            <div className="body-content-body ps">
              <div className="tab-content">
                <div id="guestInformation" className="tab-pane show pd-20 pd-xl-25 active">
                  <div className="d-flex align-currentContacts-center justify-content-between mg-b-25">
                    <h6 className="mg-b-0">
                      <Text>guest.personal_details</Text>
                    </h6>
                    <div className="d-flex">
                      <EditGuest
                        modal={isEditModalOpen}
                        toggle={toggleEditModal}
                        guest={guest}
                        label={editLabel}
                        value={editValue}
                        save={handleSave}
                      ></EditGuest>
                      <a
                        className="btn btn-sm btn-white d-flex align-currentContacts-center mg-l-5"
                        onClick={openNewEmail}
                      >
                        <Text>guest.add_email</Text>
                      </a>
                      <a
                        className="btn btn-sm btn-white d-flex align-currentContacts-center mg-l-5"
                        onClick={openNewPhone}
                      >
                        <Text>guest.add_phone</Text>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-4">
                      <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                        <Text>guest.name</Text>
                      </label>
                      <p className="mg-b-0">
                        {guest.name}
                        <a className="mg-l-5" onClick={openEditName}>
                          <i className="fas fa-edit"></i>
                        </a>
                      </p>
                    </div>
                    <div className="col-6 col-sm-4">
                      <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                        <Text>guest.channel_profile</Text>
                      </label>
                      <p className="mg-b-0">{guest.channel_profile}</p>
                    </div>
                  </div>
                  <h6 className="mg-t-40 mg-b-20">
                    <Text>guest.contact_details</Text>
                  </h6>
                  <div className="row row-sm">
                    {guest?.emails?.map((email) => (
                      <div key={email.id} className="col-6 col-sm-4">
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                          <Text>guest.email</Text>
                        </label>
                        <p className="tx-rubik mg-b-0">
                          {email.email}
                          <a className="mg-l-5" onClick={() => openEdcurrentContactail(email.id)}>
                            <i className="fas fa-edit"></i>
                          </a>
                          {guest.emails.length > 1 && (
                            <a className="mg-l-5" onClick={() => deleteEmail(email.id)}>
                              <i className="fas fa-times"></i>
                            </a>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="row row-sm">
                    {guest?.phones?.map((phone) => (
                      <div key={phone.id} className="col-6 col-sm-4 mg-t-20 mg-sm-t-30">
                        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                          <Text>guest.phone_number</Text>
                        </label>
                        <p className="mg-b-0">
                          {phone.number}
                          <a className="mg-l-5" onClick={() => openEditPhone(phone.id)}>
                            <i className="fas fa-edit"></i>
                          </a>
                          {guest.phones.length > 1 && (
                            <a className="mg-l-5" onClick={() => deletePhone(phone.id)}>
                              <i className="fas fa-times"></i>
                            </a>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>

                  <h6 className="mg-t-40 mg-b-20">
                    <Text>guest.notes</Text>
                  </h6>
                  {guest?.notes?.map((note) => (
                    <div key={note.id} className="my-2 rounded">
                      <Toast>
                        <ToastHeader>
                          {note.name}
                          <Button close onClick={() => handleNoteDeleteClick(guest.id, note.id)} />
                        </ToastHeader>
                        <ToastBody>{note.note}</ToastBody>
                      </Toast>
                    </div>
                  ))}
                  <div className="row row-sm">
                    <FormGroup>
                      <Label for="notes"></Label>
                      <Input
                        type="textarea"
                        className="note"
                        placeholder="Add new note"
                        name="notes"
                        id="notes"
                        value={note}
                        onChange={(ev) => setNote(ev.target.value)}
                      />
                      {note !== '' ? (
                        <Button
                          className="note-button"
                          color="primary"
                          size="sm"
                          onClick={() =>
                            handleAddNewNote(guest.id, {
                              ...guest,
                              ...guest.notes.push({ note: note }),
                            })
                          }
                        >
                          <Text>guest.submit</Text>
                        </Button>
                      ) : null}
                    </FormGroup>
                  </div>
                  <h6 className="mg-t-40 mg-b-20">
                    <Text>guest.reservations</Text>
                  </h6>
                  <div className="row row-sm">
                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {' '}
                            <Text>guest.reservations.confirmation_code</Text>
                          </th>
                          <th>
                            <Text>guest.reservations.check_in</Text>
                          </th>
                          <th>
                            <Text>guest.reservations.check_out</Text>
                          </th>
                          <th>
                            <Text>guest.reservations.status</Text>
                          </th>
                          <th>
                            <Text>guest.reservations.channel</Text>
                          </th>
                          <th>
                            <Text>guest.reservations.price</Text>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {guest?.reservations?.map((reservation) => (
                          <tr key={reservation.id}>
                            <th scope="row">{reservation.id}</th>
                            <td>
                              {reservation.confirmation_code
                                ? reservation.confirmation_code
                                : `link to reservation`}
                            </td>
                            <td>{reservation.check_in}</td>
                            <td>{reservation.check_out}</td>
                            <td>{reservation.status}</td>
                            <td>{reservation.channel}</td>
                            <td>{reservation.total_price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div className="body-content-sidebar ps">
              <div className="clearfix mg-b-25">
                <div id="contactAvatar" className="pos-relative float-left">
                  <div className="avatar avatar-xl">
                    <span className="avatar-initial rounded-circle bg-gray-700">
                      {guest?.name?.slice(0, 1)}
                    </span>
                  </div>
                  <a
                    href=""
                    className="body-edit-photo"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Upload Photo"
                  >
                    <FeatherIcon icon="edit-2" />
                  </a>
                </div>
              </div>
              <h5 id="contactName" className="tx-18 tx-xl-20 mg-b-5">
                {guest.name}
              </h5>
              <p className="tx-13 tx-lg-12 tx-xl-13 tx-color-03 mg-b-20">
                President &amp; CEO - ThemePixels, Inc.
              </p>
              <nav className="body-call-nav mg-b-20">
                <a
                  href="#"
                  className="nav-call"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Make a Phone Call"
                >
                  <FeatherIcon icon="phone" />
                </a>
                <a
                  href="#"
                  className="nav-video"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Make a Video Call"
                >
                  <FeatherIcon icon="video" />
                </a>
                <a
                  href="#"
                  className="nav-msg"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Send Message"
                >
                  <FeatherIcon icon="message-square" />
                </a>
              </nav>

              <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                <Text>guest.biography</Text>
              </label>
              <p className="tx-13 mg-b-0">
                Gambler, Tea Drinker, Ultimate Piggie, Replacement President of a Major Soft Drink
                Manufacturer. When I give out candies, I give people the flavour I don't like.{' '}
              </p>

              <hr className="mg-y-20"></hr>

              <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-15">
                <Text>guest.options</Text>
              </label>
              <nav className="nav flex-column body-content-nav mg-b-25">
                <a href="" className="nav-link">
                  <FeatherIcon icon="share" />
                  <Text>guest.share</Text>
                </a>
                <a href="" className="nav-link">
                  <FeatherIcon icon="star" />
                  <Text>guest.add</Text>
                </a>
                <a href="" className="nav-link">
                  <FeatherIcon icon="slash" />
                  <Text>guest.block</Text>
                </a>
              </nav>

              <div className="ps__rail-x">
                <div className="ps__thumb-x" tabindex="0"></div>
              </div>
              <div class="ps__rail-y">
                <div class="ps__thumb-y" tabindex="0"></div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default SingleGuest;
