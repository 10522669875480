import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../../components/Base/Loading';

const Logout = () => {
  const { logout, isAuthenticated } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
  useEffect(() => {
    if (isAuthenticated) {
      logoutWithRedirect();
    }
  });
  return <Loading />;
};

export default Logout;
