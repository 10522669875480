import './ForgottenPassword.css';
import { default as fs_logo } from '../../assets/icons/fs-logo.svg';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EMAIL_REGEX } from '../../common/constants';
import * as authActions from '../../store/actions/auth';
import SubmitBtn from '../../components/elements/SubmitBtn';

const ForgottenPassword = () => {
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {   
        if (email.match(EMAIL_REGEX)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }, [email]);

    const submitEmail = async () => {
        setIsRequestPending(true);
        try {
            await dispatch(authActions.resetPassword(email));
            setIsRequestPending(false);
            setDidSubmitSucceed(true);
        } catch (error) {
            setIsRequestPending(false);
            setDidSubmitSucceed(false);
        }
    };

    return (
        <div className='the-whole-sign-up-page'>
            <div className='left-side-of-sign-up'>
                <div>
                    <img src={fs_logo} alt='' />
                </div>
                <div className='sign-up-mid-section'>
                    <div className='reset-your-password-header-text w-auto'>Reset your password</div>
                    <div className='sign-up-password-header'>Email</div>
                    <input
                        type='text'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className='input-sign-up-email-address mb-1' style={{marginRight: '15px'}}>
                    </input>
                    <div className='sign-up-password-details-text mt-0 pt-0'>You will receive an email with a link to reset your password.</div>
                    {validEmail ?
                        <SubmitBtn
                            submittingState={isRequestPending}
                            didSubmitSucceed={didSubmitSucceed}
                            submitFunction={submitEmail}
                            disabled={false}
                            submittingClassName='forgotten-password-sending-btn'
                            submitClassName='forgotten-password-send-btn'
                            disabledBtnClassName='inv-form-invite-btn-disabled'
                            failedSubmitClassName='forgotten-password-send-btn-failed-submit'
                            submitText='Send'
                            submittingText='Sending'
                            promiseSucceedText='Sent'
                            promiseFailText='Not Sent'
                        />
                    :
                        <div className='forgotten-password-send-btn-disabled'>
                            <div className='text-inside-set-your-password-next-button-disabled'>Send</div>
                        </div>
                    }
                </div>
            </div>
            <div className='sign-up-page-image' style={{backgroundPosition: 'center center'}}></div>
        </div>
    );
};

export default ForgottenPassword;
