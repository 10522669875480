import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PagePreloader from '../../Base/PagePreloader';
import FSMultipleSelect from '../../../components/elements/FSMultipleSelect.js';
import FSSelect from '../../../components/elements/FSSelect.js';
import Text from '../../ui/Text';
import { Button } from 'reactstrap';
import '../css/BulkEditListings.scss';

import * as listActions from '../../../store/actions/list';

const BulkEditListings = (props) => {
  const { objectType, toggle, modal, selectedIds } = props;
  const options = [
    { value: '', text: 'Select' },
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' },
  ];
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [services, setServices] = useState(options[0].value);
  const [masterCalendar, setMasterCalendar] = useState(options[0].value);
  const [selectedTags, setSelectedTags] = useState([]);
  const [cleaningFee, setCleaningFee] = useState('');
  const [guestFee, setGuestFee] = useState('');
  const [markUp, setMarkUp] = useState('');
  const [ownerRevenue, setOwnerRevenue] = useState('');
  const [netRevenue, setNetRevenue] = useState('');
  const [deposit, setDeposit] = useState('');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const tags = useSelector((state) => state.list.tags);

  useEffect(() => {
    const initData = async () => {
      await dispatch(listActions.getTags(objectType));
      setIsLoaded(true);
    };
    initData();
  }, []);

  if (!isLoaded) {
    return <PagePreloader />;
  }

  const handleSubmit = async () => {
    const data = {
      fantastic_services: services,
      master_calendar: masterCalendar,
      tags: selectedTags,
      security_deposit: deposit,
      cleaning_fee: cleaningFee,
      extra_person: guestFee,
      price_markup: markUp,
      owner_revenue_formula: ownerRevenue,
      net_revenue_formula: netRevenue,
    };

    setRequestInProgress(true);
    const isEmpty = !Object.values(data).some(
      (field) => field !== null && field !== '' && field.length,
    );

    if (isEmpty) {
      setRequestInProgress(false);
    } else {
      let finalData = Object.fromEntries(
        Object.entries(data).filter(([_, field]) => field != null && field !== '' && field.length),
      );
      try {
        dispatch(listActions.listingbulkEdit(finalData, selectedIds));
        dispatch(listActions.getItems(objectType));
        toggle();
      } catch (e) {
        alert(e.error);
      }
    }
  };

  const handleClose = () => {
    toggle();
    dispatch(listActions.setSelectedIds([]));
  };

  return (
    <div
    onClick={(e) => e.stopPropagation()}
    className={modal ? 'side-menu' : 'hidden-side-menu'}
    >
      <button className='close-button' onClick={handleClose}>
        X
      </button>
      <div className='scrollable-content'>
        <label style={{ fontSize: '18px' }}>
          <Text>Fantastic Services</Text>
        </label>
        <div className="input-container">
        <FSSelect
              selected={services}
              options={options}
              onChange={(v) => setServices(v.target.value)}
            />
             </div>
          <br/>
         
            <div className="extra-info">
              {' '}
              In order to activate any of our services Fantastic Services must be ON. Note, you are
              going to be charged for each booking when this option is activated.{' '}
              <Link
                to="http://help.fantasticstay.com/fantastic-services/the-fantastic-services-toggle"
                target="_blank"
              >
                <Text>Learn more</Text>
              </Link>
              .{' '}
            </div>
            <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Master calendar</Text>
          </label>
          <div className="input-container">
          <FSSelect
              selected={masterCalendar}
              options={options}
              onChange={(v) => setMasterCalendar(v.target.value)}
            />
      </div>
          <br/>
            <div className="extra-info">
              Once you activate the PMS services by default the PMS will become your Master
              Calendar. It will override all calendar changes made directly in any channel. If you
              disable this option this would allow you to make changes to the Calendar(s) directly
              into the channels. The PMS will sync changes made directly into Airbnb once every few
              hours.
            </div>
            <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Assign tags</Text>
          </label>
          <div className="input-container" style={{maxWidth: '20rem' }}>
            <FSMultipleSelect
              selected={selectedTags}
              options={tags}
              onChange={(v) => setSelectedTags(v.target.value)}
            />
          </div>
          <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Security deposit</Text>
          </label>
          <br/>
          <input type="number" onChange={(v) => setDeposit(v.target.value)} style={{ fontSize: '18px' }} />
          <br/>
          <div className="extra-info"> Do you require security deposit?</div>
          <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Cleaning fee</Text>
          </label>
          <br/>
          <input type="number" onChange={(v) => setCleaningFee(v.target.value)} style={{ fontSize: '18px' }} />
          <br/>
          <div className="extra-info"> What is your listing fee? </div>
          <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Extra guest fee</Text>
          </label>
          <br/>
          <input type="number" onChange={(v) => setGuestFee(v.target.value)} style={{ fontSize: '18px' }} />
          <br/>
          <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Markup</Text>
          </label>
          <br/>
          <input type="number" onChange={(v) => setMarkUp(v.target.value)} style={{ fontSize: '18px' }} />
          <br/>
          <div className="extra-info">
            Do you wish the prices to be marked up with a %, when pushed to the channel?
          </div>
          <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Owner revenue formula</Text>
          </label>
          <br/>
          <input type="text" onChange={(v) => setOwnerRevenue(v.target.value)} style={{ fontSize: '18px' }} />
          <br/>
          <br/>
          <label style={{ fontSize: '18px' }}>
            <Text>Net revenue formula</Text>
          </label>
          <br/>
          <input type="text" onChange={(v) => setNetRevenue(v.target.value)} style={{ fontSize: '18px' }} />
          <br />
          <br />
          <Button color='primary' inProgress={requestInProgress} onClick={handleSubmit}>
            <Text>Save</Text>
          </Button>{' '}
          <Button color='secondary' onClick={handleClose}>
            <Text>Cancel</Text>
          </Button>
        </div>
    </div>
  );
};

export default BulkEditListings;
