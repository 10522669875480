import { useState } from 'react';
import Text from '../../../components/ui/Text';
import './LastBookings.scss';

const LastBookings = ({ mltc }) => {
    const [reservationId, setReservationId] = useState(0);

    const openMoreInfo = (e) => {
        setReservationId(e.target.id);
    };

    return (
        <>
            {mltc.calendar.map((cal) => {
                if (cal.status === 'booked') {
                    return (
                        <div key={cal.id}>
                            <Text>Guest:</Text> {cal?.reservation?.guest_name} {' '}
                            <Text>Nights:</Text> {cal?.reservation?.nights} <br />
                                   {Number(reservationId) === Number(cal?.reservation?.id) ? (
                                <button
                                    id={cal?.reservation?.id}
                                    onClick={() => setReservationId(0)}
                                >
                                    <Text>Close</Text>
                                </button>
                            ) : (
                                <button id={cal?.reservation?.id} onClick={(e) => openMoreInfo(e)}>
                                    <Text>Open</Text>
                                </button>
                            )}{' '}
                            <br />
                            <div
                                className={
                                    Number(reservationId) === Number(cal?.reservation?.id)
                                        ? 'show-more'
                                        : 'hide-show-more'
                                }
                            >
                                <Text>Phone:</Text> {cal?.reservation?.guest_phone}
                                <br />
                                <Text>Email: </Text>
                                {cal?.reservation?.guest_email}
                                <br />
                                <Text>Check-in</Text>: {cal?.reservation?.check_in}
                                <Text>Check-out</Text>: {cal?.reservation?.check_out}
                                <br />
                                <br />
                                <Text>Guests:</Text> {cal?.reservation?.guests}
                                <br />
                                <Text>Channel:</Text> {cal?.reservation?.channel}
                                <br />
                                <Text>Payout:</Text> ${cal?.reservation?.price.toFixed(2)}
                            </div>
                            <hr />
                        </div>
                    );
                } else {
                    return <div key={cal.id}></div>;
                }
            })}
        </>
    );
};

export default LastBookings;
