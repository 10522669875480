import ReactDom from 'react-dom';
import SubmitBtn from '../../../components/elements/SubmitBtn';
import Text from '../../../components/ui/Text';

const RefreshApiKeyModal = ({ open, onClose, submittingState, didSubmitSucceed, submitFunction }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <div className='api-key-modal'>
      <div className="api-key-modal-overlay-styles" />
      
      <div className="api-key-modal-styles">
        <div className='close-api-key-container'>
          <button className="p-0 close-api-key-modal-button" onClick={onClose}>&times;</button>
        </div>

        <div className='api-key-modal-middle-container'>
          <div>
            <label><Text>secondary.refresh_api_key_confirmation</Text></label>
          </div>

          <div className='d-flex align-items-center pt-1'>
            <SubmitBtn
              submittingState={submittingState}
              didSubmitSucceed={didSubmitSucceed}
              submitFunction={submitFunction}
              disabled={false}
              submittingClassName='refreshing-api-key-btn'
              submitClassName='refresh-api-key-btn'
              disabledBtnClassName='refresh-api-key-btn-disabled'
              failedSubmitClassName='refresh-api-key-btn-failed-submit'
              submitText='action.refresh_key'
              submittingText='action.refreshing'
              promiseSucceedText='action.refreshed'
              promiseFailText='action.not_refreshed'
            />

            <div className='delete-modal-cancel-btn' onClick={onClose}>
              <u>{didSubmitSucceed ? <Text>action.close</Text> : <Text>action.cancel</Text>}</u>
            </div>
          </div>
        </div>
      </div>
    </div >,
    document.getElementById('centered-modal-portal')
  );
}

export default RefreshApiKeyModal;