import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FSSelect from './FSSelect.js';

import * as commonActions from '../../utils/common';
import * as siteActions from '../../store/actions/sites';

const itemsPerPageOptions = [10, 20, 30, 50, 100];

const FSSitesPagination = (props) => {
  const [canIncrPage, setCanIncrPage] = useState(false);
  const [canDecrPage, setCanDecrPage] = useState(false);
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.site.page);
  const itemsPerPage = useSelector((state) => state.site.itemsPerPage);
  const totalItemsCount = useSelector((state) => state.site.total);

  const setRestrictions = () => {
    setCanIncrPage(currentPage * itemsPerPage + 1 < totalItemsCount);
    setCanDecrPage(currentPage > 1);
  };

  useEffect(() => {
    setRestrictions();
  }, [currentPage, totalItemsCount, itemsPerPage]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) {
      return;
    }

    if ((newPage - 1) * itemsPerPage > totalItemsCount) {
      return;
    }

    handlePageChangee(newPage, itemsPerPage);
  };
  const handlePrevPageClick = () => {
    if (!canDecrPage) {
      return;
    }
    handlePageChange(parseInt(currentPage) - 1);
    dispatch(siteActions.setPageNumber(parseInt(currentPage) - 1));
    dispatch(siteActions.getSites());
  };

  const handleNextPageClick = async () => {
    if (!canIncrPage) {
      return;
    }
    handlePageChange(parseInt(currentPage) + 1);
    await dispatch(siteActions.setPageNumber(parseInt(currentPage) + 1));
    dispatch(siteActions.getSites());
  };
  const handleItemsPerPageChange = (event) => {
    const itemsPerPage = parseInt(event.target.value);

    if (itemsPerPageOptions.indexOf(itemsPerPage) === -1) {
      return;
    }

    handlePageChangee(currentPage, itemsPerPage);
  };
  const handlePageChangee = async (currentPage, itemsPerPage) => {
    let params = commonActions.getURLParams();

    if (currentPage === 1) {
      delete params['page'];
    } else {
      params['page'] = currentPage;
    }

    if (itemsPerPage === 20) {
      delete params['perPage'];
    } else {
      params['perPage'] = itemsPerPage;
    }
    await dispatch(siteActions.setItemsPerPage(itemsPerPage));
    dispatch(siteActions.getSites());
    commonActions.buildUrlQuery(params);
  };

  const rowsPerPageOptions = itemsPerPageOptions.map((n) => {
    return { value: n, text: n };
  });

  if (parseInt(totalItemsCount) === 0) {
    return '';
  }

  const resultStart = (parseInt(currentPage) - 1) * itemsPerPage + 1;
  const resultEnd = Math.min(resultStart + itemsPerPage - 1, totalItemsCount);

  return (
    <>
      <div className="rows-text mr-3">Sites per page:</div>
      <div className="rows-number">
        <FSSelect
          options={rowsPerPageOptions}
          className='rows-select-number'
          selected={itemsPerPage}
          onChange={(ev) => handleItemsPerPageChange(ev)}
        />
      </div>
      <div className="count-text ml-20">
        {resultStart}-{resultEnd} of {totalItemsCount}
      </div>
      <div className='sites-pagination-next-prev-buttons'>
        <button
          className={`page-nav-prev-page${!canDecrPage ? ' disabled' : ''}`}
          onClick={() => handlePrevPageClick()}
        >
          <i className="fa fa-angle-left"></i>
        </button>
        <button
          className={`page-nav-next-page${!canIncrPage ? ' disabled' : ''}`}
          onClick={() => handleNextPageClick()}
        >
          <i className="fa fa-angle-right"></i>
        </button>
      </div>
      </>

  );
};

export default FSSitesPagination;
