import React, { useState } from 'react';
import SidebarMenu from './SidebarMenu';
import FSInput from "../../elements/FSInput";
import FSButton from '../../elements/FSButton';
import Text from '../../ui/Text';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const ConnectAccount = () => {
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const permissions = useSelector((state) => state.auth.permissions);

    const history = useHistory();
    if (!permissions.includes('ConnectAccount')) {
        history.push('/access-denied')
    }

    return (
        <div className="app-wrapper">
            <SidebarMenu />
            <section className="app-right--contain">
                <div className="add-app-page-content">
                    <h2 className="main-title"><Text>Add RentalsUnited account</Text></h2>
                    <div className="row" style={{marginLeft: '1rem'}}>
                        <div style={{width: '150px'}}>
                            <div className="form-group">
                                <label>Nickname</label>
                                <FSInput value={nickname} onChange={(v) => setNickname(v)} />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <FSInput value={email} onChange={(v) => setEmail(v)} />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <FSInput value={password} onChange={(v) => setPassword(v)} />
                            </div>
                            <div className="form-group">
                                <FSButton className="button button-solid button-inline button-medium" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ConnectAccount;
