import React from 'react'
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet'

function PageTitle(props) {
    const key = props.children.toString();
    const translation = useSelector(state => state.translation.translations[key]);
    return (
        <>
            <Helmet>
                <title>{ translation || key || '' }</title>
            </Helmet>
        </>
    );
}

export default PageTitle;
