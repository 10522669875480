import React, { useState } from 'react';
import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/ui/PageTitle';
import Text from '../../components/ui/Text';
import PagePreloader from "../../components/Base/PagePreloader";

const Dashboard = () => {
    const user = useSelector((state) => state.auth.user);
    const customer = useSelector((state) => state.auth.customer);

    if (!customer.hasOwnProperty('id')) {
        return <PagePreloader/>
    }

    var METABASE_SITE_URL = "https://bi.fsapp.io";
    var METABASE_SECRET_KEY = "ac24113778cc02568f86e5dceaff3e8cf1a1d152b19771c96adebb9f244e4c04";
    var payload = {
        resource: { dashboard: 6 },
        params: { customer_id: customer.id },
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };

    var token = jwt.sign(payload, METABASE_SECRET_KEY);
    var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true&theme=night";

    return (
        <div>
            <PageTitle>Home</PageTitle>
            <Text>Hi</Text>, {user.name}

            <div>
                <iframe
                    src={iframeUrl}
                    frameBorder="0"
                    width="100%"
                    height="820"
                    style={{position: 'relative'}}
                ></iframe>
            </div>
        </div>
    );
};

export default Dashboard;
