import { Fragment } from 'react';

const ListTableItemPrice = (props) => {
  const s = props.value < 0 ? '-' : '';
  return (
    <Fragment>
      {props.format && props.value
        ? s + props.format.replace('{num}', parseFloat(Math.abs(props.value)).toFixed(2))
        : props.value}
    </Fragment>
  );
};

export default ListTableItemPrice;
