/* eslint-disable react-hooks/exhaustive-deps */
import Text from "../../../../components/ui/Text";
import { PERMISSION_AVAILABILITY, PERMISSION_CALENDAR, PERMISSION_CONTACT_INFORMATION, PERMISSION_CREDIT_CARDS_INFORMATION, PERMISSION_FINANCIAL_INFORMATION, PERMISSION_PRICING } from "../../../../common/constants";

const CUSTOMIZABLE_PERMISSIONS = [
  {
    key: 'permissions.calendar',
    permission_name: PERMISSION_CALENDAR
  },
  {
    key: 'permissions.financial_information',
    permission_name: PERMISSION_FINANCIAL_INFORMATION
  },
  {
    key: 'permissions.availability',
    permission_name: PERMISSION_AVAILABILITY
  },
  {
    key: 'permissions.credit_cards_information',
    permission_name: PERMISSION_CREDIT_CARDS_INFORMATION
  },
  {
    key: 'permissions.pricing',
    permission_name: PERMISSION_PRICING
  },
  {
    key: 'permissions.contact_information',
    permission_name: PERMISSION_CONTACT_INFORMATION
  }
]

const PermissionsComponent = ({ selectedRole, localPermissions, setLocalPermissions, setAdditionalPerms, additionalPerms }) => {

  const assignAdditionalPerms = (permission_name, access) => {
    if (selectedRole.permissions[permission_name] === additionalPerms[permission_name]) {
      delete additionalPerms[permission_name];
      setAdditionalPerms(additionalPerms);
    } else {
      setAdditionalPerms({ ...additionalPerms, [permission_name]: access });
    }
  };

  return (
    <div className='d-flex mt-5 inv-form-permissions-component'>
      <div className='ml-auto inv-form-permissions-labels'>
        <label className='single-user-select-header'><Text>secondary.permissions</Text></label>
        {CUSTOMIZABLE_PERMISSIONS.map(cp => (
          <div key={cp.key}>
            <label className='mb-0 single-user-permission'><Text>{cp.key}</Text></label>
          </div>
        ))}
      </div>
      <div className='ml-2'>
        <div className='single-user-permission-header-holder'>
          <label className='mb-0 single-user-permission-header'><Text>main.none</Text></label>
          <label className='mb-0 single-user-permission-header'><Text>main.read</Text></label>
          <label className='mb-0 single-user-permission-header'><Text>main.write</Text></label>
        </div>

        {CUSTOMIZABLE_PERMISSIONS.map((cp, index) => (
          <div className='d-flex' key={index}>
            <div
              className={!localPermissions.hasOwnProperty(cp.permission_name) ? `outer-active-radio-button` : `outer-inactive-radio-button`}
              onClick={() => {
                const newPermissions = { ...localPermissions };
                delete newPermissions[cp.permission_name];
                setLocalPermissions(newPermissions);
                assignAdditionalPerms(cp.permission_name, 'none');
              }}
            >
              <div className='inner-active-radio-button'></div>
            </div>

            <div
              className={localPermissions.hasOwnProperty(cp.permission_name) && localPermissions[cp.permission_name] === 'read' ? `outer-active-radio-button` : `outer-inactive-radio-button`}
              onClick={() => {
                const newPermissions = { ...localPermissions };
                newPermissions[cp.permission_name] = 'read';
                setLocalPermissions(newPermissions);
                assignAdditionalPerms(cp.permission_name, 'read');
              }}
            >
              <div className='inner-active-radio-button'></div>
            </div>

            <div
              className={localPermissions.hasOwnProperty(cp.permission_name) && localPermissions[cp.permission_name] === 'write' ? `outer-active-radio-button` : `outer-inactive-radio-button`}
              onClick={() => {
                const newPermissions = { ...localPermissions };
                newPermissions[cp.permission_name] = 'write';
                setLocalPermissions(newPermissions);
                assignAdditionalPerms(cp.permission_name, 'write');
              }}
            >
              <div className='inner-active-radio-button'></div>
            </div>
          </div>
        ))}
      </div>
    </div >
  )
}

export default PermissionsComponent;