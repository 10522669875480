import { Link } from 'react-router-dom';
import Text from '../../components/ui/Text';
import error_img from '../../assets/img/error-img.png';

const TechnicalError = () => {

  return (
    <div className="content content-fixed content-auth-alt">
      <div className="container ht-100p tx-center">
        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
          <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15"><img src={error_img} className="img-fluid" alt="" /></div>
          <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5"><Text>500 Internal Server Error</Text></h1>
          <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20"><Text>Oopps. There was an error, please try again later.</Text></h5>
          <p className="tx-color-03 mg-b-30"><Text>The server encountered an internal server error and was unable to complete your request.</Text></p>
          <div className="mg-b-40"><Link to="/" className='back-to-home-btn'><Text>Back to Home</Text></Link></div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalError;
