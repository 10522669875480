export const filterParamsFunc = (filters) => {
    let params = [];
    const regularFilterOptions = ['eq', 'ne', 'lt', 'gt', 'le', 'ge'];

    filters.forEach(filter => {
        if (typeof filter.value === 'object' && filter.option !== 'between' && filter.field !== 'tags') {
            params.push('(' + filter.value.map(val => `${filter.field} eq '${val}'`).join(' or ') + ')');
        } else if (filter.option === 'between') {
            params.push(`(${filter.field} ge ${filter.value[0]} and ${filter.field} le ${filter.value[1]})`);
        } else if (filter.field === 'tags') {
            params.push('(' + filter.value.map(val => `tags/any(t: t/tag eq '${val}')`).join(' or ') + ')');
        } else {
            if (regularFilterOptions.includes(filter.option)) {
                if (filter.value === 'true' || filter.value === 'false' || !isNaN(+filter.value)) {
                    params.push(`${filter.field} ${filter.option} ${filter.value}`);
                } else {
                    params.push(`${filter.field} ${filter.option} '${filter.value}'`);
                }
            } else {
                params.push(`${filter.option}(${filter.field}, '${filter.value}')`);
            }
        }
    });

    return params.join(' and ');
}
