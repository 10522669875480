import './DeactivatedAccount.css';
import Text from '../../components/ui/Text';
import error_img from '../../assets/img/error-img.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';

const DeactivatedAccount = () => {
  const history = useHistory();
  const { logout } = useAuth0();

  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className="content content-fixed content-auth-alt">
      <div className="container ht-100p tx-center">
        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
          <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15"><img src={error_img} className="img-fluid" alt="" /></div>
          <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5"><Text>403 error.forbidden</Text></h1>
          <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
            <Text>error.acc_restricted_workspace</Text></h5>
          <p className="tx-color-03 mg-b-30"><Text>tooltip.contact_admin</Text></p>
          <div className='d-flex'>
            <div className='deactivated-account-button mr-3' onClick={() => logoutWithRedirect()}>
              <label className='mb-0'><Text>action.back_to_login</Text></label>
            </div>

            <div className='deactivated-account-button' onClick={() => history.push('/name-your-workspace')}>
              <label className='mb-0'><Text>action.start_own_workspace</Text></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeactivatedAccount;
