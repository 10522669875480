const FSCheckbox = (props) => {
  const { checked, autoform, autoformSrc } = props;

  const handleChange = (event) => {
    if (typeof props.onChange === 'function') {
      props.onChange(event.target.value);
    }
  };

  const cls = props.className ? props.className : '';

  return (
    <label className="checkbox-label d-flex">
      <input
        type="checkbox"
        style={{ display: 'none' }}
        checked={props.checked}
        onChange={handleChange}
        value={props.value || 'checked'}
      />
      <span className={cls + 'checkbox' + (checked ? ' checked' : '')}>
        {checked && <span className="checked-mark"></span>}
      </span>
      {props.label}

      {autoform && (
        <div>
          <img
            src={autoformSrc}
            alt=""
            style={{
              width: '100%',
              maxWidth: '250px',
              marginTop: 30,
              marginLeft: -20,
              marginRight: 15,
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
            }}
          />
        </div>
      )}
    </label>
  );
};

export default FSCheckbox;
