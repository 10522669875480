import { SET_PERMISSIONS, SET_USER_THEME, SET_USER_NAVIGATION, SET_CUSTOMER, SET_SUBSCRIPTION_ID, SET_USER, SET_ALL_PERMISSIONS } from '../actions/auth';

const initialState = {
  user: {},
  token: '',
  permissions: [],
  customer: {},
  theme: '',
  navigation: '',
  subscriptionId: '',
  allPermissions: []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
        token: action.token
      };
    case SET_USER_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case SET_USER_NAVIGATION:
      return {
        ...state,
        navigation: action.navigation,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
      };
    case SET_SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.subscriptionId,
      };
    case SET_ALL_PERMISSIONS:
      return {
        ...state,
        allPermissions: action.allPermissions
      }
    default:
      return state;
  }
};

export default authReducer;
