import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PageTitle from '../../components/ui/PageTitle';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const Inbox = () => {
    //    const { user } = useAuth0();
    
    const permissions = useSelector((state) => state.auth.permissions);

    const history = useHistory();
    if (!permissions.includes('inbox.read')) {
        history.push('/access-denied')
    }

    return (
        <div>
            <PageTitle>inbox</PageTitle>
            <div className='chat-wrapper'>
                <div className='chat-navleft'>
                    <nav className='nav flex-column'>
                        <a
                            href=''
                            className='nav-link'
                            data-toggle='tooltip'
                            title='Contacts'
                            data-placement='right'
                        >
                            <FeatherIcon icon='users' />
                        </a>
                        <a
                            href=''
                            className='nav-link active'
                            data-toggle='tooltip'
                            title='Messages'
                            data-placement='right'
                        >
                            <FeatherIcon icon='message-square' />
                        </a>
                        <a
                            href=''
                            className='nav-link'
                            data-toggle='tooltip'
                            title='Phone Calls'
                            data-placement='right'
                        >
                            <FeatherIcon icon='phone' />
                        </a>
                        <a
                            href=''
                            className='nav-link'
                            data-toggle='tooltip'
                            title='Mentions'
                            data-placement='right'
                        >
                            <FeatherIcon icon='at-sign' />
                        </a>
                        <a
                            href=''
                            className='nav-link'
                            data-toggle='tooltip'
                            title='Help'
                            data-placement='right'
                        >
                            <FeatherIcon icon='help-circle' />
                        </a>
                    </nav>
                </div>

                <div className='chat-sidebar'>
                    <div className='chat-sidebar-header'>
                        <a
                            href='#'
                            data-toggle='dropdown'
                            className='dropdown-link'
                        >
                            <div className='d-flex align-items-center'>
                                <div className='avatar avatar-sm mg-r-8'>
                                    <span className='avatar-initial rounded-circle'>
                                        T
                                    </span>
                                </div>
                                <span className='tx-color-01 tx-semibold'>
                                    TeamName
                                </span>
                            </div>
                            <span>
                                <i data-feather='chevron-down'></i>
                            </span>
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='user-plus' /> Invite People
                            </a>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='plus-square' /> Create
                                Channel
                            </a>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='server' /> Server Settings
                            </a>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='bell' /> Notification
                                Settings
                            </a>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='zap' /> Privacy Settings
                            </a>
                            <div className='dropdown-divider'></div>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='edit-3' /> Edit Team Details
                            </a>
                            <a href='' className='dropdown-item'>
                                <FeatherIcon icon='shield-off' /> Hide Muted
                                Channels
                            </a>
                        </div>
                    </div>

                    <div className='chat-sidebar-body'>
                        <PerfectScrollbar>
                            <div className='flex-fill pd-y-20 pd-x-10'>
                                <div className='d-flex align-items-center justify-content-between pd-x-10 mg-b-10'>
                                    <span className='tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1'>
                                        All Channels
                                    </span>
                                    <a
                                        href='#modalCreateChannel'
                                        className='chat-btn-add'
                                        data-toggle='modal'
                                    >
                                        <span
                                            data-toggle='tooltip'
                                            title='Create Channel'
                                        >
                                            <FeatherIcon icon='plus-circle' />
                                        </span>
                                    </a>
                                </div>
                                <nav
                                    id='allChannels'
                                    className='nav flex-column nav-chat mg-b-20'
                                >
                                    <a
                                        href='#general'
                                        className='nav-link active'
                                    >
                                        # general
                                    </a>
                                    <a href='#engineering' className='nav-link'>
                                        # engineering
                                    </a>
                                    <a href='#products' className='nav-link'>
                                        # products{' '}
                                        <span className='badge badge-danger'>
                                            2
                                        </span>
                                    </a>
                                </nav>
                            </div>

                            <div className='flex-fill pd-y-20 pd-x-10 bd-t'>
                                <p className='tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1 pd-l-10 mg-b-10'>
                                    Direct Messages
                                </p>
                                <div
                                    id='chatDirectMsg'
                                    className='chat-msg-list'
                                >
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial bg-dark rounded-circle'>
                                                b
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>dfbot</h6>
                                            <small className='d-block tx-color-04'>
                                                5 mins ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/350'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>situmay</h6>
                                            <small className='d-block tx-color-04'>
                                                1 hour ago
                                            </small>
                                        </div>

                                        <span className='badge badge-danger'>
                                            3
                                        </span>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-offline'>
                                            <img
                                                src='https://via.placeholder.com/600'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>
                                                acantones
                                            </h6>
                                            <small className='d-block tx-color-04'>
                                                2 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-offline'>
                                            <img
                                                src='https://via.placeholder.com/500'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>rlabares</h6>
                                            <small className='d-block tx-color-04'>
                                                2 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/500'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>h.owen</h6>
                                            <small className='d-block tx-color-04'>
                                                3 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial bg-primary rounded-circle'>
                                                k
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>k.billie</h6>
                                            <small className='d-block tx-color-04'>
                                                3 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/500'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>m.audrey</h6>
                                            <small className='d-block tx-color-04'>
                                                4 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial bg-indigo rounded-circle'>
                                                m
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>
                                                macasindil
                                            </h6>
                                            <small className='d-block tx-color-04'>
                                                4 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/350'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>e.ocaba</h6>
                                            <small className='d-block tx-color-04'>
                                                4 hours ago
                                            </small>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial bg-info rounded-circle'>
                                                k
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>avendula</h6>
                                            <small className='d-block tx-color-04'>
                                                5 hours ago
                                            </small>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>

                    <div className='chat-sidebar-footer'>
                        <div className='d-flex align-items-center'>
                            <div className='avatar avatar-sm avatar-online mg-r-8'>
                                <img
                                    src='https://via.placeholder.com/500'
                                    className='rounded-circle'
                                    alt=''
                                />
                            </div>
                            <h6 className='tx-semibold mg-b-0'>Katherine</h6>
                        </div>
                        <div className='d-flex align-items-center'>
                            <a href=''>
                                <FeatherIcon icon='mic' />
                            </a>
                            <a href=''>
                                <FeatherIcon icon='settings' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='chat-content'>
                    <div className='chat-content-header'>
                        <h6 id='channelTitle' className='mg-b-0'>
                            #general
                        </h6>
                        <div id='directTitle' className='d-none'>
                            <div className='d-flex align-items-center'>
                                <div className='avatar avatar-sm avatar-online'>
                                    <span className='avatar-initial rounded-circle'>
                                        b
                                    </span>
                                </div>
                                <h6 className='mg-l-10 mg-b-0'>@dfbot</h6>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <nav id='channelNav'>
                                <a
                                    href='#modalInvitePeople'
                                    data-toggle='modal'
                                >
                                    <span
                                        data-toggle='tooltip'
                                        title='Invite People'
                                    >
                                        <FeatherIcon icon='user-plus' />
                                    </span>
                                </a>
                                <a
                                    id='showMemberList'
                                    href=''
                                    data-toggle='tooltip'
                                    title='Member list'
                                    className='d-flex align-items-center'
                                >
                                    <FeatherIcon icon='users' />
                                    <span className='tx-medium mg-l-5'>25</span>
                                </a>
                            </nav>
                            <nav id='directNav' className='d-none'>
                                <a
                                    href=''
                                    data-toggle='tooltip'
                                    title='Call User'
                                >
                                    <FeatherIcon icon='phone' />
                                </a>
                                <a
                                    href=''
                                    data-toggle='tooltip'
                                    title='User Details'
                                >
                                    <FeatherIcon icon='info' />
                                </a>
                                <a
                                    href=''
                                    data-toggle='tooltip'
                                    title='Add to Favorites'
                                >
                                    <FeatherIcon icon='star' />
                                </a>
                                <a
                                    href=''
                                    data-toggle='tooltip'
                                    title='Flag User'
                                >
                                    <FeatherIcon icon='flag' />
                                </a>
                            </nav>
                            <div className='search-form mg-l-15 d-none d-sm-flex'>
                                <input
                                    type='search'
                                    className='form-control'
                                    placeholder='Search'
                                />
                                <button className='btn' type='button'>
                                    <FeatherIcon icon='search' />
                                </button>
                            </div>
                            <nav className='mg-sm-l-10'>
                                <a
                                    href=''
                                    data-toggle='tooltip'
                                    title='Channel Settings'
                                    data-placement='left'
                                >
                                    <FeatherIcon icon='more-vertical' />
                                </a>
                            </nav>
                        </div>
                    </div>

                    <div className='chat-content-body'>
                        <div className='chat-group'>
                            <PerfectScrollbar>
                                <div className='chat-group-divider'>
                                    February 20, 2019
                                </div>
                                <div className='media'>
                                    <div className='avatar avatar-sm avatar-online'>
                                        <span className='avatar-initial rounded-circle'>
                                            k
                                        </span>
                                    </div>
                                    <div className='media-body'>
                                        <h6>
                                            katherine{' '}
                                            <small>Today at 1:30am</small>
                                        </h6>

                                        <p>
                                            Hello everyone, this is my first
                                            message to this channel
                                        </p>
                                        <p>anybody here?</p>
                                    </div>
                                </div>

                                <div className='chat-group-divider'>
                                    February 21, 2019
                                </div>
                                <div className='media'>
                                    <div className='avatar avatar-sm avatar-online'>
                                        <span className='avatar-initial rounded-circle'>
                                            k
                                        </span>
                                    </div>
                                    <div className='media-body'>
                                        <h6>
                                            katherine{' '}
                                            <small>Yesterday at 4:10am</small>
                                        </h6>

                                        <p>I'm back once again!!</p>
                                        <p>
                                            It is a long established fact that a
                                            reader will be distracted by the
                                            readable content of a page when
                                            looking at its layout.
                                        </p>
                                        <p>
                                            There are many variations of
                                            passages of Lorem Ipsum available,
                                            but the majority have suffered
                                            alteration in some form, by injected
                                            humour, or randomised words which
                                            don't look even slightly believable.
                                        </p>
                                    </div>
                                </div>

                                <div className='media'>
                                    <div className='avatar avatar-sm avatar-online'>
                                        <img
                                            src='https://via.placeholder.com/350'
                                            className='rounded-circle'
                                            alt=''
                                        />
                                    </div>
                                    <div className='media-body'>
                                        <h6>
                                            situmay{' '}
                                            <small>Yesterday at 4:15am</small>
                                        </h6>

                                        <p>
                                            Excepteur sint occaecat cupidatat
                                            non proident
                                        </p>
                                        <p>
                                            Quis autem vel eum iure
                                            reprehenderit qui in ea voluptate
                                            velit esse...
                                        </p>
                                    </div>
                                </div>

                                <div className='chat-group-divider'>
                                    February 22, 2019
                                </div>
                                <div className='media'>
                                    <div className='avatar avatar-sm avatar-offline'>
                                        <img
                                            src='https://via.placeholder.com/500'
                                            className='rounded-circle'
                                            alt=''
                                        />
                                    </div>
                                    <div className='media-body'>
                                        <h6>
                                            rlabares{' '}
                                            <small>Today at 9:40am</small>
                                        </h6>

                                        <p>
                                            Nam libero tempore, cum soluta nobis
                                        </p>
                                    </div>
                                </div>

                                <div className='media'>
                                    <div className='avatar avatar-sm avatar-online'>
                                        <span className='avatar-initial rounded-circle'>
                                            k
                                        </span>
                                    </div>
                                    <div className='media-body'>
                                        <h6>
                                            katherine{' '}
                                            <small>Today at 10:05am</small>
                                        </h6>

                                        <p>I'm back once again!!</p>
                                        <p>
                                            Et harum quidem rerum facilis est et
                                            expedita distinctio.
                                        </p>
                                        <p>
                                            Nam libero tempore, cum soluta nobis
                                            est eligendi optio cumque nihil
                                            impedit quo minus id quod maxime
                                            placeat facere possimus.
                                        </p>
                                    </div>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>

                    <div className='chat-sidebar-right show'>
                        <PerfectScrollbar>
                            <div className='pd-y-20 pd-x-10'>
                                <div className='tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1 pd-l-10'>
                                    Members List
                                </div>
                                <div className='chat-member-list'>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial rounded-circle'>
                                                b
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>dfbot</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/350'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>situmay</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-offline'>
                                            <img
                                                src='https://via.placeholder.com/600'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>
                                                acantones
                                            </h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-offline'>
                                            <img
                                                src='https://via.placeholder.com/500'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>rlabares</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/500'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>h.owen</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-xs avatar-online'>
                                            <span className='avatar-initial rounded-circle'>
                                                k
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>k.billie</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/500'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>m.audrey</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial rounded-circle'>
                                                m
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>
                                                macasindil
                                            </h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <img
                                                src='https://via.placeholder.com/350'
                                                className='rounded-circle'
                                                alt=''
                                            />
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>e.ocaba</h6>
                                        </div>
                                    </a>
                                    <a href='#' className='media'>
                                        <div className='avatar avatar-sm avatar-online'>
                                            <span className='avatar-initial rounded-circle'>
                                                k
                                            </span>
                                        </div>
                                        <div className='media-body mg-l-10'>
                                            <h6 className='mg-b-0'>avendula</h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>

                    <div className='chat-content-footer'>
                        <a
                            href=''
                            data-toggle='tooltip'
                            title='Add File'
                            className='chat-plus'
                        >
                            <FeatherIcon icon='plus' />
                        </a>
                        <input
                            type='text'
                            className='form-control align-self-center bd-0'
                            placeholder='Message'
                        />
                        <nav>
                            <a href='' data-toggle='tooltip' title='Add GIF'>
                                <FeatherIcon icon='image' />
                            </a>
                            <a href='' data-toggle='tooltip' title='Add Gift'>
                                <FeatherIcon icon='gift' />
                            </a>
                            <a href='' data-toggle='tooltip' title='Add Smiley'>
                                <FeatherIcon icon='smile' />
                            </a>
                        </nav>
                    </div>
                </div>
            </div>

            <div
                className='modal fade effect-scale'
                id='modalCreateChannel'
                tabIndex='-1'
                role='dialog'
                aria-hidden='true'
            >
                <div
                    className='modal-dialog modal-dialog-centered modal-sm'
                    role='document'
                >
                    <div className='modal-content'>
                        <div className='modal-body pd-20'>
                            <button
                                type='button'
                                className='close pos-absolute t-15 r-15'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>
                                    <FeatherIcon icon='x' className='wd-20' />
                                </span>
                            </button>

                            <h6 className='tx-uppercase tx-spacing-1 tx-semibold mg-b-20'>
                                Create Channel
                            </h6>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Channel name'
                                value=''
                            />
                        </div>
                        <div className='modal-footer pd-x-20 pd-b-20 pd-t-0 bd-t-0'>
                            <button
                                type='button'
                                className='btn btn-secondary tx-13'
                                data-dismiss='modal'
                            >
                                Cancel
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary tx-13'
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className='modal fade effect-scale'
                id='modalInvitePeople'
                tabIndex='-1'
                role='dialog'
                aria-hidden='true'
            >
                <div
                    className='modal-dialog modal-dialog-centered'
                    role='document'
                >
                    <div className='modal-content'>
                        <div className='modal-body pd-20 pd-sm-30'>
                            <button
                                type='button'
                                className='close pos-absolute t-20 r-20'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>
                                    <i data-feather='x' className='wd-20'></i>
                                </span>
                            </button>

                            <h6 className='tx-18 tx-sm-20 mg-b-5'>
                                Invite People
                            </h6>
                            <p className='tx-color-03 mg-b-20'>
                                Share this link to your friend to grant access
                                and join to this channel
                            </p>
                            <div className='input-group mg-b-5'>
                                <input
                                    type='text'
                                    className='form-control'
                                    value='http://themepixels.me/dashforge'
                                    readOnly
                                />
                                <div className='input-group-append'>
                                    <button
                                        className='btn btn-outline-light'
                                        type='button'
                                        id='button-addon2'
                                    >
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inbox;
