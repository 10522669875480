import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter, NavLink as RouterNavLink, Link } from 'react-router-dom';
import { SITE_NO_PREVIEWURL } from '../../../common/constants';
import { useMediaQuery } from 'react-responsive';
import Switch from 'react-switch';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';

import PaymentRules from './PaymentRules';
import FSInput from '../../elements/FSInput';
import FSSelect from '../../elements/FSSelect';
import FSButton from '../../elements/FSButton';
import Loading from '../../../components/Base/Loading';
import Text from '../../ui/Text';

import * as siteActions from '../../../store/actions/sites';
import * as templateActions from '../../../store/actions/templates';
import * as listingActions from '../../../store/actions/listing';

const paymentMethod = [
  { value: 'Entire unpaid balance', text: 'Entire unpaid balance' },
  { value: 'Custom percent', text: 'Custom percent' },
];
const paymentTimeOptions = [
  { value: 'New accepted reservation', text: 'New accepted reservation' },
  { value: 'Check in', text: 'Check in' },
];

const DudaEditSiteTableSection = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const selectedIds = useSelector((state) => state.site.selectedIds);
  const listings = useSelector((state) => state.listing.listings);
  const defaultAccountId = useSelector((state) => state.site.default_account_id);
  const paymentSchedules = useSelector((state) => state.site.site_payment_schedules);
  const accounts = useSelector((state) => state.site.default_accounts);
  const customer = useSelector((state) => state.auth.customer);
  const invidualListingAccounts = useSelector((state) => state.site.listing_accounts);

  const [isEditingRules, setIsEditingRules] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [searchPattern, setSearchPattern] = useState('');
  const [listingAccounts, setListingAccounts] = useState([]);
  const [site, setSite] = useState([]);
  const [defaultAccount, setDefaultAccount] = useState(defaultAccountId);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpeningEditor, setIsOpeningEditor] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [paymentRule, setPaymentRule] = useState([
    {
      method: paymentMethod[0].value,
      percent: 0,
      paymentTime: paymentTimeOptions[0].value,
      paymentTimeDays: 0,
    },
  ]);

  const initData = async () => {
    setIsLoaded(false);
    await dispatch(listingActions.getAllActiveListings(props.match.params.id));
    await dispatch(siteActions.getAccounts(props.match.params.id));
    setIsLoaded(true);
  };
  const publishSite = async () => {
    setIsPublishing(true);
    await dispatch(
      siteActions.publishSite(
        props?.location?.state?.site.id || props?.location?.state?.site.site_id,
      ),
    );
    getSite();
    setIsPublishing(false);
  };
  const unPublishSite = async () => {
    setIsPublishing(true);
    await dispatch(
      siteActions.unPublishSite(
        props?.location?.state?.site.id || props?.location?.state?.site.site_id,
      ),
    );
    getSite();
    setIsPublishing(false);
  };
  const getSite = async () => {
    setIsLoaded(false);
    const site = await dispatch(
      siteActions.getSiteById(
        props?.location?.state?.site.id || props?.location?.state?.site.site_id,
      ),
    );
    setSite(site);
    setSiteName(site.siteDefaultDomain.substring(0, site.siteDefaultDomain.indexOf('.')));
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
    const newAccount = [];
    listings.forEach((listing) => {
      if (!listing.payment_processor_id) {
        newAccount.push({ listing_id: listing.listing_id, account: 'Use default account' });
      } else {
        const account = accounts.find((account) => account.value === listing.payment_processor_id);
        newAccount.push({ listing_id: listing.listing_id, account: account.value });
      }
    });
    const schedules = [];
    if (paymentSchedules) {
      paymentSchedules.forEach((schedule) => {
        schedules.push({
          method:
            schedule.charge_type === 'entire_balance' ? 'Entire unpaid balance' : 'Custom percent',
          percent: schedule.amount,
          paymentTime:
            schedule.payment_due_date === 'new_accepted_reservation'
              ? 'New accepted reservation'
              : 'Check in',
          paymentTimeDays: schedule.balance_interval,
          id: schedule.id,
        });
      });
    }
    getSite();
    setPaymentRule(schedules);
    setListingAccounts(newAccount);
  }, []);

  const openDudaBuilderInNewTab = async () => {
    setIsOpeningEditor(true);
    const redirectLink = await dispatch(templateActions.getRedirectLink(props.match.params.id));
    const newWindow = window.open(redirectLink, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    setIsOpeningEditor(false);
  };
  const openSite = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const openChannelSettings = (id) => {
    const newWindow = window.open(
      `https://${customer.customerName.toLowerCase()}.fsapp.io/listings/channels/${id}`,
      '_blank',
      'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
  };
  const handleSelectAllIds = async () => {
    let allSelectedIds;

    if (selectedIds.length === listings.length) {
      allSelectedIds = [];
    } else {
      allSelectedIds = listings.map((i) => {
        if (i.id) return i.reservation_id;
        else return i.listing_id;
      });
    }
    dispatch(siteActions.setSelectedIds(allSelectedIds));
  };

  const handleItemCheck = async (id) => {
    let i = selectedIds.indexOf(id);
    const newItemsCheck = [...selectedIds];
    if (i === -1) {
      newItemsCheck.push(id);
    } else {
      newItemsCheck.splice(i, 1);
    }
    dispatch(siteActions.setSelectedIds(newItemsCheck));
  };
  const handleAccountSelect = (ev, id) => {
    const newAccount = [...listingAccounts];
    const isIdAvailable = (element) => element.listing_id === id;
    const index = listingAccounts.findIndex(isIdAvailable);
    newAccount[index].account = +ev;
    setListingAccounts(newAccount);
  };
  const handleSaveButton = async () => {
    setIsSaving(true);
    const listingsCopy = [...listings];
    listings.forEach((listing, index) => {
      const account = listingAccounts.find((account) => account.listing_id === listing.listing_id);
      if (account.account === 'Use default account') {
        listingsCopy[index].payment_processor_id = +defaultAccount;
      } else {
        listingsCopy[index].payment_processor_id = +account.account;
      }
    });
    const rules = [];
    paymentRule.forEach((rule, index) => {
      rules.push({
        id: index,
        site_id: location.state.site.id,
        charge_type: rule.method === 'Entire unpaid balance' ? 'entire_balance' : 'percent',
        amount: rule.percent,
        payment_due_date:
          rule.paymentTime === 'New accepted reservation' ? 'new_accepted_reservation' : 'check_in',
        balance_interval: rule.paymentTimeDays,
      });
    });
    const data = {
      select_listings: listingsCopy,
      payment_processor_id: defaultAccount,
      site_name: props.siteName,
      payment_schedules: rules,
    };
    if (siteName && location?.state?.site.id) {
      await dispatch(templateActions.createName(location.state.site.id, siteName));
      getSite();
    }
    await dispatch(siteActions.updateSiteListings(data));
    initData();
    setIsSaving(false);
    setIsEditingName(false);
  };
  if (!isLoaded) {
    return <Loading />;
  }
  console.log(props?.location?.state?.site);
  return (
    <div className={isMobile ? 'edit-site-grid-container' : 'edit-site-container'}>
      <div className="sites-breadcrumbs">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" exact className="breadcrumb-link">
                <Text>home</Text>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/apps" exact className="breadcrumb-link">
                <Text>apps</Text>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/apps/websitepro" exact className="breadcrumb-link">
                <Text>website.pro</Text>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link
                to={`/apps/websitepro/edit/${props.match.params.id}`}
                exact
                className="breadcrumb-link-active"
              >
                {site.siteDefaultDomain.substring(0, site.siteDefaultDomain.indexOf('.'))}
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="edit-site-header">
        <div className="edit-site-header-site-name flex-1">
          {!isEditingName ? (
            <div className="edit-site-header-name-header">
              <label>
                <Text>
                  {site.siteDefaultDomain.substring(0, site.siteDefaultDomain.indexOf('.'))}
                </Text>
              </label>
              <div className="edit-site-header-toggle-site-name">
                <FeatherIcon
                  icon="edit-2"
                  className="pointer"
                  onClick={() => setIsEditingName(!isEditingName)}
                />
              </div>
            </div>
          ) : (
            <div className="edit-site-header-name-action">
              <FSInput
                className="site-name-input"
                placeholder="Site Name"
                value={siteName}
                onChange={setSiteName}
              />
              <div className="edit-site-header-toggle-site-name">
                <FeatherIcon
                  icon="edit-2"
                  className="pointer"
                  onClick={() => setIsEditingName(!isEditingName)}
                />
              </div>
            </div>
          )}
          <div className="edit-site-header-actions">
            <div className="edit-site-header-toggle-payment-rules">
              <FSButton
                className="edit-site-header-toggle-payment-rules-button"
                text="Edit rules"
                icon="settings"
                onClick={() => setIsEditingRules(!isEditingRules)}
              />
            </div>
            <div className="edit-site-header-open-builder">
              <FSButton
                className="edit-site-header-open-builder-button"
                text="Website Builder"
                icon="external-link"
                isLoading={isOpeningEditor}
                onClick={() => openDudaBuilderInNewTab()}
              />
            </div>
            <div className="edit-site-header-site-status">
              {site.publishStatus === 'PUBLISHED' ? (
                <div className="edit-site-header-site-status-unpublish-container">
                  <FSButton
                    className="edit-site-header-site-status-unpublish-button"
                    text="Unpublish"
                    icon="chevron-down"
                    isLoading={isPublishing}
                    onClick={() => unPublishSite()}
                  />
                </div>
              ) : (
                <div className="edit-site-header-site-status-publish-container">
                  <FSButton
                    className="edit-site-header-site-status-publish-button"
                    text="Publish"
                    icon="chevron-up"
                    isLoading={isPublishing}
                    onClick={() => publishSite()}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="edit-site-heaeder-site-link">
            <a
              className={`edit-site-header-site-link-text-${
                site.publishStatus === 'PUBLISHED' ? 'published' : 'notpublished'
              }`}
              onClick={() => openSite(`https://${props?.location?.state?.site.siteDefaultDomain}`)}
            >
              {props?.location?.state?.site.siteDefaultDomain}
              <i className="fas fa-external-link-square-alt"></i>
            </a>
          </div>
        </div>
        <div className="edit-site-header-input-group">
          <div className="edit-site-header-search">
            <div className="edit-site-header-search-header">
              <label>
                <Text>edit.site_search_listing</Text>
              </label>
            </div>
            <div className="edit-site-header-search-action">
              <button className="edit-site-header-search-field-button">
                <FeatherIcon icon="search" className="button-search-icon" />
              </button>
              <input
                className="edit-site-header-search-field-input"
                type="text"
                placeholder="Search"
                onChange={(ev) => setSearchPattern(ev.target.value.toLowerCase())}
              />
            </div>
          </div>
          <div className="edit-site-header-default-account">
            <div className="edit-site-header-default-account-header">
              <label>
                <Text>edit.site_default_account</Text>:
              </label>
            </div>
            <div className="edit-site-header-default-account-action">
              <FSSelect
                options={accounts}
                selected={defaultAccount}
                className="edit-site-header-default-account-select"
                onChange={(ev) => setDefaultAccount(ev.target.value)}
              />
            </div>
          </div>
          <div className="edit-site-header-display-listings">
            <div className="edit-site-header-display-listings-header">
              <label>
                <Text>edit.site_display_all</Text>
              </label>
            </div>
            <div className="edit-site-header-display-listings-action">
              <Switch
                onChange={() => handleSelectAllIds()}
                checked={selectedIds.length === listings.length}
                className="react-switch"
                handleDiameter={22}
                uncheckedIcon={false}
                checkedIcon={false}
                width={50}
                height={32}
                onColor="#00C141"
                onHandleColor="#F2F2F2"
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                className={classNames({
                  'react-switch': true,
                  'react-switch-on': selectedIds.length === listings.length,
                  'react-switch-off': !selectedIds.length === listings.length,
                })}
                id="material-switch"
              />
            </div>
          </div>
        </div>
      </div>
      {isEditingRules ? (
        <PaymentRules
          paymentMethod={paymentMethod}
          paymentTimeOptions={paymentTimeOptions}
          paymentRule={paymentRule}
          setPaymentRule={setPaymentRule}
        />
      ) : null}
      <div className={isMobile ? 'edit-site-items-container row' : 'edit-site-items-container'}>
        {listings
          ?.filter((f) => {
            return f.listing_nickname.toLowerCase().indexOf(searchPattern) > -1;
          })
          .map((item, index) => {
            return (
              <div className={isMobile ? 'edit-site-item col-12' : 'edit-site-item'} key={index}>
                <div className="edit-site-item-img">
                  <img
                    className="edit-site-img"
                    src={item.listing_thumbnail_url || SITE_NO_PREVIEWURL}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = SITE_NO_PREVIEWURL;
                    }}
                    alt="listing"
                  />
                </div>
                <div className="edit-site-item-desc flex-1">
                  <div className="edit-site-item-name">
                    <div className="edit-site-item-name-header">
                      <label>
                        <Text>edit.site_listing_name</Text>:
                      </label>
                    </div>
                    <div className="edit-site-item-name-header-desc">{item.listing_nickname}</div>
                  </div>
                  <div className="edit-site-item-channel">
                    <div className="edit-site-item-channel-header">
                      <label>
                        <Text>edit.site_listing_channel.settings</Text>:
                      </label>
                    </div>
                    <div className="edit-site-item-channel-header-desc">
                      <FSButton
                        className="edit-site-item-channel-header-button"
                        text="Channel settings"
                        icon="settings"
                        isLoading={isSaving}
                        onClick={() => openChannelSettings(item.listing_id)}
                      />
                    </div>
                  </div>
                </div>
                <div className="edit-site-item-actions">
                  <div className="edit-site-item-account">
                    <div className="edit-site-item-account-header">
                      <label>
                        <Text>edit.site_select_stripe_account</Text>
                      </label>
                    </div>
                    <div className="edit-site-item-account-action">
                      <FSSelect
                        options={invidualListingAccounts}
                        className="edit-site-item-account-select"
                        selected={
                          listingAccounts.find((account) => account.listing_id === item.listing_id)
                            .account
                        }
                        onChange={(ev) => handleAccountSelect(ev.target.value, item.listing_id)}
                      />
                    </div>
                  </div>
                  <div className="edit-site-item-listing">
                    <div className="edit-site-item-listing-header">
                      <label>
                        <Text>edit.site_display_listing</Text>
                      </label>
                    </div>
                    <div className="edit-site-item-listing-action">
                      <Switch
                        onChange={() => handleItemCheck(item.listing_id)}
                        checked={selectedIds.indexOf(item.listing_id) > -1}
                        className="react-switch"
                        handleDiameter={22}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={50}
                        height={32}
                        onColor="#00C141"
                        onHandleColor="#F2F2F2"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        className={classNames({
                          'react-switch': true,
                          'react-switch-on': selectedIds.indexOf(item.listing_id) > -1,
                          'react-switch-off': !selectedIds.indexOf(item.listing_id) > -1,
                        })}
                        id="material-switch"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {listings.length === 0 ? (
          <div>
            <Text>websitepro.no.listings.found {searchPattern}</Text>
          </div>
        ) : null}
      </div>
      <div className="edit-site-save-button-container">
        <FSButton className="edit-site-clear-button" text="Clear" onClick={() => initData()} />
        <FSButton
          className="edit-site-save-button"
          text="Save"
          isLoading={isSaving}
          onClick={() => handleSaveButton()}
        />
      </div>
    </div>
  );
};

export default withRouter(DudaEditSiteTableSection);
