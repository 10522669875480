import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FSSelect from '../../../components/elements/FSSelect.js';
import FSInput from '../../../components/elements/FSInput.js';
import FSDatePicker from '../../../components/elements/FSDatePicker.js';

let availableOptions = [
  'In the last',
  'Due in',
  'On',
  'Before',
  'Before beginning of last mo',
  'Before beginning of this mo',
  'After',
  'After beginning of last mo',
  'After beginning of this mo',
  'In the future',
  'In the past',
  'Between',
  'Today',
  'Yesterday',
  'This week',
  'Last week',
  'This month',
  'Last month',
  'This year',
];

const intervals = [
  { value: 'day', text: 'Days' },
  { value: 'week', text: 'Weeks' },
  { value: 'month', text: 'Months' },
];

const ListFilterDate = (props) => {
  const { selectedFilterSettings } = props;
  const dateFormat = useSelector((state) => state.auth.customer.dateFormat);
  const [needRelativePeriod, setNeedRelativePeriod] = useState(false);
  const [needSpecificDate, setNeedSpecificDate] = useState(false);
  const value = !selectedFilterSettings ? '' : selectedFilterSettings.value;
  const option = !selectedFilterSettings ? 'This month' : selectedFilterSettings.option;

  if (props.filter.type === 'chart_date') {
    availableOptions = ['Between', 'Last month', 'This month', 'This year'];
  }

  const options = availableOptions.map((option) => {
    return { value: option, text: option };
  });
  useEffect(() => {
    props.onChange(value, option || 'This month');
    
  },[])

  useEffect(() => {
   if(selectedFilterSettings.option === 'In the last'|| selectedFilterSettings.option === 'Due in'){
     setNeedRelativePeriod(true)
     setNeedSpecificDate(false)
   }else if(selectedFilterSettings.option === 'Before'|| selectedFilterSettings.option === 'After'|| selectedFilterSettings.option === 'On'){
    setNeedRelativePeriod(false)
    setNeedSpecificDate(true)
   }else{
    setNeedSpecificDate(false)
    setNeedRelativePeriod(false)
   }
  },[selectedFilterSettings.option])

  const handleOptionChange = (e) => {
    let newValue = '';
    if (['In the last', 'Due in'].indexOf(e.target.value) > -1) {
      setNeedRelativePeriod(true);
      setNeedSpecificDate(false);
      newValue = ['', 'day'];
    } else if (['On', 'Before', 'After'].indexOf(e.target.value) > -1) {
      setNeedRelativePeriod(false);
      setNeedSpecificDate(true);
    } else {
      setNeedRelativePeriod(false);
      setNeedSpecificDate(false);
    }

    props.onChange(newValue, e.target.value);
  };

  const handleSecondaryOptionChange = (e) => {
    const newValue = [value[0], e.target.value];
    props.onChange(newValue, option);
  };

  const handleValueChange = (inputValue) => {
    const newValue = needRelativePeriod ? [inputValue, value[1]] : inputValue;
    props.onChange(newValue, option);
  };

  return (
    <div className="options-filter-container">
      <FSSelect options={options} selected={option} onChange={(e) => handleOptionChange(e)} />
      {needRelativePeriod && (
        <div className="d-flex mt-10">
          <FSInput
            type="number"
            onChange={handleValueChange}
            value={value}
            className="d-inline mr-5"
          />

          <FSSelect
            options={intervals}
            selected={value[1]}
            className="d-inline"
            onChange={handleSecondaryOptionChange}
          />
        </div>
      )}
      {needSpecificDate && (
        <FSDatePicker
          className="mt-10"
          value={value}
          dateFormat={dateFormat}
          onChange={handleValueChange}
        />
      )}

      {option === 'Between' && (
        <FSDatePicker
          className="mt-10"
          value={value}
          dateFormat={dateFormat}
          isRange={true}
          onChange={handleValueChange}
        />
      )}
    </div>
  );
};

export default ListFilterDate;
