import React, { useEffect, useState } from 'react';
import './Mapping.scss';
import PagePreloader from '../../Base/PagePreloader';
import FSButton from '../../elements/FSButton';
import { NONE_IMG } from '../../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import * as listingActions from '../../../store/actions/listing';
import Text from '../../ui/Text';
import OverwritePrompt from './OverwritePrompt';
import SidebarMenu from './SidebarMenu';
import { useHistory } from 'react-router';
import TechnicalError from '../../../pages/TechnicalError/TechnicalError';

const integrationId = 124;

const RentalsUnitedMapping = () => {
    const [mapping, setMapping] = useState({});
    const [ongoingMapping, setOngoingMapping] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [dragRUPropertyId, setDragRUPropertyId] = useState(null);
    const [dragPropertyId, setDragPropertyId] = useState(null);
    const [showOverwritePrompt, setShowOverwritePrompt] = useState(false);
    const [draggingOverNewProperty, setDraggingOverNewProperty] = useState(false);
    const [currPropertyId, setCurrPropertyId] = useState(null);
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);

    const dispatch = useDispatch();
    const properties = useSelector((state) => state.listing.active);
    const rentalsUnitedProperties = useSelector((state) => state.listing.rentalsUnitedProperties);
    const permissions = useSelector((state) => state.auth.permissions);

    const history = useHistory();
    if (!permissions.includes('RentalsUnited')) {
        history.push('/access-denied')
    }

    const toggleOverwritePrompt = () => setShowOverwritePrompt(!showOverwritePrompt);
    useEffect(() => {
        const getProperties = async () => {
            try {
                await dispatch(listingActions.setActiveListings());
                await dispatch(listingActions.setRentalsUnitedProperties());
            } catch (error) {
                setDidPromisesSucceed(false);
            }

            setLoaded(true);
        };

        getProperties();
    }, [dispatch]);

    if (!loaded) {
        return <PagePreloader />;
    }

    if (didPromisesSucceed === false) {
        return <TechnicalError />;
    }

    const availableRUPropertyDragStart = (event, propertyId) => {
        setDragRUPropertyId(propertyId);
        const node = event.currentTarget;
        node.style.width = `${node.offsetWidth}px`;
        node.style.cursor = "move";
        node.style.opacity = 1;

        setTimeout(() => {
            node.style.display = 'none';
        }, 0);
    }

    const availableRUPropertyDrag = (event) => {
        event.preventDefault();
        const node = event.currentTarget;
        node.style.position = 'fixed';
        node.style.top = `${event.clientY}px`;
        node.style.left = `${event.clientX}px`;
    }

    const createNewFSProperty = () => {
        console.log('createNewFSProperty');
    }

    const availableRUPropertyDragEnd = (event) => {
        const node = event.currentTarget;
        node.style.position = 'relative';
        node.style.top = 'auto';
        node.style.left = 'auto';
        node.style.width = 'auto';
        node.style.pointerEvents = "auto";
        node.style.display = 'flex';

        if (!dragPropertyId) {
            if (draggingOverNewProperty) {
                createNewFSProperty();
            }
            
            return;
        }

        if (Object.keys(mapping).includes(dragPropertyId)) {
            setOngoingMapping({});
            toggleOverwritePrompt();
        } else {           
            mapping[dragPropertyId] = dragRUPropertyId;

            setMapping(mapping);
            setOngoingMapping({});
            setDragPropertyId(null);
            setDragRUPropertyId(null);
        }
    }

    const mappedRUPropertyDragEnd = (event) => {
        const node = event.currentTarget;
        node.style.position = 'relative';
        node.style.top = 'auto';
        node.style.left = 'auto';
        node.style.width = '100%';
        node.style.display = 'flex';

        if (Object.keys(mapping).includes(dragPropertyId)) {
            Object.keys(mapping).forEach(propertyId => {
                if (mapping[propertyId] === dragRUPropertyId) {
                    setCurrPropertyId(propertyId);
                }
            });
            setOngoingMapping({});
            toggleOverwritePrompt();
        } else {
            Object.keys(mapping).forEach(propertyId => {
                if (mapping[propertyId] === dragRUPropertyId) {
                    delete mapping[propertyId];
                }
            });
    
            mapping[dragPropertyId] = dragRUPropertyId;
    
            setMapping(mapping);
            setDragPropertyId(null);
            setDragRUPropertyId(null);
        }
    }

    const propertyDragOver = (event, propertyId) => {
        event.preventDefault();
        
        let theOngoingMapping = {};
        theOngoingMapping[propertyId] = dragRUPropertyId;
        
        setOngoingMapping(theOngoingMapping);

        if (!dragRUPropertyId) {
            return;
        }

        setDragPropertyId(propertyId);
    }

    const mappedRUPropertyReturn = (event) => {
        event.preventDefault();

        if (!(Object.values(mapping).includes(dragRUPropertyId))) {
            return;
        }

        Object.keys(mapping).forEach(propertyId => {
            if (mapping[propertyId] === dragRUPropertyId) {
                delete mapping[propertyId];
            }
        });

        setMapping(mapping);
        setOngoingMapping({});
        setDragPropertyId(null);
        setDragRUPropertyId(null);
    };

    const submitMapping = () => {
        if (Object.values(mapping).length === 0) {
            return;
        }

        const formattedMapping = [];
        for (const prop in mapping) {
            formattedMapping.push({"channel_listing_id": mapping[prop].toString(), "integration_id": integrationId, "listing_id": +prop})
        }

        dispatch(listingActions.submitRUPropertiesMapping(mapping));
    }

    return (
        <div className="app-wrapper">
            <SidebarMenu />
            <section className="app-right--contain">
                <div className="add-app-page-content">
                    <h2 className="main-title"><Text>Properties Mapping</Text></h2>
                    <div className="gray-box-bcom">
                        <h4><Text>Add new properties</Text></h4>
                        <div className="left-mapping">
                            <ul
                                style={{backgroundColor: 'white', height: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                onDragOver={() => setDraggingOverNewProperty(true)}
                                onDragLeave={() => setTimeout(function() { setDraggingOverNewProperty(false); }, 50)}
                            >
                                <Text>Drop here to create a new property</Text>
                            </ul>
                        </div>
                        <div className="bcom-the-mapping">
                            <div className="left-mapping">
                                <h4><Text>FS Properties</Text></h4>
                                <ul>
                                    {properties.map(property => {
                                        const m = {...mapping, ...ongoingMapping};
                                        const mappedPropertyId = m.hasOwnProperty(property.listing_id) ? m[property.listing_id] : false;

                                        let mappedProperty;

                                        if (mappedPropertyId) {
                                            mappedProperty = rentalsUnitedProperties.filter(r => r.id === mappedPropertyId)[0];

                                            if (!mappedProperty) {
                                                mappedProperty = {
                                                    id: mappedPropertyId,
                                                    name: property.name
                                                }
                                            }
                                        }

                                        return (
                                            <li
                                                key={property.listing_id}
                                                className={mappedProperty ? "has-mapped" : ""}
                                                onDragOver={e => propertyDragOver(e, property.listing_id)}
                                            >
                                                <div className="listing">
                                                    <div className="thumb">
                                                        <img src={property.listing_thumb || NONE_IMG} onError={(e)=>{e.target.onerror = null; e.target.src = NONE_IMG}} alt=""/>
                                                    </div>
                                                    <p>{property.name}</p>
                                                </div>
                                                {mappedPropertyId &&
                                                <div
                                                    className="mapped-listing"
                                                    onDragStart={(e) => availableRUPropertyDragStart(e, mappedPropertyId)}
                                                    onDrag={availableRUPropertyDrag}
                                                    onDragEnd={mappedRUPropertyDragEnd}
                                                    draggable={true}
                                                >
                                                    <i className="far fa-arrows-alt"></i>
                                                    <div className="thumb"><img src={NONE_IMG} alt=""/></div>
                                                    <p>{mappedProperty.name} ({mappedProperty.id})</p>
                                                </div>
                                                }
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {rentalsUnitedProperties.length > 0 &&
                            <div className="right-mapping">
                                <h4><Text>Rentals United Properties</Text></h4>
                                <ul onDragOver={e => mappedRUPropertyReturn(e)}>
                                    {rentalsUnitedProperties.filter(property => !(Object.values(mapping).includes(property.id))).map(property => {

                                        return (
                                            <li
                                                key={property.id}
                                                onDragStart={(e) => availableRUPropertyDragStart(e, property.id)}
                                                onDrag={availableRUPropertyDrag}
                                                onDragEnd={availableRUPropertyDragEnd}
                                                onDragOver={e => e.stopPropagation()}                 
                                                draggable={true}
                                            >
                                                <i className="far fa-arrows-alt"></i>
                                                <div className="thumb"><img src={NONE_IMG} alt=""/></div>
                                                <p>{property.name} ({property.id})</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            }
                        </div>
                    </div>
                    {showOverwritePrompt ? (
                        <OverwritePrompt
                            toggle={toggleOverwritePrompt}
                            modal={showOverwritePrompt}
                            mapping={mapping}
                            currPropertyId={currPropertyId}
                            dragPropertyId={dragPropertyId}
                            dragRUPropertyId={dragRUPropertyId}
                            setMapping={setMapping}
                            setOngoingMapping={setOngoingMapping}
                            setDragPropertyId={setDragPropertyId}
                            setDragRUPropertyId={setDragRUPropertyId}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="wizard-bottom-nav">
                    <div className="buttons" >
                        <FSButton
                            onClick={submitMapping}
                            className="button button-solid button-inline button-medium"
                            style={{marginTop: '1rem'}}
                        >
                            <Text>Save</Text>
                        </FSButton>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default RentalsUnitedMapping;
