/* eslint-disable import/no-anonymous-default-export */
import { GET_ROLES } from "../actions/roles";

const initialState = {
  roles: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES: {
      return {
        ...state,
        roles: action.data,
      };
    }
    default:
      return state;
  }
}