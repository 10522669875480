import './SignUpPage.css';
import { default as fs_logo } from '../../assets/icons/fs-logo.svg';
import { default as facebook_logo } from '../../assets/icons/facebook-logo.svg';
import { default as linkedin_logo } from '../../assets/icons/linkedin-logo.svg';
import { default as google_logo } from '../../assets/icons/google-logo.svg';
import { default as error_try_again } from '../../assets/icons/error-try-again.svg';
import { useHistory, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as authActions from '../../store/actions/auth';
import { EMAIL_REGEX } from '../../common/constants';
import configJson from "../../auth_config.json";

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [duplicateEmail, setDuplicateEmail] = useState(false);
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();

    const params = Object.assign({
        configurationBaseUrl: "https://auth.fsapp.io/",
        overrides: {
          __tenant: "fantasticstay",
          __token_issuer: "https://auth.fsapp.io/"
        },
        domain:  configJson.domain,
        clientID: configJson.clientId,
        redirectUri: window.location.origin,
        responseType: "token",
    });

    const webAuth = new window.auth0.WebAuth(params);

    useEffect(() => {   
        if (email.match(EMAIL_REGEX)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }, [email]);

    const loginWithGoogle = () => {
        webAuth.authorize({
          connection: 'google-oauth2',
          access_token: 'auth.fsapp.io'
        }, function(err) {
          if (err) console.log(err);
        });
    };

    const loginWithFacebook = () => {
        webAuth.authorize({
            connection: 'facebook',
            access_token: 'auth.fsapp.io'
        }, function(err) {
            if (err) console.log(err);
        });
    };

    const loginWithLinkedIn = () => {
        webAuth.authorize({
            connection: 'linkedin',
            access_token: 'auth.fsapp.io'
        }, function(err) {
            if (err) console.log(err);
        });
    };

    const createNewUser = async () => {
        if (isRequestPending) return;
        setDuplicateEmail(false);
        setIsRequestPending(true);
        try {
            await dispatch(authActions.createUser(email));
            setIsRequestPending(false);
            history.push('/set-your-password');
        } catch (error) {
            setIsRequestPending(false);
            if (error.includes('exists')) {
                setDuplicateEmail(true);
            } else {
                setDidPromisesSucceed(false);
            }
        }
    };

    return (
        <div className='the-whole-sign-up-page'>
            <div className='left-side-of-sign-up'>
                <div className="d-flex">
                    <img src={fs_logo} alt='' />
                    {!didPromisesSucceed ?
                        <div className="error-try-again-holder ml-auto">
                            <div className="error-try-again-box">
                                <img className="error-try-again-icon" src={error_try_again} alt='' />
                            </div>
                            <div className="error-try-again-text-box">
                                <div className="ml-3">Error occured! Please try again.</div>
                                <div className="close-error-try-again ml-auto mr-3" onClick={() => setDidPromisesSucceed(true)}>&times;</div>
                            </div>
                        </div>
                    :
                        ''
                    }
                </div>
                <div className='sign-up-mid-section'>
                    <div className='sign-up-header-text'>Sign Up</div>
                    <div className='sign-up-with-google' onClick={loginWithGoogle}>
                        <img className='google-logo' src={google_logo} alt='' />
                        <label className='sign-up-with-label'>Sign up with Google</label>
                    </div>
                    <div className='sign-up-with-facebook' onClick={loginWithFacebook}>
                        <img className='facebook-logo' src={facebook_logo} alt='' />
                        <label className='sign-up-with-label'>Sign up with Facebook</label>
                    </div>
                    <div className='sign-up-with-linkedin' onClick={loginWithLinkedIn}>
                        <img className='linkedin-logo' src={linkedin_logo} alt='' />
                        <label className='sign-up-with-label'>Sign up with LinkedIn</label>
                    </div>
                    <div className='separator-line-sign-up d-flex'>
                        <div className='delimiter-line-left'></div>
                        <div className='between-delimiter-line'>OR</div>
                        <div className='delimiter-line-right'></div>
                    </div>
                    <div className='sign-up-email-address'>Email address</div>
                    <input
                        type='text'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onInput={() => setDuplicateEmail(false)}
                        className={`input-sign-up-email-address ${duplicateEmail ? 'invalid-registration-input' : ''}`}>
                    </input>
                    {duplicateEmail ? <p className='invalid-registration-input-label'>Email already exists!</p> : ''}
                    {validEmail ? 
                        <div className='sign-up-button' onClick={createNewUser}>
                            <div className='text-inside-sign-up-button'>Signup with email</div>
                            {isRequestPending ? <i className="fa fa-circle-o-notch fa-spin" style={{marginLeft: '170px', position: 'relative', top: '-10px'}}></i> : ''}
                        </div>
                    :
                        <div className='sign-up-button-disabled'>
                            <div className='text-inside-sign-up-button-disabled'>Signup with email</div>
                        </div>
                    }
                    <div className='text-below-sign-up-button'>
                        By signing up you agree to our{' '}
                        <a href='https://www.fantasticstay.com/terms-of-service/?_ga=2.147974132.2145981958.1634631644-467443536.1631796293' target="_blank" rel='noreferrer'>
                            <b style={{cursor: 'pointer'}}><u>Terms of Service</u></b>
                        </a> and{' '}
                        <a href='https://www.fantasticstay.com/privacy-policy/?_ga=2.147974132.2145981958.1634631644-467443536.1631796293' target="_blank" rel='noreferrer'>
                            <b style={{cursor: 'pointer'}}><u>Privacy Policy</u></b>
                        </a>.
                    </div>
                </div>
                <div className='already-a-member'>Already a member? <Link to='/sign-in'><u style={{color: '#009933', cursor: 'pointer'}}>Login now</u></Link></div>
            </div>
            <div className='sign-up-page-image'>
                <div className='welcome-on-board-text'>Welcome on board!</div>
            </div>
        </div>
    );
};

export default SignUpPage;
