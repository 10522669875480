import Request from '../../utils/Request';
import Env from '../../Env';
import { orderParamFunc } from '../../components/list/table/ListOrderQueryParams';
import { filterParamsFunc } from '../../components/list/filters/ListFilterQueryParams';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_VIEW = 'SET_VIEW';
export const SET_ITEMS = 'SET_ITEMS';
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE';
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_DIRECTION = 'SET_ORDER_DIRECTION';
export const SET_PAGE = 'SET_PAGE';
export const ADD_COLUMN = 'ADD_COLUMN';
export const SET_VIEWS = 'SET_VIEWS';
export const SET_SELECTED_IDS = 'SET_SELECTED_IDS';
export const SET_TAGS = 'SET_TAGS';

export const getFilters = (objectType) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/filters/${objectType}`, token);
    dispatch({ type: SET_FILTERS, filters: result });
  };
};

// export const getViews = (objectType) => {

//   return async (dispatch, getState) => {
//     const token = getState().auth.token;
//     const result = await Request.get(`${Env.dbServerUrl}/${objectType}/views`, token);
//     await dispatch({ type: SET_VIEWS, views: result });
//     return result;
//   };
// };

export const setSelectedView = (viewId) => {

  return async (dispatch, getState) => {
    // const views = getState().view.views;
    // const result = views.find((view) => view.id === viewId);
    // console.log(result);

    const result = {
      "id": "mVlmQcLHFGO9RALHf3axKt1Z6x05Gdt2",
      "name": "Active listings",
      "filters": [],
      "columns": [
        {
          "name": "Listing",
          "field": "nickname",
          "type": "listing",
          "order": 1,
          "sortable": true,
          "prefix": "l"
        },
        {
          "name": "Channels",
          "field": "channels",
          "type": "channels",
          "order": 2,
          "sortable": false,
          "prefix": null
        },
        {
          "name": "Full address",
          "field": "address/address",
          "type": "text",
          "order": 3,
          "sortable": false,
          "prefix": null
        },
        {
          "name": "Base price",
          "field": "prices/dailyPrice",
          "type": "price",
          "order": 87,
          "sortable": true,
          "prefix": null
        }
      ],
      "default": 1,
      "isMine": false,
      "dv": "2",
      "favourite": 0
    }

    await dispatch({ type: SET_SELECTED_COLUMNS, selectedColumns: result.columns });
    const newFilters = result.filters.map((filter) => {
      return { ...filter, isValid: true };
    });
    await dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: newFilters });
    await dispatch({ type: SET_VIEW, currentView: { ...result, filters: newFilters } });
    return result;
  };
};

export const getColumns = (objectType) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/columns/${objectType}`, token);
    dispatch({ type: SET_COLUMNS, allColumns: result });
  };
};

export const toggleFilter = (field) => {

  return async (dispatch, getState) => {
    const selectedFilters = getState().list.selectedFilters;

    const isFilterSelected = selectedFilters.find((f) => f.field === field);

    if (!isFilterSelected) {
      dispatch({
        type: SET_SELECTED_FILTERS,
        selectedFilters: [...selectedFilters, { field, option: '', value: '' }],
      });
    } else {
      dispatch({
        type: SET_SELECTED_FILTERS,
        selectedFilters: selectedFilters.filter((f) => f.field !== field),
      });
    }
  };
};

export const getItems = (objectType) => {

  return async (dispatch, getState) => {
    // const token = getState().auth.token;
    const encoded = window.btoa('vT8iyq811Y3pLKq:IHxrCbFWLM17nACXbpFR');
    const auth = 'Basic ' + encoded;
    const filters = getState().list.selectedFilters;
    const filterParams = filterParamsFunc(filters);
    const itemsPerPage = getState().list.itemsPerPage;
    const page = getState().list.page;
    const order = getState().list.order;
    const orderParam = orderParamFunc(order);
    const orderDirection = getState().list.orderDirection;

    const result = await Request.get(
      `${Env.apiDomainListing}/${objectType}s/by-customer?customer_id=131&integration_type=28${filterParams.length > 0 ? `&$filter=${filterParams}`: ''}&$top=${itemsPerPage}&$skip=${itemsPerPage * (page - 1)}&$count=true${orderParam}`,
      auth,
    );

    dispatch({ type: SET_ITEMS, items: orderDirection === 'desc' ? result.reverse() : result});
  };
};

export const setPageNumber = (page) => {

  return async (dispatch, getState) => {
    await dispatch({ type: SET_PAGE, page: page });
  };
};

export const setItemsPerPage = (number) => {

  return async (dispatch, getState) => {
    await dispatch({ type: SET_ITEMS_PER_PAGE, itemsPerPage: number });
  };
};

export const setOrderDirections = (orderDirection) => {

  return async (dispatch, getState) => {
    await dispatch({ type: SET_ORDER_DIRECTION, orderDirection: orderDirection });
  };
};

export const setOrder = (order) => {

  return async (dispatch, getState) => {
    await dispatch({ type: SET_ORDER, order: order });
  };
};

export const setSelectedColumns = (selected) => {
  console.log(selected);
  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_COLUMNS, selectedColumns: selected });
  };
};

export const setSelectedFilters = (selected) => {

  return async (dispatch, getState) => {
    const selectedFilters = getState().list.selectedFilters;
    let newFiltersSelected = selectedFilters.filter((f) => f.field !== selected.field);
    dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: [...newFiltersSelected, selected] });
  };
};

export const addColumn = (column) => {

  return async (dispatch, getState) => {
    dispatch({ type: ADD_COLUMN, column: column });
  };
};

export const setFavoriteView = (viewId) => {

  return async (dispatch, getState) => {
    const allViews = getState().view.views;
    const view = allViews.find((view) => view.id === viewId);
    const index = allViews.indexOf(view);
    allViews[index].favourite = +!allViews[index].favourite;
    dispatch({ type: SET_VIEWS, views: allViews });
  };
};

export const setSelectedIds = (selected) => {

  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_IDS, selectedIds: selected });
  };
};

export const clearFilters = () => {

  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: [] });
  };
};

export const getTags = (objectType) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/tags/${objectType}`, token);
    dispatch({ type: SET_TAGS, tags: result });
  };
};

export const listingbulkEdit = (data, selectedIds, selectedInputs) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.post(
      `${Env.dbServerUrl}/listing/bulk_edit_listings`,
      { data, selectedIds },
      token,
    );
    return result;
  };
};

export const reservationBulkEdit = (objectType) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/tags/${objectType}`, token);
    return result;
  };
};

export const setInitialSelectedFilters = (selected) => {

  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: selected });
  };
};

export const exportItems = (objectType, filters, columns) => {

  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/${objectType}s/export`,{filters,columns}, token);
    return result;
  };
};
