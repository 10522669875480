import { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import EditContact from '../EditContact/EditContact';
import PagePreloader from '../../Base/PagePreloader';
import Text from '../../ui/Text';
// import './SingleContact.scss';

import * as contactActions from '../../../store/actions/contact';
import TechnicalError from '../../../pages/TechnicalError/TechnicalError';

const SingleContact = () => {
  const [contact, setContact] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);

  const { id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const permissions = useSelector((state) => state.auth.permissions);

  if (!permissions.includes('SingleContact')) {
      history.push('/access-denied')
  }

  const toggleEditModal = useCallback(() => {
    setIsEditModalOpen(!isEditModalOpen);
  }, [isEditModalOpen]);

  useEffect(() => {
    const getContact = async () => {
      try {
        const result = await dispatch(contactActions.getContactbyId(id));
        setContact(result);
      } catch (error) {
        setDidPromisesSucceed(false);
      }

      setIsLoaded(true);
    };

    getContact();
  }, []);

  const handleDeleteClick = () => {
    const deleteContact = async () => {
      await dispatch(contactActions.deleteContactById(id));
      history.push('/contacts');
    };
    deleteContact();
  };

  const handleUpdateSubmit = (id, data) => {
    const updateContact = async () => {
      const result = await dispatch(contactActions.updateContactById(id, data));
      setContact(result);
    };
    updateContact();
  };

  if (!isLoaded) {
    return <PagePreloader />;
  }

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  return (
    <div>
      <div className="body-wrapper">
        {/* <div className="body-content"> */}
          <div className="body-content-header">
            <nav className="nav">
              <a href="#contactInformation" className="nav-link active" data-toggle="tab">
                <Text>contact.info</Text>
              </a>
            </nav>
          </div>
          <div className="body-content-body ps">
            <div className="tab-content">
              <div id="contactInformation" className="tab-pane show pd-20 pd-xl-25 active">
                <div className="d-flex align-items-center justify-content-between mg-b-25">
                  <h6 className="mg-b-0">
                    <Text>contact.personal_details</Text>
                  </h6>
                  <div className="d-flex">
                    <a
                      href=""
                      data-toggle="modal"
                      className="btn btn-sm btn-white d-flex align-items-center mg-r-5"
                      onClick={toggleEditModal}
                    >
                      <EditContact
                        modal={isEditModalOpen}
                        toggle={toggleEditModal}
                        contact={contact}
                        update={handleUpdateSubmit}
                      ></EditContact>
                      <FeatherIcon icon="edit-2" />
                      <span className="d-none d-sm-inline mg-l-5">
                        {' '}
                        <Text>contact.edit</Text>
                      </span>
                    </a>
                    <a
                      href="#modalDeleteContact"
                      data-toggle="modal"
                      className="btn btn-sm btn-white d-flex align-items-center"
                    >
                      <FeatherIcon icon="trash" />
                      <span
                        className="d-none d-sm-inline mg-l-5"
                        onClick={() => handleDeleteClick()}
                      >
                        {' '}
                        <Text>contact.delete</Text>
                      </span>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 col-sm">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.first_name</Text>
                    </label>
                    <p className="mg-b-0">{contact.first_name}</p>
                  </div>
                  <div className="col-6 col-sm">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.middle_name</Text>
                    </label>
                    <p className="mg-b-0">{contact.middle_name}</p>
                  </div>
                  <div className="col-sm mg-t-20 mg-sm-t-0">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.last_name</Text>
                    </label>
                    <p className="mg-b-0">{contact.last_name}</p>
                  </div>
                </div>

                <h6 className="mg-t-40 mg-b-20">
                  <Text>contact.details</Text>
                </h6>

                <div className="row row-sm">
                  <div className="col-6 col-sm-4">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.mobile_phone</Text>
                    </label>
                    <p className="tx-primary tx-rubik mg-b-0">{contact.mobile_phone}</p>
                  </div>
                  <div className="col-6 col-sm-4">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.home_phone</Text>
                    </label>
                    <p className="tx-primary tx-rubik mg-b-0">{contact.home_phone}</p>
                  </div>
                  <div className="col-6 col-sm-4 mg-t-20 mg-sm-t-0">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.work_phone</Text>
                    </label>
                    <p className="tx-primary tx-rubik mg-b-0">{contact.work_phone}</p>
                  </div>
                  <div className="col-sm-4 mg-t-20 mg-sm-t-30">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.email_address</Text>
                    </label>
                    <p className="tx-primary mg-b-0">{contact.email}</p>
                  </div>
                  <div className="col-sm-4 mg-t-20 mg-sm-t-30">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.company</Text>
                    </label>
                    <p className="mg-b-0">{contact.company}</p>
                  </div>
                  <div className="col-sm-4 mg-t-20 mg-sm-t-30">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.job_position</Text>
                    </label>
                    <p className="mg-b-0">{contact.job_position}</p>
                  </div>
                  <div className="col-sm mg-t-20 mg-sm-t-30">
                    <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                      <Text>contact.notes</Text>
                    </label>
                    <p className="tx-13 mg-b-0">{contact.note}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default SingleContact;
