import axios from 'axios';
import { API_DOMAIN } from '../common/constants';

// const getConfig = () => {
//     return {
//         headers: {
//             'x-jwt': getCookie('jwt')
//         }
//     }
// }

const handleError = (error) => {
    throw error.response.data.error.message;
};

const Request = {
    get: async (url, token) => {
        const uri = url.indexOf('/') === 0 ? API_DOMAIN + url : url;

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        if (token) {
            if (token.indexOf('Basic') > -1) {
                headers.Authorization = token;
            } else {
                headers.Authorization = `Bearer ${token}`;
            }
        }

        try {
            const result = await axios.get(uri, { headers });
            return result.data;
        } catch (e) {
            handleError(e);
        }
    },

    post: async (url, data, token) => {
        const uri = url.indexOf('/') === 0 ? API_DOMAIN + url : url;

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        try {
            const result = await axios.post(uri, data, { headers });
            return result.data;
        } catch (e) {
            handleError(e);
        }
    },
    patch: async (url, data, token) => {
        const uri = url;

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        try {
            const result = await axios.patch(uri, { headers }, data);
            return result;
        } catch (e) {
            handleError(e);
        }
    },
    delete: async (url, data, token) => {
        const uri = url.indexOf('/') === 0 ? API_DOMAIN + url : url;

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        try {
            const result = await axios.delete(uri, { headers }, data);
            return result.data;
        } catch (e) {
            handleError(e);
        }
    },
    put: async (url, data, token) => {
        const uri = url.indexOf('/') === 0 ? API_DOMAIN + url : url;

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        try {
            const result = await axios.put(uri, data, { headers });
            return result.data;
        } catch (e) {
            handleError(e);
        }
    },
};

export default Request;
