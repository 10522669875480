export const LOGO = '/assets/images/fsapp.svg';
export const GUEST_NO_AVATAR = 'https://fc-e26b.kxcdn.com/image/guests/no-avatar.png';
export const SITE_NO_PREVIEWURL = 'https://my.duda.co/editor/direct/images/nee/snapshotPlaceholder.png';
export const NONE_IMG = '/img/no-image-cities.png';
export const NO_IMAGE = '/assets/img/no-image.svg';
export const NO_PROPERTY_IMAGE = '/img/no-image-cities.png';
export const STORAGE_BASE_URI = 'https://fantastic.azureedge.net/';

export const APP_DOMAIN = 'fsapp.io';

// automation page
export const MODE_LIST = 'list';
export const MODE_ADD = 'add';
export const MODE_EDIT = 'edit';

export const FORM_PRIMARY_COLOR = '#2DBB52';
export const FORM_BACKGROUND_COLOR = '#EEEEEE';
export const FORM_COLOR = '#333';

export const CURRENCY_PLACEHOLDER = '{num}';

export const FORM_IMAGE_UPLOAD_PATH = 'images/autoforms';

export const FORM_ASSETS_URL = '/assets/images/autoform/';

export const CHANNEL_AIRBNB = 'Airbnb';
export const CHANNEL_RU = 'RentalsUnited';
export const CHANNEL_BCOM = 'Booking.com';
export const CHANNEL_HOMEAWAY = 'HomeAway';

export const INBOX_MESSAGE_TYPE_CHANNEL = 'channel';
export const INBOX_MESSAGE_TYPE_SMS = 'sms';
export const INBOX_MESSAGE_TYPE_WHATSAPP = 'whatsapp';
export const INBOX_MESSAGE_TYPE_NOTE = 'note';

export const SMS_MAX_LENGTH = 1500;
export const WHATSAPP_MESSAGE_MAX_LENGTH = 1024;

export const RESERVATION_STATUS_INQUIRY = 'inquiry';
export const RESERVATION_STATUS_PENDING = 'pending';
export const RESERVATION_STATUS_ACCEPTED = 'accepted';
export const RESERVATION_STATUS_CANCELLED = 'cancelled';
export const RESERVATION_STATUS_OFFER = 'special_offer';
export const RESERVATION_STATUS_EXPIRED = 'expired';
export const RESERVATION_STATUS_PREAPPROVED = 'preapproved';

export const RESERVATION_SOURCE_AIRBNB = 'Airbnb';

export const CAPTCHA_VERIFICATION = 'captcha';
export const TWO_FACTOR_VERIFICATION = 'two_factor_auth';

export const INTEGRATION_TYPE_AIRBNB = 1;
export const INTEGRATION_TYPE_AIRBNB_OFFICIAL = 28;
export const INTEGRATION_TYPE_RU = 3;
export const INTEGRATION_TYPE_BOOKING = 22;
export const INTEGRATION_TYPE_HOME_AWAY = 26;
export const INTEGRATION_TYPE_FS = 17;

export const APP_AUTO_MESSAGES = 14;
export const APP_EMAIL_GRABBER = 9;
export const APP_ANSWERING_MACHINE = 11;
export const APP_AUTO_REVIEWS = 8;
export const APP_WEBSITE = 12;

export const DYNAMIC_VARIABLES_LINK = 'https://help.fantasticstay.com/portal/kb/articles/dyna';
export const INTEGRATION_TYPE_HOMEAWAY = 26;

export const CUSTOMER_TIER_FREE = 'free';
export const CUSTOMER_TIER_PRO = 'pro';
export const CUSTOMER_TIER_BUSINESS = 'business';
export const CUSTOMER_TIER_GROWTH = 'growth';
export const CUSTOMER_TIER_CORPORATE = 'corporate';
export const CUSTOMER_TIER_ENTERPRISE = 'enterprise';
export const CUSTOMER_TIER_ENTERPRISE_PLUS = 'enterprise_plus';

export const PERMISSION_CALENDAR = 'Calendar';
export const PERMISSION_FINANCIAL_INFORMATION = 'Financial information';
export const PERMISSION_AVAILABILITY = 'Availability';
export const PERMISSION_PRICING = 'Pricing';
export const PERMISSION_CONTACT_INFORMATION = 'Contact information';
export const PERMISSION_CREDIT_CARDS_INFORMATION = 'Credit cards information';

export const CARD_TYPE_VISA = 'Visa';
export const CARD_TYPE_MASTERCARD = 'MasterCard';
export const CARD_TYPE_AMEX = 'American Express';

export const PATH_BILLING_PLANS = '/billing/plans';

export const API_DOMAIN = 'http://localhost:3001';

export const CURRENCY_FORMAT = {
  AED: '{num}د.إ',
  ANG: 'ƒ{num}',
  AOA: 'AOA{num}',
  ARS: `\${num}`,
  AUD: `\${num}`,
  BAM: 'KM{num}',
  BBD: `\${num}`,
  BGN: '{num}лв',
  BHD: '{num}BD',
  BND: `\${num}`,
  BRL: `R\${num}`,
  CAD: `\${num}`,
  CHF: 'Fr{num}',
  CLF: '{num}UF',
  CLP: `\${num}`,
  CNY: '¥{num}',
  COP: `\${num}`,
  CRC: '₡{num}',
  CZK: '{num}Kč',
  DKK: 'kr{num}',
  EEK: 'KR{num}',
  EGP: 'E£{num}',
  EUR: '€{num}',
  FJD: `FJ\${num}`,
  GBP: '£{num}',
  GEL: '{num}₾',
  GTQ: 'Q{num}',
  HKD: `\${num}`,
  HRK: 'kn{num}',
  HUF: 'Ft{num}',
  IDR: 'Rp{num}',
  ILS: '₪{num}',
  INR: 'Rs{num}',
  JOD: '{num}د.ا',
  JPY: '¥{num}',
  KES: 'KSh{num}',
  KRW: '₩{num}',
  KWD: '{num}KD',
  KYD: `\${num}`,
  LTL: 'Lt{num}',
  LVL: 'Ls{num}',
  MAD: '{num}د.م',
  MVR: 'Rf{num}',
  MXN: `\${num}`,
  MYR: 'RM{num}',
  NGN: '₦{num}',
  NOK: 'kr{num}',
  NZD: `\${num}`,
  OMR: '{num}OMR',
  PEN: 'S/.{num}',
  PHP: '₱{num}',
  PLN: 'zł{num}',
  QAR: '{num}QAR',
  RON: 'lei{num}',
  RUB: '{num}руб',
  SAR: '{num}SAR',
  SEK: 'kr{num}',
  SGD: `\${num}`,
  THB: '฿{num}',
  TRY: 'TL{num}',
  TTD: `\${num}`,
  TWD: `\${num}`,
  UAH: '₴{num}',
  USD: `\${num}`,
  VEF: 'Bs {num}',
  VND: '₫{num}',
  XCD: `\${num}`,
  XPF: '₣{num}',
  ZAR: 'R{num}',
};

export const TIMEZONE_OFFSET = [
  {
    offset: 'UTC-12'
  },
  {
    offset: 'UTC-11'
  },
  {
    offset: 'UTC-10'
  },
  {
    offset: 'UTC-9'
  },
  {
    offset: 'UTC-8'
  },
  {
    offset: 'UTC-7'
  },
  {
    offset: 'UTC-6'
  },
  {
    offset: 'UTC-5'
  },
  {
    offset: 'UTC-4'
  },
  {
    offset: 'UTC-3'
  },
  {
    offset: 'UTC-2'
  },
  {
    offset: 'UTC-1'
  },
  {
    offset: 'UTC'
  },
  {
    offset: 'UTC+1'
  },
  {
    offset: 'UTC+2'
  },
  {
    offset: 'UTC+3'
  },
  {
    offset: 'UTC+4'
  },
  {
    offset: 'UTC+5'
  },
  {
    offset: 'UTC+6'
  },
  {
    offset: 'UTC+7'
  },
  {
    offset: 'UTC+8'
  },
  {
    offset: 'UTC+9'
  },
  {
    offset: 'UTC+10'
  },
  {
    offset: 'UTC+11'
  },
  {
    offset: 'UTC+12'
  }
];

export const USER_ROLES = {
  admin: 'Admin',
  team_member: 'Team member',
  housekeeping: 'Housekeeping',
  property_owner: 'Property owner',
  revenue_manager: 'Revenue manager',
  accountant: 'Accountant'
};

export const USER_ROLES_ARRAY = [{
  text: 'Admin',
  value: 'admin'
},
{
  text: 'Team member',
  value: 'team_member'
},
{
  text: 'Housekeeping',
  value: 'housekeeping'
},
{
  text: 'Property owner',
  value: 'property_owner'
},
{
  text: 'Revenue manager',
  value: 'revenue_manager'
},
{
  text: 'Accountant',
  value: 'accountant'
}
];

export const USER_PERMISSIONS = [
  {
    text: 'Read only',
    value: 'Read only'
  },
  {
    text: 'Write',
    value: 'Write'
  }
  , {
    text: 'See prices',
    value: 'See prices '
  },
  {
    text: 'See contact information',
    value: 'See contact information'
  }
  ,
  {
    text: 'See Financial information',
    value: 'See Financial information'
  }
  ,
  {
    text: 'See Credit card info',
    value: 'See Credit card info'
  }];

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME_REGEX = /^(([A-Za-z]+[-']?)+([A-Za-z]+)?)+([A-Za-z]+[-']?)*([A-Za-z]+)?$/;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/g

export const TEST_PROPERTIES = ['RS 108', 'RS A-59', 'RS B-17', 'RS B-40', 'RS House 1', 'RS House 2', 'RS House 3', 'RS House 4', 'B R01', 'HOTEL BOROVETS 1', 'HOTEL BOROVETS 2', 'HOTEL LAZUR'];

export const ACCOUNT_STATUSES = ['active', 'deactivated', 'invited'];

export const SELECTABLE_PERMISSIONS = [
  {
    key: 'permissions.calendar',
    permission_name: 'Calendar'
  },
  {
    key: 'permissions.availability',
    permission_name: 'Availability'
  },
  {
    key: 'permissions.pricing',
    permission_name: 'Pricing'
  },
  {
    key: 'permissions.contact_information',
    permission_name: 'Contact information'
  },
  {
    key: 'permissions.financial_information',
    permission_name: 'Financial information'
  },
  {
    key: 'permissions.credit_cards_information',
    permission_name: 'Credit cards information'
  },
];

export const COMPANY_ACTIONS = ['Rents', 'Invests', 'Manages', 'Advertises', 'Hosts'];
export const PMS_ARRAY = ['Guesty', 'Hostify', 'Uplisting', 'Avantio', 'BookingSync', 'Tokeet'];
export const CHANNELS_ARRAY = ['Airbnb', 'Booking.com', 'VRBO / Home Away', 'Trip Advisor', 'Expedia']