import { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../../../../components/ui/Text";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";

const UserActions = props => {
  const [showDelete, setShowDelete] = useState(false);

  return (
    <div className={props.className + '-actions p-1'}>
      <label className={props.className + '-actions-label'}><Text>secondary.select_action</Text></label>

      <div className={props.className + '-actions-options'}>

        <Link to={`/user/${props.user.user_id}`}>
          <div className={props.className + '-actions-edit-option'}>
            <label><Text>main.edit</Text></label>
          </div>
        </Link>

        <div className={props.className + '-actions-delete-option'} onClick={() => setShowDelete(true)}>
          <label><Text>action.delete</Text></label>
        </div>
      </div>

      <DeleteUserModal user={props.user} users={[]} open={showDelete} onClose={() => setShowDelete(false)} />

    </div>
  );
}

export default UserActions;