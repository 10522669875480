import Request from '../../utils/Request';
import Env from '../../Env';

export const search = (search) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const result = await Request.post(`/guests/search`, {search}, token);
        return result;
    }
}

export const getGuest = (id) => {
    return async (dispatch, getState) => {
        console.log(id);
        const token = await getState().auth.token;
        const result = await Request.get(`${Env.dbServerUrl}/guests/${id}`, token);
        return result;
    }
}
export const getGuests = () => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.get(`${Env.dbServerUrl}/guests`, token);
        return result;
    }
}

export const updateGuest = (id, data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.put(`${Env.dbServerUrl}/contacts/${id}`,data, token);
        console.log(`${Env.dbServerUrl}/contacts/${id}`)
        return result;
    }
}

export const addNewNote = (id,data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.post(`${Env.dbServerUrl}/guests/${id}/notes`,data, token);
        return result;
    }
}

export const deleteGuestNote = (id,noteId) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.delete(`${Env.dbServerUrl}/guests/${id}/notes/${noteId}`, null, token);
        return result;
    }
}

export const addNewPhone = (id,data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.post(`${Env.dbServerUrl}/guests/${id}/phones`,data, token);
        return result;
    }
}

export const updateGuestPhone = (id,phoneId,data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.put(`${Env.dbServerUrl}/guests/${id}/phones/${phoneId}`,data, token);
        return result;
    }
}

export const deletePhone = (id,phoneId) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.delete(`${Env.dbServerUrl}/guests/${id}/phones/${phoneId}`, null, token);
        return result;
    }
}


export const addNewEmail = (id,data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.post(`${Env.dbServerUrl}/guests/${id}/emails`,data, token);
        return result;
    }
}

export const updateGuestEmail = (id,emailId,data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.put(`${Env.dbServerUrl}/guests/${id}/emails/${emailId}`,data, token);
        return result;
    }
}

export const deleteEmail = (id,emailId) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.delete(`${Env.dbServerUrl}/guests/${id}/emails/${emailId}`, null, token);
        return result;
    }
}


