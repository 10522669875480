import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import DudaTableSection from '../../components/apps/duda/DudaTableSection';

import '../../components/apps/duda/sites.scss';
import '../../components/apps/duda/sites-grid.scss';

const DudaPage = (props) => {
  const user = useSelector((state) => state.auth.user);

  const permissions = useSelector((state) => state.auth.permissions);

  const history = useHistory();
  if (!permissions.includes('DudaPage')) {
      history.push('/access-denied')
  }

  useEffect(() => {
    document.title = 'Website Pro';
  }, []);

  return (
    <div
      className={`body-wrapper ${
        user?.user_metadata?.navigation_layout === 'Vertical' ? 'body-wrapper-two' : ''
      }`}
    >
      <DudaTableSection />
    </div>
  );
};

export default DudaPage;
