import Env from '../../Env';
import Request from '../../utils/Request';

export const GET_ROLES = 'GET_ROLES'

const ROLES_ORDER = [8, 4, 9, 7, 11];

export const getRoles = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const allRoles = await Request.get(`${Env.apiDomainSSO}/roles`, token);
    const filteredRoles = allRoles.filter(el => ROLES_ORDER.includes(el.id))
      .sort((a, b) => ROLES_ORDER.indexOf(a.id) - ROLES_ORDER.indexOf(b.id));
      
    filteredRoles.forEach(role => {
      role.permissions = {}
      role.role_permissions.forEach(element => { role.permissions[element.permission_name] = element.access });

      delete role.role_permissions;
    });

    dispatch({ type: GET_ROLES, data: filteredRoles });
    return filteredRoles;
  }
}