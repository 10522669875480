import MonthsDropDown from '../MonthsDropDown/MonthsDropDown';
import { Button } from 'reactstrap';
import './HeaderMenu.scss';
import Text from '../../../components/ui/Text';

const HeaderMenu = ({
    addSearchTags,
    searchTerms,
    setMultiCalendarWithSearchTags,
    toggleFindAvailability,
    clearFindAvailabilityResult,
    toggleResultFindAvailability,
    findAvailabilityResult,
    bulkEditButton,
    toggleBulkEdit,
    unCheckListings,
    checkedListings,
    toggleCustomStay,
    customStayButton,
    setDateRange,
    dateRange,
}) => {
    return (
        <div className='header-box'>
            <div className='header-box-item'>
                <input
                    list='listings'
                    type='text'
                    id='search-box'
                    placeholder='Add Tags to search by...'
                    onKeyUp={(e) => addSearchTags(e)}
                />{' '}
                <span>
                    <Button color='warning' onClick={(e) => addSearchTags(e)}>
                        <Text>Add Tag</Text>
                    </Button>
                </span>
                {searchTerms?.map((st) => {
                    return (
                        <span className='search-term'>
                            <button value={st} onClick={(e) => addSearchTags(e)}>
                                X
                            </button>
                            {st}{' '}
                        </span>
                    );
                })}
                {searchTerms.length === 0 ? (
                    <></>
                ) : (
                    <>
                        <Button onClick={(e) => setMultiCalendarWithSearchTags(e)}>Search</Button>
                    </>
                )}
                {/* <datalist id='listings'>
                {' '}
                {listings?.map((l) => {
                    return <option key={l.id}>{l.name}</option>;
                })}{' '}
            </datalist> */}
            </div>

            <div className='header-box-item'>
                <Button
                    color='info'
                    onClick={() => toggleFindAvailability() + clearFindAvailabilityResult()}
                >
                    <Text>Find Availability</Text>
                </Button>{' '}
                {findAvailabilityResult ? (
                    <Button color='warning' onClick={toggleResultFindAvailability}>
                        <Text>Clear Result</Text>
                    </Button>
                ) : (
                    <span />
                )}
            </div>

            <div className='header-box-item'>
                <label htmlFor='from-date'><Text>From</Text></label>
                <input
                    value={dateRange.from_date}
                    onChange={(e) => setDateRange({ ...dateRange, from_date: e.target.value })}
                    type='date'
                    name='from-date'
                />
                <label htmlFor='to-date'><Text>To</Text></label>
                <input
                    value={dateRange.to_date}
                    onChange={(e) => setDateRange({ ...dateRange, to_date: e.target.value })}
                    type='date'
                    name='to-date'
                />{' '}
                <Button color='info'>
                    {' '}
                    <Text>Apply</Text>
                </Button>
            </div>

            <div className='header-box-item'>
                <MonthsDropDown />
                <span>
                    <Button color='info'>{'<'}</Button> <Button color='info'>{'>'} </Button>
                </span>
            </div>
            {bulkEditButton ? (
                <div className='header-box-item'>
                    <Button color='info' onClick={toggleBulkEdit}>
                        <Text> Bulk Edit</Text>
                    </Button>{' '}
                    <Button color='warning' onClick={unCheckListings}>
                        <Text>Clear</Text>({checkedListings.length})<Text> Selected</Text>
                    </Button>
                </div>
            ) : (
                <div className='header-box-item'></div>
            )}

            {customStayButton ? (
                <div className='header-box-item'>
                    <Button color='info' onClick={toggleCustomStay}>
                        <Text>Custom Stay</Text>
                    </Button>
                </div>
            ) : (
                <div className='header-box-item'></div>
            )}
        </div>
    );
};

export default HeaderMenu;
