/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */

import { GET_USERS, GET_USER_BY_ID, UPDATE_ACC_STATUS } from "../actions/users";

const initialState = {
  allUsers: [],
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        allUsers: action.data,
      };
    }
    case UPDATE_ACC_STATUS: {
      return {
        ...state,
        user: action.data
      }
    }
    case GET_USER_BY_ID: {
      return {
        ...state,
        user: action.data,
      };
    }
    default:
      return state;
  }
}

