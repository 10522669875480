import { useEffect, useState } from "react";
import RolesDropdown from "../../../../components/elements/RolesDropdown";
import Text from "../../../../components/ui/Text";
import PermissionsComponent from "./PermissionsComponent";

const ModifyRole = (props) => {
  const [parentPermissions, setParentPermissions] = useState({});
  const [localPermissions, setLocalPermissions] = useState({});
  const [localRole, setLocalRole] = useState(props.selectedRole || '');
  const [isRoleModified, setIsRoleModified] = useState(false);
  const [localAdditionalPerms, setLocalAdditionalPerms] = useState({});

  useEffect(() => {
    setParentPermissions(props.selectedPermissions);
  }, [props.selectedPermissions]);

  useEffect(() => {
    if (Object.keys(parentPermissions).length > 0) setLocalPermissions(parentPermissions);
    else setLocalPermissions(localRole.permissions);
  }, [localRole, parentPermissions]);

  useEffect(() => {
    if (JSON.stringify(localRole.permissions) !== JSON.stringify(localPermissions)) setIsRoleModified(true);
    else setIsRoleModified(false);
  }, [localPermissions, localRole.permissions]);


  return (
    <div id='modify-role-view'>
      <div className='modify-role-content'>
        <div className='modify-role-heading'>
          <label className='mb-0'><Text>secondary.modify</Text></label>

          <RolesDropdown
            wrapperClassName='modify-role-dropdown-wrapper'
            selectClassName='modify-role-dropdown'
            selectedRole={localRole}
            permissionsModified={isRoleModified}
            passDropdownRoleData={id => {
              setLocalRole(props.roles.find(r => r.id === id));
              setParentPermissions({});
              setLocalPermissions(localRole.permissions);
            }}
            optionSize={{ fontSize: '14px' }} />

          <div className="line" />

          <label className='mb-0 mt-1'><Text>lowercase.permissions</Text></label>
        </div>

        <PermissionsComponent
          selectedRole={localRole}
          localPermissions={localPermissions}
          setLocalPermissions={setLocalPermissions}
          additionalPerms={localAdditionalPerms}
          setAdditionalPerms={setLocalAdditionalPerms} />
      </div>

      <div className='d-flex mt-2 modify-role-bottom-container'>
        <div className='modify-permissions-btn' onClick={() => {
          props.passPermissions(localPermissions);
          props.passDropdownRoleData(localRole.id);
          props.passAdditionalPermissions(localAdditionalPerms);
          props.closeForm();
        }}>
          <label className='mb-0'><Text>secondary.modify</Text> <Text>lowercase.permissions</Text></label>
        </div>

        <div className='inv-form-close-btn' onClick={props.closeForm}>
          <label className='mb-0'><Text>action.back</Text></label>
        </div>
      </div>
    </div>
  );
};

export default ModifyRole;