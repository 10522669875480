import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PagePreloader from "../Base/PagePreloader";
// import { useSelector } from "react-redux";
// import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
// import Loading from "../Base/Loading";

const MetabaseAppEmbed = ({
                              title = "Metabase",
                              className,
                              style = {
                                  position: 'relative'
                              },
                              base = "https://bi.fsapp.io",
                              path = "/",
                              onMessage,
                              onLocationChange,
                              onFrameChange,
                              getAuthUrl,
                              fitHeight
                          }) => {
    // ref for the iframe HTML element
    const iframeEl = useRef(null);
    // ref for the current `src` attribute
    const src = useRef(`${base}${path}`);
    // ref for the current location, as reported via postMessage
    const location = useRef(null);
    const [frame, setFrame] = useState();
    const [loaded, setLoaded] = useState(false);

    // const customer = useSelector(state => state.auth.customer);

    const history = useHistory();

    const metabasePath = useParams()[0];

    // setup postMessage listener
    useEffect(() => {
        const handleMessage = e => {
            if (e.source === iframeEl?.current?.contentWindow && e.data.metabase) {
                // sync the location ref
                if (e.data.metabase.type === "location") {
                    const { pathname, search, hash } = e.data.metabase.location;

                    history.push('/analytics' + [pathname, search, hash].join(""));

                    // location.current = [pathname, search, hash].join("");
                    if (onLocationChange) {
                        onLocationChange(e.data.metabase.location);
                    }
                } else if (e.data.metabase.type === "frame") {
                    setFrame(e.data.metabase.frame);
                    if (onFrameChange) {
                        onFrameChange(e.data.metabase.frame);
                    }
                }
                if (onMessage) {
                    onMessage(e.data.metabase);
                }
            }
        };

        if (!!metabasePath && iframeEl?.current !== null) {
            // location syncing enabled, use postMessage to update location
            iframeEl.current.contentWindow.postMessage(
                {
                    metabase: {
                        type: "location",
                        location: metabasePath
                    },
                },
                // FIXME SECURITY: use whitelisted origin instead of "*"
                "*",
            );

            src.current = `${base}${metabasePath}`;
        }

        // on first load replace the src with the auth URL, if any
        if (getAuthUrl) {
            src.current = getAuthUrl(src.current);
        }

        window.addEventListener("message", handleMessage);

        setLoaded(true);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    if (location.current == null) {
        // location syncing not enabled, update src
        // src.current = `${base}${path}`;
    } else if (location.current !== path) {
        // location syncing enabled, use postMessage to update location
        iframeEl.current.contentWindow.postMessage(
            {
                metabase: {
                    type: "location",
                    location: metabasePath
                },
            },
            // FIXME SECURITY: use whitelisted origin instead of "*"
            "*",
        );
    }

    const frameMode = frame && frame.mode;
    const height =
        frameMode === "normal"
            ? frame.height
            : frameMode === "fit" && fitHeight != null
            ? fitHeight
            : undefined;

    if (!loaded) {
        return <PagePreloader/>
    }

    return (
        <iframe
            ref={iframeEl}
            src={src.current}
            title={title}
            className={className}
            style={{ border: "none", width: "100%", height: height || "100vh", ...style }}
        />
    );
};

export default MetabaseAppEmbed;