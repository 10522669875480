import Request from '../../utils/Request';
import Env from '../../Env';

export const getContacts = () => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.get(`${Env.dbServerUrl}/contacts`, token);
        return result;
    }
}

export const getContactbyId = (id) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.get(`${Env.dbServerUrl}/contacts/${id}`, token);
        return result;
    }
}
export const deleteContactById = (id) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.delete(`${Env.dbServerUrl}/contacts/${id}`, null,token);
        return result;
    }
}
export const updateContactById = (id, data) => {
    return async (dispatch, getState) => {
        const token = await getState().auth.token;
        const result = await Request.put(`${Env.dbServerUrl}/contacts/${id}`,data, token);
        return result;
    }
}

