import './Questionnaire.css';
import * as rolesActions from '../../store/actions/roles';
import * as authActions from '../../store/actions/auth';
import { default as fs_logo } from '../../assets/icons/fs-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { CHANNELS_ARRAY, COMPANY_ACTIONS, PMS_ARRAY } from '../../common/constants';
import QuestionnaireDropdown from './QuestionnaireDropdown';
import SubmitBtn from '../../components/elements/SubmitBtn';


const MANAGE_PROPERTIES = 'Manage my properties listings, calendars, reservations across all channels';
const BUILD_WEBSITE = 'Create my own branded website with possibility for direct booking';
const OUTSOURCE_GUEST_SERVICES = 'Outsource guest communication service';
const GET_ADDITIONAL_RESERVATIONS = 'Get additional reservations';

const Questionnaire = () => {
  const [roleSelection, setRoleSelection] = useState('');
  const [companyAction, setCompanyAction] = useState('');
  const [propertiesCount, setPropertiesCount] = useState('');
  const [websiteOwnership, setWebsiteOwnership] = useState('');
  const [website, setWebsite] = useState('www.');
  const [PMS, setPMS] = useState('');
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [additionalChannel, setAdditionalChannel] = useState('');
  const [manageProperties, setManageProperties] = useState(null);
  const [buildWebsite, setBuildWebsite] = useState(null);
  const [outsourceGuestServices, setOutsourceGuestServices] = useState(null);
  const [getAdditionalReservations, setGetAdditionalReservations] = useState(null);
  const [selectedInterests, setSelectedInterests] = useState({});
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const FS_INTERESTS_ARR = [MANAGE_PROPERTIES, BUILD_WEBSITE, OUTSOURCE_GUEST_SERVICES, GET_ADDITIONAL_RESERVATIONS];
  const dataArray = [roleSelection, companyAction, propertiesCount, websiteOwnership, website, PMS];
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const rolesOptions = useSelector(state => state.roles.roles).map(el => el.name);
  useEffect(() => {
    const initData = async () => {
      await dispatch(rolesActions.getRoles());
    };

    initData();
  }, [dispatch]);

  const handleSingleCheck = (selectedChannel) => {
    const checkedChannel = selectedChannels.find(el => el === selectedChannel);

    if (checkedChannel) {
      setSelectedChannels(selectedChannels.filter(element => element !== selectedChannel));
    } else {
      setSelectedChannels([...selectedChannels, selectedChannel]);
    }
  };

  const handleInterestSelect = (field, interest) => {

    if (field === MANAGE_PROPERTIES) {
      setManageProperties(interest);
      setSelectedInterests({ ...selectedInterests, [field]: interest })
    }
    if (field === BUILD_WEBSITE) {
      setBuildWebsite(interest);
      setSelectedInterests({ ...selectedInterests, [field]: interest })
    };
    if (field === OUTSOURCE_GUEST_SERVICES) {
      setOutsourceGuestServices(interest);
      setSelectedInterests({ ...selectedInterests, [field]: interest })
    }
    if (field === GET_ADDITIONAL_RESERVATIONS) {
      setGetAdditionalReservations(interest);
      setSelectedInterests({ ...selectedInterests, [field]: interest })
    }
  };

  const handleSubmit = async () => {
    const data = {
      user_id: user.id,
      company_role: roleSelection,
      activity: companyAction,
      properties_count: propertiesCount,
      is_website_owner: websiteOwnership === 'Own' ? true : false,
      pms: PMS,
      channels: additionalChannel.length < 1 ? selectedChannels : [...selectedChannels, additionalChannel],
      manage_properties: manageProperties,
      build_website: buildWebsite,
      outsource_guest_services: outsourceGuestServices,
      get_additional_reservations: getAdditionalReservations
    }

    if (requestInProgress) return;

    setRequestInProgress(true);

    try {
      await dispatch(authActions.submitQuestionnaire(data));
      window.location.href = "/";
    } catch (error) {
      return setDidSubmitSucceed(false);
    }

    setRequestInProgress(false);
    setDidSubmitSucceed(true);
  }

  return (
    <div id='questionnaire-page'>
      <div className='questionnaire-left-section'>
        <div>
          <img src={fs_logo} alt='' />
        </div>

        <div className='questionnaire-mid-section'>

          <div className='d-flex flex-column'>
            <label className='mb-0 questionnaire-heading'>One last thing...</label>
            <label className='mb-0 pt-3 dive-in-label'>Before we dive in, help us tailor FantasticStay for you.</label>
          </div>

          <div className='selections-container pt-5'>
            <div className='d-flex flex-column'>
              <div className='d-flex selections-row mb-4'>
                <label className='mb-0 mr-1'>I am</label>
                {<QuestionnaireDropdown
                  options={rolesOptions}
                  passDropdownSelection={setRoleSelection}
                  selectClassName='role-options-dropdown'
                  optionStyle={{ fontSize: '16px', paddingBottom: '2px', color: '#00C141' }} />}
                <label className='mb-0 mr-1 ml-1'>in a company that</label>

                {<QuestionnaireDropdown
                  options={COMPANY_ACTIONS}
                  passDropdownSelection={setCompanyAction}
                  selectClassName='company-actions-dropdown'
                  optionStyle={{ fontSize: '16px', paddingBottom: '2px', color: '#00C141' }} />}
                {<textarea className='ml-1 properties-count-textarea' onChange={event => setPropertiesCount(event.target.value)} />}
                <label className='mb-0 ml-1'>properties.</label>
              </div>

              <div className='d-flex selections-row mb-4'>
                <label className='mb-0 mr-1'>I</label>
                {<QuestionnaireDropdown
                  options={['Own', `Don't own`]}
                  passDropdownSelection={setWebsiteOwnership}
                  selectClassName='website-ownership-dropdown'
                  optionStyle={{ fontSize: '16px', paddingBottom: '2px', color: '#00C141' }} />}
                <label className='mb-0 mr-1 ml-1'>a booking website</label>
                {websiteOwnership === `Don't own`
                  ? ''
                  : <textarea className='website-ownership-textarea' value={website} onChange={event => setWebsite(event.target.value)} />}
              </div>

              <div className='d-flex selections-row'>
                <label className='mb-0 mr-1'>I use</label>
                {<QuestionnaireDropdown
                  options={PMS_ARRAY}
                  passDropdownSelection={setPMS}
                  selectClassName='pms-dropdown'
                  optionStyle={{ fontSize: '16px', paddingBottom: '2px', color: '#00C141' }} />}
                <label className='mb-0 ml-1'>to manage my work.</label>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <label className='mb-0 questionnaire-main-labels'>I use the following channels:</label>

            <div className='channels-section pt-3'>
              <div className='d-flex assign-property-boxes w-auto'>
                {CHANNELS_ARRAY.map((channel, index) => {
                  return (
                    <div key={index} className={`mb-2 mr-3 ${selectedChannels.find(el => el === channel) ? 'property-box-checked' : 'property-box'}`}>
                      <div className='p-1 d-flex align-items-center'>
                        <div className={`users-table-${selectedChannels.find(el => el === channel) ? 'checked' : 'empty'}-checkbox`}
                          onClick={() => handleSingleCheck(channel)} />
                        <label className={`mb-0 ml-2 ${selectedChannels.find(el => el === channel) ? 'selected-' : ''}channel-label`}>{channel}</label>
                      </div>
                    </div>
                  )
                })}
                <div className={`mb-2 mr-3 ${selectedChannels.find(el => el === 'I am yet to start') ? 'property-box-checked' : 'property-box'}`}>
                  <div className='p-1 d-flex align-items-center'>

                    <div className={`users-table-${selectedChannels.find(el => el === 'I am yet to start') ? 'checked' : 'empty'}-checkbox`}
                      onClick={() => handleSingleCheck('I am yet to start')} />
                    <label className={`mb-0 ml-2 ${selectedChannels.find(el => el === 'I am yet to start') ? 'selected-' : ''}channel-label`}>
                      I am yet to start
                    </label>
                  </div>
                </div>
              </div>

              <div className='pt-2 d-flex align-items-center'>
                <label className='mb-0 questionnaire-main-labels'>Other:</label>
                <textarea className='other-channel-textarea' onChange={event => setAdditionalChannel(event.target.value)} />
              </div>
            </div>
          </div>
          {(selectedChannels.length < 1 && additionalChannel.length < 1)
            ? ''
            : <div className='pt-5'>
              <label className='mb-0 questionnaire-main-labels'>I hope FantasticStay will help me for:</label>
              {FS_INTERESTS_ARR.map((el, id) => {
                return (
                  <div key={id} className='pt-3'>
                    <label className='mb-2 questionnaire-secondary-labels'>{el}</label>
                    <div className='d-flex'>
                      <div className={`interest-box${selectedInterests[el] === 'not_interested' ? '-active' : ''} 
                    mr-2 d-flex flex-column justify-content-center align-items-center`}
                        onClick={() => handleInterestSelect(el, 'not_interested')}>
                        <img src={`/img/${selectedInterests[el] === 'not_interested' ? 'green-' : ''}not-interested-emote.svg`} alt='' />
                        <label className='interest-label mt-1 mb-0'>Not Interested</label>
                      </div>

                      <div className={`interest-box${selectedInterests[el] === 'interested' ? '-active' : ''} 
                    mr-2 d-flex flex-column justify-content-center align-items-center`}
                        onClick={() => handleInterestSelect(el, 'interested')}>
                        <img src={`/img/${selectedInterests[el] === 'interested' ? 'green-' : ''}interested-emote.svg`} alt='' />
                        <label className='interest-label mt-1 mb-0'>Interested</label>
                      </div>

                      <div className={`interest-box${selectedInterests[el] === 'very_interested' ? '-active' : ''} 
                    mr-2 d-flex flex-column justify-content-center align-items-center`}
                        onClick={() => handleInterestSelect(el, 'very_interested')}>
                        <img src={`/img/${selectedInterests[el] === 'very_interested' ? 'green-' : ''}very-interested-emote.svg`} alt='' />
                        <label className='interest-label mt-1 mb-0'>Very Interested</label>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>}
          <div className='pt-4 pb-5'>
            <SubmitBtn
              submittingState={requestInProgress}
              didSubmitSucceed={didSubmitSucceed}
              submitFunction={handleSubmit}
              disabled={dataArray.some(el => el.length < 1) || Object.keys(selectedInterests).length < 4}
              submittingClassName='user-profile-saving-btn'
              submitClassName='user-profile-save-btn'
              disabledBtnClassName='user-profile-save-btn-disabled'
              failedSubmitClassName='user-profile-save-btn-failed-submit'
              submitText='action.next'
              submittingText='action.next'
              promiseSucceedText='action.saved'
              promiseFailText='action.not_saved'
            />
          </div>
        </div>
      </div>
      <div className='sign-up-page-image' />
    </div >
  )
};

export default Questionnaire;