import { useSelector } from 'react-redux';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import ListFilter from './filters/ListFilter';
import FSInput from '../elements/FSInput.js';
import FSButton from '../elements/FSButton';

import * as listActions from '../../store/actions/list';
import * as viewActions from '../../store/actions/view';

const TYPE_TEXT = 'text';
const TYPE_NUMBER = 'number';
const TYPE_TIME = 'time';
const TYPE_BOOLEAN = 'bool';
const TYPE_SELECT = 'select';
const TYPE_CHART_DATE = 'chart_date';
const TYPE_DATE = 'date';

const multipleSelectValidation = (value) => {
  return value.length > 0;
};
const textValidation = (value) => {
  return value.length > 0;
};
const numberValidation = (value, option) => {
  if (option === 'between') {
    const a = value[0] !== '' && !isNaN(parseFloat(value));
    const b = value[1] !== '' && !isNaN(parseFloat(value));
    if (a && b) {
      return value[0] < value[1];
    } else {
      return false;
    }
  }
  return value !== '' && !isNaN(parseFloat(value)) && value !== null;
};
const timeValidation = (value) => {
  return value.length > 0;
};

const dateValidation = (value, option) => {
  if (option === 'Due in') {
    return value[0] !== '';
  }
  if (option === 'Between') {
    return value[0] !== '' && value[1] !== '' && value !== '';
  }
  return value.length > 0;
};

const ListFiltersSection = (props) => {
  let history = useHistory();
  const allFilters = useSelector((state) => state.list.allFilters);
  const selectedFilters = useSelector((state) =>
  state.list.selectedFilters ? state.list.selectedFilters : state.view.selectedFilters,
  );
  const currentView = useSelector((state) => state.view.currentView);
  const [showApplyButton,setShowApplyButton] = useState(false);
  const [showSaveButton,setShowSaveButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState('');

  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();

  const handleFilterChange = (filter, value, option) => {
    let f = {
      field: filter.field,
      option: option || '',
      value: value,
    };
    if (filter.type === TYPE_SELECT) {
      f.isValid = multipleSelectValidation(value);
    }
    if (filter.type === TYPE_TEXT) {
      f.isValid = textValidation(value);
    }
    if (filter.type === TYPE_NUMBER) {
      f.isValid = numberValidation(value, option);
    }
    if (filter.type === TYPE_BOOLEAN) {
      f.isValid = true;
    }
    if (filter.type === TYPE_TIME) {
      f.isValid = timeValidation(value);
    }
    if (filter.type === TYPE_CHART_DATE || filter.type === TYPE_DATE) {
      f.isValid = dateValidation(value, option);
    }
    if (typeof props.onChange === 'function') {
      props.onChange(filter, f);
      dispatch(viewActions.setSelectedFilters(f));
    } else {
      dispatch(listActions.setSelectedFilters(f));
    }
  };
  
  useEffect(() => {
    if (selectedFilters.length !== currentView?.filters?.length) {
      setShowApplyButton(true)
    } else {
      const compare = (a, b) => {
        return a.field - b.field;
      };
      if (
        JSON.stringify(selectedFilters.sort(compare)) !==
        JSON.stringify(currentView.filters.sort(compare))
      ) {
        setShowApplyButton(true)
      }else{
        setShowApplyButton(false)
      }
    }
  },[selectedFilters?.length, currentView.filters?.length, selectedFilters, currentView.filters]);
  
  const handleApply = async () => {
    const isInvalid = (element) => element.isValid === false;
    const notValid = selectedFilters.some(isInvalid);
    if (!notValid) {
      await dispatch(listActions.getItems(props.objectType));
      setShowApplyButton(false)
      setShowSaveButton(true)
      history.replace({
        search: `?f=${JSON.stringify(selectedFilters)}`,
        state: `?f=${JSON.stringify(selectedFilters)}`,
      });
    } else {
      alert('wrong');
    }
  };

  const saveFilters = async () => {
    try {
      let view = {
        ...currentView,
      };
      if (currentView.default) {
        toggle();
        view.name = name;
        view.id = '';
        view.filters = selectedFilters;
        view.default = 0;
        view.isMine = true;
        await dispatch(viewActions.addView(props.objectType, view));
      } else {
        view.filters = selectedFilters;
        await dispatch(viewActions.updateView(props.objectType, view));
      }
      // const views = await dispatch(listActions.getViews(props.objectType));
      // const currentVieww = views.filter((v) => {
      //   return v.id === view.id;
      // });
      // await dispatch(listActions.setSelectedView(currentVieww.id));
      setShowSaveButton(false)
      if (currentView[0].id) {
        history.replace({ search: `?v=${currentView[0].id}`, state: `?v=${currentView[0].id}` });
      }
    } catch (error) {
      console.log('error saving filters', error);
    }
  };

  const clearFilters = () => {
    dispatch(listActions.clearFilters());
    setShowSaveButton(false)
    setShowApplyButton(false)
  };
  
  const handleValueChange = (inputValue) => {
    setName(inputValue);
  };

  return (
    <>
      <div className="list-filters-section">
        <div className="filters-wrapper">
          {allFilters.map((filter, index) => {
            let selectedFilterSettings = selectedFilters.find((f) => f.field === filter.field);
            return (
              <ListFilter
                key={index}
                filter={filter}
                mode={props.mode}
                selectedFilterSettings={selectedFilterSettings}
                onChange={(value, option) => handleFilterChange(filter, value, option)}
              />
            );
          })}
        </div>
      </div>
      {props.mode !== 'modify' ? (
        <div className="item-pull-down m-5" style={{ margin: '5px' }}>
          {showSaveButton && (
            <FSButton
              className="primary mr-10"
              text="Save"
              style={{ marginRight: '10px' }}
              onClick={saveFilters}
            />
          )}

          {showApplyButton && <FSButton className="primary" text="Apply" onClick={handleApply} />}

          {(showApplyButton || showSaveButton) && (
            <FSButton
              text="Clear"
              className="ml-10"
              style={{ marginLeft: '10px' }}
              onClick={clearFilters}
            />
          )}
        </div>
      ) : (
        ''
      )}
      <div>
        <Modal isOpen={modal} toggle={toggle} className="modal-content">
          <ModalHeader toggle={toggle}>Choose a name</ModalHeader>
          <ModalBody>
            <FSInput value={name} onChange={handleValueChange} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={saveFilters}>
              Save
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
 
ListFiltersSection.propTypes = {
  searchPattern: PropTypes.string,
}
export default ListFiltersSection;
