/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */
import {
  SET_FILTERS,
  SET_COLUMNS,
  SET_SELECTED_COLUMNS,
  SET_SELECTED_FILTERS,
  SET_ITEMS,
  SET_ITEMS_PER_PAGE,
  SET_ORDER,
  SET_ORDER_DIRECTION,
  SET_PAGE,
  ADD_COLUMN,
  SET_SELECTED_IDS,
  SET_TAGS,
} from '../actions/list';

const initialState = {
  items: [],
  total: 0,
  allFilters: [],
  allColumns: [],
  selectedFilters: [],
  selectedColumns: [],
  tags: [],
  selectedIds: [],
  itemsPerPage: 20,
  page: 1,
  order: null,
  orderDirection: 'asc',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        allFilters: action.filters,
      };
    }
    case SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: action.selectedFilters,
      };
    }
    case SET_SELECTED_COLUMNS: {
      return {
        ...state,
        selectedColumns: action.selectedColumns,
      };
    }
    case SET_SELECTED_IDS: {
      return {
        ...state,
        selectedIds: action.selectedIds,
      };
    }
    case SET_COLUMNS: {
      return {
        ...state,
        allColumns: action.allColumns,
      };
    }
    case SET_TAGS: {
      return {
        ...state,
        tags: action.tags,
      };
    }
    case SET_ITEMS: {
      return {
        ...state,
        items: action.items,
        total: action.total,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case SET_ITEMS_PER_PAGE: {
      return {
        ...state,
        itemsPerPage: action.itemsPerPage,
      };
    }
    case SET_ORDER_DIRECTION: {
      return {
        ...state,
        orderDirection: action.orderDirection,
      };
    }
    case SET_ORDER: {
      return {
        ...state,
        order: action.order,
      };
    }
    case ADD_COLUMN:
      return {
        ...state,
        selectedColumns: [...state.selectedColumns, action.column],
      };
    default:
      return state;
  }
};
