import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import FSInput from '../../components/elements/FSInput';
import FSChecklist from '../../components/elements/FSChecklist.js';
import FSSelect from '../../components/elements/FSSelect';
import FSRadioGroup from '../../components/elements/FSRadioGroup.js';
import FSSortList from '../../components/elements/FSSortList.js';
import FSButton from '../../components/elements/FSButton';
import ListFiltersSection from '../../components/list/ListFiltersSection';
import PagePreloader from '../../components/Base/PagePreloader';
import Text from '../../components/ui/Text';

import * as listActions from '../../store/actions/list';
import * as viewActions from '../../store/actions/view';

import './ViewPage.css';
import TechnicalError from '../TechnicalError/TechnicalError';
const ViewPage = (props) => {
  // const user = useSelector((state) => state.auth.user);
  const { objectType } = props;
  const allColumns = useSelector((state) => state.list.allColumns);
  const selectedColumns = useSelector((state) => state.list.selectedColumns);
  const name = useSelector((state) => state.view.name);
  const sortBy = useSelector((state) => state.view.order);
  const orderBy = useSelector((state) => state.view.orderDirection);
  const columns = useSelector((state) => state.view.selectedColumns);
  const filters = useSelector((state) => state.view.selectedFilters);
  const isDefault = useSelector((state) => state.view.isDefault);
  const navLayout = useSelector((state) => state.auth.navigation);
  const [/* resultMessage */, setResultMessage] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [/* error */, setError] = useState(null);
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.auth.permissions);

  const history = useHistory();
  if (!permissions.includes('ViewPage')) {
      history.push('/access-denied')
  }

  useEffect(() => {
    const initData = async () => {
      // await dispatch(listActions.getViews(objectType));
      if (props.match.params.id) {
        try {
          await dispatch(viewActions.setEditView(`${props.match.params.id}`));
        } catch (error) {
          setDidPromisesSucceed(false);
        }
      }

      try {
        await dispatch(listActions.getFilters(objectType));
        await dispatch(listActions.getColumns(objectType));
      } catch (error) {
        setDidPromisesSucceed(false);
      }
      
      setIsLoaded(true);
    };

    initData();
  }, []);

  const columnItems = allColumns.map((column) => {
    return { id: column.name, text: column.name };
  });
  const checkedItems = columns.map((column) => {
    return column.name;
  });
  const sortableColumns = allColumns.map((column) => {
    return { value: column.field, text: column.name };
  });

  const header = props.objectType === 'reporting' ? 'Choose Charts' : 'Choose Columns';
  const viewSaveButton = name.length > 0 && columns.length > 0;
  const handleSelectedColumn = (field, value) => {
    if (typeof value === 'object' && typeof value !== 'undefined') {
      value = value.target.value;
    }

    if (field === 'sort_by') dispatch(viewActions.setOrder(value));
    if (field === 'order_by') dispatch(viewActions.setOrderDirections(value));
  };
  const handleNameChange = (name) => {
    dispatch(viewActions.setName(name));
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newColumns = arrayMove(columns, oldIndex, newIndex).map((col, index) => {
      col.order = index + 1;
      return col;
    });
    dispatch(viewActions.setSelectedColumns(newColumns));
  };
  const selectedColumnChange = (selected) => {
    const currentlySelected = columns.map((col) => {
      return col.name;
    });

    const add = selected.length > columns.length;

    let diff;

    if (add) {
      diff = selected.filter((col) => {
        return currentlySelected.indexOf(col) === -1;
      });
    } else {
      diff = currentlySelected.filter((col) => {
        return selected.indexOf(col) === -1;
      });
    }

    if (add) {
      const column = allColumns.filter((col) => {
        return col.name === diff[0];
      });

      let newColumn = {
        name: column[0].name,
        field: column[0].field,
        type: column[0].type,
        order: selectedColumns.length + 1,
        sortable: column[0].sortable,
      };
      dispatch(viewActions.setSelectedColumns(columns.concat(newColumn)));
    } else {
      const newColumns = columns
        .filter((col) => {
          return col.name !== diff[0];
        })
        .map((c, i) => {
          c.order = i + 1;
          return c;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
      dispatch(viewActions.setSelectedColumns(newColumns));
    }
  };
  const handleFilterChanged = (field, filter) => {
    if (filter.isValid) {
      let newFilter = {
        field: field.field,
        option: filter.option,
        value: filter.value,
      };
      dispatch(viewActions.setSelectedFilters(newFilter));
    } else {
      dispatch(viewActions.setFilters(filters));
    }
  };
  const clearMessages = () => {
    setResultMessage(null);
    setError(null);
  };
  const cancelClick = () => {
    window.location.href = `/${props.objectType}s`;
  };
  const saveClick = async () => {
    clearMessages();
    const view = {
      id: props.match.params.id || null,
      name: name,
      filters: filters,
      columns: columns,
      sortBy: sortBy,
      orderBy: orderBy,
    };

    try {
      dispatch(viewActions.updateView(props.objectType, view));
      setResultMessage('Saved');

      // window.location = `/${props.objectType}s?v=${result.data.id}`;
    } catch (e) {
      if (e.error) {
        setError(e.error);
      }
    }
  };
  const deleteClick = async () => {
    try {
      dispatch(viewActions.setSelectedColumns(props.match.params.id));
      window.location = `/${props.objectType}s`;
    } catch (e) {
      if (e.error) setError(e.error);
    }
  };

  if (!isLoaded) {
    return <PagePreloader />;
  }

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  return (
      <div
        className={`body-wrapper ${
         navLayout === 'Vertical' ? 'body-wrapper-two' : ''
        }`}
      >
        <div className="page-container view-page-container edit-update-listing-view">
          <h3>
            {props.viewId ? 'Update' : 'Create'} {props.objectType} view
          </h3>

          <div style={{ width: '250px', padding: '0 20px' }}>
            <FSInput
              name="name"
              label="View name"
              placeholder="View name"
              value={name}
              onChange={handleNameChange}
            />
          </div>

          <div className="customize-view">
            <div className="choose-filters mr-50" style={{ width: '250px' }}>
              <h4><Text>Specify criteria</Text></h4>
              <ListFiltersSection onChange={handleFilterChanged} mode={'modify'} />
            </div>

            <div className="d-flex select-columns">
              <div className="d-flex flex-column">
                <h4>{header}</h4>
                <div className="scrollable">
                  <FSChecklist
                    items={columnItems}
                    checkedItems={checkedItems}
                    onChange={selectedColumnChange}
                  />
                </div>
              </div>

              <div className="d-flex flex-column ml-50">
                <h4><Text>Column Order</Text></h4>
                <div className="scrollable">
                  <FSSortList items={columns} onSortEnd={onSortEnd} />
                </div>
              </div>
              <div className="d-flex flex-column ml-80">
                <h4><Text>Sort by</Text></h4>
                <div className="row">
                  <FSSelect
                    options={sortableColumns}
                    onChange={(v) => {
                      handleSelectedColumn('sort_by', v);
                    }}
                    selected={sortBy}
                  />
                  {sortBy && (
                    <div className="radio">
                      <FSRadioGroup
                        className="form-check-input"
                        options={[
                          { value: 1, text: 'Ascending' },
                          { value: 2, text: 'Descending' },
                        ]}
                        onChange={(v) => {
                          handleSelectedColumn('order_by', v);
                        }}
                        value={orderBy}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="view-actions ">
            {viewSaveButton && (
              <FSButton
                className="primary mr-10"
                onClick={saveClick}
                style={{ marginRight: '10px' }}
              />
            )}

            <FSButton
              className="default"
              text="Cancel"
              onClick={cancelClick}
              style={{ marginRight: '10px' }}
            />

            {!isDefault && (
              <FSButton
                text="Delete"
                className="danger ml-10"
                onClick={deleteClick}
                withPrompt="Are you sure?"
              />
            )}
          </div>
        </div>
      </div>
  );
};

export default withRouter(ViewPage);
