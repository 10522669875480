import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import FSInput from '../../../components/elements/FSInput.js';
import Text from '../../ui/Text';
import PagePreloader from '../../../components/Base/PagePreloader';

import * as templateActions from '../../../store/actions/templates';

const DudaTemplateItem = (props) => {
  const { template } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [name, setName] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setModal(!modal);

  const handleValueChange = (inputValue) => {
    setName(inputValue);
  };

  const openDudaBuilderInNewTab = async (siteName) => {
    const redirectLink = await dispatch(templateActions.getRedirectLink(siteName));
    const newWindow = window.open(redirectLink, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const redirectToSettingsPage = (siteName, site) => {
    history.push({
      pathname: `/apps/websitepro/edit/${siteName}`,
      search: '',
      state: { site: site },
    });
  };

  const saveTemplate = async () => {
    setLoading(true);
    const site = await dispatch(templateActions.createSite(template.externalTemplateId));
    await dispatch(templateActions.createName(site.site_id, name));
    await openDudaBuilderInNewTab(site.name);
    redirectToSettingsPage(site.name, site);
    setLoading(false);
  };

  return (
    <>
      <Card>
        <CardImg
          top
          width="100%"
          src={template.desktopThumbnailUrl}
          alt="Card image cap"
          onClick={toggle}
        />
        <CardBody>
          <CardTitle tag="h5">{template.templateName}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            {template.templateName}
          </CardSubtitle>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle} className="modal-content">
        {loading ? (
          <>
            <ModalHeader toggle={toggle}>
              <Text>creating.new.site</Text>
            </ModalHeader>
            <ModalBody>
              <PagePreloader />
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              <Text>choose.name</Text>
            </ModalHeader>
            <ModalBody className="modal-body">
              <FSInput value={name} onChange={handleValueChange} />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">
                  .new.fantasticstay.com
                </span>
              </div>
            </ModalBody>
            <ModalBody className="modal-body">
              <small>The domain can be changed later</small>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={saveTemplate}>
                <Text>save</Text>
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                <Text>cancel</Text>
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

export default DudaTemplateItem;
