import './SetYourPassword.css';
import { default as fs_logo } from '../../assets/icons/fs-logo.svg';
import { default as hidden_password } from '../../assets/icons/hidden-password.svg';
import { default as shown_password } from '../../assets/icons/shown-password.svg';
import { default as error_try_again } from '../../assets/icons/error-try-again.svg';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../store/actions/auth';
import configJson from "../../auth_config.json";

const SetYourPassword = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordLength, setPasswordLength] = useState(false);
    const [passwordNumber, setPasswordNumber] = useState(false);
    const [passwordUppercaseLetter, setPasswordUppercaseLetter] = useState(false);
    const [passwordLowercaseLetter, setPasswordLowercaseLetter] = useState(false);
    const [passwordSpecialChar, setPasswordSpecialChar] = useState(false);
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const newUser = useSelector((state) => state.auth.user);

    const params = Object.assign({
        configurationBaseUrl: "https://auth.fsapp.io/",
        overrides: {
          __tenant: "fantasticstay",
          __token_issuer: "https://auth.fsapp.io/"
        },
        domain:  configJson.domain,
        clientID: configJson.clientId,
        redirectUri: window.location.origin,
        responseType: "token",
    });

    const webAuth = new window.auth0.WebAuth(params);

    useEffect(() => {   
        if (password.length >= 8) {
            setPasswordLength(true);
        } else {
            setPasswordLength(false);
        }

        if (password.match(/(?=.*?[A-Z])/)) {
            setPasswordUppercaseLetter(true);
        } else {
            setPasswordUppercaseLetter(false);
        }

        if (password.match(/(?=.*?[a-z])/)) {
            setPasswordLowercaseLetter(true);
        } else {
            setPasswordLowercaseLetter(false);
        }

        if (password.match(/(?=.*?[0-9])/)) {
            setPasswordNumber(true);
        } else {
            setPasswordNumber(false);
        }

        if (password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/)) {
            setPasswordSpecialChar(true);
        } else {
            setPasswordSpecialChar(false);
        }
    }, [password]);

    const createNewPassword = async () => {
        if (isRequestPending) return;
        setIsRequestPending(true);
        try {
            if (window.location.href.includes('code')) {
                const codeFirstIndex = window.location.href.indexOf('code') + 5;
                const codeLastIndex = window.location.href.indexOf('&user');
                const code = window.location.href.substring(codeFirstIndex, codeLastIndex);
                const userId = window.location.href.substr(window.location.href.indexOf('user') + 8);
                await dispatch(authActions.createPassword(password, code, userId));
                setIsRequestPending(false);
                webAuth.login({
                    realm: 'Username-Password-Authentication',
                    username: newUser.email,
                    password: password,
                }, function(err) {
                    if (err) console.log(err);
                });
            } else if (newUser.user_id) {
                await dispatch(authActions.setUserPassword(password, newUser.user_id));
                history.push('/');
                setIsRequestPending(false);
            } else {
                await dispatch(authActions.createPassword(password, newUser.code, newUser.id));
                setIsRequestPending(false);
                webAuth.login({
                    realm: 'Username-Password-Authentication',
                    username: newUser.email,
                    password: password,
                }, function(err) {
                    if (err) console.log(err);
                });
            }
        } catch (error) {
            setIsRequestPending(false);
            setDidPromisesSucceed(false);
        }
    };

    return (
        <div className='the-whole-sign-up-page'>
            <div className='left-side-of-sign-up'>
                <div className="d-flex">
                    <img src={fs_logo} alt='' />
                    {!didPromisesSucceed ?
                        <div className="error-try-again-holder ml-auto">
                            <div className="error-try-again-box">
                                <img className="error-try-again-icon" src={error_try_again} alt='' />
                            </div>
                            <div className="error-try-again-text-box">
                                <div className="ml-3">Error occured! Please try again.</div>
                                <div className="close-error-try-again ml-auto mr-3" onClick={() => setDidPromisesSucceed(true)}>&times;</div>
                            </div>
                        </div>
                    :
                        ''
                    }
                </div>
                <div className='sign-up-mid-section'>
                    <div className='set-your-password-header-text'>Set your password</div>
                    <div className='sign-up-password-header'>Password</div>
                    <div className='password-input-container-sign-up'>
                        <input 
                            type={passwordVisible ? 'text' : 'password'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className='input-sign-up-password'>
                        </input>
                        {passwordVisible ?
                            <img className='hidden-password-icon' src={shown_password} onClick={() => setPasswordVisible(!passwordVisible)} alt='' />
                        :
                            <img className='hidden-password-icon' src={hidden_password} onClick={() => setPasswordVisible(!passwordVisible)} alt='' />
                        }
                    </div>
                    <div className='sign-up-password-details-text'>
                        <p>Your password must contain:</p>
                        <ul>
                            <li style={passwordLength ? {color: '#00C141'} : {}}>a minimum of 8 characters</li>
                            <li style={passwordLowercaseLetter ? {color: '#00C141'} : {}}>a lowercase letter</li>
                            <li style={passwordUppercaseLetter ? {color: '#00C141'} : {}}>an uppercase letter</li>
                            <li style={passwordNumber ? {color: '#00C141'} : {}}>a number</li>
                            <li style={passwordSpecialChar ? {color: '#00C141'} : {}}>a special character</li>
                        </ul>
                    </div>
                    {passwordLength && passwordLowercaseLetter && passwordUppercaseLetter && passwordNumber && passwordSpecialChar ?
                        <div className='set-your-password-next-button' onClick={createNewPassword}>
                            <div className='text-inside-set-your-password-next-button'>Next</div>
                            {isRequestPending ? <i className="fa fa-circle-o-notch fa-spin" style={{marginLeft: '120px', position: 'relative', top: '-10px'}}></i> : ''}
                        </div>
                    :
                        <div className='set-your-password-next-button-disabled'>
                            <div className='text-inside-set-your-password-next-button-disabled'>Next</div>
                        </div>
                    }
                </div>
            </div>
            <div className='sign-up-page-image' style={{backgroundPosition: 'center center'}}></div>
        </div>
    );
};

export default SetYourPassword;
