import { useSelector } from "react-redux";
import { ACCOUNT_STATUSES } from "../../common/constants";
import Text from "../ui/Text";

const FilterDropdown = (props) => {
  const roles = useSelector(state => state.roles).roles;

  const handleCheck = (query) => {
    const index = props.queriesArray.indexOf(query);

    if (index === -1) {
      props.setQueries([...props.queriesArray, query]);
    } else {
      props.setQueries(props.queriesArray.filter(element => element !== query));
    }
  };


  return (
    <div className={props.className}>

      <div className='d-flex filter-dropdown-content'>
        <div className='roles-filter'>
          <label className='pb-3 mb-0 filter-dropdown-labels'><Text>BY ROLE</Text></label>

          {roles.map(role => {
            return (
              <div className='d-flex align-items-center pb-2'>

                <div
                  className={props.queriesArray.indexOf(role.name) > -1
                    ? 'users-table-checked-checkbox'
                    : 'users-table-empty-checkbox'}
                  onClick={() => handleCheck(role.name)} />

                <label className='pl-1 filter-dropdown-option'>{role.name}</label>
              </div>
            )
          })}
        </div>

        <div className='two-fa-and-status-tab'>

          <div className='acc-status-filter'>
            <label className='pb-3 mb-0 filter-dropdown-labels'><Text>BY USER</Text></label>
            {ACCOUNT_STATUSES.map(status => {
              return (
                <div className='d-flex align-items-center pb-2'>
                  <div className={props.queriesArray.indexOf(status) > -1
                    ? 'users-table-checked-checkbox'
                    : 'users-table-empty-checkbox'}
                    onClick={() => handleCheck(status)} />
                  <label className='pl-1 filter-dropdown-option'><Text>{status.charAt(0).toUpperCase() + status.substring(1)}</Text></label>
                </div>
              );
            })}
          </div>

        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;