import { useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
} from 'reactstrap';
import Text from '../../ui/Text';


const EditGuest = (props) => {
  const [inputValue, setInputValue] = useState('');

  const { modal, toggle, className, value, label, save } = props;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div>
      <Modal isOpen={!!modal} toggle={toggle} className={className || ''}>
        <ModalHeader toggle={toggle}><Text>edit.guest.edit_guest</Text></ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>
                <Text>{label}</Text>
              </Label>
              <Input
                type='text'
                name='name'
                value={inputValue}
                onChange={(ev) => setInputValue(ev.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => save(inputValue)}>
          <Text>edit.guest.save</Text>
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
          <Text>edit.guest.cancel</Text>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditGuest;