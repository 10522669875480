import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListFilterText from './ListFilterText';
import ListFilterBool from './ListFilterBool';
import ListFilterMultiSelect from './ListFilterMultiSelect';
import ListFilterNumber from './ListFilterNumber.js';
import ListFilterTime from './ListFilterTime.js';
import ListFilterDate from './ListFilterDate.js';
import FSCheckbox from '../../elements/FSCheckbox.js';
import PropTypes from 'prop-types';

import * as listActions from '../../../store/actions/list';
import * as viewActions from '../../../store/actions/view';

const TYPE_TEXT = 'text';
const TYPE_NUMBER = 'number';
const TYPE_TIME = 'time';
const TYPE_BOOLEAN = 'bool';
const TYPE_SELECT = 'select';
const TYPE_CHART_DATE = 'chart_date';
const TYPE_DATE = 'date';
const DEFAULT_VALUES = {
  number: '0',
  time: '12:00',
  text: '',
};

const ListFilter = (props) => {
  const { filter, selectedFilterSettings, onChange } = props;
  const selectedFilters = useSelector((state) => state.list.selectedFilters ? state.list.selectedFilters:state.view.selectedFilters);
  const isFilterSelected = selectedFilters.find((f) => f.field === filter.field);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const { type } = filter;
    const def = DEFAULT_VALUES.hasOwnProperty(type) ? DEFAULT_VALUES[type] : [];
    setValue(
      isFilterSelected && isFilterSelected.hasOwnProperty('value') ? isFilterSelected.value : def,
    );
  }, [filter, isFilterSelected]);
  
  const toggleFilter = useCallback(
    (value, option) => {
      if(props.mode === 'modify'){
        dispatch(viewActions.toggleFilter(filter.field));
      }else{
        dispatch(listActions.toggleFilter(filter.field));
      }
    },
    [filter.field],
  );

  let filterComponent;

  if (isFilterSelected) {
    if (filter.type === TYPE_TEXT) {
      filterComponent = <ListFilterText filter={filter} selectedFilterSettings={selectedFilterSettings} onChange={onChange} />;
    }
    if (filter.type === TYPE_NUMBER) {
      filterComponent = <ListFilterNumber filter={filter} selectedFilterSettings={selectedFilterSettings} onChange={onChange} />;
    }
    if (filter.type === TYPE_SELECT) {
      filterComponent = <ListFilterMultiSelect filter={filter} selectedFilterSettings={selectedFilterSettings} onChange={onChange} />;
    }
    if (filter.type === TYPE_BOOLEAN) {
      filterComponent = (
        <ListFilterBool filter={filter} value={value} selectedFilterSettings={selectedFilterSettings} onChange={onChange} />
      );
    }
    if (filter.type === TYPE_TIME) {
      filterComponent = <ListFilterTime filter={filter} selectedFilterSettings={selectedFilterSettings} onChange={onChange} />;
    }
    if (filter.type === TYPE_DATE) {
      filterComponent = <ListFilterDate selectedFilterSettings={selectedFilterSettings} filter={filter} onChange={onChange} />;
    }
    if (filter.type === TYPE_CHART_DATE) {
      filterComponent = <ListFilterDate filter={filter} selectedFilterSettings={selectedFilterSettings} onChange={onChange} />;
    }
  }

  return (
    <div className="list-filter">
      <div className="list-filter-label-wrapper">
        <FSCheckbox
          checked={isFilterSelected || false}
          onChange={toggleFilter}
          label={<label>{filter.name}</label>}
        />
      </div>
      {filterComponent}
    </div>
  );
};

ListFilter.propTypes = {
  filter: PropTypes.object,
  onChange:PropTypes.func,
  selectedFilterSettings:PropTypes.object,

}
export default ListFilter;
