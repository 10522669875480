import { useSelector } from 'react-redux';
import BulkEditListings from './BulkEditListings';

const BulkEdit = (props) => {
  const { toggle, objectType, modal } = props;  
  const selectedIds = useSelector((state) => state.list.selectedIds);
 
  return (
    <>
      {objectType === 'listing' ?
      <BulkEditListings toggle={toggle} modal={modal} objectType={objectType} selectedIds={selectedIds}/>
      :
      ''
      }
    </>
  );
};

export default BulkEdit;
