import {Fragment} from 'react';
import classNames from 'classnames';

const FSRating=(props) =>{
    let stars = [];

    const int = parseInt(props.rating);
    const dec = parseInt((props.rating % 1).toFixed(2) * 100);

    for (let i = 1; i <= 5; i++) {
        const fill = i <= int || ((i-1) === int && dec >= 75);
        const half = (i-1) === int && dec >= 25 && dec < 75;

        const classes = classNames({
            'fa-star': !half,
            'fas': fill || half,
            'far': !fill && !half,
            'fa-star-half-alt': half,
        });

        stars.push(<i key={i} className={classes}></i>);
    }

    return (
        <Fragment>
            {stars}
        </Fragment>
    )
}

export default FSRating;