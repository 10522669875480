import {
  SET_SITES,
  SET_PAGE,
  SET_ITEMS_PER_PAGE,
  SET_SELECTED_IDS,
  SET_DEFAULT_STRIPE_ACCOUNTS,
  SET_LISTING_ACCOUNTS,
  SET_DEFAULT_STRIPE_ACCOUNT_ID,
  SET_PAYMENT_SCHEDULES,
} from '../actions/sites';

const initialState = {
  sites: [],
  itemsPerPage: 20,
  page: 1,
  total: 0,
  default_accounts: [],
  selectedIds: [],
  listing_accounts: [],
  default_account_id: null,
  site_payment_schedules: [],
};

const sitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SITES:
      return {
        ...state,
        sites: action.sites,
        total: action.total,
      };
    case SET_DEFAULT_STRIPE_ACCOUNTS:
      return {
        ...state,
        default_accounts: action.default_accounts,
      };
    case SET_DEFAULT_STRIPE_ACCOUNT_ID:
      return {
        ...state,
        default_account_id: action.default_account_id,
      };
    case SET_LISTING_ACCOUNTS:
      return {
        ...state,
        listing_accounts: action.listing_accounts,
      };
      case SET_PAYMENT_SCHEDULES:
      return {
        ...state,
        site_payment_schedules: action.site_payment_schedules,
      };
    case SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: action.selectedIds,
      };
    case SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case SET_ITEMS_PER_PAGE: {
      return {
        ...state,
        itemsPerPage: action.itemsPerPage,
      };
    }
    default:
      return state;
  }
};

export default sitesReducer;
