import Request from '../../utils/Request';
import Env from '../../Env';

export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE';
export const SET_PAGE = 'SET_PAGE';

export const getTemplates = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const page = getState().template.page;
    const itemsPerPage = getState().template.itemsPerPage;
    const result = await Request.get(`${Env.apiDomainDuda}/templates/425`, token);
    // 422,425
    // const result = await Request.get(`${Env.apiDomainDuda}/templates/all-templates-pagination?page=${page}&pageSize=1000`, token);
    console.log(result);
    dispatch({ type: SET_TEMPLATES, templates: [result], total: result?.templateCount || 1 });
  };
};
export const setPageNumber = (page) => {
  return async (dispatch, getState) => {
    await dispatch({ type: SET_PAGE, page: page });
  };
};
export const setItemsPerPage = (number) => {
  return async (dispatch, getState) => {
    await dispatch({ type: SET_ITEMS_PER_PAGE, itemsPerPage: number });
  };
};
export const createTemplate = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.post(
      `${Env.apiDomainDuda}/templates/create-from-template`,
      data,
      token,
    );
    console.log(result);
    return result;
  };
};
export const createSite = (templateId) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const customerId = getState().auth.customer.id;
    const result = await Request.post(
      `${Env.apiDomainDuda}/sites`,
      { template_id: templateId, customer_id:customerId },
      token,
    );
    return result;
  };
};
export const createName = (siteId, siteName) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const data = {
      siteDomain: `${siteName}.new.fantasticstay.com`,
      siteDefaultDomain: siteName,
      externalUid: 'string',
      language: 'en',
      favIcon: 'string',
      siteSEO: {
        ogImage: 'string',
        title: 'string',
        description: 'string',
      },
      schemas: {
        localBusiness: {
          enabled: true,
          status: 'string',
          missingRequiredFields: ['string'],
          missingRecommendedFields: ['string'],
        },
      },
      siteAlternateDomains: {
        domains: ['strissssss.com'],
        isRedirect: true,
      },
      forceHttps: true,
      cookieNotification: {
        enabled: true,
      },
    };
    const result = await Request.put(`${Env.apiDomainDuda}/sites/${siteId}`, data, token);
    console.log(result);
    return result;
  };
};

export const getRedirectLink = (siteName) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const customerId = getState().auth.customer.id;
    const result = await Request.get(
      `${Env.apiDomainDuda}/accounts/${customerId}/get-sso-link?siteName=${siteName}&target=EDITOR`,
      token,
    );
    return result;
  };
};
