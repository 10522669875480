import { useSelector } from "react-redux";

const RolesDropdown = (props) => {
  const roles = useSelector(state => state.roles).roles;

  return (
    <div className={props.wrapperClassName}>
      {!!props.permissionsModified &&
        <div className='modified-role-label'>
          <div className='modified-role-label-text'>modified</div>
        </div>
      }
      <select
        className={props.selectClassName}
        onChange={(event) => {
          props.passDropdownRoleData(parseInt(event.target.value));
        }}
        value={props.selectedRole.id}
      >
        {/** on the next line - selected was changed to defaultValue because of a warning.. seems to work fine, but revert if it creates an issue */}
        <option defaultValue disabled hidden>{props.selectedRole.name || 'Select user role'}</option>
        {roles.map(role => (
          <option key={role.id} value={role.id} style={props.optionSize}>{role.name}</option>
        ))}
      </select>
    </div>
  );
};

export default RolesDropdown;