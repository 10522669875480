import { SET_ACTIVE_LISTINGS,SET_LISTINGS, SET_RENTALSUNITED_PROPERTIES } from '../actions/listing';

const initialState = {
    active: [],
    loaded: false,
    listings: [],
    rentalsUnitedProperties: []
};

const listingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_LISTINGS:
            return {
                ...state,
                active: action.listings,
                loaded: true
            };  
        case SET_LISTINGS:
            return {
                ...state,
                listings: action.listings,
            };
        case SET_RENTALSUNITED_PROPERTIES:
            return {
                ...state,
                rentalsUnitedProperties: action.listings,
            }
        default:
            return state;
    }
};

export default listingReducer;
