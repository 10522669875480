import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './components/Base/Loading';
import * as authActions from './store/actions/auth';
import * as translationActions from './store/actions/translation';
import * as listingActions from './store/actions/listing';
// import ListPage from './views/Reservations';
// fontawesome
import initFontAwesome from './common/initFontAwesome';
import { deleteCookie, getCookie } from './utils/common';
import history from './common/history';
import Routes, { GuestUserRoutes } from './Routes';
import TechnicalError from './pages/TechnicalError/TechnicalError';
import DeactivatedAccount from './pages/DeactivatedAccount/DeactivatedAccount';

//import NewReservationPage from "./views/reservations/New";
initFontAwesome();
//FeatherIcon();

//const getAppPath = location =>
//    [location.pathname, location.search, location.hash].join("");

const App = () => {
  const {
    isLoading,
    error,
    getAccessTokenSilently,
    isAuthenticated,
    getIdTokenClaims,
  } = useAuth0();
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [areAnalyticsDone, setAreAnalyticsDone] = useState(false);
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
  //  const config = useFetch("/api/config");
  //  const navEl = useRef(null);
  //  const innerHeight = useInnerHeight();
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.auth.customer);
  const currentUser = useSelector((state) => state.auth.user);
  const listingsLoaded = useSelector((state) => state.listing.loaded);
  const allListings = useSelector(state => state.listing.active);
  const gcsListings = allListings.filter(listing => listing.gcs === true);

  useEffect(() => {
    const setUserData = async () => {
      // const token = await getAccessTokenSilently();
      try {
        const token_id = await getIdTokenClaims();
        await dispatch(authActions.setCurrentUser(token_id.__raw));
      } catch (error) {
        setDidPromisesSucceed(false);
      }
    };

    if (isAuthenticated) {
      setUserData();
    }
  }, [dispatch, getAccessTokenSilently, isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    const setAppData = async () => {
      try {
        const currentWorkspaceName = window.location.hostname.split('.')[0].toLowerCase();
        const userWorkspaceName = currentUser.workspace_name.toLowerCase();
        if (currentWorkspaceName !== userWorkspaceName) {
          window.location.href = window.location.href.replace(`/${currentWorkspaceName}.`, `/${userWorkspaceName}.`);
          return;
        } else {
          await dispatch(authActions.setCustomer());
          await dispatch(listingActions.setActiveListings());
        }
      } catch (error) {
        console.log(error);
        setDidPromisesSucceed(false);
      }
    }

    if (currentUser.hasOwnProperty('workspace_name') && !!currentUser.workspace_name) {
      setAppData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      if (!currentUser.has_password && history.location.pathname !== '/set-your-password') {
        history.push('/set-your-password');
      }
      if (currentUser.has_password && !currentUser.workspace_name && history.location.pathname !== '/name-your-workspace') {
        history.push('/name-your-workspace');
      }
      if (currentUser.has_password && currentUser.workspace_name && !currentUser.phone_number && history.location.pathname !== '/thanks-for-signing-up') {
        history.push('/thanks-for-signing-up');
      }
    } 
  }, [currentUser])

  useEffect(() => {
    if (isAuthenticated && Object.keys(currentUser).length > 0 && workspace.hasOwnProperty('id') && listingsLoaded) {
      setIsUserLoaded(true);
    } else if (isAuthenticated && Object.keys(currentUser).length > 0  && !currentUser.workspace_name) {
      setIsUserLoaded(true);
    }
  }, [isAuthenticated, currentUser, workspace, listingsLoaded]);

  useEffect(() => {
    if (isUserLoaded && currentUser.workspace_name) {
      window.analytics.identify(currentUser.user_id, {
        email: currentUser.email,
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        company: workspace.workspace_name,
        role: workspace.roles[0].role_name,
        role_id: currentUser.role_id
      });

      window.analytics.group(workspace.workspace_id, {
        name: workspace.workspace_name,
        avatar: workspace.avatar,
        createdAt: workspace.created_at,
        employees: workspace.employees,
        plan: workspace.tier,
        status: 'active',
        listings: allListings.length,
        gcs_listings: gcsListings.length,
        support_tier: workspace.support_tier,
        business_model: workspace.business_model
      });

      setAreAnalyticsDone(true);
    }
  }, [isUserLoaded]);

  useEffect(() => {
    const setTranslations = async () => {
      dispatch(translationActions.setTranslations(isAuthenticated ? currentUser.locale : null));
    };

    setTranslations();
  }, [dispatch]);

  useEffect(() => {
    if (isUserLoaded && currentUser.status === 'invited') {
      const changeStatus = async () => {
        await dispatch(authActions.updateAccountStatusOnLogin(currentUser.user_id, 'active'));
      }

      changeStatus();
    }
  }, [isUserLoaded, dispatch]);


  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  if (isUserLoaded && currentUser.status === 'deactivated') {
    return <DeactivatedAccount />
  }

  const redirectPath = getCookie('redirect_path');

  if (!isAuthenticated) {
    return <GuestUserRoutes />;
  } else if (!!redirectPath) {
    deleteCookie('redirect_path');
    history.push(redirectPath);
  }

  if (!isUserLoaded) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <Routes areAnalyticsDone={areAnalyticsDone} />
    </Router>
  );
};

/*  <Route path="/reservations/add" component={NewReservationPage} />
              <Route path="/reservations" component={() => <ListPage
                  objectType="reservation"
                  viewId=""
                  filters=""
                  perPage="100"
                  page=""
                  logo=""
              />} />*/

/*const useFetch = async (...fetchArgs) => {
    const [value, setState] = useState(null);
    useEffect(async () => {
        setState(await fetch(...fetchArgs).then(r => r.json()));
    }, fetchArgs);
    return value;
};

function useInnerHeight() {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    useEffect(() => {
        const onResize = () => setInnerHeight(window.innerHeight);
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);
    return innerHeight;
}*/

export default App;
