import { useEffect, useState } from 'react';
// import {useSelector} from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
// import '../../css/flatpickr.css';

const style = {
  width: '100%',
};

const FSDatePicker = (props) => {
  const [value, setValue] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [modifiedOptions, setModifiedOptions] = useState({});
  // const dateFormat = useSelector((state) => state.auth.customer.dateFormat);
  const initOptions = {
    dateFormat: 'Y-m-d',
    mode: props.isRange?'range':'single',
    enableTime: props.time || false,
    time_24hr: true,
    disableMobile: true,
  };

  let options = {};

  if (props.options) {
    options = { ...initOptions, ...props.options };
  } else {
    options = initOptions;
  }

  if (typeof props.minDate !== 'undefined') {
    options.minDate = props.minDate;
  }

  if (typeof props.maxDate !== 'undefined') {
    options.maxDate = props.maxDate;
  }
  // const parseDateFormat = (dateFormat) => {
  //   const useTime = typeof props.time !== 'undefined' && props.time;

  //   let format =
  //     dateFormat.replace('YYYY', 'Y').replace('MM', 'm').replace('DD', 'd') +
  //     (useTime ? ' H:i' : '');
  //   if (options.noCalendar) {
  //     format = 'H:i';
  //   }
  //   return format;
  // };
  const parseDefaultValue = () => {
    if (!props.value) {
      return;
    }

    if (typeof props.value === 'string') {
      if (props.value.indexOf('-') === -1) {
        return;
      }

      const MomentValue = moment(props.value).format(
        props.dateFormat + (props.time ? ' HH:mm:ss' : ''),
      );

      setValue(MomentValue);
      return;
    }

    const MomentValue = props.value.map((val) => {
      return moment(val).format(props.dateFormat);
    });

    setValue(!props.isRange ? MomentValue[0] : MomentValue);
  };

  useEffect(() => {
    setModifiedOptions(options);
    parseDefaultValue();
  }, [props.isRange]);

  const handleChange = (v) => {
    const useTime = typeof props.time !== 'undefined' && props.time;
    const unavailableDates = props.unavailableDates;

    if (modifiedOptions.mode === 'range' && v.length < 2) {
      return;
    }

    const value = v.map((val) => {
      return moment(val).format('YYYY-MM-DD' + (useTime ? ' HH:mm:ss' : ''));
    });

    const isSingle = modifiedOptions.mode === 'single' && value.length;

    if (!isSingle && unavailableDates) {
      const result = unavailableDates.filter((date) => {
        return value.indexOf(date) > -1;
      });

      setShowMsg(result.length > 1);
    }

    props.onChange(isSingle ? value[0] : value);
  };

  return (
    <div style={style} className={props.className}>
      <Flatpickr
        value={value}
        onChange={(v) => handleChange(v)}
        options={modifiedOptions}
        mode={initOptions.mode}
      />
      {showMsg && <span style={{ color: 'red' }}>Dates are not available</span>}
    </div>
  );
};

export default FSDatePicker;
