import Text from "../../../../components/ui/Text";

const PropertiesContainer = (props) => {

  return (
    <div className={props.className}>
      <label className='p-1' style={{textDecoration: 'uppercase'}}><Text>secondary.assigned_properties</Text></label>
      { 
        props.properties.map((el, index) => {
          return (
            <div key={index}>
              <div >
                <p className='mb-2 ml-1'>{el.name}</p>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default PropertiesContainer;