import { combineReducers } from 'redux';
import authReducer from './auth';
import translationReducer from './translation';
import listingReducer from './listing';
import multiCalendarReducer from './multiCalendarReducer';
import listReducer from './list';
import viewReducer from './views';
import templatesReducer from './templates';
import sitesReducer from './sites';
import usersReducer from './users';
import rolesReducer from './roles';

const rootReducer = combineReducers({
    auth: authReducer,
    translation: translationReducer,
    listing: listingReducer,
    multicalendar: multiCalendarReducer,
    list: listReducer,
    view: viewReducer,
    template: templatesReducer,
    site: sitesReducer,
    users: usersReducer,
    roles: rolesReducer,
});

export default rootReducer;
