import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PageTitle from '../../components/ui/PageTitle';
const jwt = require('jsonwebtoken');

var PrivateKey = '8e71a213-065a-454d-a4de-db48df6500ea';

function createProductboardToken(user) {
    var userData = {
        email: user.email,
        id: user.id,
        name: user.name,
        company_name: user.company_name,
        company_domain: user.company_domain,
    };
    return jwt.sign(userData, PrivateKey, { algorithm: 'HS256' });
}

export const Updates = () => {
    const { user } = useAuth0();

    const permissions = useSelector((state) => state.auth.permissions);

    const history = useHistory();
    if (!permissions.includes('Updates')) {
        history.push('/access-denied')
    }

    return (
        <div>
            <PageTitle>Product Updates</PageTitle>
            <iframe
                title='updates'
                src={`https://portal.productboard.com/93etwut4zwgz8gmju9awe9jp?token=${createProductboardToken(
                    user
                )}`}
                frameborder='0'
                style={{ width: '100%', minHeight: '800px', marginTop: '55px' }}
            />
        </div>
    );
};

export default Updates;
