import './Profile.css';
import './PhoneDropdown.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../components/ui/Text';
import SideMenuInterface from '../SideMenuInterface/SideMenuInterface';
import { NAME_REGEX } from '../../../common/constants';
import PhoneInput from 'react-phone-input-2'
import * as authActions from '../../../store/actions/auth';
import Loading from '../../../components/Base/Loading';
import TechnicalError from '../../TechnicalError/TechnicalError';
import SubmitBtn from '../../../components/elements/SubmitBtn';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const authUserState = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [didPromiseSucceed, setDidPromiseSucceed] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const [userData, setUserData] = useState([]);
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isAllDataValid, setIsAllDataValid] = useState(null);

  useEffect(() => {
    setUserData({ ...authUserState });
  }, [authUserState]);

  const handleUpdateSubmit = async () => {

    if (requestInProgress) return;

    setRequestInProgress(true);
    const data = {
      avatar: avatar,
      phone_number: userData?.phone_number,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
    };

    try {
      await dispatch(authActions.updateUser(authUserState.user_id, data));
    } catch (error) {
      return setDidSubmitSucceed(false);
    }

    setRequestInProgress(false);
    setDidSubmitSucceed(true);
  };



  const handleFirstNameChange = (event) => {
    setIsFirstNameValid(NAME_REGEX.test(event.target.value));
    setUserData({ ...userData, first_name: event.target.value });
    setDidSubmitSucceed(null);
  };

  const handleLastNameChange = (event) => {
    setIsLastNameValid(NAME_REGEX.test(event.target.value));
    setUserData({ ...userData, last_name: event.target.value });
    setDidSubmitSucceed(null);
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
    setUserData({ ...userData, phone_number: phone });
    if (phone.length < 1) {
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }
  };

  useEffect(() => {
    if (isEmailValid === true
      && isFirstNameValid === true
      && isLastNameValid === true
      && isPhoneValid === true) {
      setIsAllDataValid(true);
    } else {
      setIsAllDataValid(false);
    }

  }, [isEmailValid, isFirstNameValid, isLastNameValid, isPhoneValid]);

  if (isLoading) {
    return <Loading />;
  }

  if (didPromiseSucceed === false) {
    return <TechnicalError />;
  }

  return (
    <div id="user-profile-page">
      <SideMenuInterface selectedInterface='profile' />
      <div className='profile-tab-content'>

        <div className='pb-2 user-profile-heading'>
          <h4 className='mb-0'>
            <Text>main.profile</Text>
          </h4>
        </div>

        <div className='d-flex align-items-center pt-4'>
          <div className='avatar-container'>
            <div className='user-settings-avatar'>
              {user?.picture ?
                <img src={user?.picture} className='user-settings-picture' alt='' />
                : <label className='user-avatar-initials'>{authUserState?.first_name?.slice(0, 1)}{authUserState?.last_name?.slice(0, 1)}</label>
              }
            </div>
            {/* avatar uploading feature -> <div className='user-settings-upload-avatar'>
              <i className='fas fa-arrow-up'></i>
            </div> */}
          </div>

          <div className='ml-4 user-settings-names d-flex flex-column'>
            <label className='mb-0'><b>{authUserState?.first_name} {authUserState?.last_name}</b></label>
            <label>{authUserState?.email}</label>
          </div>
        </div>

        <div className='user-settings-inputs-container'>
          <div className='user-settings-names-container'>
            <div className='user-settings-first-name'>
              <label>
                <Text>edit.first_name</Text>
              </label>
              <input
                type='text'
                className={`form-control ${isFirstNameValid ? '' : 'invalid-input-style'}`}
                placeholder='First Name'
                value={userData?.first_name || ''}
                onChange={handleFirstNameChange}
              ></input>
              {isFirstNameValid ? '' : <p className='mb-0 invalid-input-label'><Text>error.invalid_name</Text></p>}
            </div>

            <div className='user-settings-last-name'>
              <label>
                <Text>edit.last_name</Text>
              </label>
              <input
                type='text'
                className={`form-control ${isLastNameValid ? '' : 'invalid-input-style'}`}
                placeholder='Last Name'
                value={userData?.last_name || ''}
                onChange={handleLastNameChange}
              ></input>
              {isLastNameValid ? '' : <p className='mb-0 invalid-input-label'><Text>error.invalid_name</Text></p>}
            </div>
          </div>

          <div className='user-settings-phone'>
            <label>
              <Text>reservation.phone</Text>
            </label>
            <PhoneInput
              country={'us'}
              value={userData?.phone_number || ''}
              inputClass={isPhoneValid === true ? 'user-profile-phone-input' : 'invalid-input-style'}
              onChange={phone => {
                handlePhoneChange(phone);
                setDidSubmitSucceed(null);
              }}
            />
            {isPhoneValid === false ? <p className='mb-0 invalid-phone-label'><Text>error.invalid_phone</Text></p> : ''}
          </div>
        </div>

        <SubmitBtn
          submittingState={requestInProgress}
          didSubmitSucceed={didSubmitSucceed}
          submitFunction={handleUpdateSubmit}
          disabled={!isAllDataValid}
          submittingClassName='user-profile-saving-btn'
          submitClassName='user-profile-save-btn'
          disabledBtnClassName='user-profile-save-btn-disabled'
          failedSubmitClassName='user-profile-save-btn-failed-submit'
          submitText='action.save'
          submittingText='action.saving'
          promiseSucceedText='action.saved'
          promiseFailText='action.not_saved'
        />
      </div>
    </div >
  );
};

export default Profile;