import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import './SitesTableItem.css'

import { SITE_NO_PREVIEWURL } from '../../../common/constants';
import Loading from '../../../components/Base/Loading';
import Text from '../../ui/Text';

import * as templateActions from '../../../store/actions/templates';

const SitesTableItem = (props) => {
    const { item } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const openDudaBuilderInNewTab = async () => {
        setIsLoading(true);
        const redirectLink = await dispatch(
            templateActions.getRedirectLink(item.siteName)
        );
        const newWindow = window.open(redirectLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        setIsLoading(false);
    };

    const handleSettingsClick = (siteName) => {
        history.push({
            pathname: `/apps/websitepro/edit/${siteName}`,
            search: '',
            state: { site: item },
        });
    };
    console.log(item)
    return (
        <div className={props.isMobile ? 'sites-item col-12' : 'sites-item'}>
            <div className='sites-item-img'>
                <img
                    className='sites-img'
                    src={item.thumbnailURL || SITE_NO_PREVIEWURL}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = SITE_NO_PREVIEWURL;
                    }}
                    alt='guest'
                />
                {item.publishStatus === 'NOT_PUBLISHED_YET' && props.isMobile ? (
                    <div className='sites-item-not-published-container'>
                        <div className='sites-item-not-published-text'>
                            <Text>NOT PUBLISHED</Text>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className='sites-item-desc flex-1'>
                <div className='sites-item-domain'>
                    <div
                        className='sites-item-domain-text curs'
                        style={{ cursor: 'pointer' }}
                        onClick={() => openDudaBuilderInNewTab()}
                    >
                        {item.siteDefaultDomain.substring(0, item.siteDefaultDomain.indexOf('.'))}
                    </div>
                    {(item.publishStatus === 'NOT_PUBLISHED_YET' || item.publishStatus === 'UNPUBLISHED') && !props.isMobile ? (
                        <div className='sites-item-not-published-container'>
                            <div className='sites-item-not-published-text'>
                                <Text>NOT PUBLISHED</Text>
                            </div>
                        </div>
                    ) : null}
                </div>
                {item.publishStatus === 'NOT_PUBLISHED_YET' || item.publishStatus === 'UNPUBLISHED' ? (
                    <div className='sites-item-url-not-avaiable'>
                        <Text>url.not.available</Text>
                    </div>
                ) : (
                    <div
                        className='sites-item-defaultDomain'
                        style={{ cursor: 'pointer'}}
                        onClick={() => openInNewTab(`http://${item.siteDefaultDomain}`)}
                    >

                        {item.siteDefaultDomain}{' '}<i className="fas fa-external-link-square-alt"></i>
                    </div>
                )}
                <div className='sites-item-dates'>
                    <Text>created</Text>: {moment(item.creationDate).format('MMM Do, YY')}
                </div>
                {item.publishStatus === 'NOT_PUBLISHED_YET' ? (
                    <div className='sites-item-dates'>
                        <Text>lastpublished</Text>:-
                    </div>
                ) : (
                    <div className='sites-item-dates'>
                        <Text>lastpublished</Text>:{' '}
                        {moment(item.lastPublishedDate).format('MMM Do, YY')}
                    </div>
                )}
            </div>
            <div className='sites-item-actions'>
                <div className='sites-item-actions-button-preview'>
                    <a
                        target='_blank'
                        className='sites-actions-button'
                        onClick={() => openInNewTab(item.previewSiteUrl)}
                    >
                        <FeatherIcon icon='play' />
                        {props.isMobile ? (
                            ''
                        ) : (
                            <label className='site-actions-button-text'>
                                <Text>preview</Text>
                            </label>
                        )}
                    </a>
                </div>
                <div className='sites-item-actions-button-settings'>
                    <a
                        target='_blank'
                        className='sites-actions-button'
                        onClick={() => handleSettingsClick(item.siteName)}
                    >
                        <FeatherIcon icon='settings' />
                        <label className='site-actions-button-text'>
                            <Text>settings</Text>
                        </label>
                    </a>
                </div>
                <div className='sites-item-actions-button-edit'>
                    <a
                        target='_blank'
                        className='sites-actions-button'
                        onClick={() => openDudaBuilderInNewTab()}
                    >
                        <FeatherIcon icon='edit-2' />
                        <label className='site-actions-button-text'>
                            <Text>edit</Text>
                            {isLoading ? (
                                <>
                                    &nbsp;
                                    <Loading />
                                </>
                            ) : null}
                        </label>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SitesTableItem;
