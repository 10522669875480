import { SET_TEMPLATES,SET_PAGE,SET_ITEMS_PER_PAGE } from '../actions/templates';

const initialState = {
  templates: [],
  itemsPerPage: 20,
  page: 1,
  total: 0,
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPLATES: 
      return {
        ...state,
        templates: action.templates,
        total: action.total,
      };
      case SET_PAGE: {
        return {
          ...state,
          page: action.page,
        };
      }
      case SET_ITEMS_PER_PAGE: {
        return {
          ...state,
          itemsPerPage: action.itemsPerPage,
        };
      }
    default:
      return state;
  }
};

export default templatesReducer;