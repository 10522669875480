import moment from 'moment';
import { useState } from 'react';
import { Button } from 'reactstrap';
import '../EditAvailabilityMenu/EditAvailabilityMenu.scss';
import Text from '../../../components/ui/Text';

const BulkEditModal = ({ toggle, modal, checkedListings }) => {
    const [newAvailability, setNewAvailability] = useState({
        date: moment(Date.now()).format('YYYY-MM-DD'),
        end_date: moment(Date.now()).add(1, 'days').format('YYYY-MM-DD'),
        status: 'available',
        days_of_week: [],
    });

    const getDaysOfTheWeek = () => {
        const daysOfTheWeek = Array.from(document.getElementsByName('days_of_week'))
            .filter((d) => d.checked)
            .map((d) => d.value);
        setNewAvailability({ ...newAvailability, days_of_week: daysOfTheWeek });
    };

    const saveChanges = (listings, newInfo) => {
        console.log(listings, newInfo);
        // to send data to backend
    };
    return (
        <div className={modal ? 'side-menu' : 'hidden-side-menu'}>
            <h3>
                <Text>Bulk Edit</Text>({checkedListings.length}) <Text>Listings</Text>{' '}
            </h3>

            <button className='close-button' onClick={toggle}>
                X
            </button>

            <div className='side-menu-content'>
                <label htmlFor='from' style={{ fontSize: '20px' }}>
                    <Text>From</Text>
                </label>
                <input
                    type='date'
                    name='from'
                    style={{ fontSize: '20px' }}
                    value={newAvailability.date}
                    onChange={(e) =>
                        setNewAvailability({ ...newAvailability, date: e.target.value })
                    }
                />
                <br />
                <label htmlFor='to' style={{ fontSize: '20px' }}>
                    <Text>To</Text>
                </label>
                <input
                    type='date'
                    name='to'
                    style={{ fontSize: '20px' }}
                    value={newAvailability.end_date}
                    onChange={(e) =>
                        setNewAvailability({ ...newAvailability, end_date: e.target.value })
                    }
                />
                <br />
                <br />
                <label htmlFor='weekdays'>
                    {' '}
                    <Text>Days of week: </Text>
                </label>
                <br /> <Text>Mon </Text>
                <input
                    type='checkbox'
                    name='days_of_week'
                    value='Mon'
                    onClick={getDaysOfTheWeek}
                />{' '}
                <Text>Tue </Text>
                <input
                    type='checkbox'
                    name='days_of_week'
                    value='Tue'
                    onClick={getDaysOfTheWeek}
                />{' '}
                <Text>Wed </Text>
                <input
                    type='checkbox'
                    name='days_of_week'
                    value='Wed'
                    onClick={getDaysOfTheWeek}
                />{' '}
                <Text>Thu </Text>
                <input
                    type='checkbox'
                    name='days_of_week'
                    value='Thu'
                    onClick={getDaysOfTheWeek}
                />{' '}
                <Text>Fri </Text>
                <input
                    type='checkbox'
                    name='days_of_week'
                    value='Fri'
                    onClick={getDaysOfTheWeek}
                />{' '}
                <Text>Sat </Text>
                <input
                    type='checkbox'
                    name='days_of_week'
                    value='Sat'
                    onClick={getDaysOfTheWeek}
                />{' '}
                <Text>Sun </Text>
                <input type='checkbox' name='days_of_week' value='Sun' onClick={getDaysOfTheWeek} />
                <br />
                <br />
                <span style={{ fontSize: '20px' }}>
                    {newAvailability.status === 'available' ? 'Available' : 'Unavailable'}
                </span>
                <label className='switch'>
                    <input
                        type='checkbox'
                        checked={newAvailability.status === 'available'}
                        onChange={() => {
                            if (newAvailability.status === 'available') {
                                setNewAvailability({
                                    ...newAvailability,
                                    status: 'unavailable',
                                });
                            } else if (newAvailability.status === 'unavailable') {
                                setNewAvailability({ ...newAvailability, status: 'available' });
                            }
                        }}
                    />
                    <span className='slider round'></span>
                </label>
                <br />
                <label htmlFor='price'>
                    <Text>Price</Text>
                </label>
                <input
                    type='number'
                    name='price'
                    id='price'
                    placeholder='$'
                    value={newAvailability.price || ''}
                    onChange={(e) =>
                        setNewAvailability({
                            ...newAvailability,
                            price: Number(e.target.value),
                        })
                    }
                />
                <br />
                <label htmlFor='min-nights'>
                    <Text>Min Nights</Text>
                </label>
                <input
                    type='number'
                    name='min-nights'
                    id='min-nights'
                    placeholder='minimum nights stay'
                    value={newAvailability.min_nights || ''}
                    onChange={(e) =>
                        setNewAvailability({
                            ...newAvailability,
                            min_nights: Number(e.target.value),
                        })
                    }
                />
                <br />
                <label htmlFor='Note'>
                    <Text>Note</Text>
                </label>
                <input
                    type='text'
                    id='note'
                    name='note'
                    placeholder={'Any Notes?'}
                    value={newAvailability.note}
                    onChange={(e) =>
                        setNewAvailability({ ...newAvailability, note: e.target.value })
                    }
                />
                <br />
                <br />
                <Button
                    color='primary'
                    onClick={() => saveChanges(checkedListings, newAvailability) + toggle()}
                >
                    <Text>Save</Text>
                </Button>{' '}
                <Button color='secondary' onClick={toggle}>
                    <Text>Cancel</Text>
                </Button>
            </div>
        </div>
    );
};

export default BulkEditModal;
