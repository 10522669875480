import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DudaEditSiteTableSection from '../../components/apps/duda/DudaEditSiteTableSection';
import Loading from '../../components/Base/Loading';
import * as listingActions from '../../store/actions/listing';
import * as siteActions from '../../store/actions/sites';
import '../../components/apps/duda/edit-sites.scss';
import '../../components/apps/duda/edit-sites-grid.scss';
import TechnicalError from '../TechnicalError/TechnicalError';
import { useHistory } from 'react-router';

const EditDudaSite = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);

  const permissions = useSelector((state) => state.auth.permissions);

  const history = useHistory();
  if (!permissions.includes('EditDudaSite')) {
      history.push('/access-denied')
  }

  useEffect(() => {
    const initData = async () => {
      try {
        await dispatch(listingActions.getAllActiveListings(props.match.params.id));
        await dispatch(siteActions.getAccounts(props.match.params.id));
      } catch (error) {
        setDidPromisesSucceed(false);
      }

      document.title = 'Edit Website';
      setIsLoaded(true);
    };
    initData();
  }, []);

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  return (
    <div
      className={`body-wrapper ${
        user?.user_metadata?.navigation_layout === 'Vertical' ? 'body-wrapper-two' : ''
      }`}
    >
      {!isLoaded ? <Loading /> : <DudaEditSiteTableSection siteName={props.match.params.id} />}
    </div>
  );
};

export default EditDudaSite;
