import './SingleUser.css';
import Text from '../../../components/ui/Text';
import SideMenuInterface from '../SideMenuInterface/SideMenuInterface';
import { default as go_back } from '../../../assets/icons/go-back.svg';
import { default as user_delete } from '../../../assets/icons/user-delete-btn.svg';
import { default as checkbox_property } from '../../../assets/icons/checkbox-property.svg';
import { default as empty_checkbox } from '../../../assets/icons/empty-checkbox.svg';
import { default as limit_info } from '../../../assets/icons/limit-info.svg';
import { default as dropdown_close } from '../../../assets/icons/dropdown-close.svg';
import { default as dropdown_open } from '../../../assets/icons/dropdown-open.svg';
import RolesDropdown from '../../../components/elements/RolesDropdown';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';
import { useEffect } from 'react';
import PagePreloader from '../../../components/Base/PagePreloader';
import * as userActions from '../../../store/actions/users';
import * as rolesActions from '../../../store/actions/roles';
import * as authActions from '../../../store/actions/auth';
import TechnicalError from '../../TechnicalError/TechnicalError';
import RolesInfo from './RolesInfo/RolesInfo';
import { SELECTABLE_PERMISSIONS } from '../../../common/constants';
import SubmitBtn from '../../../components/elements/SubmitBtn';
import { combinePermissions } from '../../../utils/common';
import AccessDenied from '../../AccessDenied/AccessDenied';

const SingleUser = () => {
    const [loaded, setLoaded] = useState(false);
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
    const [rolesAndPermissionsOpen, setRolesAndPermissionsOpen] = useState(false);
    const [propertiesAccessOpen, setPropertiesAccessOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const roles = useSelector(state => state.roles).roles;
    const theUser = useSelector(state => state.users.user);
    const [userRole, setUserRole] = useState(null);
    const [rolePermissions, setRolePermissions] = useState(null);
    const [additionalPermissions, setAdditionalPermissions] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const workspace = useSelector((state) => state.auth.customer);
    const allProperties = useSelector(state => state.listing.active);
    const allPermissions = useSelector(state => state.auth.allPermissions);
    const userPermissions = useSelector(state => state.auth.user.default_permissions);
    const [assignedProperties, setAssignedProperties] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        const getTheUser = async () => {
            try {
                await dispatch(userActions.getUserById(id));
                await dispatch(rolesActions.getRoles());
                await dispatch(authActions.setAllPermissions());
            } catch (error) {
                setDidPromisesSucceed(false);
            }

            setLoaded(true);
        };

        getTheUser();
    }, [dispatch, workspace.id, id]);

    useEffect(() => {
        if (theUser.role_id && roles) {
            setUserRole(roles.filter((r) => r.id === theUser.role_id)[0]);
            setRolePermissions(roles.find(r => r.id === theUser.role_id));
        }
    }, [roles, theUser.role_id]);

    useEffect(() => {
        if (userRole && rolePermissions) {
            if (userRole.id !== theUser.role_id) {
                setRolePermissions(userRole);
                setAdditionalPermissions({});
            } else {
                setRolePermissions(roles.find(r => r.id === theUser.role_id));
                setAdditionalPermissions(theUser.permissions)
            }
        }
    }, [userRole, rolePermissions, theUser.permissions, roles, theUser.role_id])

    useEffect(() => {
        if (theUser && rolePermissions && additionalPermissions) {
            const p = combinePermissions(rolePermissions.permissions, additionalPermissions);
            setPermissions(p);
            setCurrentStatus(theUser.status);
            setAssignedProperties(theUser.properties);
        }
    }, [theUser, rolePermissions, additionalPermissions]);

    const assignAdditionalPerms = (permission_name, access) => {
        const copyAdditionalPermissions = { ...additionalPermissions };
        if (rolePermissions.permissions[permission_name] === additionalPermissions[permission_name]) {
            if (rolePermissions.permissions[permission_name] === undefined && additionalPermissions[permission_name] === undefined) {
                copyAdditionalPermissions[permission_name] = access;
            } else {
                delete copyAdditionalPermissions[permission_name];
            }
        } else {
            if (rolePermissions.permissions[permission_name] === undefined && access === 'none') {
                delete copyAdditionalPermissions[permission_name];
            } else {
                copyAdditionalPermissions[permission_name] = access;
            }
        }

        const p = combinePermissions(rolePermissions.permissions, copyAdditionalPermissions);
        setPermissions(p);

        if (rolePermissions.permissions[permission_name] === copyAdditionalPermissions[permission_name]) {
            delete copyAdditionalPermissions[permission_name];
            setAdditionalPermissions(copyAdditionalPermissions);
        } else {
            setAdditionalPermissions({ ...copyAdditionalPermissions, [permission_name]: access });
        }
    };

    const assignProperty = (property) => {
        setAssignedProperties([...assignedProperties, property.id]);
    };

    const removeProperty = (property) => {
        setAssignedProperties(assignedProperties.filter(el => el !== property.id));
    };

    if (!loaded) {
        return <PagePreloader />;
    }

    if (didPromisesSucceed === false) {
        return <TechnicalError />;
    }

    if (!userPermissions.hasOwnProperty('Manage users')) {
        return <AccessDenied />
    }

    const updateTheUser = async () => {
        setIsRequestPending(true);
        const additionalPermissionsArr = [];

        if (Object.keys(additionalPermissions).length > 0) {
            for (const perm in additionalPermissions) {
                const currPermission = allPermissions.find(el => el.name === perm);

                if (currPermission) {
                    additionalPermissionsArr.push({
                        permission_id: currPermission.id,
                        access: additionalPermissions[perm]
                    });
                };
            };
        };

        const updateData = {
            status: currentStatus,
            role_id: userRole.id,
            properties: assignedProperties,
            additional_permissions: additionalPermissionsArr
        }

        try {
            await dispatch(userActions.updateWorkspaceUser(id, updateData));
            setDidSubmitSucceed(true);
        } catch (e) {
            setDidSubmitSucceed(false);
        }

        setIsRequestPending(false);
    };

    return (
        <div className='users-control-tab'>
            <SideMenuInterface selectedInterface='users' />
            <div className='single-user-content'>
                <div className='users-control-heading'>
                    <div className='go-back-icon-holder'><Link to="/workspace/users-control"><img className='go-back-icon-border' src={go_back} alt='' /></Link></div>
                </div>
                <div className="d-flex align-items-center">
                    <div className='create-mandatory-margin d-flex'>
                        <div className='single-user-avatar mr-2'>
                            <div className='single-user-avatar-label'>{theUser.first_name.slice(0, 1)}{theUser.last_name.slice(0, 1)}</div>
                        </div>
                        <div className='single-user-email-name-field mr-5'>
                            <label className='single-user-names'>{theUser.first_name} {theUser.last_name}</label>
                            <label className='single-user-email'>{theUser.email}</label>
                        </div>
                    </div>
                    <div className='activate-deactivate ml-auto'>
                        <label className='deactivate-the-user'><Text>action.deactivate</Text></label>
                        <label className="switch mt-3 mr-0 ml-3">
                            <input checked={currentStatus === 'active' ? true : false}
                                type="checkbox"
                                onChange={() => currentStatus === 'active' ? setCurrentStatus('deactivated') : setCurrentStatus('active')} />
                            <span className="slider round"></span>
                        </label>
                        <label className='activate-the-user'><Text>action.activate</Text></label>
                    </div>
                    <div className='user-delete-btn ml-auto' onClick={() => setIsOpen(!isOpen)}>
                        <img className='user-delete-btn-icon' src={user_delete} alt='' />
                        <label className='user-delete-btn-text'><Text>action.delete_user</Text></label>
                    </div>
                </div>
                {rolesAndPermissionsOpen ?
                    <>
                        <div className='separating-line'></div>
                        <div className="d-flex">
                            <label className='single-user-role-and-permissions-header'><Text>secondary.user_role_permission</Text></label>
                            <img className="the-dropdown-close ml-auto" src={dropdown_close} onClick={() => setRolesAndPermissionsOpen(!rolesAndPermissionsOpen)} alt='' />
                        </div>
                        <div className='single-user-settings-header'>
                            <div className='single-user-role-select'>
                                <label className='single-user-select-header'><Text>secondary.user_role</Text></label>
                                <div className="d-flex">
                                    <RolesDropdown
                                        wrapperClassName='single-user-roles-wrapper'
                                        selectClassName='single-user-roles-dropdown'
                                        selectedRole={userRole}
                                        permissionsModified={Object.keys(additionalPermissions).length > 0}
                                        passDropdownRoleData={id => setUserRole(roles.find(r => r.id === id))} />
                                    <img className='the-permissions-info ml-3' src={limit_info} alt='' />
                                    <div className='roles-info-container'>
                                        <RolesInfo currentRole={userRole.name} />
                                    </div>
                                </div>
                            </div>
                            <div className='ml-auto'>
                                <label className='single-user-select-header'><Text>secondary.permissions</Text></label>
                                {SELECTABLE_PERMISSIONS.map(cp => (
                                    <div key={cp.key}>
                                        <label className='single-user-permission'><Text>{cp.key}</Text></label>
                                    </div>
                                ))}
                            </div>
                            <div className='mr-4'>
                                <div className='single-user-permission-header-holder'>
                                    <label className='single-user-permission-header'><Text>main.none</Text></label>
                                    <label className='single-user-permission-header'><Text>main.read</Text></label>
                                    <label className='single-user-permission-header'><Text>main.write</Text></label>
                                </div>
                                {SELECTABLE_PERMISSIONS.map((cp, index) => (
                                    <div className='d-flex' key={index}>
                                        <div
                                            className={!permissions.hasOwnProperty(cp.permission_name) ? `outer-active-radio-button` : `outer-inactive-radio-button`}
                                            onClick={() => assignAdditionalPerms(cp.permission_name, 'none')}
                                        >
                                            <div className='inner-active-radio-button'></div>
                                        </div>
                                        <div
                                            className={permissions.hasOwnProperty(cp.permission_name) && permissions[cp.permission_name] === 'read' ? `outer-active-radio-button` : `outer-inactive-radio-button`}
                                            onClick={() => assignAdditionalPerms(cp.permission_name, 'read')}
                                        >
                                            <div className='inner-active-radio-button'></div>
                                        </div>
                                        <div
                                            className={permissions.hasOwnProperty(cp.permission_name) && permissions[cp.permission_name] === 'write' ? `outer-active-radio-button` : `outer-inactive-radio-button`}
                                            onClick={() => assignAdditionalPerms(cp.permission_name, 'write')}
                                        >
                                            <div className='inner-active-radio-button'></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='separating-line-bottom'></div>
                    </>
                    :
                    <div className='border-box-of-the-roles-permissions' onClick={() => setRolesAndPermissionsOpen(!rolesAndPermissionsOpen)}>
                        <label className='single-user-role-and-permissions-header-closed'><Text>secondary.user_role_permission</Text></label>
                        <img className='ml-auto the-dropdown-open' src={dropdown_open} alt='' />
                    </div>
                }
                {propertiesAccessOpen ?
                    <>
                        <div className="d-flex">
                            <label className='single-user-properties-access-header'><Text>secondary.properties_access</Text></label>
                            <img className="the-dropdown-close ml-auto" src={dropdown_close} onClick={() => setPropertiesAccessOpen(!propertiesAccessOpen)} alt='' />
                        </div>
                        <div className='assigning-to-properties-header mt-3'><Text>secondary.assigned_to</Text> {assignedProperties.length} <Text>secondary.properties</Text></div>
                        <div className="assigned-properties-box d-flex align-items-center">
                            {allProperties.map((el, index) => {
                                return (
                                    assignedProperties.length && assignedProperties.includes(el.id) ?
                                        <div key={index} className='assigned-property-holder mt-3 mr-1'>
                                            <img className='mt-1' style={{ cursor: 'pointer' }} src={checkbox_property} onClick={() => removeProperty(el)} alt='' />
                                            <label className='label-of-assigned-property'>{el.name}</label>
                                        </div>
                                        :
                                        <div key={index} className='not-assigned-property-holder mt-3 mr-1'>
                                            <img className='mt-1' style={{ cursor: 'pointer' }} src={empty_checkbox} onClick={() => assignProperty(el)} alt='' />
                                            <label className='label-of-assigned-property'>{el.name}</label>
                                        </div>
                                )
                            })}
                        </div>
                        <div className='second-separating-line-bottom'></div>
                    </>
                    :
                    <div className='border-box-of-the-properties-access' onClick={() => setPropertiesAccessOpen(!propertiesAccessOpen)}>
                        <label className='single-user-role-and-permissions-header-closed'><Text>secondary.properties_access</Text></label>
                        <img className='ml-auto the-dropdown-open' src={dropdown_open} alt='' />
                    </div>
                }
                <div className='d-flex inv-form-bottom-container ml-0 mt-5'>
                    <SubmitBtn
                        submittingState={isRequestPending}
                        didSubmitSucceed={didSubmitSucceed}
                        submitFunction={updateTheUser}
                        disabled={false}
                        submittingClassName='workspace-user-saving-btn'
                        submitClassName='inv-form-invite-btn'
                        disabledBtnClassName='inv-form-invite-btn-disabled'
                        failedSubmitClassName='workspace-user-save-btn-failed-submit'
                        submitText='action.save'
                        submittingText='action.saving'
                        promiseSucceedText='action.saved'
                        promiseFailText='action.not_saved'
                    />
                    <div className='inv-form-close-btn mb-5'>
                        <Link to="/workspace/users-control"><label className='mb-0'><Text>action.back</Text></label></Link>
                    </div>
                </div>
            </div>
            <DeleteUserModal user={theUser} users={[]} open={isOpen} onClose={() => setIsOpen(false)} />
        </div>
    );
};

export default SingleUser;
