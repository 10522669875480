import React from 'react';

function PagePreloader(props) {
    return (
        <div className='page-preloader'>
            <img src={window.ownerIcon ? window.ownerIcon : '../../assets/img/preloader-circle.svg'} alt="" />
            <span>Getting everything ready...</span>
        </div>
    );
}

export default PagePreloader;
