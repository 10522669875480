import React from 'react';

import './css/style.css';

const FSSelect = props => {
    return (
        <select
            value={props.selected}
            onChange={props.onChange}
            className={props.className}
            disabled={props.className === 'disabled'}
        >
            {props.options.map((option, index) => {
                return <option key={index} value={option.value}>{option.text}</option>
            })}
        </select>
    )
}

export default FSSelect;