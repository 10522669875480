import { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Text from '../../ui/Text';

const EditContact = (props) => {
  const { className, modal, toggle, contact, update } = props;

  const [firstName, setFirstName] = useState(contact?.first_name || '');
  const [middleName, setMiddleName] = useState(contact?.middle_name || '');
  const [lastName, setLastName] = useState(contact?.last_name || '');
  const [mobilePhone, setMobilePhone] = useState(contact?.mobile_phone || '');
  const [homePhone, setHomePhone] = useState(contact?.home_phone);
  const [workPhone, setWorkPhone] = useState(contact?.work_phone);
  const [email, setEmail] = useState(contact?.email || '');
  const [company, setCompany] = useState(contact?.company || '');
  const [jobPosition, setJobPosition] = useState(contact?.job_position || '');
  const [note, setNote] = useState(contact?.note || '');

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}> <Text>contact.edit.edit_contact</Text></ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for='first_name'>
                <Text>contact.edit.first_name</Text>
              </Label>
              <Input
                type='text'
                name='first_name'
                id='first_name'
                placeholder='First Name'
                value={firstName}
                onChange={(ev) => setFirstName(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='middle_name'>
                <Text>contact.edit.middle_name</Text>
              </Label>
              <Input
                type='text'
                name='middle_name'
                id='middle_name'
                placeholder='Middle Name'
                value={middleName}
                onChange={(ev) => setMiddleName(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='last_name'>
                <Text>contact.edit.last_name</Text>
              </Label>
              <Input
                type='text'
                name='last_name'
                id='last_name'
                placeholder='Last Name'
                value={lastName}
                onChange={(ev) => setLastName(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='mobile_phone'>
                <Text>contact.edit.mobile_phone</Text>
              </Label>
              <Input
                type='text'
                name='mobile_phone'
                id='mobile_phone'
                placeholder='Mobile Phone'
                value={mobilePhone}
                onChange={(ev) => setMobilePhone(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='home_phone'>
                <Text>contact.edit.home_phone</Text>
              </Label>
              <Input
                type='text'
                name='home_phone'
                id='home_phone'
                placeholder='Home Phone'
                value={homePhone}
                onChange={(ev) => setHomePhone(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='work_phone'>
                <Text>contact.edit.work_phone</Text>
              </Label>
              <Input
                type='text'
                name='work_phone'
                id='work_phone'
                placeholder='Work phone'
                value={workPhone}
                onChange={(ev) => setWorkPhone(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='email'>
                <Text>contact.edit.email</Text>
              </Label>
              <Input
                type='email'
                name='email'
                id='email'
                placeholder='Email'
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='company'>
                <Text>contact.edit.company</Text>
              </Label>
              <Input
                type='text'
                name='company'
                id='company'
                placeholder='Company name'
                value={company}
                onChange={(ev) => setCompany(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='job_position'>
                <Text>contact.edit.position</Text>
              </Label>
              <Input
                type='email'
                name='job_position'
                id='job_position'
                placeholder='Job position'
                value={jobPosition}
                onChange={(ev) => setJobPosition(ev.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='notes'>
                <Text>contact.edit.notes</Text>
              </Label>
              <Input
                type='textarea'
                name='notes'
                id='notes'
                value={note}
                onChange={(ev) => setNote(ev.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              update(contact.id, {
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                mobile_phone: mobilePhone,
                home_phone: homePhone,
                work_phone: workPhone,
                email: email,
                company: company,
                job_position: jobPosition,
                note: note
              });
              toggle();
            }}
          >
            <Text>contact.edit.submit</Text>
          </Button>{' '}
          <Button color='secondary' onClick={toggle}>
          <Text>contact.edit.cancel</Text>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditContact;
