import React from 'react';
import {Link} from "react-router-dom";
import './SidebarMenu.scss';
import Text from '../../ui/Text';

const SidebarMenu = () => {

    return (
        <aside className="app-aside">
            <a className="app-aside--logo"><img src="" alt=""/></a>
            <h2 className="app-aside-title"><a><Text>RentalsUnited</Text></a></h2>
            <nav className="app-aside--nav">
                <Link to="/integrations/rentalsunited/connect" className="button button-solid button-small">
                    <Text>Connect Account</Text>
                </Link>
                <ul className="app-aside--menu">
                    <li><Link><Text>Connected Accounts</Text></Link></li>
                    <li><Link to="/integrations/rentalsunited/mapping"><Text>Properties Mapping</Text></Link></li>
                    <li><Link><Text>Push Properties</Text></Link></li>
                    <li><a><Text>Need help?</Text></a></li>
                </ul>
            </nav>
        </aside>
    )
}

export default SidebarMenu;
