import {Link} from 'react-router-dom';
import { GUEST_NO_AVATAR } from "../../../common/constants";

const ListTableItemGuest = (props) => {
  const { item } = props;

  return (
    <Link to={window.ownerURL ? '#' : `/guests/${item.guest_id}`} className='listing-thumb-guest'>

      <span>
        <img
          src={item.guest_thumb || GUEST_NO_AVATAR}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = GUEST_NO_AVATAR;
          }}
          alt='guest'
          />
      </span>
      <div className="text">{item.guest_name}</div>
          </Link>
  );
};

export default ListTableItemGuest;
