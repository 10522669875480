import './InviteUsersForm.scss';
import { useState, useEffect, useRef } from 'react';
import { default as close_icon } from '../../../../assets/icons/close-icon.svg'
import RolesDropdown from '../../../../components/elements/RolesDropdown'
import ModifyRole from './ModifyRole';
import AssignProperties from './AssignProperties';
import { TagInput } from 'reactjs-tag-input'
import InvitedUsers from './InvitedUsers';
import { useDispatch, useSelector } from 'react-redux';
import SubmitBtn from '../../../../components/elements/SubmitBtn';
import * as userActions from '../../../../store/actions/users';
import Text from '../../../../components/ui/Text';

const InviteUsersForm = (props) => {
  const roles = useSelector(state => state.roles).roles;
  const activeListings = useSelector(state => state.listing.active);
  const [viewinvMultiple, setViewInvMultiple] = useState(false);
  const [viewModifyRole, setViewModifyRole] = useState(false);
  const [viewAssignProperties, setViewAssignProperties] = useState(false);
  const [viewInvitedUsers, setViewInvitedUsers] = useState(false);
  const [assignedProperties, setAssignedProperties] = useState(activeListings.map(el => el.id) || []);
  const [role, setRole] = useState(roles[0] || {});
  const [emails, setEmails] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [additionalPermissions, setAdditionalPermissions] = useState({});
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const [isRoleModified, setIsRoleModified] = useState(false);
  const [readyForSubmit, setReadyForSubmit] = useState(false);
  const emailsInputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (emails[0] === '') setEmails([]);
    if (emails.length < 1 || role.length < 1) setReadyForSubmit(false);
    else setReadyForSubmit(true);
  }, [emails, role, assignedProperties]);

  useEffect(() => {
    if (Object.keys(permissions).length > 0 && JSON.stringify(role.permissions) !== JSON.stringify(permissions)) setIsRoleModified(true);
    else setIsRoleModified(false);
  }, [permissions]);

  const clearTabs = () => {
    setAssignedProperties(activeListings.map(el => el.id));
    setEmails([]);
    setViewInvitedUsers(false);
    setViewInvMultiple(false);
  };

  const handleInviteUsers = async () => {
    const data = {
      emails: viewinvMultiple ? emails.map(email => email.displayValue) : emails,
      role_id: role.id,
      additional_permissions: additionalPermissions,
      properties: assignedProperties
    };

    setRequestInProgress(true);

    if (requestInProgress) return;

    try {
      await dispatch(userActions.setInvitedUsers(data));
    } catch (e) {
      return setDidSubmitSucceed(false);
    };

    setRequestInProgress(false);
    setDidSubmitSucceed(true);
    setViewInvitedUsers(true);
  };


  return (
    <div id="invite-user-form-wrapper">
      <div className={props.modal ? "tint" : ''}></div>
      <div className={props.modal ? "invite-form" : ''}>
        <div className={props.modal ? 'invite-users-form' : 'hidden-invite-users-form'}>
          <div className='close-inv-form d-flex'>
            <div
              className={`inv-form-back-btn${viewModifyRole || viewAssignProperties ? '' : '-hidden'} d-flex align-items-center justify-content-center`}
              onClick={() => {
                setViewModifyRole(false);
                setViewAssignProperties(false);
              }}>
              <img src='/img/arrow-left.svg' alt='' />
            </div>

            <div className='inv-form-close-x'>
              <img src={close_icon} alt='' onClick={() => props.toggle()} />
            </div>
          </div>

          {viewModifyRole
            ? <ModifyRole
              closeForm={() => setViewModifyRole(false)}
              selectedRole={role}
              roles={roles}
              passDropdownRoleData={id => setRole(roles.find(r => r.id === id))}
              selectedPermissions={permissions}
              passPermissions={setPermissions}
              passAdditionalPermissions={setAdditionalPermissions}
            />
            : viewAssignProperties
              ? <AssignProperties
                setAssignedProperties={setAssignedProperties}
                assignedProperties={assignedProperties}
                closeForm={() => setViewAssignProperties(false)} />
              : viewInvitedUsers
                ? <InvitedUsers invitedUsers={emails} closeForm={() => clearTabs()} />
                : <div>
                  <div className='scrollable-content'>
                    <h2 className='inv-users-heading'>
                    <Text>secondary.invite_users</Text>
                    </h2>

                    <div className='d-flex mt-4 mb-4 inv-form-email'>
                      {viewinvMultiple
                        ?
                        <div className='inv-multiple-users'>
                          <label className='inv-form-labels'><Text>action.inv_many_users</Text></label>
                          <div className='tag-input'>
                            <TagInput
                              placeholder='Type in emails separated by newlines..'
                              ref={emailsInputRef}
                              tags={emails}
                              onTagsChanged={(event) => {
                                setEmails(event);
                                setDidSubmitSucceed(null);
                                setRequestInProgress(false);
                              }}
                              wrapperStyle={wrapperStyle}
                              inputStyle={inputStyle}
                              tagStyle={tagStyle}
                              hideInputPlaceholderTextIfTagsPresent={false}
                              tagDeleteStyle={tagDeleteStyle}
                            />
                          </div>

                          <label className='inv-many-users-back-label' onClick={() => {
                            setEmails([]);
                            setViewInvMultiple(false);
                          }}>
                            <Text>action.back</Text>
                          </label>
                        </div>
                        :
                        <div>
                          <label className='inv-form-labels'><Text>main.email</Text></label>
                          <textarea
                            className='inv-form-email-input'
                            placeholder='e.g user@company.com'
                            value={emails}
                            onChange={event => {
                              setEmails([event.target.value]);
                              setDidSubmitSucceed(null);
                              setRequestInProgress(false);
                            }} />
                          <label className='inv-many-users-label' onClick={() => {
                            setEmails([]);
                            setViewInvMultiple(true);
                          }}>
                            <Text>action.inv_many_users</Text>
                          </label>
                        </div>}
                    </div>

                    <div className='mb-4 mt-4 inv-form-role'>
                      <label className='inv-form-labels'><Text>secondary.role</Text></label>

                      <div className='d-flex align-items-center position-relative'>
                        <RolesDropdown
                          passDropdownRoleData={id => {
                            setPermissions({});
                            setRole(roles.find(r => r.id === id))
                          }}
                          selectedRole={role}
                          userRole={role}
                          permissionsModified={isRoleModified}
                          wrapperClassName='inv-form-roles-dropdown-wrapper'
                          selectClassName='inv-form-roles-dropdown' />
                        <img className='inv-form-role-info-icon ml-2' src='/img/info-circle-dark.svg' alt='' />
                        <div className='inv-form-roles-info-container'>
                          <label>{role?.description || ''}</label>
                        </div>
                      </div>
                      <label
                        className='inv-form-edit-permissions-label'
                        onClick={() => {
                          setViewModifyRole(true);
                        }}>
                        <Text>action.modify_role</Text>
                      </label>
                    </div>

                    <div onClick={() => setViewAssignProperties(true)}>
                      <label className='inv-form-labels'><Text>secondary.restrict_to_properties</Text></label>

                      <div className='d-flex assign-property-boxes w-auto'>
                        {assignedProperties.length > 0 && assignedProperties.map((property, index) => {
                          return (
                            <div key={index} className='mb-2 mr-3 property-box-checked'>
                              <div className='p-1 d-flex align-items-center'>

                                <div className='users-table-checked-checkbox'
                                  onClick={() => setAssignedProperties(assignedProperties.filter((el, id) => id !== index))} />
                                <label className='mb-0 ml-2'>{activeListings.find(el => el.id === property).name}</label>
                              </div>
                            </div>
                          )
                        })}
                      </div>

                      <div className={`d-flex mt-1 inv-form-assign-properties-btn`}>
                        <img src='/img/white-pen.svg' alt='' />
                        <label className='mb-0 ml-2'><Text>action.restrict</Text></label>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex inv-form-bottom-container'>

                    <SubmitBtn
                      submittingState={requestInProgress}
                      didSubmitSucceed={didSubmitSucceed}
                      submitFunction={handleInviteUsers}
                      disabled={!readyForSubmit}
                      submittingClassName='inv-form-inviting-btn'
                      submitClassName='inv-form-invite-btn'
                      disabledBtnClassName='inv-form-invite-btn-disabled'
                      failedSubmitClassName='inv-form-save-btn-failed-submit'
                      submitText='action.invite'
                      submittingText='action.inviting'
                      promiseSucceedText='action.invited'
                      promiseFailText='action.not_invited'
                    />
                    <div className='inv-form-close-btn' onClick={() => props.toggle()}>
                      <label className='mb-0'><Text>action.close</Text></label>
                    </div>
                  </div>
                </div>}
        </div>
      </div>
    </div >
  );
};

const wrapperStyle = {
  width: '325px',
  height: '120px',
  position: 'unset',
  borderRadius: '2px',
  background: '#FFF',
  margin: '55px 0 0 162px',
  padding: '0',
  overflowX: 'auto'
};

const inputStyle = {
  padding: '0 5px',
  fontSize: '16px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 'normal',
  color: '#19303E',
  background: '#FFF'
};

const tagStyle = {
  fontSize: '12px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
  background: 'rgba(154, 196, 236, 0.24)',
  borderRadius: '2px',
  color: '#19303E'
}

const tagDeleteStyle = {
  color: '#19303E'
}

export default InviteUsersForm;
