import { useDispatch } from 'react-redux';
import { useState } from 'react/cjs/react.development';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { findAvailability } from '../../../store/actions/multiCalendar';
import './FindAvailabilityMenu.scss';
import moment from 'moment';
import Text from '../../../components/ui/Text';

const FindAvailabilityModal = ({ modal, toggle, multicalendar, toggleResult }) => {
    const [dates, setDates] = useState({
        start_date: moment(Date.now()).format('YYYY-MM-DD'),
        end_date: moment(Date.now()).add(1, 'days').format('YYYY-MM-DD')
    });
    const [filters, setFilters] = useState({
        guests: 2,
        city: '',
        state: '',
        children_allowed: 1,
        infants_allowed: 1,
        pets_allowed: 1,
        smoking_allowed: 0,
        events_allowed: 0,
    });
    const dispatch = useDispatch();

    return (
        <Modal className='find-menu' isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}><Text>Find Availability</Text></ModalHeader>
            <ModalBody>
                <div>
                    <label htmlFor='from'><Text>From</Text></label>{' '}
                    <input
                        type='date'
                        name='from'
                        value={dates.start_date}
                        onChange={(e) => setDates({ ...dates, start_date: e.target.value })}
                    />
                    <br />
                    <label htmlFor='to'><Text>To</Text></label>{' '}
                    <input
                        type='date'
                        name='to'
                        value={dates.end_date}
                        onChange={(e) => setDates({ ...dates, end_date: e.target.value })}
                    />
                    <br />
                    <label htmlFor='guests'><Text>Guests</Text></label>{' '}
                    <input
                        type='number'
                        name='guests'
                        className='guests-filter'
                        placeholder='number of guests..'
                        value={filters.guests}
                        onChange={(e) => setFilters({ ...filters, guests: e.target.value })}
                    />
                    <br />
                    <br />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    onClick={() =>
                        dispatch(findAvailability(multicalendar, dates, filters)) +
                        toggleResult() +
                        toggle()
                    }
                >
                    <Text>Find</Text>
                </Button>{' '}
                <Button color='secondary' onClick={toggle}>
                <Text>Cancel</Text>
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default FindAvailabilityModal;
