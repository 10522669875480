import {Link} from 'react-router-dom';

const ListTableItemListing = (props) => {
  const { item } = props;

  return (
    <Link to={`/listings/view/${item.id}`} className='listing-thumb-listing'>
      <span>
        <img src={item.thumbnailUrl} alt='item_listing'/>
      </span>
      <div className="text">
        {item.nickname ? item.nickname : item.name.value}
      </div>
    </Link>
  );
};

export default ListTableItemListing;
