import moment from 'moment';
import './MonthsDropDown.scss';
import {useSelector} from "react-redux";


const MonthsDropDown = () => {

    const defaultViewText = useSelector(state => state.translation.translations.Default_view);
    return (
        <div className='select'>
            <select name='months' defaultValue='default'>
                <option value='-12'>{moment().add(-12, 'months').format('MMMM YYYY')}</option>
                <option value='-11'>{moment().add(-11, 'months').format('MMMM YYYY')}</option>
                <option value='-10'>{moment().add(-10, 'months').format('MMMM YYYY')}</option>
                <option value='-9'>{moment().add(-9, 'months').format('MMMM YYYY')}</option>
                <option value='-8'>{moment().add(-8, 'months').format('MMMM YYYY')}</option>
                <option value='-7'>{moment().add(-7, 'months').format('MMMM YYYY')}</option>
                <option value='-6'>{moment().add(-6, 'months').format('MMMM YYYY')}</option>
                <option value='-5'>{moment().add(-5, 'months').format('MMMM YYYY')}</option>
                <option value='-4'>{moment().add(-4, 'months').format('MMMM YYYY')}</option>
                <option value='-3'>{moment().add(-3, 'months').format('MMMM YYYY')}</option>
                <option value='-2'>{moment().add(-2, 'months').format('MMMM YYYY')}</option>
                <option value='-1'>{moment().add(-1, 'months').format('MMMM YYYY')}</option>
                <option value='default' selected>
                    {defaultViewText}
                </option>
                <option value='1'>{moment().add(1, 'months').format('MMMM YYYY')}</option>
                <option value='2'>{moment().add(2, 'months').format('MMMM YYYY')}</option>
                <option value='3'>{moment().add(3, 'months').format('MMMM YYYY')}</option>
                <option value='4'>{moment().add(4, 'months').format('MMMM YYYY')}</option>
                <option value='5'>{moment().add(5, 'months').format('MMMM YYYY')}</option>
                <option value='6'>{moment().add(6, 'months').format('MMMM YYYY')}</option>
                <option value='7'>{moment().add(7, 'months').format('MMMM YYYY')}</option>
                <option value='8'>{moment().add(8, 'months').format('MMMM YYYY')}</option>
                <option value='9'>{moment().add(9, 'months').format('MMMM YYYY')}</option>
                <option value='10'>{moment().add(10, 'months').format('MMMM YYYY')}</option>
                <option value='11'>{moment().add(11, 'months').format('MMMM YYYY')}</option>
                <option value='12'>{moment().add(12, 'months').format('MMMM YYYY')}</option>
            </select>
        </div>
    );
};

export default MonthsDropDown;
