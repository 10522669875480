import React from 'react';
import FSCheckbox from './FSCheckbox.js';

const FSChecklist = (props) => {
  const { items, checkedItems } = props;
  const handleChange = (itemId) => {
    let i = checkedItems.indexOf(itemId);

    if (i === -1) {
      checkedItems.push(itemId);
    } else {
      checkedItems.splice(i, 1);
    }

    props.onChange(checkedItems);
  };

  return (
    <div className="d-flex flex-column">
      {items.map((value, i) => (
        <FSCheckbox
          key={i}
          label={value.text}
          value={value.id}
          onChange={handleChange}
          checked={checkedItems.indexOf(value.id) > -1}
        />
      ))}
    </div>
  );
};

export default FSChecklist;
