import React, { useEffect, Fragment } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import MetabaseAppEmbed from './components/Metabase/Bi-metabase';
import Dashboard from './pages/DashboardPage/Dashboard';
import Inbox from './pages/InboxPage/Inbox';
import Updates from './pages/UpdatesPage/Updates';
import SingleContact from './components/Contacts/SingleContact/SingleContact';
import Start from './components/start/Start';
import SingleGuest from './components/Guests/SingleGuest/SingleGuest';
import ListPage from './pages/ListPage/ListPage';
import ViewPage from './pages/ViewPage/ViewPage';
import Logout from './pages/Logout/Logout';
import DudaPage from './pages/DudaPage/DudaPage';
import EditDudaSite from './pages/DudaPage/EditDudaSite';
import DudaTemplatesPage from './pages/DudaPage/DudaTemplatesPage';
import MultiCalendar from './pages/MultiCalendar/MultiCalendar';
import Env from './Env';
import RentalsUnitedMapping from './components/apps/rentalsunited/Mapping';
import AccessDenied from './pages/AccessDenied/AccessDenied';
import ConnectAccount from './components/apps/rentalsunited/ConnectAccount';
import Profile from './pages/UserPage/Profile/Profile';
import UsersControl from './pages/UserPage/Users/UsersControl';
import Billing from './pages/UserPage/Billing/Billing';
import SingleUser from './pages/UserPage/Users/SingleUser';
import SignUpPage from './pages/RegisterPages/SignUpPage';
import SetYourPassword from './pages/RegisterPages/SetYourPassword';
import NameYourWorkspace from './pages/RegisterPages/NameYourWorkspace';
import { Router } from 'react-router-dom';
import history from './common/history';
import { useAuth0 } from '@auth0/auth0-react';
import ThanksForSigningUp from './pages/RegisterPages/ThanksForSigningUp';
import ForgottenPassword from './pages/RegisterPages/ForgottenPassword';
import Security from './pages/UserPage/Security/Security';
import Settings from './pages/UserPage/Settings/Settings';
import Questionnaire from './pages/Questionnaire/Questionnaire';
import Base from './components/Base/Base';
import DeactivatedAccount from './pages/DeactivatedAccount/DeactivatedAccount';
import AutoLoginPage from './pages/RegisterPages/AutoLoginPage';

const LogInRedirect = () => {
    const { loginWithRedirect } = useAuth0();

    return loginWithRedirect();
}

const fullscreenPaths = ['/set-your-password', '/name-your-workspace', '/thanks-for-signing-up', '/questionnaire']; 

export const GuestUserRoutes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path='/sign-up' component={SignUpPage} />
                <Route exact path='/set-your-password' component={SetYourPassword} />
                <Route exact path='/forgotten-password' component={ForgottenPassword} />
                <Route exact path='/auto-login' component={AutoLoginPage} />
                <Route path="*" component={LogInRedirect} />
            </Switch>
        </Router>
    );
}

const Routes = ({ areAnalyticsDone }) => {
    const location = useLocation();

    useEffect(() => {
        if (areAnalyticsDone) {
            window.analytics.page();
        }
    }, [areAnalyticsDone, location.pathname]);

    const Wrapper = fullscreenPaths.includes(location.pathname) ? Fragment : Base;
    
    return (
        <Wrapper>
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path='/set-your-password' component={SetYourPassword} />
                <Route exact path='/name-your-workspace' component={NameYourWorkspace} />
                <Route exact path='/thanks-for-signing-up' component={ThanksForSigningUp} />
                <Route exact path='/questionnaire' component={Questionnaire} />
                <Route path="/start" exact component={Start} />
                <Route path="/inbox" exact component={Inbox} />
                <Route exact path="/apps/websitepro" component={DudaPage} />
                <Route exact path="/apps/websitepro/templates" component={DudaTemplatesPage} />
                <Route exact path="/apps/websitepro/edit/:id" component={EditDudaSite} />
                <Route exact path="/integrations/rentalsunited/mapping" component={RentalsUnitedMapping} />
                <Route exact path="/integrations/rentalsunited/connect" component={ConnectAccount} />
                <Route path="/updates" exact component={Updates} />
                <Route path="/user" exact component={Profile} />
                <Route path="/user/security" exact component={Security} />
                <Route path="/workspace" exact component={Settings} />
                <Route exact path="/workspace/users-control" component={UsersControl} />
                <Route exact path="/workspace/billing" component={Billing} />
                <Route exact path="/user/:id" component={SingleUser} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/multicalendar" exact component={MultiCalendar} />
                <Route path="/guests/:id" component={SingleGuest} />
                <Route path="/guests" component={() => <ListPage objectType="guest" />} />
                <Route path="/guest/add" component={() => <ViewPage objectType="guest" />} />
                <Route path="/guest/modify/:id">
                    <ViewPage objectType="guest" />
                </Route>
                <Route path="/reviews" component={() => <ListPage objectType="review" />} />
                <Route path="/review/add" component={() => <ViewPage objectType="review" />} />
                <Route path="/review/modify/:id">
                    <ViewPage objectType="review" />
                </Route>
                <Route path="/reservations" component={() => <ListPage objectType="reservation" />} />
                <Route path="/reservation/add" component={() => <ViewPage objectType="reservation" />} />
                <Route path="/reservation/modify/:id">
                    <ViewPage objectType="reservation" />
                </Route>
                <Route path="/listings" component={() => <ListPage objectType="listing" />} />
                <Route path="/listing/add" component={() => <ViewPage objectType="listing" />} />
                <Route path="/listing/modify/:id">
                    <ViewPage objectType="listing" />
                </Route>
                <Route path="/transactions" component={() => <ListPage objectType="transaction" />} />
                <Route path="/transaction/add" component={() => <ViewPage objectType="transaction" />} />
                <Route path="/transaction/modify/:id">
                    <ViewPage objectType="transaction" />
                </Route>
                <Route path="/contacts/:id" component={SingleContact} />
                <Route path="/contacts" component={() => <ListPage objectType="contact" />} />
                <Route path="/contact/add" component={() => <ViewPage objectType="contact" />} />
                <Route path="/contact/modify/:id">
                    <ViewPage objectType="contact" />
                </Route>
                <Route
                    path="/analytics*"
                    component={() => (
                        <MetabaseAppEmbed
                            getAuthUrl={(url) =>
                                `https://auth.fsapp.io/authorize?client_id=${Env.auth0clientId
                                }&response_type=token&redirect_uri=${encodeURIComponent(url)}`
                            }
                        />
                    )}
                />
                <Route exact path="/access-denied" component={AccessDenied} />
                <Route exact path="/deactivated-account" component={DeactivatedAccount} />
                <Route path="*" component={Dashboard}>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Wrapper>
    );
};

export default Routes;
