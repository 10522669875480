import FSMultipleSelect from '../../../components/elements/FSMultipleSelect.js';

const ListFilterMultiSelect = (props) => {
  const { filter, selectedFilterSettings } = props;
  let optionKeys = Object.keys(filter.options);

  if (optionKeys.length) {
    let hasStringValues = optionKeys.filter((key) => {
      return key !== parseInt(key);
    });

    if (hasStringValues.length === 0) {
      optionKeys = optionKeys.map(Number);
    }
  }

  const options = optionKeys.map((key) => {
    return { value: key, text: filter.options[key] };
  });

  const selected = !selectedFilterSettings ? [] : selectedFilterSettings.value;

  return (
    <div className="options-filter-container">
      <FSMultipleSelect
        options={options}
        selected={selected}
        onChange={props.onChange}
      />
    </div>
  );
};

export default ListFilterMultiSelect;
