import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import './OverwritePrompt.scss';
import Text from '../../../components/ui/Text';

const OverwritePrompt = ({ modal, toggle, mapping, currPropertyId, dragPropertyId, dragRUPropertyId, setMapping, setOngoingMapping, setDragPropertyId, setDragRUPropertyId }) => {
    const accept = () => {
        if (currPropertyId !== null) {
            delete mapping[currPropertyId];
        }
        
        mapping[dragPropertyId] = dragRUPropertyId;

        setMapping(mapping);
        setOngoingMapping({});
        setDragPropertyId(null);
        setDragRUPropertyId(null);
    }

    return (
        <Modal className='find-menu' isOpen={modal} toggle={toggle} style={{maxHeight: '15rem'}}>
            <ModalHeader toggle={toggle}>
                <Text>Are you sure you want to overwrite this mapped property?</Text>
            </ModalHeader>
            <ModalFooter>
                <Button
                    color='primary'
                    onClick={() =>
                        toggle() + accept()
                    }
                >
                    <Text>Yes</Text>
                </Button>{' '}
                <Button color='secondary' onClick={toggle}>
                    <Text>No</Text>
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OverwritePrompt;
