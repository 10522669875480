import {
    SET_MULTICALENDAR,
    FIND_AVAILABLITY,
    ADD_UNAVAILABLE_BLOCK,
    EDIT_UNAVAILABLE_BLOCK,
    CHANGE_PRICE_AND_MIN_NIGHTS,
} from '../actions/multiCalendar';
const initialState = [];

const multiCalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MULTICALENDAR:
            return action.multicalendar;
        case FIND_AVAILABLITY:
            return action.multicalendar;
        case ADD_UNAVAILABLE_BLOCK:
            return action.multicalendar;
        case EDIT_UNAVAILABLE_BLOCK:
            return action.multicalendar;
        case CHANGE_PRICE_AND_MIN_NIGHTS:
            return action.multicalendar;
        default:
            return state;
    }
};

export default multiCalendarReducer;
