import { useEffect } from 'react';
import FSSelect from '../../../components/elements/FSSelect.js';
import FSInput from '../../../components/elements/FSInput.js';

const availableOptions = [
  { value: 'eq', text: 'Is' },
  { value: 'ne', text: 'Is not' },
  { value: 'contains', text: 'Contains' },
  { value: 'not contains', text: 'Does not contain' },
  { value: 'startswith', text: 'Starts with' },
  { value: 'endswith', text: 'Ends with' },
];

const ListFilterText = (props) => {
  const { selectedFilterSettings } = props;
  const option = !selectedFilterSettings ? 'eq' : selectedFilterSettings.option;
  const value = !selectedFilterSettings ? '' : selectedFilterSettings.value;
  useEffect(() => {
    props.onChange(value, option || 'eq');
  }, []);

  const handleValueChange = (inputValue) => {
    props.onChange(inputValue, option);
  };

  const handleOptionChange = (e) => {
    props.onChange(value, e.target.value);
  };
  
  return (
    <div className="options-filter-container">
      <FSSelect options={availableOptions} value={option} onChange={handleOptionChange} />
      <FSInput value={value} onChange={handleValueChange} />
    </div>
  );
};

export default ListFilterText;
