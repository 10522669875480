import { useEffect } from "react";
import configJson from "../../auth_config.json";

const AutoLoginPage = () => {
    const params = Object.assign({
        configurationBaseUrl: "https://auth.fsapp.io/",
        overrides: {
          __tenant: "fantasticstay",
          __token_issuer: "https://auth.fsapp.io/"
        },
        domain:  configJson.domain,
        clientID: configJson.clientId,
        redirectUri: window.location.origin,
        responseType: "token",
    });

    const webAuth = new window.auth0.WebAuth(params);

    useEffect(() => {
        webAuth.authorize({
            connection: 'FantasticStay',
            access_token: 'auth.fsapp.io'
        }, function(err) {
            if (err) console.log(err);
        });
    }, []);

    return '';
}

export default AutoLoginPage;