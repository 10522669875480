import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FSButton from '../../elements/FSButton.jsx';
import FSMultipleSelect from '../../elements/FSMultipleSelect.js';
import ListHeaderViewsManager from './ListHeaderViewsManager';
import BulkEdit from './BulkEdit';
import FeatherIcon from 'feather-icons-react';
import FSInput from '../../../components/elements/FSInput.js';

import '../css/ListHeader.css';

import * as listActions from '../../../store/actions/list';
import * as viewActions from '../../../store/actions/view';

const ListHeader = (props) => {
  const { objectType } = props;
  const columnOptions = useSelector((state) => state.list.allColumns);
  // const initialView = useSelector((state) => state.view.currentView);
  const initialView = {columns: [{field: "nickname", name: "Listing", order: 1, prefix: "l", sortable: true, type: "listing"},
  {field: "channels", name: "Channels", order: 2, prefix: null, sortable: false, type: "channels"},
  {field: "address/address", name: "Full address", order: 3, prefix: null, sortable: false, type: "text"},
  {field: "prices/dailyPrice", name: "Base price", order: 87, prefix: null, sortable: true, type: "price"}],
  default: 1, dv: "2", favourite: 0, filters: [], id: "mVlmQcLHFGO9RALHf3axKt1Z6x05Gdt2", isMine: false, name: "Active listings"};
  // const selectedColumns = useSelector((state) => state.list.selectedColumns);
  const selectedColumns = [{field: "nickname", name: "Listing", order: 1, prefix: "l", sortable: true, type: "listing"},
                          {field: "channels", name: "Channels", order: 2, prefix: null, sortable: false, type: "channels"},
                          {field: "address/address", name: "Full address", order: 3, prefix: null, sortable: false, type: "text"},
                          {field: "prices/dailyPrice", name: "Base price", order: 87, prefix: null, sortable: true, type: "price"}]
  const selectedFilters = useSelector((state) => state.list.selectedFilters);
  const selectedIds = useSelector((state) => state.list.selectedIds);

  const [showColumns, setShowColumns] = useState(false);
  const [modal, setModal] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareDialogLink, setShareDialogLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [name, setName] = useState('');
  const [exportInProgress, setExportInProgress] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [showBulkEdit, setShowBulkEdit] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleShare = () => setShareDialogOpen(!shareDialogOpen);
  const toggleBulkEdit = () => setShowBulkEdit(!showBulkEdit);

  const dispatch = useDispatch();

  const handleNewColumns = async () => {
    setShowColumns(true);
  };

  let tmp = columnOptions.map((v) => {
    let parsedValue = v.field;
    if (v.field.indexOf('.') > -1) {
      parsedValue = parsedValue.substring(parsedValue.indexOf('.') + 1);
    }
    return { value: parsedValue, text: v.name };
  });

  const parseColumns = useCallback(() => {
    return columnOptions.map((col, index) => {
      let parsedValue = col.field;
      col.prefix = null;
      if (col.field.indexOf('.') > -1) {
        col.prefix = parsedValue.substring(0, parsedValue.indexOf('.'));
        col.field = parsedValue.substring(parsedValue.indexOf('.') + 1);
      }
      return col;
    });
  }, [columnOptions]);

  const handleSelectedColumns = async (selected) => {
    let parsed = parseColumns();
    const newColumns = parsed.filter((column) => selected.indexOf(column.field) > -1);
    dispatch(listActions.setSelectedColumns(newColumns));
  };

  useEffect(() => {
    if (selectedColumns.length !== initialView.columns.length) {
      setShowApplyButton(true);
    } else {
      const compare = (a, b) => {
        return a.field - b.field;
      };
      if (
        JSON.stringify(selectedColumns.sort(compare)) !==
        JSON.stringify(initialView.columns.sort(compare))
      ) {
        setShowApplyButton(true);
      } else {
        setShowApplyButton(false);
      }
    }
  }, []);

  const saveColumns = async () => {
    let view = { ...initialView };
    if (initialView.default) {
      toggle();
      view.name = name;
      view.id = '';
      view.columns = selectedColumns;
      view.default = 0;
      view.isMine = true;
      dispatch(viewActions.addView(props.objectType, view));
    } else {
      view.columns = selectedColumns;
      dispatch(viewActions.updateView(props.objectType, view));
    }
    setShowColumns(false);
    setShowApplyButton(false);
  };
  const handleValueChange = (inputValue) => {
    setName(inputValue);
  };
  const handleExport = async () => {
    setExportInProgress(true);
    try {
      const result = await listActions.exportItems(
        objectType,
        selectedFilters,
        initialView.columns,
      );
      setExportInProgress(false);
      window.location.href = `/apps/get_export_file/${result.data.filename}`;
    } catch (error) {
      console.log(error);
    }
  };
  const handleShare = () => {
    toggleShare();
    setShareDialogLink(`${window.location.origin}/${objectType}s/report?r=${initialView.id}`);
  };

  const handleCopy = () => {
    let copyText = document.getElementById('link');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    try {
      document.execCommand('copy');
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error(error);
    }
    copyText.blur();
  };
  return (
    <div className="list-header">
      <Modal isOpen={shareDialogOpen} toggle={toggleShare}>
        <ModalBody>
          <input style={{ width: '100%' }} defaultValue={shareDialogLink} readOnly id="link" />
          <FSButton
            text={copied ? 'Copied' : 'Copy'}
            className="primary ml-10"
            onClick={handleCopy}
          />
        </ModalBody>
      </Modal>
      <ListHeaderViewsManager
        objectType={props.objectType}
      />
      <div className="list-actions">
        {!showColumns && (
          <div className="edit-canvas">
            <FeatherIcon icon="edit" onClick={() => handleNewColumns()} />
          </div>
        )}
        {showColumns && (
          <div className="list-columns">
            <FSMultipleSelect
              options={tmp}
              selected={selectedColumns.map((c) => c.field)}
              onChange={handleSelectedColumns}
            />
            {showApplyButton && (
              <div className="save-columns">
                <FSButton className="primary mr-10" text="Save" onClick={saveColumns} />
              </div>
            )}
          </div>
        )}
        {selectedIds.length > 0 && (
          <FSButton
            className="primary ml-30"
            text="Edit"
            style={{ marginLeft: '10px' }}
            onClick={toggleBulkEdit}
          />
        )}
        <FSButton
          className="ml-30"
          text="Add new"
          style={{ marginLeft: '10px' }}
          onClick={() => window.location.href = '/listing/add'}
        />
        {objectType === 'reservation' && (
          <FSButton
            text="Share"
            className="ml-30"
            onClick={handleShare}
            style={{ marginLeft: '10px' }}
          />
        )}
        <FSButton
          text="Export"
          className="ml-30"
          style={{ marginLeft: '10px' }}
          onClick={handleExport}
          inProgress={exportInProgress}
        />
        {showBulkEdit ? (
          <BulkEdit modal={showBulkEdit} toggle={toggleBulkEdit} objectType={props.objectType} />
        ) : null}
        <Modal isOpen={modal} toggle={toggle} className="modal-content">
          <ModalHeader toggle={toggle}>Choose a name</ModalHeader>
          <ModalBody>
            <FSInput value={name} onChange={handleValueChange} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={saveColumns}>
              Save
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ListHeader;
