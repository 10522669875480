const ListTableItemLabel = (props) => {
  const className = `label label-sm label-${props.label}`;
  return (
    <span className={className} data-tip={props.error || ''}>
      {props.value}
    </span>
  );
};

export default ListTableItemLabel;
