import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import FeatherIcon from 'feather-icons-react';

import SitesTable from './SitesTable';
import FSSitesPagination from '../../../components/elements/FSSitesPagination';
import FSButton from '../../../components/elements/FSButton';
import Loading from '../../../components/Base/Loading';
import Text from '../../ui/Text';

import * as sitesActions from '../../../store/actions/sites';
import { useAuth0 } from '@auth0/auth0-react';

const DudaTableSection = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [searchInput, setSearchInput] = useState('');

  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const initData = async () => {
      const token_id = await getIdTokenClaims();
      await dispatch(sitesActions.getSites(searchInput, token_id.__raw));
      setIsLoaded(true);
    };
    initData();
  }, [searchInput]);

  const redirectToChooseTemplatePage = () => {
    history.push('/apps/websitepro/templates');
  };

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className={isMobile ? 'sites-grid-container' : 'sites-container'}>
      <div className="sites-breadcrumbs">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" exact className="breadcrumb-link">
                <Text>home</Text>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/apps" exact className="breadcrumb-link">
                <Text>apps</Text>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to="/apps/websitepro" exact className="breadcrumb-link-active">
                <Text>website.pro</Text>
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="sites-header">
        <h1 className={isMobile ? 'sites-header-text' : 'sites-header-text flex-1'}>
          <Text>sites.dashboard</Text>
        </h1>
        <div className="search-field">
          <button className="search-field-button">
            <FeatherIcon icon="search" />
          </button>
          <input
            className="search-field-input"
            type="text"
            placeholder="Search for sites"
            onChange={(ev) => setSearchInput(ev.target.value)}
          />
        </div>
        <FSButton
          className="sites-createsite-button"
          text="Create a responsive site"
          onClick={redirectToChooseTemplatePage}
        />
      </div>
      <SitesTable isMobile={isMobile} />
      <div className="sites-pagination">
        <FSSitesPagination />
      </div>
    </div>
  );
};

export default withRouter(DudaTableSection);
