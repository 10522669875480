import './Billing.scss';
import Text from '../../../components/ui/Text';
import SideMenuInterface from '../SideMenuInterface/SideMenuInterface';
import { default as limit_info } from '../../../assets/icons/limit-info.svg';
import { default as plan_tick } from '../../../assets/icons/plan-tick.svg';
import { default as credit_card } from '../../../assets/icons/the-credit-card.svg';
import { default as sub_icon } from '../../../assets/icons/sub-icon.svg';
import { default as billing_address } from '../../../assets/icons/billing-address.svg';
import { default as billing_history } from '../../../assets/icons/billing-history.svg';
import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Request from '../../../utils/Request';
import * as authActions from '../../../store/actions/auth';
import PagePreloader from '../../../components/Base/PagePreloader';
import Env from '../../../Env';
import AccessDenied from '../../AccessDenied/AccessDenied';

const billingPlansLimits = {
    pro: 9,
    business: 19,
    growth: 29,
    corporate: 39,
    enterprise: 49
};

const Billing = () => {
    const cbInstance = useRef(null);
    const token = useSelector((state) => state.auth.token);
    const customer = useSelector((state) => state.auth.customer);
    const subscriptionId = useSelector((state) => state.auth.subscriptionId);
    const allListings = useSelector(state => state.listing.active);
    const userPermissions = useSelector(state => state.auth.user.default_permissions);
    const conicGradient = (allListings.length/billingPlansLimits[customer.tier])*360; 
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        cbInstance.current = window.Chargebee.init({
            site: Env.chargebeeSite,
            publishableKey: Env.chargebeeKey
        })

        cbInstance.current.setPortalSession(() => {
            return Request.post(
                `${Env.apiDomainSSO}/chargebee/portal-session/${customer.id}`,
                { id: customer.id },
                token
            )
        });

        const getSubscriptionId = async () => {
            try {
                await dispatch(authActions.setSubscriptionId(customer.id));
            } catch { }

            setLoaded(true);
        };

        getSubscriptionId();
    }, [token, customer.id, dispatch]);

    const handleUpdatePM = () => {
        let cbPortal = cbInstance.current.createChargebeePortal();
        cbPortal.openSection({
            sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES
        })
    }

    const handleSubscriptionDetails = () => {
        let cbPortal = cbInstance.current.createChargebeePortal();
        cbPortal.openSection({
            sectionType: window.Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
            params: {
                subscriptionId: subscriptionId
            }
        })
    }

    const handleAddress = () => {
        let cbPortal = cbInstance.current.createChargebeePortal();
        cbPortal.openSection({
            sectionType: window.Chargebee.getPortalSections().ADDRESS
        })
    }

    const handleBillingHistory = () => {
        let cbPortal = cbInstance.current.createChargebeePortal();
        cbPortal.openSection({
            sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY
        })
    }

    if (!loaded) {
        return <PagePreloader />;
    }

    if (!userPermissions.hasOwnProperty('Billing')) {
        return <AccessDenied />
    }

    return (
        <div className='billing-page'>
            <SideMenuInterface selectedInterface='billing' />
            <div className='billing-page-content'>
                <div className='billing-heading'>
                    <h4><Text>main.billing</Text></h4>
                </div>

                <div className='both-labels'>
                    <label className='my-current-plan'>
                        <Text>secondary.current_plan</Text>
                    </label>
                </div>

                <div className='both-border-boxes'>
                    <div className='border-box-current-plan'>
                        <div className='plan-icon-and-name'>
                            <div className='plan-icon-border'>
                                <img className='plan-tick' src={plan_tick} alt='' />
                            </div>
                            <p className='plan-name'>{customer.tier.toUpperCase()} <Text>secondary.plan</Text></p>
                        </div>
                        <p className='assistance-text'>
                            <Text>tooltip.assistance_support</Text><a href='mailto:support@fantasticstay.com'>{' '}<u style={{ color: '#00C141', cursor: 'pointer' }}><Text>secondary.contact_us</Text></u></a>
                        </p>
                    <div className='upgrade-plan-btn'>
                        <Text>action.upgrade_plan</Text>
                    </div>
                </div>
                <div className='second-label'>
                    <label className='my-properties-limit-text ml-auto'>
                        <Text>secondary.properties_limit</Text>
                    </label>
                    <div className='border-box-plan-limit'>
                        <img className='limit-info' src={limit_info} alt='' />
                        <div
                            className='big-circle'
                            style={{ background: `conic-gradient(#00C141 0deg, #00C141 ${conicGradient}deg, #354955 ${conicGradient}deg, #354955 360deg)` }}
                        >
                            <p className='my-properties'>{allListings.length}</p>
                            <p className='my-properties-limit'>/{billingPlansLimits[customer.tier]}</p>
                        </div>
                        <div className='little-circle'></div>
                        <p className='plan-limit-text'><Text>secondary.registered_properties</Text></p>
                    </div>
                </div>
            </div>

            <label className='billing-settings-header'>
                <Text>main.edit_billing_settings</Text>
            </label>

            <div className='group-billing-settings'>
                <div className='payment-method-parent'>
                    <img className='billing-settings-icons' src={credit_card} alt='' />
                    <a className='payment-method' onClick={handleUpdatePM}><Text>secondary.payment_method</Text></a>
                </div>
                {subscriptionId ?
                    <div className='subscription-parent'>
                        <img className='billing-settings-icons' src={sub_icon} alt='' />
                        <a className='subscription-details' onClick={handleSubscriptionDetails}><Text>secondary.subscription_details</Text></a>
                    </div>
                    :
                    <></>
                }
                <div className='billing-shipping-parent'>
                    <img className='billing-settings-icons' src={billing_address} alt='' />
                    <a className='billing-shipping-address' onClick={handleAddress}><Text>secondary.billing_shipping_address</Text></a>
                </div>
                <div className='billing-history-parent'>
                    <img className='billing-settings-icons' src={billing_history} alt='' />
                    <a className='billing-history' onClick={handleBillingHistory}><Text>secondary.billing_history</Text></a>
                </div>
            </div>
        </div>
        </div >
    );
};

export default Billing;
