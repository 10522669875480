const getStyle = (startCoordinates, endCoordinates) => {
    const style = {
        width: Math.abs(startCoordinates[0] - endCoordinates[0]) || 0,
        height: Math.abs(startCoordinates[1] - endCoordinates[1]) || 0,
        position: 'fixed',
        border: '2px solid blue',
        zIndex: 100,
    };

    let positionStyle = {};

    if (startCoordinates[0] > endCoordinates[0]) {
        positionStyle.right = document.body.clientWidth - startCoordinates[0];

        if (startCoordinates[1] > endCoordinates[1]) {
            positionStyle.bottom = window.innerHeight - startCoordinates[1];
        } else {
            positionStyle.top = startCoordinates[1];
        }
    } else {
        positionStyle.left = startCoordinates[0];

        if (startCoordinates[1] > endCoordinates[1]) {
            positionStyle.bottom = window.innerHeight - startCoordinates[1];
        } else {
            positionStyle.top = startCoordinates[1];
        }
    }

    return { ...style, ...positionStyle };
};

const SelectionBox = ({ startCoordinates, endCoordinates }) => {
    const position = getStyle(startCoordinates, endCoordinates);

    return <div className='selection-box' style={position}></div>;
};

export default SelectionBox;
