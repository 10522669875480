import Text from '../../ui/Text';
import FSInput from '../../elements/FSInput';
import FSSelect from '../../elements/FSSelect';
import FSButton from '../../elements/FSButton';

const PaymentRules = (props) => {
  const { paymentMethod, paymentTimeOptions, paymentRule, setPaymentRule } = props;

  const handleSelectChange = (e, index, field) => {
    const { value } = e.target;
    const list = [...paymentRule];
    if (value === 'Entire unpaid balance') {
      list[index].percent = null;
    }
    if (value === 'New accepted reservation') {
      list[index].paymentTimeDays = null;
    }
    list[index][field] = value;
    setPaymentRule(list);
  };

  const handleInputChange = (e, index, field) => {
    const list = [...paymentRule];
    list[index][field] = +e;
    setPaymentRule(list);
  };

  const handleAddClick = () => {
    setPaymentRule([
      ...paymentRule,
      {
        method: paymentMethod[0].value,
        percent: 0,
        paymentTime: paymentTimeOptions[0].value,
        paymentTimeDays: 0,
      },
    ]);
  };
  
  const handleRemoveClick = (index) => {
    const list = [...paymentRule];
    list.splice(index, 1);
    setPaymentRule(list);
  };

  return (
    <div className="edit-site-payment-rules-container">
      <div className="edit-site-payment-rules-header">
        <div className="edit-site-payment-rules-header-name">
          <label>
            <Text>edit.site_payment_rules</Text>
          </label>
        </div>
      </div>
      <div className="edit-site-payment-rules-section">
        {paymentRule.map((method, index) => {
          return (
            <div className="edit-site-payment-rule" key={index}>
              <div className="edit-site-payment-rule-charge">
                <div className="edit-site-payment-rule-charge-header">
                  <span className="charge-amount">
                    <Text>edit.site_charge_amount</Text>
                  </span>
                </div>
                <div className="edit-site-payment-rule-charge-method">
                  <FSSelect
                    className="edit-site-payment-rule-charge-amount-select"
                    options={paymentMethod}
                    value={method.method}
                    selected={method.method}
                    onChange={(ev) => handleSelectChange(ev, index, 'method')}
                  />
                </div>
                {paymentRule[index].method === 'Custom percent' ? (
                  <div className="edit-site-payment-rule-charge-amount">
                    <div className="edit-site-payment-rule-charge-amount-input">
                      <FSInput
                        type="number"
                        max={100}
                        min={1}
                        className="form-control-percent-input"
                        placeholder="1-100"
                        value={method.percent}
                        onChange={(ev) => handleInputChange(ev, index, 'percent')}
                      />
                    </div>
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">
                        %
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="edit-site-payment-rule-time">
                <div className="edit-site-payment-rule-time-header">
                  <span className="charge-amount">
                    <Text>edit.site_schedule_to</Text>
                  </span>
                </div>
                {paymentRule[index].paymentTime === 'Check in' ? (
                  <div className="edit-site-payment-rule-time-amount">
                    <div className="edit-site-payment-rule-time-input">
                      <FSInput
                        type="number"
                        className="form-control-payment-days"
                        placeholder="Days"
                        value={method.paymentTimeDays}
                        onChange={(ev) => handleInputChange(ev, index, 'paymentTimeDays')}
                      />
                    </div>
                    <div class="input-group-append">
                      <span class="input-group-text">Before</span>
                    </div>
                  </div>
                ) : null}
                <div className="edit-site-payment-rule-time-method">
                  <FSSelect
                    options={paymentTimeOptions}
                    value={method.paymentTime}
                    selected={method.paymentTime}
                    className="payment-time-select"
                    onChange={(ev) => handleSelectChange(ev, index, 'paymentTime')}
                  />
                </div>
              </div>
              <div className="edit-site-payment-rule-remove-button-container">
                <FSButton
                  className="edit-site-payment-rules-remove-button"
                  text="Remove rule"
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                  onClick={() => handleRemoveClick(index)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="edit-site-payment-rules-footer">
        <FSButton
          className="edit-site-payment-rules-footer-remove-button"
          text="Add rule"
          style={{ width: 'auto', marginTop: '20px', marginBottom: '20px' }}
          onClick={() => handleAddClick()}
        />
      </div>
    </div>
  );
};

export default PaymentRules;
