import { Fragment } from 'react';

const ListTableItemCreditCard = (props) => {
  const { item } = props;

  return (
    <Fragment>
      <i className={'fa fa-' + item.card_image}></i>
      <span className="ml-10">{item.card_number}</span>
    </Fragment>
  );
};

export default ListTableItemCreditCard;
