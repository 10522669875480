/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */
import { SET_VIEW,SET_VIEWS } from "../actions/list"
import { SET_EDIT_VIEW,SET_NAME,SET_ORDER,SET_ORDER_DIRECTION,SET_SELECTED_FILTERS,SET_SELECTED_COLUMNS,SET_IS_DEFAULT } from "../actions/view"
const initialState = {
    currentView: [],
    views: [],
    selectedViewId: '2MI9AQLGxUneOCyPrjKRRJ9HqRCecE2so6gLd2KF',
    editView: {},
    name: '',
    order:'',
    orderDirection:'',
    selectedFilters: [],
    selectedColumns:[],
    isDefault: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_VIEW: {
            return {
                ...state,
                currentView: action.currentView
            }
        }
        case SET_VIEWS: {
            return {
                ...state,
                views: action.views
            }
        }
        case SET_EDIT_VIEW: {
            return {
                ...state,
                editView: action.editView
            }
        }
        case SET_NAME: {
            return {
                ...state,
                name: action.name
            }
        }
        case SET_ORDER: {
            return {
                ...state,
                order: action.order
            }
        }
        case SET_ORDER_DIRECTION: {
            return {
                ...state,
                orderDirection: action.orderDirection
            }
        }
        case SET_SELECTED_FILTERS: {
            return {
                ...state,
                selectedFilters: action.selectedFilters
            }
        }
        case SET_SELECTED_COLUMNS: {
            return {
                ...state,
                selectedColumns: action.selectedColumns
            }
        }
        case SET_IS_DEFAULT: {
            return {
                ...state,
                isDefault: action.isDefault
            }
        }
        default:
            return state
    }
}