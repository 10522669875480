import {useState} from 'react';
import FeatherIcon from 'feather-icons-react';
import Loading from '../../components/Base/Loading';

const FSButton =(props)=>  {
    const [color,setColor] = useState(window.ownerColor);
    // const [hover,setHover] = useState(false);
    // const [isLoading,setIsLoading] = useState(false);
        const className =
            "fs-button " +
            (props.className ? props.className : '') + 
            (props.inProgress ? ' in-progress' : '') +
            (props.isLoading ? ' btn-loading' : '');

        let style = {}

        if (window.ownerColor && className.indexOf('primary') > -1) {
            style = { backgroundColor: color, borderColor: color };
        }

        let disabled = false;

        if (typeof props.disabled !== "undefined") {
            disabled = props.disabled;
        }

        if (!!props.style) {
            style = {...style, ...props.style}
        }

        return (
            <button
                className={className}
                onClick={() => {
                    if (props.inProgress) {
                        return false;
                    }


                    if (props.hasOwnProperty('withPrompt')) {
                        /*if (!confirm(this.props.withPrompt)) {
                            return false;
                        }*/
                    }

                    return props.onClick();
                }}
                onMouseEnter={() => setColor(window.ownerColor ? window.ownerColor + 'bb' : '')}
                onMouseLeave={() => setColor(window.ownerColor ? window.ownerColor : '')}
                style={style}
                disabled={disabled}
            >
                {props.isLoading && <Loading/> }
                <span>{props.icon ? <FeatherIcon icon={`${props.icon}`} /> : null}{props.text ? props.text : "Save"}</span>
                
            </button>
        );
    }

export default FSButton;
