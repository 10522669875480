import Request from '../../utils/Request';
import Env from '../../Env';

export const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_DIRECTION = 'SET_ORDER_DIRECTION';
export const SET_EDIT_VIEW = 'SET_EDIT_VIEW';
export const SET_NAME = 'SET_NAME';
export const SET_IS_DEFAULT = 'SET_IS_DEFAULT';

export const setEditView = (viewId) => {
  return async (dispatch, getState) => {
    const views = getState().view.views;
    const result = views.find((view) => view.id === viewId);
    await dispatch({ type: SET_SELECTED_COLUMNS, selectedColumns: result.columns });
    const newFilters = result.filters.map((filter) => {
      return { ...filter, isValid: true };
    });
    await dispatch({ type: SET_NAME, name: result.name });
    await dispatch({ type: SET_ORDER_DIRECTION, orderDirection: result.order_by });
    await dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: newFilters });
    await dispatch({ type: SET_IS_DEFAULT, isDefault: result.default });
    await dispatch({ type: SET_EDIT_VIEW, editView: result });
    return result;
  };
};

export const setOrderDirections = (orderDirection) => {
  return async (dispatch, getState) => {
    await dispatch({ type: SET_ORDER_DIRECTION, orderDirection: orderDirection });
  };
};
export const setOrder = (order) => {
  return async (dispatch, getState) => {
    await dispatch({ type: SET_ORDER, order: order });
  };
};
export const setSelectedColumns = (selected) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_COLUMNS, selectedColumns: selected });
  };
};
export const setName = (name) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_NAME, name: name });
  };
};
export const setSelectedFilters = (selected) => {
  return async (dispatch, getState) => {
    const selectedFilters = getState().view.selectedFilters;
    let newFiltersSelected = selectedFilters.filter((f) => f.field !== selected.field);
    dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: [...newFiltersSelected, selected] });
  };
};
export const setFilters = (filters) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_FILTERS, selectedFilters: filters });
  };
};
export const toggleFilter = (field) => {
  return async (dispatch, getState) => {
    const selectedFilters = getState().view.selectedFilters;

    const isFilterSelected = selectedFilters.find((f) => f.field === field);
    if (!isFilterSelected) {
      dispatch({
        type: SET_SELECTED_FILTERS,
        selectedFilters: [...selectedFilters, { field, option: '', value: '' }],
      });
    } else {
      dispatch({
        type: SET_SELECTED_FILTERS,
        selectedFilters: selectedFilters.filter((f) => f.field !== field),
      });
    }
  };
};
export const deleteView = (viewId) => {
  return async (dispatch, getState) => {
      const token = await getState().auth.token;
      const result = await Request.delete(`${Env.dbServerUrl}/views/:${viewId}`, null, token);
      return result;
  }
}
export const updateView = (objectType,view) => {
  return async (dispatch, getState) => {
      const token = await getState().auth.token;
      const result = await Request.post(`${Env.dbServerUrl}/${objectType}s/views`,view, token);
      return result;
  }
}
export const addView = (objectType,view) => {
  return async (dispatch, getState) => {
      const token = await getState().auth.token;
      const result = await Request.post(`${Env.dbServerUrl}/${objectType}s/views/add`,view, token);
      return result;
  }
}

