import Text from '../../../components/ui/Text';
import { useState } from 'react';
import './TableMap.scss';
import { useSelector } from 'react-redux';
import LastBookings from '../LastBookings/LastBookings';

const TableMap = ({
    mltc,
    numberOfCheckedListings,
    dateObj,
    setDateObj,
    setListing,
    dispatch,
    setModalEditAvailability,
    listing,
    toggleEditAvailability,
    isMulti,
    notCheckedRowIDs,
    setNotCheckedRowIDs,
    selectionMode,
    setSelectionMode,
    setEndDate,
    setStartCoordinates,
    setToggleSelectedCells
}) => {
    const [showMultiUnits, setShowMultiUnits] = useState(false);

    const multicalendar = useSelector((state) => state.multicalendar);

    const lowLightNotCheckedRows = (e) => {
        const checkedItemID = e.target.id;

        const itemIsChecked = e.target.checked;
        const checklistItems = Array.from(document.getElementsByName('listing-name'));

        const multiunitsLength = multicalendar
            .reduce((acc, mu) => {
                if (mu.multiunits) {
                    acc.push(mu.multiunits.map((m) => m.id));
                }
                return acc;
            }, [])
            .flat(2).length;

        if (checklistItems.filter((cl) => cl.checked).length > 0) {
            if (itemIsChecked) {
                if (
                    notCheckedRowIDs?.length === 0 ||
                    notCheckedRowIDs?.length === multicalendar.length + multiunitsLength
                ) {
                    setNotCheckedRowIDs([Number(checkedItemID)]);
                } else if (
                    notCheckedRowIDs?.length > 0 &&
                    notCheckedRowIDs?.length < multicalendar.length + multiunitsLength
                ) {
                    setNotCheckedRowIDs([...notCheckedRowIDs, Number(checkedItemID)]);
                }
            } else if (!itemIsChecked && notCheckedRowIDs?.includes(Number(checkedItemID))) {
                setNotCheckedRowIDs(notCheckedRowIDs?.filter((id) => id !== Number(checkedItemID)));
            }
        } else if (checklistItems.filter((cl) => cl.checked).length === 0) {
            setNotCheckedRowIDs(
                multicalendar
                    .map((ml) => {
                        if (ml.multiunits) {
                            return [ml.id, ml.multiunits.map((m) => m.id)];
                        } else return ml.id;
                    })
                    .flat(2)
            );
        }
    };

    const iconSelector = (channel) => {
        switch (channel) {
            case 'Airbnb':
                return '/img/airbnb-sm.png';
            case 'Booking.com':
                return '/img/booking-sm.png';
            case 'HomeAway':
                return '/img/homeaway-sm.png';
            case 'RentalsUnited':
                return '/img/ru-sm.png';
            case 'Agoda':
                return '/img/agoda-sm.png';
            default:
                return '/img/fs-sm.png';
        }
    };

    return (
        <>
            <tr
                className={
                    notCheckedRowIDs?.includes(Number(mltc.id)) ? 'listing-row' : 'not-checked-row'
                }
                id={mltc.id}
                key={mltc.id}
            >
                <th colSpan={2} rowSpan={1} className={isMulti ? 'multiunits' : 'singleunit'}>
                    <span className='check-list'>
                        <input
                            type='checkbox'
                            name='listing-name'
                            id={mltc.id}
                            onClick={(e) => numberOfCheckedListings() + lowLightNotCheckedRows(e)}
                        />
                    </span>
                    {mltc.name}
                    {!showMultiUnits && mltc.multiunits ? (
                        <button onClick={() => setShowMultiUnits(true)}>+</button>
                    ) : mltc.multiunits ? (
                        <button onClick={() => setShowMultiUnits(false)}>-</button>
                    ) : (
                        <></>
                    )}
                </th>

                {mltc.calendar.map((cal) => {
                    if (cal.status === 'available' && !cal.removed) {
                        const className =
                            listing &&
                            listing.id === mltc.id &&
                            dateObj.start_date <= cal.date &&
                            cal.date <= dateObj.end_date
                                ? 'available-cell-selected'
                                : 'available-cell';

                        return (
                            <td
                                className={className}
                                name='available-cells'
                                key={cal.id}
                                id={cal.date}
                                onMouseDown={(e) => {
                                    setDateObj({
                                        ...cal,
                                        start_date: cal.date,
                                        end_date: cal.date,
                                        note: '',
                                    });
                                    setListing(mltc);
                                    setModalEditAvailability(false);
                                    setSelectionMode(true);
                                    setStartCoordinates([e.pageX, e.pageY]);
                                    setToggleSelectedCells(false);
                                }}
                                onMouseEnter={() => {
                                    if (selectionMode) {
                                        setEndDate(cal.date);
                                    }
                                }}
                            >
                                <div>
                                    <div>${cal.price}</div>
                                    <span>{cal.min_nights}n</span>
                                </div>
                            </td>
                        );
                    } else if (cal.status === 'booked') {
                        return (
                            <td key={cal.id} className='booked-cell' colSpan={cal.days}>
                                <img
                                    className='channel-icon'
                                    src={iconSelector(cal.reservation.channel)}
                                    alt=''
                                />{' '}
                                {cal.reservation?.guest_name}
                                <span className='tooltiptext'>
                                    <Text>Guest:</Text> {cal.reservation.guest_name}
                                    <br />
                                    <Text>Phone:</Text> {cal.reservation.guest_phone}
                                    <br />
                                    <Text>Email: </Text>
                                    {cal.reservation.guest_email}
                                    <br />
                                    <Text>Check-in:</Text> {cal.reservation.check_in}
                                    <br />
                                    <Text>Check-out</Text>: {cal.reservation.check_out}
                                    <br />
                                    <Text>Nights:</Text> {cal.reservation.nights}
                                    <br />
                                    <Text>Guests:</Text> {cal.reservation.guests}
                                    <br />
                                    <Text>Channel:</Text> {cal.reservation.channel}
                                    <br />
                                    <Text>Payout:</Text> ${cal.reservation.price.toFixed(2)}
                                    <br />
                                </span>
                            </td>
                        );
                    } else if (cal.status === 'unavailable' && !cal.removed) {
                        return (
                            <td
                                className='unavailable-cell'
                                key={cal.id}
                                id={cal.id}
                                onClick={() =>
                                    toggleEditAvailability() + setListing(mltc) + setDateObj(cal)
                                }
                                colSpan={cal.days}
                            >
                                {cal.note}
                                <span className='tooltiptext'>{cal.note}</span>
                            </td>
                        );
                    }
                })}
                {mltc.calendar.length === 0 && !mltc.multiunits ? (
                    <td key={mltc.id} className='no-calendar' colSpan={31}>
                        <Text>No available information for this listing</Text>
                    </td>
                ) : (
                    <></>
                )}
                {mltc.multiunits ? (
                    <td key={mltc.id} className='multi-units' colSpan={30}>
                        <Text>{mltc.multiunits.length} MultiUnits</Text>
                    </td>
                ) : (
                    <></>
                )}
            </tr>
            <tr key={mltc.name}>
                <td colSpan={27} />
                <td className='last-booked-row' colSpan={5}>
                    <div className='last-booked-text'>
                        <Text>last_booked_by</Text>: {mltc.prev_reservation?.guest_name}
                    </div>
                    <span className='tooltiptext'>
                        <LastBookings mltc={mltc} />
                    </span>
                </td>
            </tr>
            {showMultiUnits ? (
                mltc.multiunits?.map((mu) => {
                    return (
                        <TableMap
                            key={mu.id}
                            mltc={mu}
                            isMulti={true}
                            numberOfCheckedListings={numberOfCheckedListings}
                            dateObj={dateObj}
                            setDateObj={setDateObj}
                            setListing={setListing}
                            dispatch={dispatch}
                            setModalEditAvailability={setModalEditAvailability}
                            listing={listing}
                            toggleEditAvailability={toggleEditAvailability}
                            notCheckedRowIDs={notCheckedRowIDs}
                            setNotCheckedRowIDs={setNotCheckedRowIDs}
                            selectionMode={selectionMode}
                            setSelectionMode={setSelectionMode}
                            setEndDate={setEndDate}
                        />
                    );
                })
            ) : (
                <></>
            )}
        </>
    );
};

export default TableMap;
