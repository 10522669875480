import { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import SubmitBtn from '../../../../components/elements/SubmitBtn';
import Text from '../../../../components/ui/Text';
import * as userActions from '../../../../store/actions/users';

const DeleteUserModal = ({ open, onClose, users, user }) => {
  const [deleteUserIds, setDeleteUserIds] = useState([]);
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      setDeleteUserIds({ user_id: user.user_id });
    } else {
      setDeleteUserIds([users.map(user => ({ user_id: user.user_id }))]);
    }
  }, [user, users]);

  const handleDelete = async () => {
    if (requestInProgress) return;

    setRequestInProgress(true);

    if (user) {
      try {
        await dispatch(userActions.deleteUsers([deleteUserIds]));
      } catch (e) {
        return setDidSubmitSucceed(false);
      }
    } else {
      try {
        await dispatch(userActions.deleteUsers([...deleteUserIds[0]]));
      } catch (e) {
        return setDidSubmitSucceed(false);
      }
    }

    setRequestInProgress(false);
    setDidSubmitSucceed(true);

    if (!(window.location.href).includes('workspace')) {
      history.push('/workspace/users-control');
    }
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <div>
      <div className="overlay-styles" />
      <div className="modal-styles">
        <button className="close-button" onClick={onClose}>&times;</button>
        {users.length > 0
          ? <div>
            <label className='the-deleting-user-label'><Text>secondary.delete_users</Text></label>
            <label className='the-deleting-user-paragraph'>
              <Text>tooltip.delete_users_confirmation</Text> ({users.length}) <Text>lowercase.users</Text>?
            </label>
          </div>
          : <div>
            <label className='the-deleting-user-label'><Text>secondary.delete_users</Text></label>
            <p className='the-deleting-user-paragraph'>
              <Text>tooltip.delete_user_confirmation</Text>
              {user.first_name.length < 1 || user.last_name < 1 ? ` ${user.email}` : ` ${user.first_name} ${user.last_name}`} ?
            </p>
          </div>
        }
        <div className="buttons-in-delete-user-modal d-flex align-items-center">
          <SubmitBtn
            submittingState={requestInProgress}
            didSubmitSucceed={didSubmitSucceed}
            submitFunction={handleDelete}
            disabled={!true}
            submittingClassName='delete-modal-submitting-btn'
            submitClassName='delete-modal-submit-btn'
            disabledBtnClassName='delete-modal-submit-btn-disabled'
            failedSubmitClassName='delete-modal-submit-btn-failed-submit'
            submitText='action.delete'
            submittingText='action.deleting'
            promiseSucceedText='action.deleted'
            promiseFailText=' ' />
          <div className='delete-modal-cancel-btn' onClick={onClose}><u><Text>action.cancel</Text></u></div>
        </div>
      </div>
    </div>,
    document.getElementById('centered-modal-portal')
  );
}

export default DeleteUserModal;
