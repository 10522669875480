import './Security.css';
import { useEffect, useState } from "react";
import { PASSWORD_REGEX } from "../../../common/constants";
import Text from "../../../components/ui/Text";
import SideMenuInterface from "../SideMenuInterface/SideMenuInterface";
import * as authActions from '../../../store/actions/auth';
import SubmitBtn from '../../../components/elements/SubmitBtn';
import { useDispatch, useSelector } from 'react-redux';

const Security = () => {
  const dispatch = useDispatch();
  const authUserState = useSelector((state) => state.auth.user);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatching, setIsPasswordMatching] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordUppercaseLetter, setPasswordUppercaseLetter] = useState(false);
  const [passwordLowercaseLetter, setPasswordLowercaseLetter] = useState(false);
  const [passwordSpecialChar, setPasswordSpecialChar] = useState(false);

  useEffect(() => {

    if (password.length > 0 && confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        setIsPasswordMatching(false);
      } else {
        setIsPasswordMatching(true);
      }
    }

    if (password.length === 0 && confirmPassword.length === 0) {
      setIsPasswordMatching(null);
      setIsPasswordValid(null);
    }

    if (password.length > 0 && confirmPassword.length === 0) {
      setIsPasswordMatching(false);
    }

    if (password.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }

    if (password.match(/(?=.*?[A-Z])/)) {
      setPasswordUppercaseLetter(true);
    } else {
      setPasswordUppercaseLetter(false);
    }

    if (password.match(/(?=.*?[a-z])/)) {
      setPasswordLowercaseLetter(true);
    } else {
      setPasswordLowercaseLetter(false);
    }

    if (password.match(/(?=.*?[0-9])/)) {
      setPasswordNumber(true);
    } else {
      setPasswordNumber(false);
    }

    if (password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/)) {
      setPasswordSpecialChar(true);
    } else {
      setPasswordSpecialChar(false);
    }
  }, [password, confirmPassword]);

  const handlePasswordChange = (event) => {
    setIsPasswordValid(PASSWORD_REGEX.test(event.target.value));
    setPassword(event.target.value);
    setDidSubmitSucceed(null);
  };

  const handleUpdateSubmit = async () => {

    if (requestInProgress) return;

    setRequestInProgress(true);
    const data = {
      password
    };

    try {
      await dispatch(authActions.updateUser(authUserState.user_id, data));
    } catch (error) {
      return setDidSubmitSucceed(false);
    }

    setRequestInProgress(false);
    setDidSubmitSucceed(true);
  };

  return (
    <div id='user-security-page'>
      <SideMenuInterface selectedInterface='security' />

      <div className='security-tab-content'>
        <div className='pb-2 user-security-heading'>
          <h4 className='mb-0'>
            <Text>main.security</Text>
          </h4>
        </div>

        <div className='pt-4'>
          <label className='pt-4 mb-1 change-pass-label'><Text>edit.change_password</Text></label>

          <div className='new-password-interface'>
            <label className='mb-0'>
              <Text>edit.new_password</Text>
            </label>
            <input
              type='password'
              className={`form-control ${isPasswordValid === false ? 'invalid-input-style' : ''}`}
              placeholder='Password'
              value={password}
              onChange={event => {
                handlePasswordChange(event);
                setDidSubmitSucceed(null);
              }}
            ></input>
          </div>
        </div>

        <div className='security-password-details-text'>
          <Text>edit.password_must_contain</Text>
          <ul>
            <li style={passwordLength ? { textDecoration: 'line-through' } : {}}><Text>validation.min_pass_characters</Text></li>
            <li style={passwordLowercaseLetter ? { textDecoration: 'line-through' } : {}}><Text>validation.pass_lowercase_letter</Text></li>
            <li style={passwordUppercaseLetter ? { textDecoration: 'line-through' } : {}}><Text>validation.pass_uppercase_letter</Text></li>
            <li style={passwordNumber ? { textDecoration: 'line-through' } : {}}><Text>validation.pass_number</Text></li>
            <li style={passwordSpecialChar ? { textDecoration: 'line-through' } : {}}><Text>validation.pass_special_char</Text></li>
          </ul>
        </div>

        <div className='mb-4 confirm-password-interface'>
          <Text>edit.confirm_new_password</Text>
          <input
            type='password'
            className={`form-control ${isPasswordMatching === false ? 'invalid-input-style' : ''}`}
            placeholder='Confirm new password'
            disabled={password?.length < 1 ? true : false}
            onChange={event => setConfirmPassword(event.target.value)}
          ></input>
          {isPasswordMatching === false ? <p className='mb-0 invalid-input-label'><Text>error.passwords_dont_match</Text></p> : ''}
        </div>

        <SubmitBtn
          submittingState={requestInProgress}
          didSubmitSucceed={didSubmitSucceed}
          submitFunction={handleUpdateSubmit}
          disabled={!(isPasswordValid && isPasswordMatching)}
          submittingClassName='user-profile-saving-btn'
          submitClassName='user-profile-save-btn'
          disabledBtnClassName='user-profile-save-btn-disabled'
          failedSubmitClassName='user-profile-save-btn-failed-submit'
          submitText='action.save'
          submittingText='action.saving'
          promiseSucceedText='action.saved'
          promiseFailText='action.not_saved'
        />
      </div>
    </div>
  );
};

export default Security;