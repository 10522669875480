import './Settings.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CURRENCY_FORMAT, TIMEZONE_OFFSET } from '../../../common/constants.js';
import moment from 'moment';
import SideMenuInterface from '../SideMenuInterface/SideMenuInterface';
import Text from '../../../components/ui/Text';
import SubmitBtn from '../../../components/elements/SubmitBtn';
import * as authActions from '../../../store/actions/auth';
import RefreshApiKeyModal from './RefreshApiKeyModal';
import AccessDenied from '../../AccessDenied/AccessDenied';

const Settings = () => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.auth.customer);
  const userPermissions = useSelector(state => state.auth.user.default_permissions);
  const currencies = Object.entries(CURRENCY_FORMAT);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(null);
  const [refreshRequestInProgress, setRefreshRequestInProgress] = useState(false);
  const [didRefreshSubmitSucceed, setDidRefreshSubmitSucceed] = useState(null);
  const [customerData, setCustomerData] = useState({});
  const [timezone, setTimezone] = useState('');
  const [viewApiKeyModal, setViewApiKeyModal] = useState(false);

  useEffect(() => {
    setCustomerData(customer);

    if (customer.default_date_format === 'm/d/Y') {
      setTimezone(`mm/dd/YY (${moment(Date.now()).format('MM-DD-YYYY')})`)
    } else if (customer.default_date_format === 'Y-m-d') {
      setTimezone(`YY-mm-dd (${moment(Date.now()).format('YYYY-MM-DD')})`)
    } else {
      setTimezone(`dd.mm.YY (${moment(Date.now()).format('DD-MM-YYYY')})`)
    }
  }, [customer]);

  const handleSubmit = async () => {
    const data = {
      name: customerData.workspace_name,
      default_date_format: customerData.default_date_format,
      default_currency: customerData.currency,
      timezone: customerData.timezone,
    }

    if (requestInProgress) return;

    setRequestInProgress(true);

    try {
      await dispatch(authActions.updateWorkspace(data));
    } catch (e) {
      return setDidSubmitSucceed(false);
    };

    setRequestInProgress(false);
    setDidSubmitSucceed(true);
  };

  const handleRefreshKey = async () => {
    if (refreshRequestInProgress) return;

    setRefreshRequestInProgress(true);

    try {
      await dispatch(authActions.refreshApiKey());
    } catch (e) {
      return setDidRefreshSubmitSucceed(false);
    };

    setRefreshRequestInProgress(false);
    setDidRefreshSubmitSucceed(true);
  };

  if (!userPermissions.hasOwnProperty('Workspace settings')) {
    return <AccessDenied />
  }

  return (
    <div id='workspace-settings'>
      <SideMenuInterface selectedInterface='settings' />

      <div className='workspace-settings-page-content'>
        <div className='pb-2 workspace-settings-heading'>
          <h4>
            <Text>main.settings</Text>
          </h4>
        </div>

        <div className='pt-4'>
          <div className='workspace-name-field'>
            <label className='mb-0 workspace-settings-label'><Text>main.name</Text></label>

            <div className='input-group'>
              <input type='text'
                className='form-control'
                placeholder="Customer's username"
                value={customerData.workspace_name || ''}
                onChange={(event) => setCustomerData({ ...customerData, workspace_name: event.target.value })}
              />

              <span className='input-group-text' id='basic-addon2'>.fsapp.io/customer</span>
            </div>
          </div>

          <div className='workspace-date-format'>
            <label className='mb-0 workspace-settings-label'><Text>secondary.date_format</Text></label>
            <select
              className='form-control'
              id='date-format-field'
              value={customerData?.default_date_format || ''}
              onChange={event => setCustomerData({ ...customerData, default_date_format: event.target.value })}>
              {/** on the next line - selected was changed to defaultValue because of a warning.. seems to work fine, but revert if it creates an issue */}
              <option defaultValue disabled hidden>{timezone || 'Select a timezone'}</option>
              <option value='m/d/Y'>mm/dd/YY ({moment(Date.now()).format('MM-DD-YYYY')})</option>
              <option value='d.m.Y'>dd.mm.YY ({moment(Date.now()).format('DD-MM-YYYY')})</option>
              <option value='Y-m-d'>YY-mm-dd ({moment(Date.now()).format('YYYY-MM-DD')})</option>
            </select>
          </div>

          <div className='d-flex currency-timezone-container'>
            <div className='workspace-currency'>
              <label className='mb-0 workspace-settings-label'><Text>secondary.currency</Text></label>
              <select
                className='form-control'
                id='currency-field'
                value={customerData?.currency || ''}
                onChange={event => setCustomerData({ ...customerData, currency: event.target.value })}>
                {/** on the next line - selected was changed to defaultValue because of a warning.. seems to work fine, but revert if it creates an issue */}
                <option defaultValue disabled hidden>{customerData?.currency || 'action.choose_currency'}</option>
                {currencies.map((element, index) => {
                  return <option key={index}>{element[0]}</option>
                })}
              </select>
            </div>

            <div className='workspace-timezone'>
              <label className='mb-0 workspace-settings-label'><Text>secondary.timezone</Text></label>
              <select
                className='form-control pr-1'
                id='timezone-field'
                value={customerData.timezone}
                onChange={event => setCustomerData({ ...customerData, timezone: event.target.value })}>
                {/** on the next line - selected was changed to defaultValue because of a warning.. seems to work fine, but revert if it creates an issue */}
                <option defaultValue disabled hidden>{customerData?.timezone || 'action.choose_timezone'}</option>
                {TIMEZONE_OFFSET.map((element, index) => {
                  return <option key={index}>{element.offset}</option>
                })}
              </select>
            </div>
          </div>

          <div className='pb-4 d-flex  workspace-api-key '>
            <label className='mb-2 workspace-settings-label'><Text>secondary.api_key</Text></label>
            <div className='d-flex api-key-textarea-button-container'>
              <textarea className='workspace-api-key-field' disabled={true} value={customerData['api-key']} />

              {customer.tier === 'free'
                ? <Link to='/workspace/billing' className='refresh-api-key-btn'>
                  <label><Text>action.refresh_key</Text></label>
                </Link>
                : <div className='refresh-api-key-btn' onClick={() => {
                  setDidRefreshSubmitSucceed(null);
                  setViewApiKeyModal(true);
                }}>
                  <label><Text>action.refresh_key</Text></label>
                </div>}
            </div>
          </div>

          <SubmitBtn
            submittingState={requestInProgress}
            didSubmitSucceed={didSubmitSucceed}
            submitFunction={handleSubmit}
            disabled={false}
            submittingClassName='workspace-saving-btn'
            submitClassName='workspace-save-btn'
            disabledBtnClassName='workspace-save-btn-disabled'
            failedSubmitClassName='workspace-save-btn-failed-submit'
            submitText='action.save'
            submittingText='action.saving'
            promiseSucceedText='action.saved'
            promiseFailText='action.not_saved'
          />

          <RefreshApiKeyModal
            submittingState={refreshRequestInProgress}
            didSubmitSucceed={didRefreshSubmitSucceed}
            submitFunction={handleRefreshKey}
            open={viewApiKeyModal}
            onClose={() => setViewApiKeyModal(false)} />
        </div>
      </div>
    </div>
  );
};

export default Settings;