import './SideMenuInterface.css';
import Text from '../../../components/ui/Text';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasPermissions } from '../../../utils/common';

const SideMenuInterface = (props) => {
  const selectedInterface = props.selectedInterface;
  const userPermissions = useSelector(state => state.auth.user.default_permissions);

  return (
    <div className='user-settings-side-menu'>
      <div className='side-menu-interface'>
        <div className='side-menu-first-submenu'>
          <p className='side-menu-first-heading'><Text>main.my_profile</Text></p>
          <div className='submenu-options'>
            <p>
              <Link className='submenu-option-link' to='/user' style={{ color: selectedInterface === 'profile' ? '#009933' : '' }}>
                <Text>main.profile</Text>
              </Link>
            </p>

            <p>
              <Link className='submenu-option-link' to='/user/security' style={{ color: selectedInterface === 'security' ? '#009933' : '' }}>
                <Text>main.security</Text>
              </Link>
            </p>
          </div>
        </div>

        {hasPermissions(userPermissions, 'Manage users', 'read') || hasPermissions(userPermissions, 'Billing', 'read')
          ?
          <div className='side-menu-second-submenu'>
            <p className='side-menu-second-heading'><Text>main.workspace</Text></p>
            <div className='submenu-options'>
              {hasPermissions(userPermissions, 'Manage users', 'read') &&
                <p>
                  <Link className='submenu-option-link' to='/workspace/users-control' style={{ color: selectedInterface === 'users' ? '#009933' : '' }}>
                    <Text>main.users</Text>
                  </Link>
                </p>
              }
              {hasPermissions(userPermissions, 'Billing', 'read') &&
                <p>
                  <Link className='submenu-option-link' to='/workspace/billing' style={{ color: selectedInterface === 'billing' ? '#009933' : '' }}>
                    <Text>main.billing</Text>
                  </Link>
                </p>
              }
              {
                hasPermissions(userPermissions, 'Workspace settings', 'read') &&
                <p>
                  <Link className='submenu-option-link' to='/workspace' style={{ color: selectedInterface === 'settings' ? '#009933' : '' }}>
                    <Text>settings</Text>
                  </Link>
                </p>
              }
            </div>
          </div>
          : ''
        }
      </div>
    </div >

  );
};

export default SideMenuInterface;