import {Fragment} from 'react';

const ListTableItemBool = (props) => {
  const { value } = props;

  if (typeof value === 'undefined') {
    return '';
  }

  return <Fragment>{parseInt(value) === 0 ? 'No' : 'Yes'}</Fragment>;
};

export default ListTableItemBool;
