// import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FSSelect from './FSSelect.js';
import './css/table.css';

import * as commonActions from '../../utils/common';
import * as listActions from '../../store/actions/list';

const itemsPerPageOptions = [10, 20, 30, 50, 100];

const FSTablePagination = (props) => {
  // const [canIncrPage, setCanIncrPage] = useState(false);
  // const [canDecrPage, setCanDecrPage] = useState(false);
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.list.page);
  const itemsPerPage = useSelector((state) => state.list.itemsPerPage);
  const totalItemsCount = useSelector((state) => state.list.total);

  // useEffect(() => {
  //   setCanIncrPage(currentPage * itemsPerPage + 1 < totalItemsCount);
  //   setCanDecrPage(currentPage > 1);
  // }, [currentPage, totalItemsCount, itemsPerPage]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) {
      return;
    }

    if ((newPage - 1) * itemsPerPage > totalItemsCount) {
      return;
    }

    handlePageChangee(newPage, itemsPerPage);
  };
  const handlePrevPageClick = () => {
    if (currentPage === 1) {
      return;
    }
    // if (!canDecrPage) {
    //   return;
    // }
    handlePageChange(parseInt(currentPage) - 1);
    dispatch(listActions.setPageNumber(parseInt(currentPage) - 1));
    dispatch(listActions.getItems(props.objectType));
  };

  const handleNextPageClick = async () => {
    // if (!canIncrPage) {
    //   return;
    // }
    handlePageChange(parseInt(currentPage) + 1);
    await dispatch(listActions.setPageNumber(parseInt(currentPage) + 1));
    dispatch(listActions.getItems(props.objectType));
  };
  const handleItemsPerPageChange = (event) => {
    const itemsPerPage = parseInt(event.target.value);

    if (itemsPerPageOptions.indexOf(itemsPerPage) === -1) {
      return;
    }

    handlePageChangee(currentPage, itemsPerPage);
  };
  const handlePageChangee = async (currentPage, itemsPerPage) => {
    let params = commonActions.getURLParams();

    if (currentPage === 1) {
      delete params['page'];
    } else {
      params['page'] = currentPage;
    }

    if (itemsPerPage === 20) {
      delete params['perPage'];
    } else {
      params['perPage'] = itemsPerPage;
    }
    await dispatch(listActions.setItemsPerPage(itemsPerPage, props.objectType));
    dispatch(listActions.getItems(props.objectType));
    commonActions.buildUrlQuery(params);
  };

  const rowsPerPageOptions = itemsPerPageOptions.map((n) => {
    return { value: n, text: n };
  });

  if (parseInt(totalItemsCount) === 0) {
    return '';
  }


  const resultStart = (parseInt(currentPage) - 1) * itemsPerPage + 1;
  // const resultEnd = Math.min(resultStart + itemsPerPage - 1, totalItemsCount);

  return (
    <div className="table-pagination">
      <div className="rows-text mr-5">Rows per page:</div>
      <div className="w-auto">
        <FSSelect
          options={rowsPerPageOptions}
          selected={itemsPerPage}
          onChange={(ev) => handleItemsPerPageChange(ev)}
        />
      </div>
      <div className="count-text ml-20">
        {resultStart}-{resultStart + itemsPerPage - 1} {/* of {totalItemsCount} */}
      </div>
      <div>
        <button
          className={`page-nav prev-page${currentPage === 1 ? ' disabled' : ''}`}
          onClick={() => handlePrevPageClick()}
        >
          <i className="fa fa-angle-left"></i>
        </button>
        <button
          className={`page-nav next-page`}
          onClick={() => handleNextPageClick()}
        >
          <i className="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
  );
};

export default FSTablePagination;
