import Text from "../ui/Text";

const SubmitBtn = (props) => {

  return (
    props.didSubmitSucceed === null
      ? <div className={props.submittingState ? props.submittingClassName : props.disabled ? props.disabledBtnClassName : props.submitClassName}
        onClick={() => props.submitFunction()}>
        <label className='mb-0'>
          <Text>{props.submittingState ? props.submittingText : props.submitText}</Text>
        </label>
        {props.submittingState ? <i className="fa fa-circle-o-notch fa-spin"></i> : ''}
      </div>
      : props.didSubmitSucceed === true
        ? <div className={props.submittingClassName}>
          <label>
          <Text>{props.promiseSucceedText}</Text>
          </label>
          <img src='/img/check.svg' alt='' />
        </div>
        : <div className={props.failedSubmitClassName}>
          <label className='mr-2'>
          <Text>{props.promiseFailText}</Text>
          </label>
          <img src='/img/white-cross.svg' alt='' />
        </div>
  );
};

export default SubmitBtn;