import React from 'react';

import './css/style.css';

const FSRadioGroup =(props)=> {
    return (
        <React.Fragment>
            {props.options.map((option, index) => {
                return <label key={index} style={{marginRight: '10px'}}>
                    <input
                        style={{marginTop: '10px'}}
                        className={props.className}
                        type="radio"
                        name="radio"
                        value={option.value}
                        onChange={props.onChange}
                        checked={parseInt(props.value) === parseInt(option.value)}
                    />
                    {option.text}
                </label>
            })}
        </React.Fragment>
    )
}

export default FSRadioGroup;