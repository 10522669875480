import Request from '../../utils/Request';

export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';

export const setTranslations = (locale) => {
    return async dispatch => {

        if (!locale) {
            locale = 'en';
        }

        const translations = await Request.get('https://app.fsapp.io/translations?lang=' + locale);

        dispatch({type: SET_TRANSLATIONS, translations: translations});
    }
}