import { useSelector } from 'react-redux';
import ListTableItem from './ListTableItem';

const ListTable = (props) => {
    const items = useSelector((state) => state.list.items);

    return(
        <tbody>
        {items.map((item,index)=>{
            return(
                <ListTableItem key={index} item={item} selectedIds={props.selectedIds} objectType={props.objectType}/>
            )
        })}
        </tbody>
    )
}

export default ListTable;