import './ThanksForSigningUp.css';
import { default as fs_logo } from '../../assets/icons/fs-logo.svg';
import { default as error_try_again } from '../../assets/icons/error-try-again.svg';
// import { default as checked_checkbox } from '../../assets/icons/checked-checkbox.svg';
// import { default as empty_checkbox } from '../../assets/icons/empty-checkbox.svg';
import PhoneInput from 'react-phone-input-2';
import { useState, useEffect } from 'react';
import * as authActions from '../../store/actions/auth';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { NAME_REGEX } from '../../common/constants';

const ThanksForSigningUp = () => {
    // const [acceptTerms, setAcceptTerms] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstNameValid, setFirstNameValid] = useState(false);
    const [lastNameValid, setLastNameValid] = useState(false);
    const [phone, setPhone] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const newUser = useSelector((state) => state.auth.user);

    useEffect(() => {   
        if (firstName.match(NAME_REGEX)) {
            setFirstNameValid(true);
        } else {
            setFirstNameValid(false);
        }

        if (lastName.match(NAME_REGEX)) {
            setLastNameValid(true);
        } else {
            setLastNameValid(false);
        }
    }, [firstName, lastName]);

    const setNamesAndPhone = async () => {
        setIsRequestPending(true);
        try {
            await dispatch(authActions.createNamesAndPhone(firstName, lastName, phone, newUser.user_id));
            setIsRequestPending(false);
            history.push('/questionnaire');
        } catch (error) {
            setIsRequestPending(false);
            setDidPromisesSucceed(false);
        }
    };

    return (
        <div className='the-whole-sign-up-page'>
            <div className='left-side-of-sign-up'>
                <div className="d-flex">
                    <img src={fs_logo} alt='' />
                    {!didPromisesSucceed ?
                        <div className="error-try-again-holder ml-auto">
                            <div className="error-try-again-box">
                                <img className="error-try-again-icon" src={error_try_again} alt='' />
                            </div>
                            <div className="error-try-again-text-box">
                                <div className="ml-3">Error occured! Please try again.</div>
                                <div className="close-error-try-again ml-auto mr-3" onClick={() => setDidPromisesSucceed(true)}>&times;</div>
                            </div>
                        </div>
                    :
                        ''
                    }
                </div>
                <div className='sign-up-mid-section'>
                    <div className='thanks-for-signing-up-header-text'>Thanks for signing up!</div>
                    <div className='thanks-for-signing-up-details'>We will need just a few more details and you will be ready to go.</div>
                    <div className='thanks-for-signing-up-input-container'>
                        <div className='thanks-for-signing-up-names-container'>
                            <div className='thanks-for-signing-up-first-name'>
                                <label>First name</label>
                                <input
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    type='text'
                                    className='form-control'>
                                </input>
                            </div>
                            <div className='thanks-for-signing-up-last-name'>
                                <label>Last name</label>
                                <input
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    type='text'
                                    className='form-control'>
                                </input>
                            </div>
                        </div>
                    </div>
                    <div className='thanks-for-signing-up-phone'>
                        <label>Phone</label>
                        <PhoneInput country={'gb'} onChange={phone => setPhone(phone)} />
                    </div>
                    {/* <div className='accept-the-terms-container d-flex'>
                        {acceptTerms ?
                            <img src={checked_checkbox} className='cursor-pointer-checkbox' alt='' onClick={() => setAcceptTerms(!acceptTerms)} />
                        :
                            <img src={empty_checkbox} className='cursor-pointer-checkbox' alt='' onClick={() => setAcceptTerms(!acceptTerms)} />
                        } 
                        <div className='accept-the-terms-text'>Sure, send me product news and offers by email. I know I can unsubscribe at any time.</div>
                    </div> */}
                    {phone && firstNameValid && lastNameValid ?
                        <div className='set-your-password-next-button' onClick={setNamesAndPhone} style={{marginRight: '365px'}}>
                            <div className='text-inside-set-your-password-next-button'>Next</div>
                            {isRequestPending ? <i className="fa fa-circle-o-notch fa-spin" style={{marginLeft: '120px', position: 'relative', top: '-10px'}}></i> : ''}
                        </div>
                    :
                        <div className='set-your-password-next-button-disabled' style={{marginRight: '365px'}}>
                            <div className='text-inside-set-your-password-next-button-disabled'>Next</div>
                        </div>
                    }
                </div>
            </div>
            <div className='sign-up-page-image' style={{backgroundPosition: 'center center'}}></div>
        </div>
    );
};

export default ThanksForSigningUp;
