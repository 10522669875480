import Request from '../../utils/Request';
import Env from '../../Env';

export const SET_USER = 'SET_USER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_USER_THEME = 'SET_USER_THEME';
export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';

export const setCurrentUser = (token) => {
  return async (dispatch, getState) => {
    const result = await Request.get(`${Env.apiDomainSSO}/workspace-user`, token);
    result.default_permissions = {};

    if (result.role_permissions !== null) {
      result.role_permissions.forEach(element => { result.default_permissions[element.permission_name] = element.access });
      delete result.role_permissions;
    }

    dispatch({ type: SET_USER, user: result, token });
  }
};

export const setPermissions = () => {
  const permissions = [
    "inbox.read",
    "reservations.read",
    "reservations.write",
    "reporting.read",
    "multicalendar.read",
    "listings.read",
    "reviews.read",
    "guests.read",
    "contacts.read",
    "settings.read",
    "transactions.read",
    "apps.read",
    "RentalsUnited",
    "DudaPage",
    "DudaTemplatesPage",
    "EditDudaSite",
    "ConnectAccount",
    "Updates",
    "NewUser",
    "SingleGuest",
    "ListPage",
    "ViewPage",
    "SingleContact",
    "Customer",
    "UsersList"
  ];

  return async (dispatch, getState) => {
    // const token = getState().auth.token;
    // const result = await Request.get(`${Env.apiDomainSSO}/workspace-user/permissions`, token);
    // dispatch({ type: SET_PERMISSIONS, permissions: result });
    dispatch({ type: SET_PERMISSIONS, permissions: Object.values(permissions) });
  };
};

export const getUser = (id) => {
  return async (dispatch, getState) => {
    const token = await getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/user/${id}`, token);
    return result;
  };
};

export const setCustomer = (token) => {

  return async (dispatch, getState) => {
    const token = await getState().auth.token;
    const result = await Request.get(`${Env.apiDomainSSO}/workspace`, token);
    result.id = result.workspace_id;
    dispatch({ type: SET_CUSTOMER, customer: result });
  };
};

// export const updateUser = (id, data) => {
//   return async (dispatch, getState) => {
//     const token = await getState().auth.token;

//     // PUT request to our backend to update user
//     const result = await Request.put(`${Env.dbServerUrl}/user/${id}`, data, token);

//     // PATCH request to Auth0 to Update user https://auth0.com/docs/users/update-user-profiles-using-your-database
//     dispatch({ type: SET_CURRENT_USER, user: result, token });
//   };
// };
export const getUserByEmail = () => {
  return async (dispatch, getState) => {
    const token = await getState().auth.token;
    const result = await Request.get(`${Env.dbServerUrl}/user/email`, token);
    return result;
  };
};
export const updateTheme = (theme) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_USER_THEME, theme: theme });
  };
};
export const updateLayout = (navLayout) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_USER_NAVIGATION, navigation: navLayout });
  };
};

export const createUser = (email) => {
  return async (dispatch, getState) => {
    const result = await Request.post(`${Env.apiDomainSSO}/users`, { email });
    dispatch({ type: SET_USER, user: result });
  }
};

export const createPassword = (password, code, userId) => {
  return async () => {
    await Request.put(`${Env.apiDomainSSO}/users/${userId}/set-password`, { password, code });
  }
}

export const setUserPassword = (password, userId) => {
  return async (dispatch, getState) => {
    const user = await getState().auth.user;
    await Request.put(`${Env.apiDomainSSO}/users/${userId}`, { password });
    dispatch({ type: SET_USER, user: { ...user, has_password: true } })
  }
}

export const createWorkspace = (name, user_id, source) => {
  return async (dispatch, getState) => {
    const result = await Request.post(`${Env.apiDomainSSO}/workspace`, { name, user_id, source });
    dispatch({ type: SET_CUSTOMER, customer: result })
  }
}

export const createNamesAndPhone = (first_name, last_name, phone_number, userId) => {
  return async (dispatch, getState) => {
    const result = await Request.put(`${Env.apiDomainSSO}/users/${userId}`, { first_name, last_name, phone_number });
    dispatch({ type: SET_USER, user: result })
  }
}

export const setSubscriptionId = (workspaceId) => {
  return async (dispatch, getState) => {
    const token = await getState().auth.token;
    const result = await Request.get(`${Env.apiDomainSSO}/chargebee/subscription/${workspaceId}`, token);
    dispatch({ type: SET_SUBSCRIPTION_ID, subscriptionId: result })
  }
}

export const updateUser = (id, data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    await Request.put(`${Env.apiDomainSSO}/users/${id}`, data, token);

    const user = getState().auth.user;
    const updatedUser = { ...user, ...data };

    dispatch({ type: SET_USER, user: updatedUser, token });
  }
};

export const updateWorkspace = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    await Request.put(`${Env.apiDomainSSO}/workspace`, data, token);
    
    const customer = getState().auth.customer;
    const updatedCustomer = { ...customer, ...data };

    dispatch({ type: SET_CUSTOMER, customer: updatedCustomer });
  };
};

export const refreshApiKey = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const newKey = await Request.post(`${Env.apiDomainSSO}/workspace/api-key`, '', token);
    const currentCustomer = getState().auth.customer;
    const updatedCustomer = { ...currentCustomer, 'api-key': newKey };

    dispatch({ type: SET_CUSTOMER, customer: updatedCustomer });
  }
};

export const setAllPermissions = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const result = await Request.get(`${Env.apiDomainSSO}/permissions`, token);

    dispatch({ type: SET_ALL_PERMISSIONS, allPermissions: result });
  }
};

export const resetPassword = (email) => {
  return async () => {
    await Request.post(`${Env.apiDomainSSO}/users/${email}/reset-password`);
  }
};

export const submitQuestionnaire = (data) => {
  return async () => {
    await Request.post(`${Env.apiDomainSSO}/questionnaire`, data);
  }
};

export const updateAccountStatusOnLogin = (userId, data) => {
  const status = `"${data}"`;
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const user = getState().auth.user;

    await Request.put(`${Env.apiDomainSSO}/workspace/users/${userId}/status`, status, token);

    user.status = data;

    dispatch({ type: SET_USER, user: user });
  }
};