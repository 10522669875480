import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import PagePreloader from '../../components/Base/PagePreloader';
import FeatherIcon from 'feather-icons-react';
import ListFiltersSection from '../../components/list/ListFiltersSection';
import ListTableSection from '../../components/list/ListTableSection';
import ListHeader from '../../components/list/table/ListHeader';
import TechnicalError from '../TechnicalError/TechnicalError';
import * as listActions from '../../store/actions/list';
import './ListPageFilters.css';
import './ListPage.css';

const ListPage = (props) => {
  const { objectType } = props;
  const location = useLocation();
  const history = useHistory();
  // const user = useSelector((state) => state.auth.user);
  const navLayout = useSelector((state) => state.auth.navigation);
  const permissions = useSelector((state) => state.auth.permissions);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchPattern, setSearchPattern] = useState('');
  const [didPromisesSucceed, setDidPromisesSucceed] = useState(true);
  const dispatch = useDispatch();

  if (!permissions.includes('ListPage')) {
      history.push('/access-denied')
  }

  const parsed = queryString.parse(location.search);

  // const isJsonParsable = (string) => {
  //   try {
  //     JSON.parse(string);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // };
  // const isUrlValid = isJsonParsable(Object.values(parsed));
  // useEffect(() => {
  //   if (!isUrlValid) {
  //     history.push('/listings');
  //   }
  // }, [location.search, history, isUrlValid]);

  const objectValues = Object.values(parsed);
  
  useEffect(() => {
    const initData = async () => {
      // const views = await dispatch(listActions.getViews(objectType));
      const views = [{columns: [{field: "nickname", name: "Listing", order: 1, prefix: "l", sortable: true, type: "listing"},
      {field: "channels", name: "Channels", order: 2, prefix: null, sortable: false, type: "channels"},
      {field: "address/address", name: "Full address", order: 3, prefix: null, sortable: false, type: "text"},
      {field: "prices/dailyPrice", name: "Base price", order: 87, prefix: null, sortable: true, type: "price"}],
      default: 1, dv: "2", favourite: 0, filters: [], id: "mVlmQcLHFGO9RALHf3axKt1Z6x05Gdt2", isMine: false, name: "Active listings"}]

      try {
        if (parsed && parsed.v) {
          await dispatch(listActions.setSelectedView(JSON.parse(objectValues[0])));
        } else {
          await dispatch(listActions.setSelectedView(views[0].id));
        }
      } catch (error) {
        setDidPromisesSucceed(false);
        return;
      }
      
      try {
        await dispatch(listActions.getFilters(objectType));
        await dispatch(listActions.getColumns(objectType));
      } catch (error) {
        setDidPromisesSucceed(false);
        return;
      }

      if (parsed && parsed.f) {
        try {
          dispatch(listActions.setInitialSelectedFilters(JSON.parse(objectValues[0])));
        } catch (error) {
          setDidPromisesSucceed(false);
          return;
        }
      }


      try {
        await dispatch(listActions.getItems(objectType));
      } catch (error) {
        setDidPromisesSucceed(false);
      }

      setIsLoaded(true);
    };
    
    initData();
  }, [location.search]);

  if (!isLoaded) {
    return <PagePreloader />;
  }

  if (didPromisesSucceed === false) {
    return <TechnicalError />;
  }

  return (
    <div
      className={`body-wrapper ${
        navLayout === 'Vertical' ? 'body-wrapper-two' : ''
      }`}
    >
      <div className="body-sidebar">
        <div className="body-sidebar-header">
          <FeatherIcon icon="search" />
          <div className="search-form">
            <input
              type="search"
              className="form-control"
              placeholder="Search Filter"
              onChange={(ev) => setSearchPattern(ev.target.value.toLowerCase())}
            ></input>
          </div>
        </div>
        <div className="body-sidebar-body ps">
          <div id="tabContact" className="tab-pane fade active show">
            <ListFiltersSection objectType={objectType} searchPattern={searchPattern} />
          </div>
        </div>
      </div>
      <div className="body-content">
        <div className="body-content-header">
          <ListHeader objectType={objectType} />
        </div>
        <div className="body-content-body">
          <ListTableSection pagination="true" objectType={objectType} />
        </div>
      </div>
    </div>
  );
};

export default ListPage;
