import Text from "../../../../components/ui/Text";

const RolesInfo = ({ currentRole }) => {
    if (currentRole === 'CEO') {
        return (
            <label><Text>roles.ceo</Text></label>
        );
    } else if (currentRole === 'Members') {
        return (
            <label><Text>roles.members</Text></label>
        );
    } else if (currentRole === 'Housekeeper') {
        return (
            <label><Text>roles.housekeeper</Text></label>
        );
    } else if (currentRole === 'Revenue Manager') {
        return (
            <label><Text>roles.revenue_manager</Text></label>
        );
    } else if (currentRole === 'Listing Owner') {
        return (
            <label><Text>roles.listing_owner</Text></label>
        );
    }
};

export default RolesInfo;
