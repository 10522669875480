import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { addUnavailableBlock, changePriceAndMinNights } from '../../../store/actions/multiCalendar';
// import { useOutsideAlerter } from '../../ui/ClickOut';
import './EditAvailabilityMenu.scss';
import Text from '../../../components/ui/Text';

const EditAvailabilityModal = ({ toggle, modal, dateObj, clearCellSelection, listing }) => {
    const [newAvailability, setNewAvailability] = useState({ ...dateObj });

    // const wrapperRef = useRef(null);
    // useOutsideAlerter(wrapperRef,toggle);

    useEffect(() => {
        setNewAvailability({ ...dateObj });
    }, [dateObj]);

    const multicalendar = useSelector((state) => state.multicalendar);

    const dispatch = useDispatch();

    //Send the info to the Backend and get the response with new multicalendar
    const saveChanges = (listing, newInfo) => {
   
        const listingOriginalDates = listing.calendar?.filter(
            (cal) =>
                cal.date >= newInfo.start_date &&
                cal.end_date <= newInfo.end_date &&
                cal.status === 'available'
        );

        const listingUnavailableOriginalDates = listing.calendar.filter(
            (cal) => cal.start_date <= newInfo.start_date && cal.end_date >= newInfo.end_date
        );

        //Trying to edit over a block which is booked
        if (listingOriginalDates.some((d) => d.status === 'booked')) {
            return alert("You can't change a booking!");
        }
        // New block of unavailability over available block
        if (
            listingOriginalDates.every((d) => d.status === 'available') &&
            newInfo.status === 'unavailable' &&
            listingUnavailableOriginalDates.every((d) => d.status !== 'unavailable')
        ) {
            newInfo.days =
                (Date.parse(newInfo.end_date) - Date.parse(newInfo.start_date)) / 86400000 + 1;
            dispatch(addUnavailableBlock(listing, newInfo, multicalendar));

            //Change Price || Min Nights of available date(s)
        } else if (
            newInfo.status === 'available' &&
            listingOriginalDates.every((d) => d.status === 'available') &&
            (listingOriginalDates.some((d) => d.min_nights !== newInfo.min_nights) ||
                listingOriginalDates.some((d) => d.price !== newInfo.price))
        ) {
            dispatch(changePriceAndMinNights(listing, newInfo, multicalendar));
        }
    };

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={modal ? 'side-menu' : 'hidden-side-menu'}
        >
            <h3>
                <Text>Edit the current listing:</Text>
            </h3>
            <button className='close-button' onClick={() => toggle() + clearCellSelection()}>
                X
            </button>
            <h2>{listing.name}</h2>
            <div className='side-menu-content'>
                <div>
                    <label htmlFor='from' style={{ fontSize: '20px' }}>
                        <Text>From</Text>
                    </label>
                    <input
                        type='date'
                        name='from'
                        style={{ fontSize: '20px' }}
                        value={newAvailability.start_date}
                        onChange={(e) =>
                            setNewAvailability({ ...newAvailability, start_date: e.target.value })
                        }
                    />
                    <br />
                    <label htmlFor='to' style={{ fontSize: '20px' }}>
                        <Text>To</Text>
                    </label>
                    <input
                        type='date'
                        name='to'
                        style={{ fontSize: '20px' }}
                        value={newAvailability.end_date}
                        onChange={(e) =>
                            setNewAvailability({ ...newAvailability, end_date: e.target.value })
                        }
                    />
                    <br />
                    <br />
                    <span style={{ fontSize: '20px' }}>
                        {newAvailability.status === 'available' ? 'Available' : 'Unavailable'}
                    </span>
                    <label className='switch'>
                        <input
                            type='checkbox'
                            checked={newAvailability.status === 'available'}
                            onChange={() => {
                                if (newAvailability.status === 'available') {
                                    setNewAvailability({
                                        ...newAvailability,
                                        status: 'unavailable',
                                    });
                                } else if (newAvailability.status === 'unavailable') {
                                    setNewAvailability({ ...newAvailability, status: 'available' });
                                }
                            }}
                        />
                        <span className='slider round'></span>
                    </label>
                    <br />
                    <label htmlFor='price'>
                        <Text>Price</Text>
                    </label>
                    <input
                        type='number'
                        name='price'
                        id='price'
                        placeholder='$'
                        value={newAvailability.price || ''}
                        onChange={(e) =>
                            setNewAvailability({
                                ...newAvailability,
                                price: Number(e.target.value),
                            })
                        }
                    />
                    <br />
                    <label htmlFor='min-nights'>
                        <Text>Min Nights</Text>
                    </label>
                    <input
                        type='number'
                        name='min-nights'
                        id='min-nights'
                        placeholder='minimum nights stay'
                        value={newAvailability.min_nights || ''}
                        onChange={(e) =>
                            setNewAvailability({
                                ...newAvailability,
                                min_nights: Number(e.target.value),
                            })
                        }
                    />
                    <br />
                    <label htmlFor='Note'>
                        <Text>Note</Text>
                    </label>
                    <input
                        type='text'
                        id='note'
                        name='note'
                        placeholder={'Any Notes?'}
                        value={newAvailability.note}
                        onChange={(e) =>
                            setNewAvailability({ ...newAvailability, note: e.target.value })
                        }
                    />
                </div>
                <br />
                <br />
                <Button
                    color='primary'
                    onClick={() =>
                        saveChanges(listing, newAvailability) + toggle() + clearCellSelection()
                    }
                >
                    <Text>Save</Text>
                </Button>{' '}
                <Button color='secondary' onClick={() => toggle() + clearCellSelection()}>
                    <Text>Cancel</Text>
                </Button>
            </div>
        </div>
    );
};

export default EditAvailabilityModal;
